export const TableHeader = [
  {
    label: 'Jornada Laboral',
    key: 'coverage_name',
    align: 'left',
    alignFlex: 'flex-start',
    flexGrow: 2,
  },
  {
    label: 'Duración',
    key: 'duration',
    align: 'left',
    alignFlex: 'start',
    flexGrow: 1,
  },
  {
    label: 'Servicio',
    key: 'service_name',
    align: 'left',
    alignFlex: 'start',
    flexGrow: 1,
  }
]

export const TableHeaderDiscount = [
  {
    label: 'Servicio',
    key: 'service_id',
    align: 'left',
    alignFlex: 'flex-start',
    flexGrow: 2,
  },
  {
    label: 'Inicio',
    key: 'start',
    align: 'left',
    alignFlex: 'start',
    flexGrow: 1,
  },
  {
    label: 'Término',
    key: 'end',
    align: 'left',
    alignFlex: 'start',
    flexGrow: 1,
  },
  {
    label: 'Día de la semana',
    key: 'day_of_week',
    align: 'left',
    alignFlex: 'start',
    flexGrow: 1,
  },
  {
    label: 'Descuento (%)',
    key: 'discount',
    align: 'left',
    alignFlex: 'start',
    flexGrow: 1,
  }
]

export const TableData = [
  {
    id: 1,
    day: 'Lunes',
    initial_hour: '07:00 hs',
    end_hour: '17:00 hs',
    service: 'Kerosene',
  },
  {
    id: 2,
    day: 'Martes',
    initial_hour: '07:00 hs',
    end_hour: '17:00 hs',
    service: 'Kerosene',
  },
  {
    id: 3,
    day: 'Miércoles',
    initial_hour: '07:00 hs',
    end_hour: '17:00 hs',
    service: 'Kerosene',
  },
  {
    id: 4,
    day: 'Lunes - Viernes',
    initial_hour: '07:00 hs',
    end_hour: '17:00 hs',
    service: 'Lub',
  },
]


export const Roles = {
  ADMIN: 'admin',
  CONCESSIONAIRE: 'concessionaire',
  CALLCENTER: 'callcenter',
  COMPANY: 'user_company',
};

export const RolesList = [
  {
    label: 'Call Center',
    value: 'callcenter',
  },
  {
    label: 'Administrador',
    value: 'admin',
  },
  {
    label: 'Operador',
    value: 'operator',
  },
  {
    label: 'Concesionario',
    value: 'concessionaire',
  },
  {
    label: 'Administrador Empresa',
    value: 'user_company',
  },
];

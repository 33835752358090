import { RadioGroup, Radio, TagInput } from 'rsuite';
import ErrorValidation from '../../components/ErrorValidation';

const AddCoupon = (props: any) => {
  const { data, handleChange } = props;
  return (
    <div className="container-fluid">
      <div className="size-13 bold mb-2">Cupón</div>
      <div className="row mt-2 p-2">
        <div className="col-12 mb-3">
          <div className="mt-2">
            <input name="pk" value={data.inputs.pk} onChange={handleChange} type="hidden" />
            <RadioGroup inline name="codigo" onChange={(e: any) => handleChange({ target: { name: 'codigo', value: e } })} value={data.inputs.codigo}>
              <Radio value="generado">Generado por sistema</Radio>
              <Radio value="manual">Ingreso manual</Radio>
              <Radio value="carga">Carga masiva</Radio>
            </RadioGroup>
          </div>
          <ErrorValidation className="size-09 bold-300 text-end" validations={data.validations} name="codigo" />
          {data.inputs.codigo === 'generado' && (
            <>
              <input
                placeholder="Ingrese cantidad"
                name="codigo_n"
                value={data.inputs.codigo_n}
                onChange={handleChange}
                className="input-default w-100 m-2"
                type="text"
                maxLength={9}
              />
              <ErrorValidation className="size-09 bold-300 text-end" validations={data.validations} name="codigo_n" />
            </>
          )}
          {data.inputs.codigo === 'manual' && (
            <>
              <TagInput
                data={data.inputs.codigo_list}
                onChange={(e: any) => handleChange({ target: { name: 'codigo_list', value: e } })}
                trigger={['Enter', 'Space', 'Comma']}
                placeholder="Ingrese los códigos"
                className="rs-cleanable input-default w-100 m-2"
              />
              <small className="px-2">Ingresa el nombre del cupón y agrega coma, espacios o presiona enter, <strong>antes de clikear guardar</strong></small>
              <ErrorValidation className="size-09 bold-300 text-end" validations={data.validations} name="codigo_list" />
            </>
          )}
          {data.inputs.codigo === 'carga' && (
            <>
              <input name="codigo_file" className="btn" type="file" accept=".csv" onChange={(e: any) => handleChange({ target: { name: 'codigo_file', value: e } })} />
              <ErrorValidation className="size-09 bold-300 text-end" validations={data.validations} name="codigo_file" />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddCoupon;

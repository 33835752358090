export const days_of_week: any = [
  {
    value: '0',
    label: 'Domingo',
  },
  {
    value: '1',
    label: 'Lunes',
  },
  {
    value: '2',
    label: 'Martes',
  },
  {
    value: '3',
    label: 'Miércoles',
  },
  {
    value: '4',
    label: 'Jueves',
  },
  {
    value: '5',
    label: 'Viernes',
  },
  {
    value: '6',
    label: 'Sábado',
  },
];

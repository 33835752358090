import { useEffect, useState } from 'react';
import ModalComponent from '../Modal';
import './style.scss';

const PaginationFront = (props: any) => {
  const { currentPage, totalPages, setPage } = props;
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    setPageNumber(currentPage)
  }, [currentPage])

  const _setPage = () => {
    let page: any = pageNumber;
    if (page < 1) page = 1;
    if (page > totalPages) page = totalPages;
    page = parseInt(page);
    setPageNumber(page);
    setModal(false);
    setPage(page);
  };

  return (
    <div className="w-100 pagination-component">
      <ModalComponent open={modal} size="xs" noFull={true} hideFooter handleClose={() => setModal(false)}>
        <div className="container-fluid">
          <div className="row mb-4">
            <div className="col-12 bold-300 text-center size-12">Informa la pagina</div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <input type="number" className="pagination-component__page_input" min={1} max={totalPages} onChange={(e: any) => setPageNumber(e.target.value)} value={pageNumber} />
              <button className="pagination-component__page_btn" onClick={_setPage}>
                Ir
              </button>
            </div>
          </div>
        </div>
      </ModalComponent>

      <button className="pagination-component__btn" disabled={currentPage === 1} onClick={() => setPage(currentPage - 1)}>
        Anterior
      </button>
      <div className="pagination-component__center" onClick={() => setModal(true)}>
        {currentPage} de {totalPages}
      </div>
      <button className="pagination-component__btn" disabled={currentPage === totalPages} onClick={() => setPage(currentPage + 1)}>
        Próximo
      </button>
    </div>
  );
};

export default PaginationFront;

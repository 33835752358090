/* eslint-disable no-useless-escape */
import * as yup from 'yup';

export const CarSchema = yup.object().shape({
  model: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .max(80, "Este campo acepta un máximo de 80 caracteres")
    .required('Este campo es requerido'),
  brand: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .max(80, "Este campo acepta un máximo de 80 caracteres")
    .required('Este campo es requerido'),
  capacity: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .max(80, "Este campo acepta un máximo de 80 caracteres")
    .required('Este campo es requerido'),
  plate: yup
  .string()
  .matches(/^[a-z]{2}[a-z0-9]{2}[0-9]{2}$/igm, 'Formato de patente inválido')
  .required('Este campo es requerido'),
  year: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .required('Este campo es requerido'),
  clearance: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .max(80, "Este campo acepta un máximo de 80 caracteres")
    .required('Este campo es requerido'),
  driver_id: yup.string().required('Este campo es requerido'),
});

export type CarType = yup.InferType<typeof CarSchema>;

import { getSession } from '../helpers/session';
import store from '../redux/store';
import instanceOvert from './api_overt';
import instance from './api_admin';
class SubscriptionService {
  _setHeaders = () => {
    const state: any = store.getState();
    const { session } = state;

    const oidcSession = getSession();

    if (oidcSession) {
      instance.defaults.headers.common['user'] = session.profile?.PK;
      instance.defaults.headers.common['usersk'] = session.profile?.SK;
      instance.defaults.headers.common['Authorization'] = `Bearer ${oidcSession?.access_token}`;
      instanceOvert.defaults.headers.common['user'] = session.profile?.PK;
      instanceOvert.defaults.headers.common['usersk'] = session.profile?.SK;
      instanceOvert.defaults.headers.common['Authorization'] = `Bearer ${oidcSession?.access_token}`;
    }
  };

  list = async () => {
    console.log("list")
    this._setHeaders();
    
    try {
      const response = await instance.get('/admin/subscription/list?limit=10');
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
}

const Subscription = new SubscriptionService();
export default Subscription;
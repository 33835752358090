/* eslint-disable no-useless-escape */
import * as yup from 'yup';

export const ServiceSchema = yup.object().shape({
  name: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .max(255, "Este campo acepta un máximo de 255 caracteres")
    .required('Este campo es requerido'),
  shipping_price: yup.string().required('Este campo es requerido'),
  min_reservation_time: yup.number().min(1, 'El valor de las horas debe ser mayor o igual a 1').required('Este campo es requerido'),
});

export type ServiceType = yup.InferType<typeof ServiceSchema>;

import React from 'react'
import IImage from '../../interfaces/IImage'

const Image: React.FC<IImage> = (props) => {

  const { className, image, alt, ...rest } = props
  return (
    <img className={className || ''} src={image} alt={alt} {...rest} />
  )
}

export default Image
/* eslint-disable no-useless-escape */
import * as yup from 'yup';

export const ServiceMessagesSchema = yup.object().shape({
  message: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .max(255, "Este campo acepta un máximo de 255 caracteres")
    .required('Este campo es requerido'),
  service_id: yup.string().required('Este campo es requerido'),
});

export type ServiceMessagesType = yup.InferType<typeof ServiceMessagesSchema>;

export const reasons: any = [
  {
    value: 1,
    label: 'Cliente solicitó la anulación.',
    role: 'concessionaire',
    inputText: false,
  },
  {
    value: 2,
    label: 'No logré acceder a la vivienda.',
    role: 'concessionaire',
    inputText: false,
  },
  {
    value: 3,
    label: 'Otro motivo:',
    role: 'concessionaire',
    inputText: true,
  },
  {
    value: 4,
    label: 'Atendedor no llegó a la hora solicitada.',
    role: 'callcenter',
    inputText: false,
  },
  {
    value: 5,
    label: 'Cliente ya no puede recibir el servicio a la hora indicada.',
    role: 'callcenter',
    inputText: false,
  },
  {
    value: 6,
    label: 'Otro:',
    role: 'callcenter',
    inputText: true,
  },
  {
    value: 7,
    label: 'Anulado por un administrador',
    role: 'admin',
    inputText: false,
  },
];

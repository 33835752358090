import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import validate from '../../../helpers/validate';
import { set_eds } from '../../../redux/actions/eds';
import { LOADING_OFF, LOADING_ON } from '../../../redux/actions/loader';
import EDSDAO from '../../../services/eds.service';
import { EdsSchema } from '../../../validations/eds.validations';
import ModalComponent from '../../Modal';
import ModalEditEds from './ModalEditEds';
import ModalMessageServices from './ModalMessageServices';

const EditEds = () => {
  const dispatch = useDispatch();
  const [tab, setTab] = useState<any>(1);

  const { selected_eds } = useSelector((state: any) => state);
  const [state, setState] = useState<any>({
    modal: {
      eds: false,
    },
    validations: {},
  });

  const _handleToggleModal = (target: any) => {
    state.modal[target] = !state.modal[target];
    setState({ ...state, validations: {} });
    if (state.modal[target] === true) {
      setTab(1);
    }
  };

  const _handleUpdateEds = async (payload: any) => {
    try {
      const errors = await validate(EdsSchema, payload);
      setState({ ...state, validations: errors });

      if (errors) {
        return;
      }

      dispatch(LOADING_ON());
      const params = {
        info: {
          ...selected_eds.info,
          payment_types: payload.payment_types,
          address: payload.address,
          phone: payload.phone,
          comerce_simultaneous: payload.comerce_simultaneous,
          comerce_deferred: payload.comerce_deferred,
          services_id: payload.services_id,
          concessionaire_id: payload.concessionaire_id,
          code_ref: payload.code_ref
        },
        code: payload.code,
        name: payload.name,
        rut: payload.rut,
        PK: payload.PK,
        services_id: payload.services_id,
      };

      await EDSDAO.update(params);
      dispatch(set_eds(params));
      _handleToggleModal('eds');
    } catch (e: any) {
      console.log('ERROR LOG', e.message);
      toast.error('No fue posible concluir esta accion');
      dispatch(LOADING_OFF());
    }
    dispatch(LOADING_OFF());
  };


  return (
    <div>
      <ModalComponent open={state.modal.eds} handleClose={() => _handleToggleModal('eds')} hideFooter>
        <div className="row w-100 px-3 mt-5">
          <div
            className={`col-4 copec-tab py-2 bold text-center size-12 ${tab === 1 ? 'color-white bg-color-blue' : ''}`}
            onClick={() => setTab(1)}
            style={{ cursor: 'pointer' }}
          >
            Editar Eds
          </div>
          <div
            className={`col-4 copec-tab py-2 bold text-center size-12 ${tab === 2 ? 'color-white bg-color-blue' : ''}`}
            onClick={() => setTab(2)}
            style={{ cursor: 'pointer' }}
          >
            Mensaje servicio desactivado
          </div>
        </div>

        <div className="border border p-3 rounded">
          {tab === 1 ? <ModalEditEds handleClose={() => _handleToggleModal('eds')} handleConfirm={_handleUpdateEds} validations={state.validations} /> : null}
          {tab === 2 ? <ModalMessageServices handleClose={() => _handleToggleModal('eds')} /> : null}
        </div>
      </ModalComponent>

      <button className="border-2-blue py-4 w-100 text-center rounded-10 size-11 bold bg-color-white" onClick={() => _handleToggleModal('eds')}>
        Editar EDS
      </button>
    </div>
  );
};

export default EditEds;

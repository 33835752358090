export const TableHeader = [
  {
    label: 'Nombre',
    key: 'name',
    align: 'left',
    alignFlex: 'flex-start',
    flexGrow: 1,
  },
  {
    label: 'Desde',
    key: 'start',
    align: 'center',
    alignFlex: 'center',
    flexGrow: 0,
  },
  {
    label: 'Hasta',
    key: 'end',
    align: 'center',
    alignFlex: 'center',
    flexGrow: 0,
  },
  {
    label: 'Día de la semana',
    key: '_string',
    align: 'center',
    alignFlex: 'center',
    flexGrow: 1,
  }
]

export const TableData = [
  {
    id: 1,
    day_of_week: 1,
    start: '07:00 hs',
    end: '17:00 hs',
  },
  {
    id: 2,
    day_of_week: 2,
    start: '07:00 hs',
    end: '17:00 hs',
  },
  {
    id: 3,
    day_of_week: 3,
    start: '07:00 hs',
    end: '17:00 hs',
  },
  {
    id: 4,
    day_of_week: 4,
    start: '07:00 hs',
    end: '17:00 hs',
  },
]


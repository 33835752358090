import { getSession } from '../helpers/session';
import store from '../redux/store';
import instance from './api_admin';

class DiscountDataService {
  _setHeaders = () => {
    const state: any = store.getState();
    const { session } = state;

    const oidcSession = getSession();

    if (oidcSession) {
      instance.defaults.headers.common['user'] = session.profile?.PK;
      instance.defaults.headers.common['usersk'] = session.profile?.SK;
      instance.defaults.headers.common['Authorization'] = `Bearer ${oidcSession?.access_token}`;
    }
  };

  list = async (id: any, paginationParams: any = null) => {
    this._setHeaders();
    try {
      const params: any = [];
      if (paginationParams && paginationParams.last) {
        params.push(`last=${paginationParams.last}`);
      }
      const response = await instance.get(`/admin/schedule/discount/list?eds_id=${id}&limit=${process.env.REACT_APP_ORDERS_BASE_LIMIT}&${params.join('&')}`);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  create = async (params: any) => {
    this._setHeaders();
    try {
      const response = await instance.post('/admin/schedule/discount', params);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  update = async (params: any) => {
    this._setHeaders();
    try {
      const response = await instance.put(`/admin/schedule/discount/update/${params.PK}`, params);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  delete = async (params: any) => {
    this._setHeaders();
    try {
      const response = await instance.delete(`/admin/schedule/discount/delete/${params.PK}`, { data: { hard: true, PK: params.PK, SK: params.SK } });
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  handleGetCrossSelling = async (params: any) => {
    this._setHeaders();
    try {
      const response = await instance.get(`/admin/cross-selling/config/get`, params);
      return response?.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  handleSaveCrossSelling = async (params: any) => {
    this._setHeaders();
    try {
      const response = await instance.post(`/admin/cross-selling/config`, params);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
}

export default new DiscountDataService();

import { Modal } from 'rsuite';

const ModalComponent = (props: any) => {
  const { handleClose, handleConfirm, children, open, hideCancel, hideFooter, noFull, btnConfirmText, size, is_static } = props;

  const _handleTranslatetext = () => {
    setTimeout(() => {
      const closeElements: any = document.querySelectorAll('[title=Apagar]');
      const clearElements: any = document.querySelectorAll('[title=Clear]');
      const tagElements: any = document.querySelectorAll('.rs-tag-icon-close');

      Array.from(closeElements).forEach((item: any) => {
        item.setAttribute('title', 'Cerrar');
      });
      Array.from(clearElements).forEach((item: any) => {
        item.setAttribute('title', 'Limpiar');
      });
      Array.from(tagElements).forEach((item: any) => {
        item.setAttribute('title', 'Remover');
      });
    }, 400);
  };

  return (
    <div className="modal-container">
      <Modal
        backdrop={is_static ? 'static' : true}
        full={noFull ? false : true}
        open={open}
        onClose={handleClose}
        size={size || 'md'}
        onEntered={_handleTranslatetext}
        onChange={_handleTranslatetext}
      >
        <Modal.Header />
        <Modal.Body>{children}</Modal.Body>
        {!hideFooter && (
          <Modal.Footer>
            <div className={`row mb-4 ${hideCancel ? 'justify-content-end' : 'justify-content-around'}`}>
              {!hideCancel && (
                <div className="col-2 text-center">
                  <button className="btn-danger" onClick={handleClose}>
                    Cancelar
                  </button>
                </div>
              )}
              <div className="col-2 text-center text-white">
                <button className="btn-default" onClick={handleConfirm}>
                  {btnConfirmText || 'Guardar'}
                </button>
              </div>
            </div>
          </Modal.Footer>
        )}
      </Modal>
    </div>
  );
};

export default ModalComponent;

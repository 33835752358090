import { useState } from "react";
import Title from "../../components/Title";
import CrossSelling from "./CrossSelling";

const Discounts = () => {

    const [tab, setTab] = useState<any>(1);

  return (
    <div className="container-fluid">
      <div className="row mb-5">
        <Title size="sm" text={`Descuentos`} className="mt-5 bold" />
      </div>
      <div className="row px-3 mt-5 d-none d-sm-flex">
        <div
          className={`col-3 copec-tab py-2 bold text-center size-12 shadow ${tab === 1 ? 'color-white bg-color-blue' : 'border'}`}
          onClick={() => setTab(1)}
          style={{ cursor: 'pointer' }}
        >
          Venta Cruzada
        </div>
      </div>

      <div className="row border rounded-10 py-4 px-3 mb-3 position-relative" style={{zIndex: 1000, background: '#fff'}}>
        {tab === 1 && <CrossSelling />}
      </div>
    </div>
  );
};

export default Discounts;

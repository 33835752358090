export const createCsv = (data: any, name: any = null) => {
  try {
    let csvContent = 'data:text/csv;charset=utf-8,';
    csvContent += data.headers.map((e: any) => e.join(',')).join('\n');
    csvContent += '\n';
    csvContent += data.data.map((e: any) => e.join(',')).join('\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', name || 'my_data.csv');
    document.body.appendChild(link);
    link.click();
  } catch (e: any) {
    return false;
  }
};

export const createCsvSimple = (data: any, name: any = null) => {
  try {
    let csvContent = 'data:text/csv;charset=utf-8,';
    const universalBOM: any = '\uFEFF';
    csvContent += universalBOM + data;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', name || 'my_data.csv');
    document.body.appendChild(link);
    link.click();
  } catch (e: any) {
    return false;
  }
};

export const createCsvByText = (data: any, name: any = null) => {
  try {
    const csvFile = new Blob([data], { type: 'text/csv;charset=utf-8' });
    const link = document.createElement('a');
    link.setAttribute('href', window.URL.createObjectURL(csvFile));
    link.setAttribute('download', name || 'my_data.csv');
    document.body.appendChild(link);
    link.click();
  } catch (e: any) {
    console.log('LOGGER', e.message);
    return false;
  }
};

export const createFieldsData = (fields: any, data: any) => {
  try {
    const csvData: any = data.map((element: any) => {
      const row: any = [];
      fields.forEach((item: any) => {
        const arrayFields: any = item.split('.');
        let info: any = null;
        arrayFields.forEach((sub_item: any) => {
          if (info === null || info === undefined) {
            info = element[sub_item];
          } else {
            info = info[sub_item];
          }
        });
        let result: any = '';

        if (info) {
          info = info.toString();
          result = info.replace(/,/g, ' -');
          result = '"' + result + '"';
        }

        row.push(result);
      });
      return row;
    });
    return csvData;
  } catch (e: any) {
    return false;
  }
};

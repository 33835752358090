/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { SelectPicker, TagPicker } from 'rsuite';
import Title from '../Title';
import { useDispatch, useSelector } from 'react-redux';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import { toast } from 'react-toastify';
import concessionaireService from '../../services/concessionaire.service';
import ReactInputMask from 'react-input-mask';
import ErrorValidation from '../ErrorValidation';
import Service from '../../services/service_and_products.service';
import { payment_types } from '../../helpers/payment_types';

const AddEds = (props: any) => {
  const { handleClose, handleConfirm, validations } = props;

  const { services } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [state, setState] = useState<any>({
    inputs: {
      code: '',
      name: '',
      address: '',
      phone: '',
      concessionaire_id: '',
      comerce_deferred: '597037485047',
      comerce_simultaneous: '597039161591',
      services_id: [],
      payment_types: [],
    },
    serviceData: [],
    concessionaires: [],
  });

  const setInputs = ({ target }: any) => {
    const { name, value }: any = target;
    state.inputs[name] = value;
    setState({ ...state });
  };

  useEffect(() => {
    _loadData();
  }, [services]);

  const _loadData = async () => {
    dispatch(LOADING_ON());
    try {
      const response = await concessionaireService.listAll();
      const responseService: any = await Service.list();

      const services = responseService.data;
      const serviceData = services
        .filter((item: any) => item.type !== 'recommended_subservice')
        .map((s: any) => {
          return {
            label: `${s.name}`,
            value: s.PK,
          };
        });

      setState({
        ...state,
        serviceData,
        concessionaires: response.data.map((item: any) => {
          return { label: item.name, value: item.PK };
        }),
      });
      dispatch(LOADING_OFF());
    } catch (e: any) {
      console.log('ERROR LOG', e.message);
      toast.error('No fue posible comunicar con el servidor');
      dispatch(LOADING_OFF());
    }
  };

  return (
    <div className="component-services px-5">
      <div className="row">
        <Title size="sm" text="Nueva EDS" className="bold to-upper size-11" />
      </div>

      <div className="row mb-3">
        <div className="col-6">
          <span className="size-08 bold me-2">Código</span>
          <div className="">
            <input value={state.inputs.code} name="code" onChange={setInputs} className="input-default w-100" type="text" placeholder="123456789" />
            <ErrorValidation name="code" validations={validations} className="text-end size-09 bold-300" />
          </div>
        </div>

        <div className="col-6">
          <span className="size-08 bold me-2">Nombre</span>
          <div className="">
            <input value={state.inputs.name} name="name" onChange={setInputs} className="input-default w-100" type="text" placeholder="Peñalólen" />
            <ErrorValidation name="name" validations={validations} className="text-end size-09 bold-300" />
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-6">
          <span className="size-08 bold me-2">Dirección</span>
          <div className="">
            <input value={state.inputs.address} name="address" onChange={setInputs} className="input-default w-100" type="text" placeholder="Adolfo Orma, Las Condes, RM" />
            <ErrorValidation name="address" validations={validations} className="text-end size-09 bold-300" />
          </div>
        </div>

        <div className="col-6">
          <span className="size-08 bold me-2">Teléfono</span>
          <div className="">
            <ReactInputMask mask="+56999999999" value={state.inputs.phone} name="phone" className="input-default w-100" onChange={setInputs} placeholder="+56999999999" />
            <ErrorValidation name="phone" validations={validations} className="text-end size-09 bold-300" />
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-6">
          <span className="size-08 bold me-2">Código comercio diferido</span>
          <div className="">
            <input value={state.inputs.comerce_deferred} name="comerce_deferred" onChange={setInputs} className="input-default w-100" type="text" placeholder="597039161591" />
            <ErrorValidation name="comerce_deferred" validations={validations} className="text-end size-09 bold-300" />
          </div>
        </div>

        <div className="col-6">
          <span className="size-08 bold me-2">Código comercio simultáneo</span>
          <div className="">
            <input
              value={state.inputs.comerce_simultaneous}
              name="comerce_simultaneous"
              onChange={setInputs}
              className="input-default w-100"
              type="text"
              placeholder="597039161591"
            />
            <ErrorValidation name="comerce_simultaneous" validations={validations} className="text-end size-09 bold-300" />
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-6">
          <span className="size-08 bold me-2">Concesionario</span>
          <div className="">
            <SelectPicker
              locale={{ searchPlaceholder: 'Buscar', noResultsText: 'Ningún resultado encontrado.' }}
              data={state.concessionaires}
              value={state.inputs.concessionaire_id}
              classPrefix="copec"
              onChange={(e: any) => setInputs({ target: { name: 'concessionaire_id', value: e } })}
              placeholder="Seleccionar Concesionario"
              className="rs-copec-cleanable"
              cleanable={false}
            />
            <ErrorValidation name="concessionaire_id" validations={validations} className="text-end size-09 bold-300" />
          </div>
        </div>

        <div className="col-6">
          <span className="size-08 bold me-2">Servicios</span>
          <div className="">
            <TagPicker
              value={state.inputs.services_id}
              onChange={(e: any) => setInputs({ target: { name: 'services_id', value: e } })}
              data={state.serviceData}
              placeholder="Seleccionar Servicios"
            />
            <ErrorValidation name="services_id" validations={validations} className="text-end size-09 bold-300" />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <span className="size-08 bold me-2">Tipo de pagos</span>
          <div>
            <TagPicker
              placeholder="Seleccionar Tipo de Pagos"
              onChange={(e: any) => setInputs({ target: { name: 'payment_types', value: e } })}
              value={state.inputs.payment_types}
              data={payment_types}
            />
          </div>
        </div>
      </div>

      <div className={`row mt-5 justify-content-around`}>
        <div className="col-2 text-center">
          <button className="btn-danger" onClick={handleClose}>
            Cancelar
          </button>
        </div>
        <div className="col-2 text-center">
          <button className="btn-default" onClick={() => handleConfirm(state.inputs)}>
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddEds;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SelectPicker } from 'rsuite';
import Title from '../../components/Title';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import driverService from '../../services/driver.service';
import EDSDAO from '../../services/eds.service';
import Drivers from './Drivers';

const DriversConcessionaire = () => {
  const dispatch = useDispatch();
  const { session } = useSelector((state: any) => state);
  const [state, setState] = useState<any>({
    tabs: 1,
    checks: {},
    eds: [],
    inputs: {
      eds: null,
    },
    drivers: [],
  });

  const _getEds = async (eds: any = null) => {
    dispatch(LOADING_ON());
    try {
      const response = await EDSDAO.getAllAvailableEds(50);
      const edsList: any = response.data.filter((item: any) => item?.info?.concessionaire_id === session.profile?.info?.concessionaire_id);

      let responseOperatorsList: any = null;
      if (eds === null) {
        responseOperatorsList = edsList.length > 0 ? await driverService.listDrivers({ PK: edsList[0].SK, SK: edsList[0].SK }) : { data: { operator: [] } };
      } else {
        responseOperatorsList = await EDSDAO.getDetails(eds);
      }

      const operators: any = responseOperatorsList.data.map((item: any) => {
        item.user_PK = item.user?.PK;
        item.user_SK = item.user?.SK;
        delete item.user?.PK;
        delete item.user?.SK;
        return { ...item, ...item.user };
      });

      setState({ ...state, eds: edsList, drivers: operators, inputs: { ...state.inputs, eds: eds === null ? edsList[0]?.PK : eds } });
    } catch (e: any) {
      console.log('LOGGER', e);
    }
    dispatch(LOADING_OFF());
  };

  useEffect(() => {
    _getEds();
  }, []);

  const _handleOnChange = async ({ target }: any) => {
    const { name, value } = target;
    state.inputs[name] = value;

    dispatch(LOADING_ON());
    if (state.tabs === 1 && value) {
      const eds: any = state.eds.find((item: any) => item.PK === value);
      const responseListOperators = await driverService.listDrivers({ PK: eds?.PK, SK: eds?.SK });
      const operators: any = responseListOperators.data.map((item: any) => {
        item.user_PK = item.user?.PK;
        item.user_SK = item.user?.SK;
        delete item.user?.PK;
        delete item.user?.SK;
        return { ...item, ...item.user };
      });
      setState({ ...state, drivers: operators });
    }

    dispatch(LOADING_OFF());
  };

  const _getEdsData = async () => {
    dispatch(LOADING_ON());
    try {
      const eds: any = state.eds.find((item: any) => item.PK === state.inputs.eds);
      const responseListOperators = await driverService.listDrivers({ PK: eds?.PK, SK: eds?.SK });
      const operators: any = responseListOperators.data.map((item: any) => {
        item.user_PK = item.user?.PK;
        item.user_SK = item.user?.SK;
        delete item.user?.PK;
        delete item.user?.SK;
        return { ...item, ...item.user };
      });
      setState({ ...state, drivers: operators });
    } catch (e: any) {
      console.log('LOGGER', e);
    }
    dispatch(LOADING_OFF());
  };

  return (
    <div className="container-fluid">
      <div className="row my-5 align-items-center">
        <div className="col-8">
          <Title size="sm" text={state.tabs === 1 ? 'Conductores' : 'Vehículos'} className="to-upper bold" />
        </div>
        <div className="col-4">
          <SelectPicker
            cleanable={false}
            locale={{ searchPlaceholder: 'Buscar', noResultsText: 'Ningún resultado encontrado.', emptyMessage: 'Ningún resultado encontrado.' }}
            classPrefix="copec"
            className="rs-copec-cleanable"
            value={state.inputs.eds}
            data={state.eds}
            onChange={(e: any) => _handleOnChange({ target: { name: 'eds', value: e } })}
            style={{ width: '100%' }}
            placeholder="EDS"
            labelKey="name"
            valueKey="PK"
          />
        </div>
      </div>

      <div className="row border rounded-10 py-4 px-3">
        <Drivers drivers={state.drivers} eds_id={state.inputs.eds} handleEndUpdate={_getEdsData} />
      </div>
    </div>
  );
};

export default DriversConcessionaire;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { Table, Tooltip, Whisper, Toggle } from 'rsuite';
import ModalComponent from '../../components/Modal';
import Title from '../../components/Title';
import moment from 'moment';
import validate from '../../helpers/validate';
import { CouponConfigSchema } from '../../validations/coupon.config.validations';
import { CouponSchema } from '../../validations/coupon.validations';
import AddCouponConfig from './AddCouponConfig';
import AddCoupon from './AddCoupon';
import ListCoupon from './ListCoupon';
import { TableCouponConfig } from './tableConfigs';
import campaignService from '../../services/campaign.service';
import couponService from '../../services/coupon.service';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import Service from '../../services/service_and_products.service';
import EDSDAO from '../../services/eds.service';
import Papa from 'papaparse';
import { inputs } from './inputs';

const Coupons = (props: any) => {
  const params: any = useParams();
  const history = useHistory();
  const [state, setState] = useState<any>({
    inputs: { ...inputs },
    validations: {},
    modal: {
      add: {
        show: false,
      },
      update: {
        show: false,
      },
      create: {
        show: false,
      },
      list: {
        show: false,
      },
    },
    servicios: [],
    eds: [],
    data: [],
    coupons: [],
    list_coupons: [],
    campaign_name: '',
    campaign_pk: '',
    days_of_week: [
      {
        value: 0,
        label: 'Domingo',
      },
      {
        value: 1,
        label: 'Lunes',
      },
      {
        value: 2,
        label: 'Martes',
      },
      {
        value: 3,
        label: 'Miércoles',
      },
      {
        value: 4,
        label: 'Jueves',
      },
      {
        value: 5,
        label: 'Viernes',
      },
      {
        value: 6,
        label: 'Sábado',
      },
    ],
  });

  const Actions = (props: any) => {
    const { payload } = props;
    return (
      <div className="row">
        <div className="col-12">
          {payload && !payload.status && (
            <>
              <button className="btn p-0 me-3 size-11 color-grey-menu" onClick={() => _toggleUpdateModal(payload.PK)}>
                <i className="bi bi-pencil"></i>
              </button>
              <button className="btn p-0 me-3 size-11 color-grey-menu" onClick={() => _toggleCreateCouponsModal(payload.PK)}>
                <i className="bi bi-gear"></i>
              </button>
            </>
          )}
          <button className="btn p-0 me-3 size-11 color-grey-menu" onClick={() => history.push(`/cupones/campana/tags/${state.campaign_pk}/${payload.PK}`)}>
            <i className="bi bi-tags"></i>
          </button>
        </div>
      </div>
    );
  };

  const dispatch = useDispatch();

  useEffect(() => {
    _getCoupons();
  }, []);

  const _toggleAddModal = () =>
    setState({
      ...state,
      validations: {},
      inputs: { ...inputs },
      modal: { ...state.modal, add: { show: !state.modal.add.show } },
    });

  const _toggleUpdateModal = (edit_id = null) => {
    const cc: any = state.coupons.find((e: any) => e.PK === edit_id) || {};
    const start: any = cc.start ? moment(cc.start).toDate() : '';
    const end: any = cc.end ? moment(cc.end).toDate() : '';
    let vigencia: any = 'campana';

    if (cc.start && cc.end) {
      vigencia = 'intervalo';
      if (cc.apply_days.length > 0) {
        vigencia = 'dias';
      }
    }

    const inputs: any = {
      ...cc,
      pk: edit_id,
      restriccion: cc.use_limit > 0 ? true : false,
      quantity_available_checkbox: cc.initial_quantity_available > 0 ? true : false,
      vigencia: vigencia,
      start: start,
      end: end,
      apply_days: cc.apply_days,
      codigo: '',
      codigo_n: '',
      codigo_list: [],
      codigo_file: {},
    };

    setState({
      ...state,
      validations: {},
      inputs: { ...state.inputs, ...inputs },
      modal: { ...state.modal, update: { show: !state.modal.update.show } },
    });
  };

  const _toggleAddModalClose = () => {
    setState({
      ...state,
      inputs: { ...inputs },
      validations: {},
      modal: { ...state.modal, add: { show: false } },
    });
  };

  const _toggleUpdateModalClose = () => {
    setState({
      ...state,
      validations: {},
      modal: { ...state.modal, update: { show: false } },
    });
  };

  const _toggleListCouponsModalClose = () => {
    setState({
      ...state,
      inputs: { ...inputs },
      validations: {},
      modal: { ...state.modal, list: { show: false } },
    });
  };

  const _toggleCreateCouponsModalClose = () => {
    setState({
      ...state,
      inputs: { ...inputs },
      validations: {},
      modal: { ...state.modal, create: { show: false } },
    });
  };

  const _toggleCreateCouponsModal = (edit_id = null) =>
    setState({
      ...state,
      validations: {},
      inputs: {
        pk: edit_id,
        name: '',
        pk_services: [],
        pk_eds: [],
        apply: '',
        limitsame_campaign: false,
        restriccion: false,
        use_limit: 0,
        limit_used: 0,
        type_discount: '',
        max_amount_discount: 0,
        initial_quantity_available: 0,
        quantity_available_checkbox: false,
        minimum_amount: false,
        minimum_amount_in_cart: 0,
        cumulative_day_discount: false,
        vigencia: '',
        start: '',
        end: '',
        apply_days: [],
        codigo: '',
        codigo_n: '',
        codigo_list: [],
        codigo_file: {},
      },
      modal: { ...state.modal, create: { show: !state.modal.create.show } },
    });

  const _handleOnChange = ({ target }: any) => {
    switch (target.name) {
      case 'limit_used':
      case 'use_limit':
      case 'minimum_amount_in_cart':
      case 'codigo_n':
      case 'max_amount_discount':
      case 'initial_quantity_available':
        target.value = target.value.replace(/\D/g, '');
        target.value = isNaN(parseInt(target.value)) ? 0 : parseInt(target.value);
        break;
      case 'codigo_list':
        const values: any = target.value.map((item: any) => item?.toUpperCase());
        target.value = values;
        break;
    }

    const { name, value } = target;

    if (name === 'vigencia' && value === 'campana') {
      state.inputs.start = '';
      state.inputs.end = '';
    }

    if (name === 'pk_services') {
      const selectedServices: any = state.servicios.filter((service: any) => value.find((pk: any) => pk === service.value));
      if (selectedServices?.length === 0 || selectedServices.find((service: any) => service.type !== 'kerosene' && service.type !== 'kerosene-schedule')) {
        if (state.inputs.type_discount === 'litro') state.inputs.type_discount = '';
      }
    }

    if (name === 'apply') {
      state.inputs.type_discount = '';
    }
    if (state.validations[name]) {
      delete state.validations[name];
    }
    setState({ ...state, inputs: { ...state.inputs, [name]: value }, validations: { ...state.validations } });
  };

  const _getCoupons = async () => {
    try {
      dispatch(LOADING_ON());
      const responseCoupons = await campaignService.get(params.id);
      const couponsList = responseCoupons.data.find((item: any) => item.PK === params.id);
      const newstate = {
        ...state,
        coupons: couponsList.setup,
        data: couponsList,
        campaign_name: couponsList.name,
        campaign_pk: couponsList.PK,
        modal: {
          add: {
            show: false,
          },
          update: {
            show: false,
          },
          create: {
            show: false,
          },
          list: {
            show: false,
          },
        },
      };

      if (state.servicios && state.servicios.length === 0) {
        const responseServices = await Service.listAllServices();
        const availableServices: any = responseServices.data
          .filter((item: any) => item.type !== 'recommended_subservice')
          .map((s: any) => {
            return {
              label: `${s.name}`,
              value: s.PK,
              type: s.type,
            };
          });
        newstate.servicios = availableServices;
      }

      if (state.eds && state.eds.length === 0) {
        const responseEds = await EDSDAO.getAllAvailableEds();
        const availableEds: any = responseEds.data
          .filter((item: any) => item.name !== '' && item.name !== undefined)
          .map((s: any) => {
            return {
              label: `${s.name}`,
              value: s.PK,
            };
          });

        availableEds.unshift({
          label: 'Todos',
          value: 'all',
        });
        newstate.eds = availableEds;
      }

      setState(newstate);
      dispatch(LOADING_OFF());
    } catch (e: any) {
      dispatch(LOADING_OFF());
      toast.error('No fue posible obtener los cupones');
    }
  };

  const _getListCoupons = async (id: any) => {
    try {
      dispatch(LOADING_ON());
      const params: any = {
        campaign: state.campaign_pk,
        setup: id,
      };
      const response = await couponService.list(params);
      setState({
        ...state,
        list_coupons: response.data.filter((item: any) => item.setup === id),
        modal: { ...state.modal, list: { show: true } },
      });
    } catch (e: any) {
      toast.error('No fue posible obtener los cupones');
    }
    dispatch(LOADING_OFF());
  };

  const _handleCreate = async (action: any) => {
    let validations = await validate(CouponConfigSchema, state.inputs);

    if (state.inputs.limitsame_campaign && parseInt(state.inputs.limit_used) < 1) {
      validations = validations
        ? {
          ...validations,
          limit_used: ['Este campo debe ser mayor a 0'],
        }
        : {
          limit_used: ['Este campo debe ser mayor a 0'],
        };
    }

    if (state.inputs.quantity_available_checkbox && parseInt(state.inputs.initial_quantity_available) < 1) {
      validations = validations
        ? {
          ...validations,
          initial_quantity_available: ['Este campo debe ser mayor a 0'],
        }
        : {
          initial_quantity_available: ['Este campo debe ser mayor a 0'],
        };
    }

    if (state.inputs.type_discount === 'porcentaje' && parseInt(state.inputs.max_amount_discount) > 100) {
      validations = validations
        ? {
          ...validations,
          max_amount_discount: ['El valor máximo aceptado para este campo es 100'],
        }
        : {
          max_amount_discount: ['El valor máximo aceptado para este campo es 100'],
        };
    }

    const start = moment(state.inputs.start);
    const end = moment(state.inputs.end);

    if (start.isAfter(end)) {
      validations = validations
        ? {
          ...validations,
          start: ['La fecha del campo Hasta debe ser posterior a la fecha del campo Desde'],
          end: ['La fecha del campo Hasta debe ser posterior a la fecha del campo Desde'],
        }
        : {
          start: ['La fecha del campo Hasta debe ser posterior a la fecha del campo Desde'],
          end: ['La fecha del campo Hasta debe ser posterior a la fecha del campo Desde'],
        };
    }

    if (validations) {
      setState({ ...state, validations: validations });
      return;
    }

    setState({ ...state, validations: {} });

    if(!state.inputs.limitsame_campaign) state.inputs.limit_used = 0;
    if(!state.inputs.quantity_available_checkbox) state.inputs.initial_quantity_available = 0;

    if (action === 'add') {
      dispatch(LOADING_ON());
      try {
        const paramsCoupon = {
          PK: new Date().getUTCMilliseconds(),
          name: state.inputs.name,
          status: false,
          apply: state.inputs.apply,
          type_discount: state.inputs.type_discount,
          minimum_amount: state.inputs.minimum_amount,
          minimum_amount_in_cart: state.inputs.minimum_amount_in_cart,
          max_amount_discount: state.inputs.max_amount_discount,
          initial_quantity_available: parseInt(state.inputs.initial_quantity_available),
          limitsame_campaign: state.inputs.limitsame_campaign,
          limit_used: state.inputs.limit_used,
          use_limit: state.inputs.use_limit,
          pk_eds: state.inputs.pk_eds,
          pk_services: state.inputs.pk_services,
          cumulative_day_discount: state.inputs.cumulative_day_discount,
          start: state.inputs.start ? moment(state.inputs.start) : '',
          end: state.inputs.end ? moment(state.inputs.end) : '',
          apply_days: state.inputs.apply_days,
        };

        const paramsCampaign: any = state.data;
        paramsCampaign.setup.push(paramsCoupon);
        await campaignService.update(paramsCampaign);
        await _toggleAddModalClose();
        await _getCoupons();
        toast.success('Cupón creado con éxito');
      } catch (e: any) {
        if (e && e.message) {
          const errors = e.message.split('::');
          if (errors.length > 1) {
            toast.error('No fue posible crear el cupón. Vuelve a intentarlo en unos minutos.');
          } else {
          }
        } else {
          toast.error('No fue posible crear el cupón. Vuelve a intentarlo en unos minutos.');
        }
      }
    }

    if (action === 'update') {
      dispatch(LOADING_ON());
      const paramsCoupon = {
        PK: state.inputs.pk,
        name: state.inputs.name,
        apply: state.inputs.apply,
        type_discount: state.inputs.type_discount,
        minimum_amount: state.inputs.minimum_amount,
        minimum_amount_in_cart: state.inputs.minimum_amount_in_cart,
        max_amount_discount: state.inputs.max_amount_discount,
        initial_quantity_available: parseInt(state.inputs.initial_quantity_available),
        limitsame_campaign: state.inputs.limitsame_campaign,
        limit_used: state.inputs.limit_used,
        use_limit: state.inputs.use_limit,
        pk_eds: state.inputs.pk_eds,
        pk_services: state.inputs.pk_services,
        cumulative_day_discount: state.inputs.cumulative_day_discount,
        start: state.inputs.start ? moment(state.inputs.start) : '',
        end: state.inputs.end ? moment(state.inputs.end) : '',
        apply_days: state.inputs.apply_days,
      };
      const paramsCampaign: any = state.data;
      const updateCoupon = paramsCampaign.setup.filter((item: any) => item.PK !== paramsCoupon.PK);
      updateCoupon.push(paramsCoupon);
      paramsCampaign.setup.splice(0, paramsCampaign.setup.length);
      paramsCampaign.setup = updateCoupon;
      await campaignService.update(paramsCampaign);
      toast.success('Cupón actualizado con éxito');
      _toggleUpdateModalClose();
      _getCoupons();
    }
  };

  const _handleSubmitCupons = async () => {
    const result = await validate(CouponSchema, state.inputs);

    if (result) {
      setState({ ...state, validations: result });
      return;
    }
    setState({ ...state, validations: {} });
    dispatch(LOADING_ON());

    try {
      if (state.inputs.codigo === 'generado') {
        const paramsCouponGenerate = {
          create: parseInt(state.inputs.codigo_n),
          status: 1,
          setup: state.inputs.pk,
          campaign_id: state.campaign_pk,
        };
        await couponService.create(paramsCouponGenerate);
        toast.success('Cupones registrados con éxito');
        await _toggleCreateCouponsModalClose();
        await _getCoupons();
        dispatch(LOADING_OFF());
      } else if (state.inputs.codigo === 'manual') {
        const paramsCouponManual = {
          list: state.inputs.codigo_list,
          status: 1,
          setup: state.inputs.pk,
          campaign_id: state.campaign_pk,
        };
        await couponService.create(paramsCouponManual);
        toast.success('Cupones registrados con éxito');
        await _toggleCreateCouponsModalClose();
        await _getCoupons();
        dispatch(LOADING_OFF());
      } else if (state.inputs.codigo === 'carga') {
        const file: any = state.inputs.codigo_file;
        Papa.parse(file.target.files[0], {
          complete: async (results: any) => {
            const list: any = [];
            results.data.forEach((d: any) => {
              list.push(d[0]);
            });

            if (list.length > 0) {
              const filtered:any = list.filter((el:any) => el !== '').map((el:any) => el?.toUpperCase());
              const paramsCouponCsv = {
                list: filtered,
                status: 1,
                setup: state.inputs.pk,
                campaign_id: state.campaign_pk,
              };
             
              try {
                await couponService.create(paramsCouponCsv);
                toast.success('Cupones registrados con éxito');
                await _toggleCreateCouponsModalClose();
                await _getCoupons();
              } catch (error: any) {
                toast.error('No fue posible generar los cupónes. Revisa el archivo csv.');
              }
              dispatch(LOADING_OFF());
            } else {
              toast.error('El archivo seleccionado no contiene código.');
            }
          },
          error: (e: any) => {
            toast.error('El archivo seleccionado no contiene código.');
          },
        });
      }
    } catch (e: any) {
      dispatch(LOADING_OFF());
      if (e.response?.status === 422) {
        e?.response?.data?.data?.forEach((item: any) => toast.error(`${item}.`));
      } else {
        setState({ ...state, modal: { ...state.modal, create: { ...state.modal.create, show: false } } });
        toast.error('No fue posible generar los cupónes.');
      }
    }
  };

  const _handleOnChangeStatusCoupon = async ({ row, target }: any) => {
    const { setup, name, PK } = row;
    const { value } = target;

    const dataCoupon: any = {
      name: name,
      status: value ? 1 : 0,
      setup: setup,
      PK: PK,
    };
    await _handleUpdateStatusCoupon(PK, dataCoupon);
  };

  const _handleUpdateStatusCoupon = async (PK: any, dataCoupon: any) => {
    dispatch(LOADING_ON());
    try {
      await couponService.update(PK, dataCoupon);
      toast.success('El estado del cupón ha sido actualizado con éxito.');
      await _getListCoupons(dataCoupon.setup);
    } catch (e: any) {
      toast.error('No fue posible actualizar el estado del cupón.');
    }
    dispatch(LOADING_OFF());
  };

  const _handleOnChangeStatusCouponConfig = ({ row, target }: any) => {
    const { PK } = row;
    const { value } = target;
    const newData: any = state.coupons.map((e: any) => {
      if (e.PK === PK) {
        e.status = value;
      }
      return e;
    });

    const dataCouponConfig: any = {
      PK: state.campaign_pk,
      setup: newData,
    };
    _handleUpdateStatusCouponConfig(dataCouponConfig);
  };

  const _handleUpdateStatusCouponConfig = async (dataCouponConfig: any) => {
    dispatch(LOADING_ON());
    try {
      await campaignService.update({ ...dataCouponConfig });
      toast.success('El estado de la configuración del cupón ha sido actualizado con éxito.');
      await _getCoupons();
    } catch (e: any) {
      toast.error('No fue posible actualizar la configuración del cupón. Vuelve a intentarlo en unos minutos.');
    }
    dispatch(LOADING_OFF());
  };

  return (
    <div className="container-fluid">
      <ModalComponent is_static size="lg" noFull open={state.modal.add.show} handleClose={_toggleAddModalClose} handleConfirm={() => _handleCreate('add')}>
        <AddCouponConfig data={state} handleChange={_handleOnChange} />
      </ModalComponent>

      <ModalComponent is_static size="lg" noFull open={state.modal.update.show} handleClose={_toggleUpdateModalClose} handleConfirm={() => _handleCreate('update')}>
        <AddCouponConfig data={state} handleChange={_handleOnChange} is_edit />
      </ModalComponent>

      <ModalComponent is_static size="lg" noFull open={state.modal.create.show} handleClose={_toggleCreateCouponsModalClose} handleConfirm={_handleSubmitCupons}>
        <AddCoupon data={state} handleChange={_handleOnChange} />
      </ModalComponent>

      <ModalComponent is_static hideFooter size="lg" noFull open={state.modal.list.show} handleClose={_toggleListCouponsModalClose}>
        <ListCoupon data={state} handleChange={_handleOnChangeStatusCoupon} />
      </ModalComponent>

      <div className="row">
        <Title size="sm" text={`Cupones`} className="mt-5 bold" />
        <Title size="sm" text={`Campaña ${state.campaign_name}`} className="mt-0 to-capitalize" />
      </div>

      <div className="row">
        <div className="col-12 mt-4 text-white">
          <button className="btn-default" onClick={_toggleAddModal}>
            {' '}
            + Nueva configuración del cupón
          </button>
        </div>
      </div>
      <div className="row border rounded-10 mt-5 p-3">
        <div className="col-12">
          <div className="row">
            <Title size="sm" text={`Listado de configuraciones`} className="mt-3 mb-3 bold" />
          </div>
          <Table data={state.coupons} autoHeight rowClassName="striped" locale={{ emptyMessage: 'no hay configuraciones' }}>
            {TableCouponConfig &&
              TableCouponConfig.map((column: any, index: any) => (
                <Table.Column flexGrow={column.key !== 'code' ? 1 : 0} key={`table-column-${index}`}>
                  <Table.HeaderCell>
                    <span style={{ textTransform: 'capitalize' }}>{column.label}</span>
                  </Table.HeaderCell>
                  <Table.Cell>
                    {(rowData) => {
                      switch (column.key) {
                        case 'status':
                          return (
                            <Toggle
                              checked={rowData[column.key]}
                              onChange={(e: any) => _handleOnChangeStatusCouponConfig({ row: rowData, target: { name: 'status', value: e } })}
                            />
                          );
                        case 'fund':
                          return (
                            <Whisper trigger="hover" placement="auto" controlId={`control-id-auto`} speaker={<Tooltip>{rowData[column.key]}</Tooltip>}>
                              <span style={{ textTransform: 'capitalize' }}>${rowData[column.key]?.toLocaleString('pt-BR')}</span>
                            </Whisper>
                          );
                        default:
                          return (
                            <Whisper trigger="hover" placement="auto" controlId={`control-id-auto`} speaker={<Tooltip>{rowData[column.key]}</Tooltip>}>
                              <span style={{ textTransform: 'capitalize' }}>{rowData[column.key]}</span>
                            </Whisper>
                          );
                      }
                    }}
                  </Table.Cell>
                </Table.Column>
              ))}

            <Table.Column align="right" flexGrow={1} verticalAlign="middle">
              <Table.HeaderCell>{''}</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  return <Actions payload={rowData} />;
                }}
              </Table.Cell>
            </Table.Column>
          </Table>
        </div>
      </div>
    </div>
  );
};
export default Coupons;

/* eslint-disable react-hooks/exhaustive-deps */
import { Loader } from "@googlemaps/js-api-loader";
import { useEffect, useRef, useState } from "react";
import ResultItem from "./ResultItem";
import { toast } from "react-toastify";
import { _getGoogleAddressComponents } from "../../helpers/Utils";

interface GoogleAutocompleteProps {
    onClick: (data: any) => void;
    onChange?: (data: any) => void;
    initialData?: {
        latitude: number,
        longitude: number,
        direction: string,
        _string: string,
        address: string,
        action: string
    };
}

const GoogleAutocomplete = ({ onClick, onChange, initialData }: GoogleAutocompleteProps) => {

    const [state, setState] = useState({
        google: null,
        autocompleteService: null,
        map: null,
        api_key: "",
        inputs: {
            address: "",
        },
        directionData: {
            latitude: 0,
            longitude: 0,
            direction: "",
            _string: "",
        }
    } as {
        google: any,
        autocompleteService: any,
        map: any,
        api_key: string,
        directionData: {
            latitude: number,
            longitude: number,
            direction: string,
            _string: string,
        },
        inputs: {
            address: string
        }
    })

    const googleRef = useRef<any>(null);
    const googleAutocompleteRef = useRef<any>(null);
    const googleMapRef = useRef<any>(null);

    const [results, setResults] = useState<any>([]);
    const [markers, setMarkers] = useState<any>([]);

    useEffect(() => {

        if (state.google) return;

        /**
         * LOAD GOOGLE MAPS
         */
        const loader: any = new Loader({
            apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : '',
            version: 'weekly',
            libraries: ['places', 'geometry'],
            ...{},
        });

        /**
         * CREATE AUTOCOMPLETE SERVICE
         * CREATE MAP
         */
        loader.load().then((google: any) => {
            const autocompleteService = new google.maps.places.AutocompleteService();
            let location = { lat: -33.44875413813615, lng: -70.66793565449402 };
            let zoom = 10;

            const map = new google.maps.Map(document.querySelector('#companymaps'), {
                center: location,
                zoom: zoom,
            });

            if (initialData && initialData?.latitude && initialData?.longitude && initialData?.action === "update") {
                location = { lat: initialData.latitude, lng: initialData.longitude };
                zoom = 19
                state.inputs = initialData

                const LatLng: any = new google.maps.LatLng(initialData.latitude, initialData.longitude);
                markers.forEach((item: any) => {
                    item.setMap(null);
                });

                const marker: any = new google.maps.Marker({
                    position: LatLng,
                    map: map,
                });

                map.setCenter(LatLng);
                map.setZoom(19);
                markers.push(marker);
                setMarkers(markers);

                map.addListener('click', (event: any) => {
                    _handleOnClickMap(event)
                });
            }

            googleRef.current = google
            googleAutocompleteRef.current = autocompleteService
            googleMapRef.current = map

            setState({
                ...state,
                google,
                autocompleteService,
                api_key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
                map
            });
        });

    }, []);

    /**
     * HANDLE AUTOCOMPLETE RESULTS
     */
    const _handleOnChange = ({ target }: any) => {
        onChange && onChange(target.value);
        setState({ ...state, inputs: { ...state.inputs, [target.name]: target.value } });
        const { autocompleteService } = state;
        if (!autocompleteService) return;

        if (target.value === '') {
            setResults([]);
        } else {
            autocompleteService.getPlacePredictions({ input: target.value, componentRestrictions: { country: 'cl' } }, _displaySuggestions);
        }
    }

    /**
    * SHOW AUTOCOMPLETE RESULTS
    */
    const _displaySuggestions = (payload: any, status: any) => {
        if (status !== 'OK') {
            setResults([]);
            return;
        }

        if (payload && payload.length > 0) {
            const results = payload.map((item: any) => {
                let show: any = true;
                let number: any = 0;
                item.terms.forEach((term: any) => {
                    if (!isNaN(term.value)) {
                        show = true;
                        number = Number(term.value);
                    }
                });
                const data: any = {
                    title: item.structured_formatting.main_text,
                    subtitle: item.structured_formatting.secondary_text,
                    description: item.description,
                    place_id: item.place_id,
                    number: number,
                    show: show,
                };
                return data;
            });

            setResults(results.filter((item: any) => item.show));
        }
    };

    /**
    * HANDLE CLICK ON MAP
    */
    const _handleOnClickMap = (event: any) => {
        const LatLng: any = new googleRef.current.maps.LatLng(event.latLng.lat(), event.latLng.lng());
        markers.forEach((item: any) => {
            item.setMap(null);
        });

        const marker: any = new googleRef.current.maps.Marker({
            position: LatLng,
            map: googleMapRef.current,
        });

        googleMapRef.current.setCenter(LatLng);
        googleMapRef.current.setZoom(19);
        markers.push(marker);
        setMarkers(markers);



        setState((prevState: any) => {
            const data = {
                ...prevState,
                directionData: {
                    ...prevState.directionData,
                    latitude: event.latLng.lat(),
                    longitude: event.latLng.lng(),
                }
            }

            onClick({ ...data.inputs, ...data.directionData, });

            return {
                ...data
            }
        });


    }

    const _handleOnClickGoogleItem = (payload: any) => {
        const { google } = state;
        const geocoder = new google.maps.Geocoder();

        /** 
         * GET LATITUD AND LONGITUD FROM GOOGLE
         */
        geocoder.geocode({ placeId: payload.place_id }, async (response: any, status: any) => {
            if (status === 'OK') {
                if (response.length > 0) {
                    const directions = response[0];
                    const { geometry, address_components, formatted_address } = directions;
                    const { location } = geometry;
                    const latitude = location.lat();
                    const longitude = location.lng();

                    /**
                     * FORMAT ADDRESS
                     */
                    const { streetNumber, street, country, commune_adm_lvl_3, region } = _getGoogleAddressComponents(address_components, payload.number);

                    if (!street) {
                        toast.error('No fue posible encontrar la dirección');
                        return;
                    }

                    const address: any = `${street?.long_name} ${streetNumber?.long_name}, ${commune_adm_lvl_3?.long_name || ''} - ${region?.long_name || ''} ${country?.long_name || ''}`;



                    setState((prevState: any) => {
                        const data = {
                            ...prevState,
                            inputs: {
                                ...prevState.inputs,
                                address: address,
                            },
                            directionData: {
                                latitude,
                                longitude,
                                direction: formatted_address,
                                _string: formatted_address,
                            }
                        }

                        onClick({ ...data.directionData, ...data.inputs });

                        return {
                            ...data
                        }
                    });

                    /**
                     * CLEAR RESULTS
                     */
                    setResults([])


                    /**
                     * SET MARKER
                     * ADD EVENT LISTENER TO MAP
                     */

                    const { map } = state;

                    const LatLng: any = new google.maps.LatLng(latitude, longitude);
                    markers.forEach((item: any) => {
                        item.setMap(null);
                    });

                    const marker: any = new google.maps.Marker({
                        position: LatLng,
                        map: map,
                    });

                    map.setCenter(LatLng);
                    map.setZoom(19);
                    markers.push(marker);
                    setMarkers(markers);

                    map.addListener('click', (event: any) => {
                        _handleOnClickMap(event)
                    });
                }
            }
        });
    };

    return (
        <div className="position-relative">
            <input name="address" onChange={_handleOnChange} value={state.inputs.address} className="input-default w-100" type="text" placeholder="Dirección donde se realizará el servicio" />
            {results && results.length > 0 ? (
                <div className="position-absolute bg-white w-100 border shadow p-2" style={{ zIndex: 100 }}>
                    {results.map((item: any, index: any) => (
                        <ResultItem className="p-2 border-bottom" key={item.place_id} item={item} onClick={_handleOnClickGoogleItem} />
                    ))}
                </div>
            ) : null}
        </div>
    )
}

export default GoogleAutocomplete;
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Title from "../../components/Title";
import ModalComponent from "../../components/Modal";
import UserForm from "./UserForm";
import UserTable from "./UserTable";
import User from "../../services/user.service";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { LOADING_OFF, LOADING_ON } from "../../redux/actions/loader";
import Pagination from "../../components/PaginationFront/Pagination";
import usePagination from "../../hooks/usePagination";
import ConfirmationModal from "../../components/ConfirmationModal";
import MassiveLoad from "./MassiveLoad";

const UsersCompany = () => {
    const [state, setState] = useState<any>({
        formModal: {
            openCreate: false,
            openUpdate: false,
            massiveLoad: false
        },
        confirmationModal: {
            open: false
        },
        user: {},
        data: [],
        search: '',
    })
    const dispatch: any = useDispatch()

    const onPrev = async (response: any) => {
        _formatData(response.data)
        const users: any = response.data
        setState({
            ...state,
            data: users,
        });
    };

    const onNext = async (response: any) => {
        _formatData(response.data)
        const users: any = response.data
        setState({
            ...state,
            data: users,
        });
    };

    const { currentPage, prevPage, nextPage, setFirstPage } = usePagination({ onPrev, onNext, service: User, endpoint: '_list' });


    useEffect(() => {
        _getData()
    }, [])

    const _getData = async () => {
        dispatch(LOADING_ON());
        try {
            const response: any = await User._list("CLIENT_COMPANY")
            _formatData(response.data)
            setFirstPage(response.next_page);
            setState({
                ...state,
                data: response.data,
                formModal: {
                    openCreate: false,
                    openUpdate: false,
                    massiveLoad: false,
                },
                confirmationModal: {
                    open: false
                }
            })
            dispatch(LOADING_OFF())
        } catch (e: any) {
            toast.error("No fue posible cargar los usuarios")
            dispatch(LOADING_OFF())
        }
    }

    const _formatData = (data: any) => {
        data.map((el: any) => {
            el.status = !el.info?.isInactive;
            return el;
        })

        return data;
    }

    const _toggleUserFormModal = (event: any, target: any = "openCreate") => {
        setState({
            ...state,
            formModal: {
                ...state.formModal,
                [target]: !state.formModal[target],
            }
        })
    }

    const closeModal = (target: any = 'openCreate') => {
        setState({
            ...state,
            formModal: {
                ...state.formModal,
                [target]: false,
            }
        })
    }

    const _handleUpdate = (payload: any) => {
        setState({
            ...state,
            user: payload,
            formModal: {
                ...state.formModal,
                openUpdate: true
            }
        })
    }

    const _handleCloseConfirmation = () => {
        setState({
            ...state,
            user: {},
            confirmationModal: {
                open: false,
                text: ''

            }
        })
    }

    const _handleToggleStatus = (payload: any) => {
        const text: any = `¿Seguro que quieres ${payload.status ? 'desactivar' : 'activar'} este usuario?`
        setState({
            ...state,
            user: payload,
            confirmationModal: {
                open: true,
                text

            }
        })
    }

    const _toggleStatus = async () => {
        const { user } = state
        try {
            dispatch(LOADING_ON())
            user.info.isInactive = user.status;
            delete user.status;
            await User._update(user);
            const { data } = state;
            const userTable: any = data.find((el: any) => el.PK === user.PK && el.SK === user.SK)

            if (userTable) {
                userTable.status = !user.info.isInactive
                setState({
                    ...state,
                    confirmationModal: { open: false, text: '' },
                    data
                })
            }
            dispatch(LOADING_OFF())
        } catch (e: any) {
            dispatch(LOADING_OFF())
            toast.error(e?.message || "No fue posible cambiar el status de este usuario")
        }
    }

    const _handleSearch = async () => {
        try {
            dispatch(LOADING_ON())
            if (!state.search) {
                _getData();
                return
            }
            const response: any = await User._search({ email: state.search, role: "CLIENT_COMPANY" })
            const data: any = response.data?.filter((el: any) => el.GS2PK === `ROLE#CLIENT_COMPANY`)
            _formatData(data)
            setFirstPage(response.next_page)
            setState({
                ...state,
                data: data
            })
            dispatch(LOADING_OFF())
        } catch (e: any) {
            dispatch(LOADING_OFF())
            toast.error("No fue posible encontrar este usuario")
        }
    }

    const handleCsvLoad = async (data: any) => {
        try {
            dispatch(LOADING_ON())
            await User._massiveLoad(data);
            _getData();
            dispatch(LOADING_OFF())
        } catch (e: any) {
            dispatch(LOADING_OFF())
            toast.error(e?.message || "No fue posible cargar los usuarios")
        }
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <Title size="sm" text={`Usuarios`} className="mt-5 to-upper bold" />
            </div>

            <div className="row">
                <div className="col-12 col-md-5 color-white">
                    <input
                        type="text"
                        className="input-default w-100 color-black"
                        placeholder="buscar por correo electronico"
                        value={state.search}
                        onChange={(e: any) => setState({ ...state, search: e.target.value })}
                    />
                </div>
                                
                <div className="col-12 col-md-7 text-end color-white">
                    <button className="btn-default me-3" onClick={_handleSearch}>
                        Buscar
                    </button>
                    <button className="btn-default me-3" onClick={_toggleUserFormModal}>
                        + Nuevo Usuario
                    </button>
                    <button className="btn-default" onClick={(e: any) => _toggleUserFormModal(e, "massiveLoad")}>
                        + Carga Masiva
                    </button>
                </div>
            </div>

            <ModalComponent open={state.formModal.openCreate} size="md" noFull handleClose={(e: any) => _toggleUserFormModal("openCreate")} hideFooter>
                <UserForm action="create" handleClose={(e: any) => closeModal("openCreate")} handleSuccess={_getData} />
            </ModalComponent>

            <ModalComponent open={state.formModal.openUpdate} size="md" noFull handleClose={(e: any) => _toggleUserFormModal(e, "openUpdate")} hideFooter>
                <UserForm action="update" handleClose={(e: any) => closeModal("openUpdate")} handleSuccess={_getData} data={state.user} />
            </ModalComponent>

            <ModalComponent open={state.formModal.massiveLoad} size="md" noFull handleClose={(e: any) => _toggleUserFormModal(e, "massiveLoad")} hideFooter>
                <MassiveLoad handleConfirm={(data) => handleCsvLoad(data)} />
            </ModalComponent>

            <ConfirmationModal size="md" title="Atención" type="warning" noFull open={state.confirmationModal.open} handleClose={_handleCloseConfirmation} handleConfirm={_toggleStatus}>
                <span className="px-4">{state.confirmationModal.text}</span>
            </ConfirmationModal>

            <UserTable data={state.data} handleUpdate={_handleUpdate} toggleStatus={_handleToggleStatus} />

            <Pagination nextPage={nextPage} prevPage={prevPage} index={currentPage.index} />
        </div>
    )
}

export default UsersCompany;
import { Provider } from 'react-redux';
import AppRouter from './routes/AppRouter';
import store from './redux/store';
import { CustomProvider } from 'rsuite';
import esES from 'rsuite/locales/es_ES';

function App() {
  return (
    <Provider store={store}>
      <CustomProvider locale={esES}>
        <AppRouter />
      </CustomProvider>
    </Provider>
  );
}

export default App;

/* eslint-disable no-useless-escape */
import * as yup from 'yup';

export const SignUpSchema = yup.object().shape({
  email: yup
    .string()
    .matches(/^[\w+.]+@\w{2,}\.\w{2,}(?:\.\w{2,})?$/, 'Formato de email no es válido')
    .required('Este campo es requerido'),
  name: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .max(255, 'Este campo acepta un máximo de 255 caracteres')
    .required('Este campo es requerido'),
  family_name: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .max(255, 'Este campo acepta un máximo de 255 caracteres')
    .required('Este campo es requerido'),
  phone_number: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .required('Este campo es requerido'),
  rut: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .required('Este campo es requerido'),
});

export type SignUpType = yup.InferType<typeof SignUpSchema>;

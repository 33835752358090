export const TableHeaderDriver = [
  {
    label: "Nombre",
    key: "name",
    flexGrow: 1,
  },
  {
    label: "Email",
    key: "email",
    flexGrow: 1,
  },
  {
    label: "Teléfono",
    key: "phone_number",
    flexGrow: 1,
  },
  {
    label: "Fecha creación",
    key: "created_at",
    flexGrow: 1,
  },
];

export const TableHeaderCar = [
  {
    label: "ID",
    key: "PK",
    flexGrow: 1,
  },
  {
    label: "Modelo",
    key: "model",
    flexGrow: 1,
  },
  {
    label: "Patente",
    key: "plate",
    flexGrow: 1,
  },
  {
    label: "Capacidad",
    key: "capacity",
    flexGrow: 1,
  },
  {
    label: "Conductor",
    key: "driver_id",
    flexGrow: 1,
  },
];

export const SelectActions = [
  {
    label: "Editar",
    value: 1,
  },
];

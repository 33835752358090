/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Table } from 'rsuite';
import ModalComponent from '../../components/Modal';
import Title from '../../components/Title';
import validate from '../../helpers/validate';
import { SignUpSchema } from '../../validations/signup.validations';
import AddConsessionaire from './AddConsessionaire';
import { TableHeader } from './tableConfigs';
import { validateRut } from '../../helpers/Utils';
import UpdateConcessionaire from './UpdateConcessionaire';
import concessionaireService from '../../services/concessionaire.service';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import usePagination from '../../hooks/usePagination';
import Pagination from '../../components/PaginationFront/Pagination';

const Concessionaire = (props: any) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    inputs: {
      email: '',
      password: '',
      password_c: '',
      phone_number: '',
      rut: '',
      name: '',
      family_name: '',
    },
    validations: {},
    modal: {
      add: {
        show: false,
      },
      update: {
        show: false,
      },
    },
    data: [],
  });

  const onPrev = async (response: any) => {
    setState({
      ...state,
      data: response.data,
    });
  };

  const onNext = async (response: any) => {
    setState({
      ...state,
      data: response.data,
    });
  };

  const endpoint = 'list';

  const { currentPage, prevPage, nextPage, setFirstPage } = usePagination({ onPrev, onNext, service: concessionaireService, endpoint });

  useEffect(() => {
    _getConcessionaires();
  }, []);

  const _toggleAddModal = () => setState({ ...state, validations: {}, modal: { ...state.modal, add: { show: !state.modal.add.show } } });
  const _toggleUpdateModal = () => setState({ ...state, validations: {}, modal: { ...state.modal, update: { show: !state.modal.update.show } } });

  const _handleOnChange = ({ target }: any) => {
    const { name, value } = target;
    setState({ ...state, inputs: { ...state.inputs, [name]: value } });
  };

  const _getConcessionaires = async () => {
    try {
      dispatch(LOADING_ON());
      const response = await concessionaireService.list();

      setFirstPage(response.next_page);

      dispatch(LOADING_OFF());
      setState({
        ...state,
        data: response.data,
        modal: {
          add: {
            show: false,
          },
          update: {
            show: false,
          },
        },
      });
    } catch (e: any) {
      dispatch(LOADING_OFF());
      toast.error('No fue posible rescatar los concesionarios');
    }
  };

  const _handleCreate = async (action: any) => {
    let validations = await validate(SignUpSchema, state.inputs);

    if (state.inputs.phone_number?.replace(/_/g, '')?.length !== 12) {
      validations = { ...validations, phone_number: ['Número de teléfono inválido'] };
    }

    if (!validateRut(state.inputs.rut)) {
      validations = { ...validations, rut: ['El rut no es válido'] };
    }

    if (validations) {
      setState({ ...state, validations: validations });
      return;
    }

    setState({ ...state, validations: {} });
    dispatch(LOADING_ON());

    if (action === 'create') {
      try {
        const paramsConcessionaire = {
          name: `${state.inputs.name} ${state.inputs.family_name}`,
          info: {
            email: state.inputs.email,
            rut: state.inputs.rut,
            phone_number: state.inputs.phone_number,
          },
        };
        const response = await concessionaireService.create(paramsConcessionaire);

        const user: any = {
          ...state.inputs,
          info: {
            roles: ['concessionaire'],
            role: 'concessionaire',
            permissions: ['all'],
            concessionaire_id: response.data.PK,
            eds_id: null,
            isAuthZero: true,
          },
        };

        delete user.password;
        delete user.password_c;

        await concessionaireService.createUser(user);
        toast.success('Concesionario registrado con éxito');
        _getConcessionaires();
      } catch (e: any) {
        if (e && e.message) {
          const errors = e.message.split('::');
          if (errors.length > 1) {
            concessionaireService.delete(errors[0]);
            toast(errors[1]);
          } else {
            toast.error('No fue posible crear su cuenta. Vuelve a intentarlo en unos minutos.');
          }
        } else {
          toast.error('No fue posible crear su cuenta. Vuelve a intentarlo en unos minutos.');
        }
      }
    }

    if (action === 'update') {
      try {
        await concessionaireService.update({ ...state.inputs });
        toast.success('Concesionario actualizado con éxito');
        _toggleAddModal();
      } catch (e: any) {
        if (e && e.response) {
          if (e.response.data.error) {
            const error: any = e.response.data.error.split(':');
            if (error.length > 1) {
              toast.error(error[1]);
            } else {
              toast.error(error[0]);
            }
          } else {
            toast.error('No fue posible actualizar su cuenta. Vuelve a intentarlo en unos minutos.');
          }
        } else {
          toast.error('No fue posible actualizar su cuenta. Vuelve a intentarlo en unos minutos.');
        }
      }
    }
    dispatch(LOADING_OFF());
  };

  return (
    <div className="container-fluid">
      <ModalComponent size="md" noFull open={state.modal.add.show} handleClose={_toggleAddModal} handleConfirm={() => _handleCreate('create')}>
        <AddConsessionaire data={state} handleChange={_handleOnChange} />
      </ModalComponent>

      <ModalComponent size="md" noFull open={state.modal.update.show} handleClose={_toggleUpdateModal} handleConfirm={() => _handleCreate('update')}>
        <UpdateConcessionaire data={state} handleChange={_handleOnChange} />
      </ModalComponent>

      <div className="row">
        <Title size="sm" text={`Concesionario`} className="mt-5 to-upper bold" />
      </div>

      <div className="row">
        <div className="col-12 text-end color-white">
          <button className="btn-default" onClick={_toggleAddModal}>
            {' '}
            + Nuevo Concesionario
          </button>
        </div>
      </div>

      <div className="row border rounded-10 p-3 my-5">
        <div className="col-12">
          <Table data={state.data} autoHeight rowHeight={80} locale={{ emptyMessage: 'No hay concesionarios' }}>
            {TableHeader &&
              TableHeader.map((column: any, index: any) => (
                <Table.Column align={column.align} flexGrow={column.flexGrow} key={`table-column-${index}`}>
                  <Table.HeaderCell>
                    <span className="bold" style={{ textTransform: 'capitalize' }}>
                      {column.label}
                    </span>
                  </Table.HeaderCell>
                  <Table.Cell style={{ display: 'flex', alignItems: 'center', justifyContent: column.alignFlex }}>
                    {(rowData) => {
                      switch (column.key) {
                        case 'email':
                          return <span>{rowData?.info?.email}</span>;
                        default:
                          return <span>{rowData[column.key]}</span>;
                      }
                    }}
                  </Table.Cell>
                </Table.Column>
              ))}
          </Table>
        </div>
      </div>

      <Pagination nextPage={nextPage} prevPage={prevPage} index={currentPage.index} />
    </div>
  );
};
export default Concessionaire;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Table, Tooltip, Whisper } from 'rsuite';
import ConfirmationModal from '../../components/ConfirmationModal';
import ModalComponent from '../../components/Modal';
import { MoreMenu } from '../../components/MoreMenu';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import CarsService from '../../services/car.service';
import ModalCreateCar from './ModalCreateCar';
import ModalUpdateCar from './ModalUpdateCar';
import { TableHeaderCar } from './tableConfig';
import { _carSpeaker } from './_speakers';

const Cars = (props: any) => {
  const [state, setState] = useState<any>({
    modal: {
      car: false,
      update_car: false,
    },
    confirmation: false,
    drivers: [],
    cars: [],
    car: {},
    delete_PK: null,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    console.log('DRIVERS', props.drivers)
    const drivers: any = props.drivers.map((driver: any) => {
      return {
        value: driver.SK,
        label: driver.name + ' ' + driver.family_name,
      };
    });
    setState({
      ...state,
      cars: props.data,
      drivers: drivers,
      modal: {
        car: false,
        update_car: false,
      },
      confirmation: false,
    });
  }, [props.data]);

  const _handleActions = (target: any, payload: any) => {
    switch (target) {
      case 1:
        setState({
          ...state,
          car: payload,
          modal: { ...state.modal, car_update: true },
        });
        break;
      case 2:
        setState({ ...state, confirmation: true, delete_PK: payload.PK, delete_SK: payload.SK });
        break;
      default:
        console.log(target, payload);
    }
  };

  const _handleDeleteCar = async () => {
    try {
      dispatch(LOADING_ON());

      const car: any = state.cars.find((item: any) => item.PK === state.delete_PK);
      console.log(car, state.cars);
      await CarsService.deleteVehicle({ PK: car.PK, SK: car.SK });
      await _handleFinishActions();
      toast.success('Vehículo eliminado con éxito');
    } catch (e: any) {
      console.log('LOGGER', e.message);
      toast.error('No fue posible eliminar este vehículo');
      dispatch(LOADING_OFF());
    }
  };

  const _handleFinishActions = async () => {
    await props.handleEndUpdate();
  };

  const Actions = (props: any) => {
    const { payload } = props;
    const _speaker: any = _carSpeaker(payload, _handleActions);
    return (
      <button className="me-3 btn">
        <Whisper controlId={payload.PK} placement="auto" trigger="click" speaker={(whisper_payload: any, ref: any) => MoreMenu(whisper_payload, ref, _speaker)}>
          <i className="bi bi-three-dots"></i>
        </Whisper>
      </button>
    );
  };

  const _toggleModal = (target: any) => {
    setState({
      ...state,
      modal: { ...state.modal, [target]: !state.modal[target] },
    });
  };

  return (
    <div className="row" style={{ minHeight: '70vh' }}>
      <ConfirmationModal
        open={state.confirmation}
        size="md"
        type="warning"
        title="Eliminar"
        handleClose={() => setState({ ...state, confirmation: false })}
        handleConfirm={_handleDeleteCar}
      >
        ¿Seguro que quieres eliminar este vehículo?
      </ConfirmationModal>

      <ModalComponent open={state.modal.car} noFull hideFooter handleClose={() => _toggleModal('car')}>
        <ModalCreateCar drivers={state.drivers} PK={props.eds?.PK} handleClose={_handleFinishActions} cars={props.cars} />
      </ModalComponent>

      <ModalComponent open={state.modal.car_update} noFull hideFooter handleClose={() => _toggleModal('car_update')}>
        <ModalUpdateCar drivers={state.drivers} car={state.car} handleClose={_handleFinishActions} PK={props.eds?.PK} cars={props.cars} />
      </ModalComponent>

      <div className="col-12 text-end mb-3 text-white">
        <button className="btn-default" onClick={() => _toggleModal('car')}>
          {' '}
          + Nuevo Vehículo
        </button>
      </div>
      <div className="col-12 h-100">
        <Table data={state.cars} rowClassName="striped" rowHeight={70} autoHeight locale={{ emptyMessage: 'No hay vehículos' }}>
          {TableHeaderCar &&
            TableHeaderCar.map((column: any, index: any) => (
              <Table.Column align={column.align} flexGrow={column.flexGrow} key={`table-column-${index}`}>
                <Table.HeaderCell>
                  <span style={{ textTransform: 'capitalize' }}>{column.label}</span>
                </Table.HeaderCell>
                <Table.Cell
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: column.alignFlex,
                  }}
                >
                  {(rowData) => {
                    switch (column.key) {
                      case 'model':
                      case 'brand':
                        return <span>{rowData?.info[column.key].label}</span>;
                      case 'capacity':
                        return <span>{rowData?.info[column.key]}</span>;
                      case 'driver_id':
                        return (
                          <Whisper
                            trigger="hover"
                            placement="auto"
                            controlId={`control-id-auto`}
                            speaker={<Tooltip>{state.drivers.find((driver: any) => driver.value === rowData.info?.driver_id)?.label}</Tooltip>}
                          >
                            <div
                              style={{
                                textTransform: 'capitalize',
                                width: '100%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {state.drivers.find((driver: any) => driver.value === rowData?.info?.driver_id)?.label}
                            </div>
                          </Whisper>
                        );
                      default:
                        return <span>{rowData[column.key]}</span>;
                    }
                  }}
                </Table.Cell>
              </Table.Column>
            ))}

          <Table.Column align="right" flexGrow={1} verticalAlign="middle">
            <Table.HeaderCell>{''}</Table.HeaderCell>
            <Table.Cell>
              {(rowData) => {
                return <Actions payload={rowData} />;
              }}
            </Table.Cell>
          </Table.Column>
        </Table>
      </div>
    </div>
  );
};

export default Cars;

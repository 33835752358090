import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { LOADING_OFF, LOADING_ON } from '../redux/actions/loader';
import { toast } from 'react-toastify';

const usePagination = (props: any) => {
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState({
    index: 0,
    next_page: {
      LastEvaluatedKey: {},
    },
  });

  const [pages, setPages] = useState([
    {
      index: 0,
      currentKey: {}, // the LastEvaluatedKey for this element
      next_page: {
        LastEvaluatedKey: {},
      },
    },
  ]);

  const setFirstPage = (nextPage: any) => {
    const firstPage = {
      index: 1,
      currentKey: {},
      next_page: nextPage,
    };
    setCurrentPage(firstPage);
    setPages([firstPage]);
  };

  const prevPage = async () => {
    dispatch(LOADING_ON());

    const prevPage = currentPage.index - 1;

    const prevItem = pages.find((item) => item.index === prevPage);

    if (!prevItem) {
      toast.warning(`Ya estas en la pagina ${currentPage.index}`);
      dispatch(LOADING_OFF());
      return;
    }

    const keyPrevPage = prevItem.currentKey;

    let prevPageUri = encodeURIComponent(JSON.stringify(keyPrevPage));

    let pageQuery;

    if (Object.keys(keyPrevPage).length > 0) {
      pageQuery = { last: prevPageUri };
    } else {
      pageQuery = {};
    }

    const response: any = await props.service[props.endpoint](pageQuery);

    const newCurrentPage = {
      index: currentPage.index - 1,
      currentKey: keyPrevPage, // The key for querying specifcally this page
      next_page: response.next_page,
    };

    setCurrentPage(newCurrentPage);
    dispatch(LOADING_OFF());

    props.onPrev(response);

  };

  const nextPage = async () => {
    dispatch(LOADING_ON());
    // Having LastEvaluatedKey means theres probably more results
    const nextPage = currentPage.next_page;

    if (nextPage && 'LastEvaluatedKey' in nextPage) {
      const lastKey = nextPage.LastEvaluatedKey; // the key for the current page

      let nextPageUri = encodeURIComponent(JSON.stringify(lastKey));

      const response: any = await props.service[props.endpoint]({ last: nextPageUri, role: props.role });

      const newCurrentPage = {
        index: currentPage.index + 1,
        currentKey: lastKey, // The key for querying specifcally this page
        next_page: response.next_page,
      };

      setCurrentPage(newCurrentPage);
      setPages([...pages, newCurrentPage]);
      dispatch(LOADING_OFF());

      props.onNext(response);
    } else {
      dispatch(LOADING_OFF());
      toast.error(`No hay más resultados.`);
    }
  };

  return {
    currentPage,
    setCurrentPage,
    pages,
    setPages,
    prevPage,
    nextPage,
    setFirstPage,
  };
};

export default usePagination;

import { createAction } from 'redux-actions';
import { CLEAR_CONFIGS } from './configs';

export const SIGNIN: any = createAction('SIGNIN');
export const SIGNOUT: any = createAction('SIGNOUT');
export const SAVE_STATE: any = createAction('SAVE_STATUS');

export const signin = (payload: any) => (dispatch: any) => {
  dispatch(SIGNIN(payload));
  dispatch(SAVE_STATE());
}

export const signout = () => (dispatch: any) => {
  dispatch(SIGNOUT());
  dispatch(CLEAR_CONFIGS())
  dispatch(SAVE_STATE());
}
/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { SelectPicker, Toggle, Uploader } from 'rsuite';
import { ServiceCategory, ServiceTypes } from '../../types';
import { toast } from 'react-toastify';
import { service_format } from './service_formats';
import { convertToBase64AndFormatImage, createImageName } from '../../helpers/convert_and_clone';
import { ServiceSchema } from '../../validations/service.validations';
import { ServiceKeroseneSchema } from '../../validations/service.kerosene.validations';
import { SubServiceSchema } from '../../validations/subservice.validations';
import { useDispatch } from 'react-redux';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import KeroseneSubService from './KeroseneSubService';
import validate from '../../helpers/validate';
import Service from '../../services/service_and_products.service';
import Title from '../Title';
import MediaFiles from '../../services/media.service';
import RelatedProducts from '../../services/products.service';
import LavamaxSubService from './LavamaxSubService';
import LubSubService from './LubSubService';
import ErrorValidation from '../ErrorValidation';
import KeroseneScheduleSubService from './KeroseneScheduleSubService';
import DynamicPopUp from './DynamicPopUp';
import { ServicePopupSchema } from '../../validations/service.popup.validations';
import WhisperComponent from '../WhisperComponent';
import { ReactComponent as QuestionMarkIcon } from '../../assets/image/svg/question_mark.svg';
import SubscriptionSubService from './SubscriptionSubService';
import { ServiceSubscriptionSchema } from '../../validations/service.subscription.validations';
import { SubServiceSubscriptonSchema } from '../../validations/subservice.subscription.validations';
import { convertTypeNames } from '../../helpers/Utils';
import EDSDAO from '../../services/eds.service';
import { campaign } from '../../mocks/campaign';

const FormDynamicService = (props: any) => {
  const { handleClose, action, data, PK, recommended_subservice } = props;
  const dispatch: any = useDispatch();

  const [state, setState] = useState<any>({
    service: { ...service_format },
    sub_services: [],
    components: [],
    service_model: null,
    fileList: [],
    popUpFileList: [],
    validations: {},
    types: [],
    sizes: [],
    shippings: [],
    sub_services_list: [],
    eds_list: [],
  });

  useEffect(() => {
    if (action === 'create') {
      _handleGetEmptySubService();
    } else {
      _handleGetData();
    }
  }, []);

  const _handleGetEmptySubService = async () => {
    try {
      dispatch(LOADING_ON());
      const types: any = ServiceCategory();
      const sizes: any = ServiceTypes();
      const { availableServices, availableEds } = await _getServiceAndEdsData();
      setState({ ...state, types, sizes, sub_services_list: availableServices, eds_list: availableEds });
      dispatch(LOADING_OFF());
    } catch (e: any) {
      dispatch(LOADING_OFF());
      toast.error('No fue posible cargar los servicios');
    }
  };

  const _getServiceAndEdsData = async () => {
    const responseServices = await Service.listAllServices();
    const availableServices: any = responseServices.data
      .filter((item: any) => item.type !== 'recommended_subservice')
      .map((s: any) => {
        const type: any = convertTypeNames(s.type);
        return {
          label: `${s.name}`,
          value: s.PK,
          type: type,
        };
      });

    const responseEds = await EDSDAO.getAllAvailableEds();
    const availableEds: any = responseEds.data
      .filter((item: any) => item.name !== '' && item.name !== undefined)
      .map((s: any) => {
        return {
          label: `${s.name}`,
          value: s.PK,
        };
      });

    availableEds.unshift({
      label: 'Todos',
      value: 'all',
    });

    return { availableServices, availableEds };
  };

  const _handleGetData = async () => {
    dispatch(LOADING_ON());
    const types: any = ServiceCategory();
    const sizes: any = ServiceTypes();
    const response = await Service.details({ PK: data?.PK, SK: data?.SK });

    const { availableServices, availableEds } = await _getServiceAndEdsData();

    dispatch(LOADING_OFF());
    const service: any = { ...service_format, ...response.data };
    const { sub_services } = service;

    if (response.data.type === 'lub') {
      sub_services.sort((a: any, b: any) => {
        if (!a?.info?.recommended) return 1;
        if (a?.info?.recommended) return -1;
        return 0;
      });
    }

    if (response.data.type === 'kerosene') {
      sub_services.sort((a: any, b: any) => {
        if (!a?.info?.required) return 1;
        if (a?.info?.required) return -1;
        return 0;
      });
    }

    if (response.data.type === 'lavamax') {
      sub_services.sort((a: any, b: any) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
    }

    const shippings: any = [];

    if (service.type === 'subscription') {
      sub_services?.map((sub_service: any) => {
        if (sub_service.info.subscription_config) {
          sub_service.info.apply = sub_service.info.subscription_config.setup.apply;
          sub_service.info.type_discount = sub_service.info.subscription_config.setup.type_discount;
          sub_service.info.pk_services = sub_service.info.subscription_config.setup.pk_services;
          sub_service.info.pk_eds = sub_service.info.subscription_config.setup.pk_eds;
          sub_service.info.max_amount_discount = sub_service.info.subscription_config.setup.max_amount_discount;
          sub_service.info.number_of_coupons_to_create = sub_service.info.subscription_config.setup.number_of_coupons_to_create;
          sub_service.info.subscription_duration = sub_service.info.subscription_config.setup.subscription_duration;
        }
        return sub_service;
      });
    }

    for (const ss of sub_services) {
      if (ss.name !== 'Envio' && ss.name !== 'Envío') {
        ss.descripcion = ss.descripcion.replace(/<br\s*[\/]?>/gi, '\n');
        _addSubService(false, ss, response.data.type);
      } else {
        shippings.push(ss);
      }
    }

    service.liter = service.info?.liter;
    service.base_division = service.info?.base_division;
    service.min_reservation_time = service.info?.min_reservation_time || 1;
    service.dont_show_grey_service = service.info?.dont_show_grey_service || 0;
    service.is_company = service.info?.is_company || 0;

    if (service.info.popup_info) {
      service.show_dynamic_popup = service.info?.popup_info?.isEnabled;
      service.popup_title = service.info?.popup_info?.title;
      service.popup_obs = service.info?.popup_info?.observations;
      service.popup_description = service.info?.popup_info?.description?.join('\n');
    }

    setState((prevState: any) => {
      return { ...prevState, types, service_model: response.data.type, service, sizes, shippings, sub_services_list: availableServices, eds_list: availableEds };
    });
  };

  const _handleOnChange = ({ target }: any) => {
    const { name, value } = target;
    if (name === 'price' || name === 'shipping_price' || name === 'base_division') {
      state.service[name] = value.replace(/\D/g, '');
    } else {
      state.service[name] = value;
    }
    setState({ ...state });
  };

  const _handleChangeServiceModel = ({ target }: any) => {
    state[target.name] = target.value;
    setState({ ...state });
    _addSubService(true);
  };

  const _handleOnChangeSubService = (target: any) => {
    /**
     * @info_names_acepted: names acepted into info object
     * @target_not_acepted: names not acepted into parent object
     */
    const info_names_acepted: any = [
      'sku',
      'air_filter',
      'extra_lubricant_sku',
      'full_points',
      'without_discount_price',
      'is_unvailable',
      'points_per_division',
      'subscription_config',
      'pk_services',
      'pk_eds',
      'number_of_coupons_to_create',
      'subscription_duration',
      'apply',
      'type_discount',
      'max_amount_discount',
    ];
    const target_not_acepted: any = [
      'sku',
      'full_points',
      'required',
      'recommended',
      'air_filter',
      'extra_lubricant_sku',
      'points_per_division',
      'pk_services',
      'pk_eds',
      'number_of_coupons_to_create',
      'subscription_duration',
      'apply',
      'type_discount',
      'max_amount_discount',
    ];

    /**
     * Fields with single true value
     */
    if (target.name === 'required' || target.name === 'recommended') {
      state.sub_services = state.sub_services.map((ss: any) => {
        if (ss.SK === target.SK) {
          ss = { ...ss, state: { ...ss.state, info: { ...ss.state.info, [target.name]: true, updated: true } } };
        } else {
          ss = { ...ss, state: { ...ss.state, info: { ...ss.state.info, [target.name]: false, updated: true } } };
        }
        return ss;
      });
    }

    if (info_names_acepted.find((item: any) => item === target.name)) {
      const sub_service: any = state.sub_services.find((ss: any) => ss.SK === target.SK);
      sub_service.state.info[target.name] = target.value;
      sub_service.state[target.name] = target.value;
      sub_service.state.info.updated = true;
    }

    if (!target_not_acepted.find((item: any) => item === target.name)) {
      const sub_service: any = state.sub_services.find((ss: any) => ss.SK === target.SK);
      sub_service.state[target.name] = target.value;
      sub_service.state.info.updated = true;
    }

    if (target.name === 'apply' && target.value === 'sobre_envio') {
      const sub_service: any = state.sub_services.find((ss: any) => ss.SK === target.SK);
      sub_service.state.info.type_discount = '';
      sub_service.state.info.max_amount_discount = '';
      if (sub_service.state.validations && sub_service.state.validations["info.type_discount"]) {
        delete sub_service.state.validations["info.type_discount"];
      }
    }

    setState({ ...state });
  };

  const _handleImage = async (e: any) => {
    try {
      if (e.length > 0) {
        const imageData = await convertToBase64AndFormatImage(e, state.fileList, 'service');
        setState({
          ...state,
          image: imageData,
          fileList: [e[state.fileList.length > 0 ? state.fileList.length : 0]],
        });
      } else {
        setState({ ...state, image: null, fileList: [] });
      }
    } catch (e: any) {
      toast.error('No fue posible cargar la imagen.');
    }
  };

  const _handlePopUpImage = async (e: any) => {
    try {
      if (e.length > 0) {
        const imageData = await convertToBase64AndFormatImage(e, state.popUpFileList, 'service');
        setState({
          ...state,
          imagePopup: imageData,
          popUpFileList: [e[state.popUpFileList.length > 0 ? state.popUpFileList.length : 0]],
        });
      } else {
        setState({ ...state, imagePopup: null, popUpFileList: [] });
      }
    } catch (e: any) {
      toast.error('No fue posible cargar la imagen.');
    }
  };

  const _handleImageSubService = async (e: any, SK: any, type: any = null) => {
    const sub_service: any = state.sub_services.find((ss: any) => ss.SK === SK);
    if (e.length > 0) {
      const imageData = await convertToBase64AndFormatImage(e, sub_service.state.fileList, type || 'service');
      sub_service.state.image = imageData;
      sub_service.state.fileList = [e[e.length - 1]];
      sub_service.state.info.updated = true;
    } else {
      sub_service.state.image = null;
      sub_service.state.fileList = [];
    }

    setState({ ...state });
  };

  const _deleteSubService = (PK: any) => {
    const sub_services: any = state.sub_services.filter((ss: any) => ss.PK !== PK);
    state.sub_services = [...sub_services];
    setState({ ...state });
  };

  const _addSubService = (empty: any = false, data: any = null, type: any = null) => {
    let Component: any = null;
    let PK: any = data ? data.PK : uuid();
    let SK: any = data ? data.SK : uuid();
    let stateComponent: any = {
      name: '',
      price: '',
      descripcion: '',
      fileList: [],
      image: null,
      validations: {},
      PK: PK,
      SK: SK,
      new: true,
      info: {
        required: false,
        recommended: false,
        sku: '',
        extra_lubricant_sku: '',
        extra_lubricant: '',
        full_points: '',
        pk_services: [],
        pk_eds: [],
        number_of_coupons_to_create: '',
        start: new Date(),
        end: new Date(),
        apply: '',
        type_discount: '',
      },
    };

    if (data) {
      const sizes: any = ServiceTypes();
      const type: any = sizes?.find((item: any) => item.size === data?.info?.size)?.value;
      stateComponent = {
        ...stateComponent,
        ...data,
        new: false,
        type,
        recommended_subservices: data.info?.recommended_subservices || [],
        info: { ...stateComponent.info, ...data.info },
      };
    }

    const modelType: any = type || state.service_model;

    switch (modelType) {
      case 'kerosene':
        Component = (data: any) => <KeroseneSubService {...data} />;
        break;
      case 'kerosene-schedule':
        Component = (data: any) => <KeroseneScheduleSubService {...data} />;
        break;
      case 'lavamax':
        Component = (data: any) => <LavamaxSubService {...data} />;
        break;
      case 'lub':
        Component = (data: any) => <LubSubService {...data} />;
        break;
      case 'subscription':
        Component = (data: any) => <SubscriptionSubService {...data} />;
        break;
      default:
        Component = (data: any) => <KeroseneSubService {...data} />;
    }

    if (data === null && (empty || state.sub_services.length === 0)) {
      stateComponent.info.required = true;
      stateComponent.info.recommended = true;
      state.sub_services = [
        {
          Component: Component,
          state: stateComponent,
          PK: PK,
          SK: SK,
        },
      ];
    } else {
      state.sub_services.push({
        Component: Component,
        state: stateComponent,
        PK: PK,
        SK: SK,
      });
    }

    setState((prevState: any) => {
      return { ...prevState, ...state };
    });
  };

  const _handleSubmit = async () => {
    let serviceValidations: any = {};
    let sub_services_validations: any = false;
    let related_products: any = [];

    if (state.service_model === 'kerosene' || state.service_model === 'kerosene-schedule') {
      serviceValidations = await validate(ServiceKeroseneSchema, state.service);
    }

    if (state.service_model !== 'kerosene' && state.service_model !== 'kerosene-schedule' && state.service_model !== 'subscription') {
      serviceValidations = await validate(ServiceSchema, state.service);
    }

    if (state.service_model === 'subscription') {
      serviceValidations = await validate(ServiceSubscriptionSchema, state.service);
    }

    if (state.service_model === 'lub') {
      try {
        dispatch(LOADING_ON());
        let i: any = 0;
        let loop: any = true;
        let responseRelatedProducts: any = null;
        let listResponse: any = [];

        while (loop) {
          if (i > 0) {
            const { next_page } = responseRelatedProducts;

            if (next_page && 'LastEvaluatedKey' in next_page) {
              const lastKey = next_page.LastEvaluatedKey;

              let nextPageUri = encodeURIComponent(JSON.stringify(lastKey));

              responseRelatedProducts = await RelatedProducts.list({ last: nextPageUri }, 100);

              const { data } = responseRelatedProducts;

              listResponse = [...listResponse, ...data];
            } else {
              loop = false;
            }
          } else {
            responseRelatedProducts = await RelatedProducts.list(null, 100);

            const { data } = responseRelatedProducts;

            listResponse = [...listResponse, ...data];
          }
          i++;
        }

        related_products = listResponse;
      } catch (e: any) {
        dispatch(LOADING_OFF());
        toast.error('No fue posible cargar los productos relacionados.');
      }
    }

    // Validate subservice data

    for (const sub_service of state.sub_services) {
      const validationSchema = state.service_model === 'subscription' ? SubServiceSubscriptonSchema : SubServiceSchema;
      let validations = await validate(validationSchema, sub_service.state);

      if (state.service_model === 'lub' && sub_service.state?.info?.extra_lubricant_sku) {
        const lubricant: any = related_products.find((rp: any) => rp.sku === sub_service.state?.info?.extra_lubricant_sku);
        if (!lubricant) {
          if (validations) {
            validations.extra_lubricant_sku = ['SKU inválido'];
          } else {
            validations = { extra_lubricant_sku: ['SKU inválido'] };
          }
        }
      }

      sub_service.state.validations = validations || {};
      if (validations !== false) {
        sub_services_validations = true;
      }
    }

    // Validate popup data if is enabled

    if (state.service.show_dynamic_popup) {
      let popUpValidations: any = await validate(ServicePopupSchema, state.service);

      if (popUpValidations && !serviceValidations) {
        serviceValidations = { ...popUpValidations };
      }

      if (popUpValidations && serviceValidations) {
        serviceValidations = { ...serviceValidations, ...popUpValidations };
      }
    }

    /**
     * check if all validations pass
     */

    if (sub_services_validations || serviceValidations !== false) {
      dispatch(LOADING_OFF());
      setState({ ...state, validations: serviceValidations });
      return;
    }

    setState({ ...state });

    const service: any = { ...service_format };
    const slug = state.service.name.toLowerCase().trim().replace(/\s/g, '-');

    service.price = null;
    service.shipping_price = Number(state.service.shipping_price);

    const popUpData: any = {
      ...state.service?.info?.popup_info,
      isEnabled: 0,
    };

    if (state.service.show_dynamic_popup) {
      const popup_description: any = state.service.popup_description.replace(/(?:\r\n|\r|\n)/g, '||');
      const descriptionArray: any = popup_description.split('||') || [];
      popUpData.description = descriptionArray;
      popUpData.title = state.service.popup_title;
      popUpData.observations = state.service.popup_obs;
      popUpData.isEnabled = 1;
    }

    service.info = {
      ...state.service.info,
      slug,
      liter: state.service.liter ? Number(state.service.liter) : 0,
      base_division: state.service.base_division ? Number(state.service.base_division) : 0,
      min_reservation_time: state.service.min_reservation_time ? Number(state.service.min_reservation_time) : 0,
      dont_show_grey_service: state.service.dont_show_grey_service ? 1 : 0,
      is_company: state.service.is_company ? 1 : 0,
      popup_info: popUpData,
    };

    delete service.show_dynamic_popup;
    delete service.popup_title;
    delete service.popup_obs;
    delete service.popup_description;
    delete service.base_division;

    service.slug = slug;
    service.name = state.service.name;
    service.type = state.service_model;
    service.liter = state.service.liter ? Number(state.service.liter) : 0;
    service.min_reservation_time = state.service.min_reservation_time ? Number(state.service.min_reservation_time) : 0;
    service.scheduling = state.service_model === 'kerosene' ? false : true;
    service.sub_services = [];

    if (state.service_model === 'kerosene') {
      const mainProduct: any = state.sub_services.find((sub_service: any) => sub_service.state?.info?.required);
      if (mainProduct) {
        mainProduct.state.info.updated = true;
      }
    }

    state.sub_services.forEach((ss: any) => {
      if (ss.state.info?.updated) {
        const item: any = {};
        item.shipping_price = Number(state.service.shipping_price);
        item.price = Number(ss.state.price);
        item.scheduling = state.service_model === 'kerosene' ? false : true;
        item.name = ss.state.name;
        if (ss.state.new === false) {
          item.PK = ss.PK;
          item.SK = ss.SK;
        }

        if (ss.state.type) {
          item.type = ss.state.type;
        }

        item.info = {
          ...ss.state.info,
          size: state.sizes.find((type: any) => type.value === ss.state.type)?.size,
          dinamic_PK: ss.PK,
          dinamic_SK: ss.SK,
          recommended_subservices: ss.state.recommended_subservices ? ss.state.recommended_subservices : [],
          liter: service.liter || null,
        };

        if (state.service_model === 'lub' && ss.state?.info?.extra_lubricant_sku) {
          const lubricant: any = related_products.find((rp: any) => rp.sku === ss.state?.info?.extra_lubricant_sku);
          item.info.extra_lubricant_sku = ss.state.info?.extra_lubricant_sku;
          item.info.extra_lubricant = lubricant || null;
        } else {
          item.info.extra_lubricant_sku = null;
          item.info.extra_lubricant = null;
        }

        delete item.info.updated;
        item.descripcion = ss.state.descripcion.replace(/(?:\r\n|\r|\n)/g, '<br>');
        service.sub_services.push({ ...item });
      }
    });

    if (state.service_model === 'subscription') {
      const fields_to_delete = ['number_of_coupons_to_create', 'subscription_duration', 'apply', 'type_discount', 'pk_services', 'pk_eds', 'end', 'start', 'max_amount_discount'];
      service.scheduling = false;
      service.sub_services.forEach((sub_service: any) => {
        sub_service.scheduling = false;
        sub_service.type_subservice = 'subscription';

        sub_service.info.required = true;
        sub_service.info.subscription_config = campaign;
        sub_service.info.subscription_config.name = sub_service.name;
        sub_service.info.subscription_config.description = sub_service.name;

        sub_service.info.subscription_config.lock.start = new Date();
        sub_service.info.subscription_config.lock.end = new Date();

        sub_service.info.subscription_config.setup.name = sub_service.name;
        sub_service.info.subscription_config.setup.start = new Date();
        sub_service.info.subscription_config.setup.end = new Date();
        sub_service.info.subscription_config.setup.apply = sub_service.info.apply;
        sub_service.info.subscription_config.setup.type_discount = sub_service.info.type_discount;
        sub_service.info.subscription_config.setup.pk_services = sub_service.info.pk_services;
        sub_service.info.subscription_config.setup.pk_eds = sub_service.info.pk_eds;
        sub_service.info.subscription_config.setup.max_amount_discount = sub_service.info.max_amount_discount;
        sub_service.info.subscription_config.setup.number_of_coupons_to_create = sub_service.info.number_of_coupons_to_create;
        sub_service.info.subscription_config.setup.subscription_duration = sub_service.info.subscription_duration;
        sub_service.info.type_subservice = 'subscription';

        Object.keys(sub_service).forEach((key: any) => {
          if (fields_to_delete.includes(key)) {
            delete sub_service[key];
          }
        });

        Object.keys(sub_service.info).forEach((key: any) => {
          if (fields_to_delete.includes(key)) {
            delete sub_service.info[key];
          }
        });
      });
    }

    if (state.shippings?.length > 0) {
      state.shippings.forEach((item: any) => {
        item.price = service.shipping_price;
        service.sub_services.push(item);
      });
    }

    dispatch(LOADING_ON());

    try {
      let responseActionService: any = null;
      let countImagesToSend: any = 0;

      if (action === 'create') {
        responseActionService = await Service.create(service);
      } else {
        service.PK = PK;
        service.SK = state.service.SK;
        responseActionService = await Service.update(service);
      }

      if (state.image && state.fileList.length > 0) {
        countImagesToSend += 1;
      }

      if (state.imagePopup && state.popUpFileList.length > 0) {
        countImagesToSend += 1;
      }

      for (const subService of state.sub_services) {
        if (subService.state.image && subService.state.fileList.length > 0) {
          countImagesToSend += 1;
        }
      }

      if (countImagesToSend) {
        const serviceDetails: any = await Service.details({ PK: responseActionService.data.PK, SK: responseActionService.data.SK });
        const serviceData: any = serviceDetails.data;

        /**
         * UPLOAD SERVICE IMAGE
         */

        if (state.image && state.fileList.length > 0) {
          const imageService = { ...state.image };
          imageService.element_pk = serviceData.PK;

          //CREATE IMAGE NAME
          const image_name: any = createImageName(imageService?.file?.name, service?.name);

          // CREATE UPLOAD URL
          const presignUrl = await MediaFiles.presigned({ path: `services/${image_name.toLowerCase()}`, content_type: state.image.file.type });

          // UPLOAD FILE
          await fetch(presignUrl.data.url, {
            body: state.image.file,
            method: 'PUT',
            headers: new Headers({ 'content-type': state.image.file.type, 'x-amz-acl': 'public-read' }),
          });

          //GET IMG URL
          const finalImageUrl: any = presignUrl.data?.url?.split('?')[0];
          serviceData.info = {
            ...serviceData.info,
            gallery: { url: finalImageUrl },
          };
        }

        /**
         * UPLOAD POPUP IMAGE
         */

        if (state.imagePopup && state.popUpFileList.length > 0) {
          const imageService = { ...state.imagePopup };
          imageService.element_pk = serviceData.PK;

          //CREATE IMAGE NAME
          const image_name: any = createImageName(imageService?.file?.name, 'popup');

          // CREATE UPLOAD URL
          const presignUrl = await MediaFiles.presigned({ path: `services/${image_name.toLowerCase()}`, content_type: state.imagePopup?.file.type });

          // UPLOAD FILE
          await fetch(presignUrl.data.url, {
            body: state.imagePopup.file,
            method: 'PUT',
            headers: new Headers({ 'content-type': state.imagePopup.file.type, 'x-amz-acl': 'public-read' }),
          });

          //GET IMG URL
          const finalImageUrl: any = presignUrl.data?.url?.split('?')[0];
          serviceData.info = {
            ...serviceData.info,
            popup_info: {
              ...serviceData.info?.popup_info,
              image_url: finalImageUrl,
            },
          };
        }

        /**
         * UPLOAD SUBSERVICE IMAGE
         */

        const { sub_services } = serviceData;
        for (const subService of state.sub_services) {
          if (subService.state.image && subService.state.fileList.length > 0) {
            const item: any = sub_services.find((ss: any) => ss.info?.dinamic_SK === subService.SK);
            if (item) {
              const { image } = subService.state;
              image.element_pk = item.SK;

              //CREATE IMAGE NAME
              const image_name: any = createImageName(image?.file?.name, subService?.state?.name);

              // CREATE UPLOAD URL
              const presignUrl = await MediaFiles.presigned({ path: `services/${image_name.toLowerCase()}`, content_type: image.file.type });

              // UPLOAD FILE
              await fetch(presignUrl.data.url, {
                body: image.file,
                method: 'PUT',
                headers: new Headers({ 'content-type': image.file.type, 'x-amz-acl': 'public-read' }),
              });

              //GET IMG URL
              const finalImageUrl: any = presignUrl.data?.url?.split('?')[0];
              item.info = {
                ...item.info,
                gallery: { url: finalImageUrl },
              };
            }
          }
        }

        delete serviceData.gallery;
        await Service.update(serviceData);
      }
      toast.success(`Servicio ${action === 'create' ? 'creado' : 'actualizado'} con éxito`);
      handleClose();
    } catch (e: any) {
      toast.error(`No fue posible ${action === 'create' ? 'crear' : 'actualizar'} el servicio`);
      dispatch(LOADING_OFF());
    }
  };

  return (
    <div className="component-services px-5 mb-4">
      <div className="row mb-3">
        <Title size="sm" text="Nuevo Servicio" className="bold to-upper size-11" />
      </div>

      <div className="row">
        <div className="col-12">
          <span className="size-08 bold me-4">Tipo de servicio</span>
          <div>
            <SelectPicker
              classPrefix="copec"
              className="rs-copec-cleanable"
              cleanable={false}
              placeholder="Selecciona el tipo de servicio"
              onChange={(e: any) => _handleChangeServiceModel({ target: { name: 'service_model', value: e } })}
              data={state.types}
              value={state.service_model}
              disabled={action === 'update'}
              locale={{ searchPlaceholder: 'Buscar', noResultsText: 'Ningún resultado encontrado.', emptyMessage: 'Ningún resultado encontrado.' }}
            />
          </div>
          <ErrorValidation validations={state.validations} name="service_model" className="bold size-09 text-end" />
        </div>
      </div>

      {/* SERVICES */}

      {state.service_model !== null && (
        <div className="container-fluid p-0 m-0">
          <div className="row mt-3">
            <div className="col-4">
              <span className="size-08 bold me-4">Nombre servicio</span>
              <div>
                <input value={state.service?.name} onChange={_handleOnChange} name="name" className="input-default w-100" type="text" placeholder="Nombre del servicio" />
              </div>
              <ErrorValidation validations={state.validations} name="name" className="bold size-09 text-end" />
            </div>

            <div className="col-4">
              <span className="size-08 bold me-4">Precio Despacho</span>
              <div>
                <input
                  value={state.service.shipping_price}
                  onChange={_handleOnChange}
                  name="shipping_price"
                  maxLength={12}
                  className="input-default w-100"
                  type="string"
                  placeholder="10000"
                />
              </div>
              <ErrorValidation validations={state.validations} name="shipping_price" className="bold size-09 text-end" />
            </div>

            <div className="col-4">
              <span className="size-08 bold me-2">Imagen</span>
              <div className="w-100">
                <Uploader accept=".png, .jpg, .jpeg" listType="text" fileList={state.fileList} onChange={_handleImage} autoUpload={false}>
                  <button>
                    <span className="me-1">+</span> Seleccionar archivo
                  </button>
                </Uploader>
              </div>
            </div>

            <div className="col-4 mt-3">
              <div className="d-flex justify-content-start">
                <span className="size-08 bold me-1">Base para calcular Puntos Full</span>
                <span>
                  <WhisperComponent
                    text={`Valor por el que se dividirá el monto antes de multiplicar por el número de puntos`}
                    placement="topStart"
                    className="p-0 m-0 size-08 bold flex aling-items-center"
                    align="left"
                  >
                    <QuestionMarkIcon className="ms-1 mb-1" style={{ width: '20px', height: '20px' }} />
                  </WhisperComponent>
                </span>
              </div>
              <div>
                <input
                  value={state.service.base_division}
                  onChange={_handleOnChange}
                  name="base_division"
                  maxLength={12}
                  className="input-default w-100"
                  type="string"
                  placeholder="100"
                />
              </div>
              <ErrorValidation validations={state.validations} name="base_division" className="bold size-09 text-end" />
            </div>

            {state.service_model === 'kerosene' || state.service_model === 'kerosene-schedule' ? (
              <div className="col-4 mt-3">
                <span className="size-08 bold me-4">Litros del Bidón</span>
                <div>
                  <input value={state.service.liter} onChange={_handleOnChange} name="liter" className="input-default w-100" type="number" placeholder="20" />
                </div>
                <ErrorValidation validations={state.validations} name="liter" className="bold size-09 text-end" />
              </div>
            ) : null}

            {state.service_model !== 'kerosene' && state.service_model !== 'subscription' ? (
              <div className="col-4 mt-3">
                <span className="size-08 bold me-4">Tiempo minimo para reserva</span>
                <div>
                  <input
                    value={state.service.min_reservation_time}
                    onChange={_handleOnChange}
                    name="min_reservation_time"
                    className="input-default w-100"
                    type="number"
                    placeholder="Informar valor en horas"
                  />
                </div>
                <ErrorValidation validations={state.validations} name="min_reservation_time" className="bold size-09 text-end" />
              </div>
            ) : null}
          </div>

          <div className="row">
            <div className="col-4 mt-3">
              <span className="size-08 bold me-4">No mostrar el servicio cuando no esté disponible.</span>
              <div className="d-flex align-items-center" style={{ height: '50px' }}>
                <Toggle checked={state.service.dont_show_grey_service} onChange={(e: any) => _handleOnChange({ target: { name: 'dont_show_grey_service', value: e } })} />
              </div>
            </div>

            <div className="col-4 mt-3">
              <span className="size-08 bold me-4">Servicio tipo empresa</span>
              <div className="d-flex align-items-center" style={{ height: '50px' }}>
                <Toggle checked={state.service.is_company} onChange={(e: any) => _handleOnChange({ target: { name: 'is_company', value: e } })} />
              </div>
            </div>
          </div>

          <hr style={{ opacity: '.1' }} />

          <div className="row">
            <div className="col-12 bold">Configuraciones Popup</div>
          </div>

          <DynamicPopUp data={state} _handleOnChange={_handleOnChange} validations={state.validations} _handlePopUpImage={_handlePopUpImage} />

          <hr style={{ opacity: '.1' }} />

          <div className="row justify-content-end mt-3">
            <div className="col-12 text-end text-white">
              <button className="btn-default" onClick={() => _addSubService()}>
                <span className="me-1">+</span> Nuevo SubServicio o Producto
              </button>
            </div>
          </div>

          <div className="row">
            {state.sub_services.map((Component: any, index: any) => (
              <Component.Component
                key={index}
                state={Component.state}
                type={state.service_model}
                recommended_subservice={recommended_subservice}
                _handleOnChange={_handleOnChangeSubService}
                _handleImage={_handleImageSubService}
                _handleDelete={_deleteSubService}
                PK={Component.PK}
                SK={Component.SK}
                sub_services_list={state.sub_services_list}
                eds_list={state.eds_list}
              />
            ))}
          </div>
        </div>
      )}

      {/* FOOTER */}

      <div className={`row my-4 justify-content-around`}>
        <div className="col-3 text-center text-white">
          <button className="btn-danger" onClick={handleClose}>
            Cancelar
          </button>
        </div>
        <div className="col-3 text-center text-white">
          <button className="btn-default" onClick={_handleSubmit} disabled={state.loading || !state.service_model}>
            Guardar
            {state.loading && <div className="spinner-border ms-2" role="status" style={{ width: '15px', height: '15px' }} />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormDynamicService;

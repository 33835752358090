export const inputs: any = {
  pk: null,
  name: '',
  pk_services: [],
  pk_eds: [],
  apply: '',
  limitsame_campaign: false,
  restriccion: false,
  use_limit: 0,
  limit_used: 0,
  type_discount: '',
  max_amount_discount: 0,
  minimum_amount: false,
  minimum_amount_in_cart: 0,
  cumulative_day_discount: false,
  vigencia: '',
  start: '',
  end: '',
  apply_days: [],
  codigo: '',
  codigo_n: '',
  codigo_list: [],
  codigo_file: {},
};

import instance from './api_account';
import store from '../redux/store';
import { getSession } from '../helpers/session';

instance.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error: any) {
    // if (error.response && error.response.status === 401) {
    //   const dispatch: any = store.dispatch;
    //   dispatch(signout())
    // }
    return Promise.reject(error);
  }
);

class CarsDataObject {
  _setHeaders = () => {
    const state: any = store.getState();
    const { session } = state;

    const oidcSession = getSession();

    if (oidcSession) {
      instance.defaults.headers.common['user'] = session.profile?.PK;
      instance.defaults.headers.common['usersk'] = session.profile?.SK;
      instance.defaults.headers.common['Authorization'] = `Bearer ${oidcSession?.access_token}`;
    }
  };

  createVehicle = async (params: any) => {
    this._setHeaders();
    try {
      const state: any = store.getState();
      const response = await instance.post(`/account/admin/vehicle/create`, params, {
        headers: { user: state.session.profile.PK },
      });
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  updateVehicles = async (PK: any, payload: any) => {
    this._setHeaders();
    try {
      const state: any = store.getState();
      const response = await instance.put(`/account/admin/vehicle/update/${PK}`, payload, { headers: { user: state.session.profile.PK } });
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  deleteVehicle = async (payload: any) => {
    this._setHeaders();
    try {
      const state: any = store.getState();
      const response = await instance.delete(`/account/admin/vehicle/delete/${payload.PK}`, {
        headers: { user: state.session.profile.PK },
        data: { hard: true, PK: payload.PK, SK: payload.SK },
      });
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getVehicles = async (payload: any) => {
    this._setHeaders();
    try {
      const response = await instance.get(`/account/admin/vehicle/list?eds_id=${payload.PK}&PK=${payload.PK}&SK=${payload.SK}`);
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };
}

const CarsService = new CarsDataObject();
export default CarsService;

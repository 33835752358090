import { Divider, Tooltip, Whisper } from 'rsuite';
import ParagraphIcon from '@rsuite/icons/Paragraph';
import { useState } from 'react';
import DrawerComponent from '../../../components/Drawer';
import OrderDetails from '../OrderDetails';
import UpdateOrderData from '../../../components/Orders/UpdateOrderData';
import UpdateOrder from '../../../components/Orders/UpdateOrder';
import Reschedule from '../../../components/Orders/Reschedule';
import CancelOrder from '../../../components/CancelOrder';

const OrderCard = (props: any) => {
  const { _actionMenuSpeaker, MoreMenu, handleCloseAndUpdate } = props;
  const [open, setOpen] = useState(false);
  const [option, setOption] = useState(0);

  const handleSelectChange = (payload: any) => {
    setOption(payload.option);
    _toggleDrawer();
  };

  const _toggleDrawer = () => {
    const html = document.querySelector('html');

    if (html) {
      if (open) {
        html.style.overflowY = 'auto';
      } else {
        html.style.overflowY = 'hidden';
      }
    }

    setOpen(!open);
  };

  const _submitUpdateOrder = () => {
    handleCloseAndUpdate();
    _toggleDrawer();
  };

  const Actions = (props: any) => {
    const { payload } = props;
    const _speaker: any = _actionMenuSpeaker(payload, handleSelectChange);
    return (
      <div className="row mb-3 align-items-center">
        <div className="col-12 text-end">
          <Whisper placement="auto" speaker={<Tooltip>Detalles</Tooltip>}>
            <button className="btn px-0" onClick={() => handleSelectChange({ option: 2, id: payload.PK })}>
              <ParagraphIcon />
            </button>
          </Whisper>
          <Divider vertical />
          <span style={{ cursor: 'pointer' }}>
            <Whisper controlId={payload.PK} placement="auto" trigger="click" speaker={(whisper_payload: any, ref: any) => MoreMenu(whisper_payload, ref, _speaker)}>
              <i className="bi bi-three-dots"></i>
            </Whisper>
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="col-12 border rounded p-3 mb-2 size-08 shadow-sm" style={{ overflow: 'hidden' }}>
      <Actions payload={props.data} />

      <DrawerComponent open={open} setOpen={_toggleDrawer} handleClose={_toggleDrawer} title="Orden" data={props.data} status={props.status}>
        {open && option === 1 ? <UpdateOrder data={props.data} handleCloseAndUpdate={_submitUpdateOrder} /> : null}
        {open && option === 2 ? <OrderDetails /> : null}
        {open && option === 3 ? <UpdateOrderData data={props.data} handleCloseAndUpdate={_submitUpdateOrder} /> : null}
        {open && option === 4 ? <Reschedule order={props.data} handleSuccess={_submitUpdateOrder} /> : null}
        {open && option === 5 ? <CancelOrder order={props.data} handleCloseAndUpdate={_submitUpdateOrder} /> : null}
      </DrawerComponent>

      <div className="row mb-2">
        <div className="col-4 bold-300">ID Orden</div>
        <div className="col-8">{props.data?.PK}</div>
      </div>

      <div className="row mb-2 align-items-center">
        <div className="col-4 bold-300">Servicio</div>
        <div className="col-8">{props.services.find((service: any) => service.value === props.data?.GS4PK)?.label}</div>
      </div>

      <div className="row mb-2 align-items-center">
        <div className="col-4 bold-300">Nombre</div>
        <div className="col-8 capitalize">{props.data?.user?.name?.toLowerCase()}</div>
      </div>

      <div className="row mb-2 align-items-center">
        <div className="col-4 bold-300">Email</div>
        <div className="col-8">{props.data?.user?.email}</div>
      </div>

      <div className="row mb-2 align-items-center">
        <div className="col-4 bold-300">Estado</div>
        <div className="col-8">{props.status.find((status: any) => status.value?.toLowerCase() === props.data?.GS1PK?.split('-')[1]?.toLowerCase())?.label}</div>
      </div>

      <div className="row mb-2 align-items-center">
        <div className="col-4 bold-300">Dirección</div>
        <div className="col-8">
          <a href={`https://waze.com/ul?ll=${props.data?.info?.direction?.latitude},${props.data?.info?.direction?.longitude}&z=10&navigate=yes`} target="_blank" rel='noreferrer'>
            {props.data?.info?.direction?._string}
          </a>
        </div>
      </div>
    </div>
  );
};

export default OrderCard;

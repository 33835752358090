export const TableCampaign = [
  {
    label: 'Nombre campaña',
    key: 'name',
    align: 'center',
    alignFlex: 'center',
    flexGrow: 1,
  },
  {
    label: 'Descripción',
    key: 'description',
    align: 'center',
    alignFlex: 'center',
    flexGrow: 1,
  },
  {
    label: 'Fondo',
    key: 'fund',
    align: 'right',
    alignFlex: 'center',
    flexGrow: 1,
  },
  {
    label: 'Estado',
    key: 'status',
    align: 'center',
    alignFlex: 'center',
    flexGrow: 1,
  }
]

export const TableCouponConfig = [
  {
    label: 'Nombre',
    key: 'name',
    align: 'left',
    alignFlex: 'center',
    flexGrow: 1,
  },
  {
    label: 'Estado',
    key: 'status',
    align: 'center',
    alignFlex: 'center',
    flexGrow: 1,
  }
]


export const TableCoupon = [
  {
    label: 'Nombre',
    key: 'name',
    align: 'left',
    alignFlex: 'center',
    flexGrow: 1,
  },
  {
    label: 'Cupones Usados',
    key: 'use_count',
    align: 'center',
    alignFlex: 'center',
    flexGrow: 1,
  },
  {
    label: 'Estado',
    key: 'status',
    align: 'center',
    alignFlex: 'center',
    flexGrow: 1,
  }
]

import instance from './api_admin';
import instanceAccount from './api_account';
import store from '../redux/store';
import getAllData from '../helpers/get_all_data';
import { getSession } from '../helpers/session';

class ConcessionaireDataService {
  _setHeaders = () => {
    const state: any = store.getState();
    const { session } = state;

    const oidcSession = getSession();

    if (oidcSession) {
      instance.defaults.headers.common['user'] = session.profile?.PK;
      instance.defaults.headers.common['usersk'] = session.profile?.SK;
      instance.defaults.headers.common['Authorization'] = `Bearer ${oidcSession?.access_token}`;
      instanceAccount.defaults.headers.common['user'] = session.profile?.PK;
      instanceAccount.defaults.headers.common['usersk'] = session.profile?.SK;
      instanceAccount.defaults.headers.common['Authorization'] = `Bearer ${oidcSession?.access_token}`;
    }
  };

  create = async (params: any) => {
    this._setHeaders();
    try {
      params.roles = ['concessionaire'];
      params.role = 'concessionaire';
      params.permissions = ['all'];
      params.eds_id = null;

      const response = await instance.post('/admin/concessionaire/create', params);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
  createUser = async (params: any) => {
    this._setHeaders();
    try {
      const response = await instance.post('/admin/user/create', params);

      if (response?.data?.error) {
        throw new Error(`${params.concessionaire_id}::${response.data.error} `);
      }
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  update = async (params: any) => {
    this._setHeaders();
    try {
      const response = await instance.put(`/admin/concessionaire/update/${params.id}`, params);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  list = async (paginationParams: any = null) => {
    this._setHeaders();
    try {
      let params: any = [];
      if (paginationParams && paginationParams.last) {
        params.push(`last=${paginationParams.last}`);
      }
      const response = await instance.get(`/admin/concessionaire/list?limit=${process.env.REACT_APP_ORDERS_BASE_LIMIT}&${params.join('&')}`);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  listAll = async (limit: any = 10) => {
    this._setHeaders();
    try {
      const response: any = getAllData(instance, '/admin/concessionaire/list', limit);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  delete = async (PK: any) => {
    this._setHeaders();
    try {
      const response = await instance.delete(`/admin/concessionaire/delete/${PK}`, { data: { hard: true } });
      response.data = response.data.data;
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  relatedProducts = async (params: any) => {
    try {
      const response = await instanceAccount.post('/account/concessionaire/eds/services/products', params);
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
    }
  };
}

export default new ConcessionaireDataService();

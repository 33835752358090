/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { SelectPicker } from 'rsuite';
import validate from '../../helpers/validate';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import ordersService from '../../services/orders.service';
import { CancelOrderSchema } from '../../validations/cancelorder.validations';
import ErrorValidation from '../ErrorValidation';
import Title from '../Title';
import { reasons } from './reasons';

const CancelOrder = (props: any) => {
  const { order, handleClose, handleCloseAndUpdate } = props;

  const { session } = useSelector((state: any) => state);
  const dispatch: any = useDispatch();

  const [state, setState] = useState({
    inputs: {
      reason: undefined,
      observations: '',
      textField: false,
    },
    reasons: [],
    validations: {},
  });

  useEffect(() => {
    const { profile } = session;
    let reasonList: any = [];
    
    if (profile?.info.roles && Array.isArray(profile?.info?.roles)) {
      for (const role of profile?.info?.roles) {
        const reasonsFilter: any = reasons.filter((item: any) => item.role === role);
        reasonList = [...reasonList, ...reasonsFilter];
      }
    }

    if (profile?.info?.role) {
      reasonList = reasons.filter((item: any) => item.role === profile?.info?.role);
    }
    setState({ ...state, reasons: reasonList });
  }, []);

  const _handleOnChange = (event: any) => {
    const { target } = event;
    const { name, value } = target;
    const { reasons } = state;

    const reason: any = reasons.find((item: any) => item.value === value);

    setState({ ...state, inputs: { ...state.inputs, textField: reason.inputText, observations: '', [name]: value } });
  };

  const _handleOnSubmit = async () => {
    dispatch(LOADING_ON());
    if (!state.inputs.reason) {
      toast.warning('Selecciona un motivo antes de continuar.');
      dispatch(LOADING_OFF());
      return;
    }

    const validations: any = await validate(CancelOrderSchema, state.inputs);

    if (validations && Object.keys(validations).length > 0) {
      setState({ ...state, validations });
      dispatch(LOADING_OFF());
      return;
    }

    const reason: any = state.reasons.find((item: any) => item.value === state.inputs.reason);

    let cancel_reason: any = '';

    if (state.inputs.reason === 3 || state.inputs.reason === 6) {
      cancel_reason = `${reason?.label} ${state.inputs.observations}`;
    } else {
      cancel_reason = reason?.label;
    }

    try {
      await ordersService.changeStatus({
        PK: order?.PK,
        SK: order?.SK,
        status: 'canceled',
        cancel_reason: cancel_reason,
      });
      toast.success('Orden anulada con exito.');
      handleCloseAndUpdate();
    } catch (e: any) {
      toast.error('No fue posible anular esta orden.');
    }
  };

  return (
    <div className="container">
      <div className="row">
        <Title size="sm" text="Anular Pedido" className="bold to-upper size-11" />
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <div className="size-08 bold">Motivo</div>
          <div className="">
            <SelectPicker
              classPrefix="copec"
              className="rs-copec-cleanable"
              cleanable={false}
              value={state.inputs.reason}
              onChange={(e: any) => _handleOnChange({ target: { name: 'reason', value: e } })}
              data={state.reasons}
              locale={{ searchPlaceholder: 'Buscar', noResultsText: 'Ningún resultado encontrado.', emptyMessage: 'Ningún resultado encontrado.' }}
              placeholder="Motivo de anulación"
            />
          </div>
        </div>
      </div>

      {state.inputs.textField ? (
        <div className="row mt-3">
          <div className="col-12">
            <div className="size-08 bold">Informe el motivo</div>
            <div className="">
              <input
                value={state.inputs.observations}
                onChange={(e: any) => setState({ ...state, inputs: { ...state.inputs, observations: e.target.value } })}
                type="text"
                className="me-2 w-100 input-default"
                placeholder="Motivo"
              />
              <ErrorValidation name="observations" validations={state.validations} className="text-end bold-300 size-09" />
            </div>
          </div>
        </div>
      ) : null}

      <div className="row mt-4">
        <div className="col-12 text-center text-sm-end color-white">
          <button className="btn btn-danger me-5" onClick={handleClose}>
            Cancelar
          </button>
          <button className="btn btn-default color-white" onClick={_handleOnSubmit}>
            Confirmar
          </button>
        </div>
      </div>
    </div>
  );
};

export default CancelOrder;

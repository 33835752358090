/* eslint-disable no-useless-escape */
import * as yup from 'yup';

export const PersonalDataSchema = yup.object().shape({
  name: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .max(255, "Este campo acepta un máximo de 255 caracteres")
    .required('Este campo es requerido'),
  phone: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .min(12, 'Número de teléfono inválido')
    .nullable()
    .required('Este campo es requerido'),
  rut: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .required('Este campo es requerido'),
  email: yup
    .string()
    .matches(/^[\w+.]+@\w{2,}\.\w{2,}(?:\.\w{2,})?$/, 'Formato de email no es válido')
    .required('Este campo es requerido'),
});

export type EdsType = yup.InferType<typeof PersonalDataSchema>;

import * as yup from 'yup';

export const CouponSchema = yup.object().shape({
  codigo: yup.string().required('Este campo es requerido'),
  codigo_n: yup
    .string()
    .nullable()
    .when('codigo', {
      is: 'generado',
      then: yup.string().min(1, 'Este campo debe ser mayor a 1').notOneOf(['0'], 'Este campo debe ser mayor a 1').required('Este campo es requerido'),
    }),
  codigo_list: yup.array().when('codigo', {
    is: 'manual',
    then: yup.array().min(1, 'Ingrese al menos un código'),
  }),
  codigo_file: yup.object().when('codigo', {
    is: 'carga',
    then: yup.object().test('required', 'Selecciona un archivo', (value) => Object.keys(value).length > 0),
  }),
});

export type CampaignType = yup.InferType<typeof CouponSchema>;

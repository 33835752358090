export const campaign = {
  description: 'Nombre Suscripción',
  name: 'Nombre Suscripción',
  info: null,
  fund: 50000000000,
  data_info: null,
  lock: {
    start: '2023-08-08T13:43:44.489Z',
    end: '2026-07-31T13:43:44.489Z',
    on: false,
    status: false,
  },
  setup: {
    use_limit: 0,
    apply: 'carrito',
    apply_days: [],
    start: '2023-08-08T13:43:44.489Z',
    end: '2026-07-31T13:43:44.489Z',
    minimum_amount_in_cart: 0,
    limitsame_campaign: true,
    initial_quantity_available: 1,
    minimum_amount: false,
    type_discount: 'monto',
    max_amount_discount: '0',
    name: 'Limite de Usos por Cupon',
    pk_services: [],
    cumulative_day_discount: false,
    limit_used: 1,
    pk_eds: [],
    status: true,
    subscription_duration: '30',
  },
  status: true,
};

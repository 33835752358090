export const TableHeader = [
    {
        label: 'ID',
        key: 'PK',
        align: 'center',
        alignFlex: 'center',
        flexGrow: 1,
    },
    {
        label: 'Nombre',
        key: 'name',
        align: 'center',
        alignFlex: 'center',
        flexGrow: 1,
    },
    {
        label: 'Email',
        key: 'email',
        align: 'center',
        alignFlex: 'center',
        flexGrow: 1,
    }
]

export const TableData = [
    {
        PK: '123432',
        name: "Franco",
        email: 'fnascimento@gux.cl',
        phone_number: '569 9999 9999'
    }
]

export const SelectActions = [
    {
        label: 'Editar',
        value: 1
    }
]


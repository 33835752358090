import axios from 'axios';
// import store from '../redux/store';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ACCOUNT,
  headers: {
    'Content-type': 'application/json',
    'gux-api-key-account': process.env.REACT_APP_API_ACCOUNT || '',
  },
});
instance.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error: any) {
    if (error.response && error.response.status === 401) {
      window.dispatchEvent(new Event('logout_start'));
    }
    return Promise.reject(error);
  }
);

export default instance;

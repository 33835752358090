import { handleActions } from 'redux-actions';

import { SAVE_VERSION } from '../actions/version';

export const INITIAL_STATE: any = { version: '1.0.0' }

export default handleActions({
    [SAVE_VERSION]: (state: any, action: any) => {
        return { ...state, ...action.payload };
    }
}, INITIAL_STATE);

import { Toggle, DatePicker } from "rsuite"
import ErrorValidation from "../../components/ErrorValidation"

const AddCampaign = (props: any) => {
  const { data, handleChange, is_edit } = props
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 size-13 bold mb-4">
          {is_edit ? 'Editar' : 'Agregar'} campaña
        </div>
        <div className="col-6 mb-3">
          <span className="size-08 bold me-2">Nombre</span>
          <div className="">
            <input name="pk" value={data.inputs.pk} onChange={handleChange} type='hidden' />
            <input name="name" value={data.inputs.name} onChange={handleChange} className="input-default w-100" type="text" placeholder="Nombre"/>
          </div>
          <ErrorValidation className="size-09 bold-300 text-end" validations={data.validations} name="name" />
        </div>
        <div className="col-6 mb-3">
          <span className="size-08 bold me-2">Fondo ($)</span>
          <div className="">
            <input name="fund" maxLength={12} value={data.inputs.fund} onChange={handleChange} className="input-default w-100" type="text" placeholder="Fondo" />
          </div>
          <ErrorValidation className="size-09 bold-300 text-end" validations={data.validations} name="fund" />
        </div>
        <div className="col-8 mb-3">
          <span className="size-08 bold me-2">Descripción</span>
          <div className="">
            <input name="description" value={data.inputs.description} onChange={handleChange} className="input-default w-100" type="text" placeholder="Descripción" />
          </div>
          <ErrorValidation className="size-09 bold-300 text-end" validations={data.validations} name="description" />
        </div>
        <div className="col-4 mb-3">
          <span className="size-08 bold me-2">Estado</span>
          <div className="mt-2">
            <Toggle
              checked={data.inputs.status ? data.inputs.status : false}
              onChange={(e: any) => handleChange({ target: { name: 'status', value: e } })}
              className="me-2"
              style={{ width: 'fit-content' }}
            />
          </div>
          <ErrorValidation className="size-09 bold-300 text-end" validations={data.validations} name="status" />
        </div>
        <div className="col-3 mb-3">
          <span className="size-08 bold me-2">Fecha de vencimiento</span>
          <div className="mt-2">
            <Toggle
              checked={data.inputs.lock}
              onChange={(e: any) => handleChange({ target: { name: 'lock', value: e } })}
              className="me-2"
              style={{ width: 'fit-content' }}
            />
          </div>
        </div>
        {data.inputs.lock &&
          <div className="col-7 mb-3">
            <div className="col-12 mt-3">
              <span className="size-08 bold me-2">Desde</span>
              <div className="w-100">
                <DatePicker
                  format="dd/MM/yyyy -- HH:mm"
                  placeholder=""
                  classPrefix="copec"
                  cleanable={false}
                  className="rs-copec-cleanable"
                  placement="auto"
                  value={data.inputs.start}
                  onChange={(e) => handleChange({ target: { value: e, name: 'start' } })}
                  locale={{
                    sunday: 'Dom',
                    monday: 'Lun',
                    tuesday: 'Mar',
                    wednesday: 'Mie',
                    thursday: 'Jue',
                    friday: 'Vie',
                    saturday: 'Sab',
                    ok: 'OK',
                    today: '',
                    yesterday: '',
                    hours: 'Horas',
                    minutes: 'Minutos',
                    seconds: 'Segundos'
                  }}
                />
              </div>
              <ErrorValidation className="size-09 bold-300 text-end" validations={data.validations} name="start" />
            </div>
            <div className="col-12 mt-3">
              <span className=" size-08 bold me-2">Hasta</span>
              <div className="w-100">
                <DatePicker
                  format="dd/MM/yyyy -- HH:mm"
                  placeholder=""
                  classPrefix="copec"
                  cleanable={false}
                  className="rs-copec-cleanable"
                  placement="auto"
                  value={data.inputs.end}
                  onChange={(e) => handleChange({ target: { value: e, name: 'end' } })}
                  locale={{
                    sunday: 'Dom',
                    monday: 'Lun',
                    tuesday: 'Mar',
                    wednesday: 'Mie',
                    thursday: 'Jue',
                    friday: 'Vie',
                    saturday: 'Sab',
                    ok: 'OK',
                    today: '',
                    yesterday: '',
                    hours: 'Horas',
                    minutes: 'Minutos',
                    seconds: 'Segundos'
                  }}
                />
              </div>
              <ErrorValidation className="size-09 bold-300 text-end" validations={data.validations} name="end" />
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default AddCampaign

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Modal, SelectPicker, Toggle, Uploader } from 'rsuite';
import { convertToBase64AndFormatImage, createImageName } from '../../helpers/convert_and_clone';
import ErrorValidation from '../ErrorValidation';
import Title from '../Title';
import { service_format } from './service_formats';
import validate from '../../helpers/validate';
import { RecommendedSubServiceSchema } from '../../validations/recommended_subservice.validations';
import Service from '../../services/service_and_products.service';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import MediaFiles from '../../services/media.service';

const FormRecommendedSubService = (props: any) => {
  const { action, categories, service: main_service, handleClose, subservice } = props;
  const [state, setState] = useState<any>({
    service: {},
    inputs: {
      name: '',
      price: '',
      descripcion: '',
      service_model: '',
      info: { service_model: '' },
    },
    validations: {},
    fileList: [],
    image: null,
  });

  useEffect(() => {
    if (action === 'create' && !main_service) {
      const service: any = { ...service_format };
      service.type = 'recommended_subservice';
      service.name = 'Subservicio recomendado';
      service.shipping_price = 0;
      setState({ ...state, service });
    }

    if (action === 'create') {
      setState({ ...state, service: { ...main_service } });
    }

    if (action === 'update') {
      setState({ ...state, service: { ...main_service }, inputs: { ...subservice, service_model: subservice?.info?.service_model } });
    }
  }, [main_service]);

  const dispatch: any = useDispatch();

  const _handleOnChangeData = ({ target }: any) => {
    const { inputs, validations } = state;
    const info_fields = ['service_model', 'is_unvailable'];

    if (info_fields.includes(target.name)) {
      inputs.info[target.name] = target.value;
    }

    if (target.value !== '' && target.name === 'price') {
      inputs[target.name] = target.value.replace(/\D/g, '');
    } else {
      inputs[target.name] = target.value;
    }

    if (validations[target.name]) {
      delete validations[target.name];
    }
    setState({ ...state, validations: { ...validations } });
  };

  const _handleImage = async ({ event, type }: any) => {
    if (event.length > 0) {
      const imageData = await convertToBase64AndFormatImage(event, state.fileList, type);
      setState({
        ...state,
        image: imageData,
        fileList: [event[state.fileList.length > 0 ? state.fileList.length : 0]],
      });
    } else {
      setState({ ...state, image: null, fileList: [] });
    }
  };

  const _handleSubmit = async () => {
    const { service, inputs } = state;
    service.name = service.name ? service.name : 'Subservicio recomendado';
    service.type = 'recommended_subservice';
    service.shipping_price = 0;
    service.scheduling = false;
    service.descripcion = '';
    const slug = service.name.toLowerCase().trim().replace(/\s/g, '-');
    service.slug = slug;
    const validations: any = await validate(RecommendedSubServiceSchema, inputs);

    if (validations !== false) {
      setState({ ...state, validations });
      return;
    }

    if (!main_service) {
      service.price = null;
      service.shipping_price = Number(service.shipping_price);
      service.info = {
        ...service.info,
        slug,
        liter: service.liter ? Number(service.liter) : 0,
        min_reservation_time: service.min_reservation_time ? Number(service.min_reservation_time) : 0,
      };
      service.liter = service.liter ? Number(service.liter) : 0;
      service.min_reservation_time = service.min_reservation_time ? Number(service.min_reservation_time) : 0;
    }

    let PK: any = uuid();
    let SK: any = uuid();
    const item: any = { ...inputs };
    item.shipping_price = Number(state.service.shipping_price);
    item.scheduling = false;
    item.info = {
      ...inputs.info,
      size: 0,
      dinamic_PK: PK,
      dinamic_SK: SK,
      service_model: item.service_model,
    };

    item.descripcion = inputs.descripcion.replace(/(?:\r\n|\r|\n)/g, '<br>');
    service.sub_services = [item];

    let responseActionService: any = null;
    dispatch(LOADING_ON());

    try {
      if (action === 'create' && !main_service) {
        responseActionService = await Service.create(service);
      }

      if (action === 'create' && main_service) {
        responseActionService = await Service.update(service);
      }

      if (action === 'update' && main_service) {
        item.PK = subservice.PK;
        item.SK = subservice.SK;
        item.info.dinamic_PK = subservice.PK;
        item.info.dinamic_SK = subservice.SK;
        PK = subservice.PK;
        SK = subservice.SK;
        service.sub_services = [item];
        responseActionService = await Service.update(service);
      }

      if (state.image && state.fileList.length > 0) {
        const serviceDetails: any = await Service.details({ PK: responseActionService.data.PK, SK: responseActionService.data.SK });
        const serviceData: any = serviceDetails.data;
        const { sub_services } = serviceData;
        const sub_service: any = sub_services.find((ss: any) => ss.info?.dinamic_SK === SK);

        const imageService = { ...state.image };
        imageService.element_pk = sub_service.SK;

        //CREATE IMAGE NAME
        const image_name: any = createImageName(state.image?.file?.name, item?.name);

        // CREATE UPLOAD URL
        const presignUrl = await MediaFiles.presigned({ path: `services/${image_name.toLowerCase()}`, content_type: state.image.file.type });

        // UPLOAD FILE
        await fetch(presignUrl.data.url, {
          body: state.image.file,
          method: 'PUT',
          headers: new Headers({ 'content-type': state.image.file.type, 'x-amz-acl': 'public-read' }),
        });

        //GET IMG URL
        const finalImageUrl: any = presignUrl.data?.url?.split('?')[0];

        sub_service.info = {
          ...sub_service.info,
          gallery: { url: finalImageUrl },
        };

        serviceData.sub_services = [sub_service];
        await Service.update(serviceData);
      }
      toast.success(` Sub servicio ${action === 'create' ? 'creado' : 'actualizado'} con éxito`);
      handleClose();
    } catch (e: any) {
      toast.error(e.message);
      dispatch(LOADING_OFF());
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="row mb-3">
          <Title size="sm" text={`${action === 'create' ? 'Nuevo Sub servicio recomendado' : 'Actualizar sub servicio recomendado'}`} className="bold to-upper size-11" />
        </div>

        <div className="row">
          <div className="col-6">
            <span className=" size-08 bold me-2">Nombre</span>
            <div className="">
              <input
                value={state?.inputs?.name}
                onChange={_handleOnChangeData}
                name="name"
                className="input-default w-100"
                type="text"
                placeholder="Ingresa el nombre del sub servicio recomendado"
                maxLength={80}
                disabled={state.inputs?.info?.is_unvailable}
              />
            </div>
            <ErrorValidation validations={state.validations} name="name" className="bold size-09 text-end" />
          </div>

          <div className="col-6">
            <span className="size-08 bold me-2">Imagen</span>
            <div className="w-100">
              <Uploader
                accept=".png, .jpg, .jpeg"
                listType="text"
                fileList={state?.fileList}
                onChange={(e: any) => _handleImage({ event: e, type: 'product' })}
                autoUpload={false}
                disabled={state.inputs?.info?.is_unvailable}
              >
                <button>
                  <span className="me-1">+</span> Seleccionar archivo
                </button>
              </Uploader>
            </div>
          </div>

          <div className="col-6">
            <span className="size-08 bold me-4">Tipo de servicio</span>
            <div>
              <SelectPicker
                classPrefix="copec"
                className="rs-copec-cleanable"
                cleanable={false}
                placeholder="Selecciona el tipo de servicio"
                onChange={(e: any) => _handleOnChangeData({ target: { name: 'service_model', value: e } })}
                data={categories}
                value={state?.inputs?.service_model}
                locale={{ searchPlaceholder: 'Buscar', noResultsText: 'Ningún resultado encontrado' }}
                disabled={state.inputs?.info?.is_unvailable}
              />
            </div>
            <ErrorValidation validations={state.validations} name="service_model" className="bold size-09 text-end" />
          </div>

          <div className="col-6">
            <span className=" size-08 bold me-2">Precio</span>
            <div className="">
              <input
                value={state?.inputs?.price}
                onChange={_handleOnChangeData}
                name="price"
                className="input-default w-100"
                type="string"
                min={0}
                maxLength={12}
                placeholder="00000"
                disabled={state.inputs?.info?.is_unvailable}
              />
            </div>
            <ErrorValidation validations={state.validations} name="price" className="bold size-09 text-end" />
          </div>

          <div className="col-12 mt-2">
            <span className=" size-08 bold me-2">Descripción</span>
            <div className="">
              <textarea
                value={state?.inputs?.descripcion}
                onChange={_handleOnChangeData}
                name="descripcion"
                className="input-default w-100"
                placeholder="Descripción"
                disabled={state.inputs?.info?.is_unvailable} />
            </div>
            <ErrorValidation validations={state.validations} name="descripcion" className="bold size-09 text-end" />
          </div>
          <div className="col-6 mt-2 d-flex flex-column justify-content-center">
            <span className=" size-08 bold me-2">Desactivado</span>
            <div className="">
              <Toggle
                checked={state.inputs?.info?.is_unvailable}
                onChange={(e: any) => _handleOnChangeData({ target: { name: 'is_unvailable', value: e } })}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal.Footer>
        <div className={`row my-3 justify-content-end`}>
          <div className="col-2 text-center">
            <button className="btn-danger" onClick={handleClose}>
              Cancelar
            </button>
          </div>
          <div className="col-2 text-center text-white">
            <button className="btn-default" onClick={_handleSubmit}>
              Guardar
            </button>
          </div>
        </div>
      </Modal.Footer>
    </div>
  );
};

export default FormRecommendedSubService;

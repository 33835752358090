import {
  CREATE_PRODUCT,
  RETRIEVE_PRODUCTS,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
} from "../types";

const initialState:any = [];

function productReducer(products = initialState, action:any) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_PRODUCT:
      return [...products, ...payload];

    case RETRIEVE_PRODUCTS:
      return payload;

    case UPDATE_PRODUCT:
      return products.map((product:any) => {
        if (product.id === payload[0].id) {
          return {
            ...product,
            ...payload[0],
          };
        } else {
          return product;
        }
      });

    case DELETE_PRODUCT:
      return products.filter(({ id }:any) => id !== payload.id);

    default:
      return products;
  }
};

export default productReducer;

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Divider, Dropdown, Popover, Table, Whisper } from 'rsuite';
import ConfirmationModal from '../../components/ConfirmationModal';
import ModalComponent from '../../components/Modal';
import { _sortItems } from '../../helpers/sort';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import Schedule from '../../services/schedule.service';
import Service from '../../services/service_and_products.service';
import AddTimeBlock from './AddTimeBlock';
import { TableHeader } from './tableConfig';
import UpdateTimeBlock from './UpdateTimeBlock';

const TimeBlock = () => {
  const { selected_eds, loader } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState<any>({
    checks: {},
    data: [],
    coverages: [],
    services: [],
    modals: {
      create: false,
      update: false,
    },
    confirmation: {
      delete: false,
    },
    selected_time_block: {},
    sort: {
      column: '',
      type: 'asc',
    },
    delete_id: '',
  });

  const [currentPage, setCurrentPage] = useState({
    index: 0,
    next_page: {
      LastEvaluatedKey: {},
    },
  });

  const [pages, setPages] = useState([
    {
      index: 0,
      currentKey: {}, // the LastEvaluatedKey for this element
      next_page: {
        LastEvaluatedKey: {},
      },
    },
  ]);

  const searchUrl = new URLSearchParams(history.location.search);

  useEffect(() => {
    if (!selected_eds.PK) {
      history.push('/eds');
    } else {
      _loadData();
    }
  }, []);

  const _loadData = async () => {
    try {
      dispatch(LOADING_ON());
      const responseSchedule: any = await Schedule.listScheduleByEds({ PK: searchUrl.get('PK'), SK: searchUrl.get('SK') });
      const responseCoverage: any = await Schedule.listAllCoverage();
      
      
      let responseService = null;
      let next_page_service = null;
      let nextPageUri = null;

      while (true) {
        try {
          nextPageUri = null;

          if (next_page_service && "LastEvaluatedKey" in next_page_service) {
            const lastKey = next_page_service.LastEvaluatedKey; // the key for the current page

            nextPageUri = encodeURIComponent(JSON.stringify(lastKey));
          }

          const getService: any = await Service.list(nextPageUri ? { last: nextPageUri } : null);

          if (!responseService) {
            responseService = getService;
          } else {
            responseService.data = [...responseService.data, ...getService.data];
          }
          next_page_service = getService.next_page;

          /** BREAK LOOP IF DONT EXISTS MORE RESULTS */
          if (!getService.next_page) break

        } catch (e) {
          toast.error('No fue posible cargar los servicios');
          dispatch(LOADING_OFF());
          break;
        }
      }

      let serviceList: any = responseService.data.filter((item: any) => item.type !== 'recommended_subservice' && item.scheduling);
      serviceList = serviceList.map((item: any) => {
        return {
          value: item.PK,
          label: item.name,
          type: item.type,
        };
      });

      const schedules = responseSchedule.data.map((item: any) => {
        const [eds_id, service_id] = item.PK?.split('-');
        item.service_id = service_id;
        item.eds_id = eds_id;
        item.schedule_coverage_id = item.GS2PK;

        const data = {
          ...item,
          service: serviceList.find((element: any) => element.value === item.service_id),
          coverage: responseCoverage.data.find((element: any) => element.PK === item.schedule_coverage_id),
          service_name: serviceList.find((element: any) => element.value === item.service_id)?.label,
          coverage_name: responseCoverage.data.find((element: any) => element.PK === item.schedule_coverage_id)?.name,
        };
        return data;
      });

      schedules.sort((a: any, b: any) => {
        if (a.info?.day_of_week > b.info?.day_of_week) return 1;
        if (a.info?.day_of_week < b.info?.day_of_week) return -1;
        return 0;
      });

      schedules.sort((a: any, b: any) => {
        if (a.service_id > b.service_id) return 1;
        if (a.service_id < b.service_id) return -1;
        return 0;
      });

      const coverage: any = responseCoverage.data.find((item: any) => parseInt(item.day_of_week) === 0);

      if (coverage) {
        coverage.day_of_week = 7;
      }

      responseCoverage.data?.sort((a: any, b: any) => {
        if (parseInt(a.day_of_week) > parseInt(b.day_of_week)) return 1;
        if (parseInt(a.day_of_week) < parseInt(b.day_of_week)) return -1;
        return 0;
      });

      const next_page: any = responseSchedule.next_page;

      const firstPage = {
        index: 1,
        currentKey: {},
        next_page: next_page,
      };

      setCurrentPage(firstPage);
      setPages([firstPage]);

      setState({
        ...state,
        data: schedules,
        coverages: responseCoverage.data,
        services: serviceList,
        modals: {
          create: false,
          update: false,
        },
        confirmation: { delete: false },
        delete_id: false,
      });
      dispatch(LOADING_OFF());
    } catch (e: any) {
      toast.error('No fue posible cargar la info...');
      dispatch(LOADING_OFF());
    }
  };

  const _handleToggleModal = (target: any) => {
    setState({ ...state, modals: { ...state.modals, [target]: !state.modals[target] } });
  };

  const _createTimeBlock = async (data: any) => {
    try {
      dispatch(LOADING_ON());
      const coverage: any = state.coverages?.find((element: any) => element.PK === data.schedule_coverage_id);
      if (data.info?.quantity_per_block) {
        data.info.quantity_per_block = parseInt(data.info.quantity_per_block);
      }
      data.info = { ...data.info, day_of_week: coverage?.day_of_week };
      await Schedule.createSchedule(data);
      await _loadData();
      toast.success('Horario creado con éxito');
    } catch (e: any) {
      toast.error('No fue posible crear el horario');
      dispatch(LOADING_OFF());
    }
    dispatch(LOADING_OFF());
  };

  const _updateTimeBlock = async (data: any) => {
    try {
      dispatch(LOADING_ON());
      const coverage: any = state.coverages?.find((element: any) => element.PK === data.schedule_coverage_id);
      if (data.info?.quantity_per_block) {
        data.info.quantity_per_block = parseInt(data.info.quantity_per_block);
      }

      if (data.quantity_per_block) {
        data.quantity_per_block = parseInt(data.quantity_per_block);
      }
      data.info = { ...data.info, day_of_week: coverage?.day_of_week };
      await Schedule.updateSchedule(data.PK, data);
      await _loadData();
      toast.success('Horario actualizado con éxito');
    } catch (e: any) {
      toast.error('No fue posible actualizar el horario');
      dispatch(LOADING_OFF());
    }
    dispatch(LOADING_OFF());
  };

  const _deleteSchedule = async () => {
    try {
      dispatch(LOADING_ON());
      const schedule: any = state.data.find((item: any) => item.SK === state.delete_id);
      await Schedule.deleteSchedule(schedule);
      await _loadData();
      toast.success('Horario eliminado con éxito');
    } catch (e: any) {
      toast.error('No fue posible eliminar este horario');
      dispatch(LOADING_OFF());
    }
    dispatch(LOADING_OFF());
  };

  const _selectTBAndOpenModal = (data: any) => {
    setState({ ...state, modals: { ...state.modals, update: true }, selected_time_block: data });
  };

  const Actions = (props: any) => {
    const { payload } = props;
    return (
      <>
        <button className="btn p-0 size-11 color-grey-menu" onClick={() => _selectTBAndOpenModal(payload)}>
          <i className="bi bi-pencil"></i>
        </button>
        <Divider vertical />
        <button className="btn p-0 size-11 color-grey-menu" onClick={() => setState({ ...state, delete_id: payload.SK, confirmation: { delete: true } })}>
          <i className="bi bi-trash"></i>
        </button>
      </>
    );
  };

  const _sortTable = (column: any, type: any) => {
    const sorted = _sortItems(state.data, column, type);
    setState({ ...state, ...sorted });
  };

  const prevPage = async () => {

    try {
      dispatch(LOADING_ON());
      const prevPage = currentPage.index - 1;

      const prevItem = pages.find((item) => item.index === prevPage);

      if (!prevItem) {
        toast.warning(`Ya estas en la pagina ${currentPage.index}`);
        dispatch(LOADING_OFF());
        return;
      }

      let LastEvaluatedKey = prevItem.currentKey;

      if (LastEvaluatedKey) {

        let nextPageUri = encodeURIComponent(JSON.stringify(LastEvaluatedKey));

        let pageQuery: any = { last: nextPageUri }

        if (Object.keys(LastEvaluatedKey).length === 0) {
          pageQuery = {}
        }

        const responseSchedule: any = await Schedule.listScheduleByEds({ PK: searchUrl.get('PK'), SK: searchUrl.get('SK') }, pageQuery);

        const newCurrentPage = {
          index: prevItem.index,
          currentKey: LastEvaluatedKey, // The key for querying specifcally this page
          next_page: responseSchedule.next_page,
        };

        setCurrentPage(newCurrentPage);
        setPages([...pages, newCurrentPage]);

        /** format and sort schedule data */

        const schedules = responseSchedule.data.map((item: any) => {
          const [eds_id, service_id] = item.PK?.split('-');
          item.service_id = service_id;
          item.eds_id = eds_id;
          item.schedule_coverage_id = item.GS2PK;

          const data = {
            ...item,
            service: state.services.find((element: any) => element.value === item.service_id),
            coverage: state.coverages.find((element: any) => element.PK === item.schedule_coverage_id),
            service_name: state.services.find((element: any) => element.value === item.service_id)?.label,
            coverage_name: state.coverages.find((element: any) => element.PK === item.schedule_coverage_id)?.name,
          };
          return data;
        });

        schedules.sort((a: any, b: any) => {
          if (a.info?.day_of_week > b.info?.day_of_week) return 1;
          if (a.info?.day_of_week < b.info?.day_of_week) return -1;
          return 0;
        });

        schedules.sort((a: any, b: any) => {
          if (a.service_id > b.service_id) return 1;
          if (a.service_id < b.service_id) return -1;
          return 0;
        });

        setState({ ...state, data: schedules, delete_id: '', confirmation: { delete: false } })
        dispatch(LOADING_OFF());

      }
    } catch (e: any) {
      dispatch(LOADING_OFF());
      toast.error(`No fue posible cargar los resultados.`);
    }
  }
  const nextPage = async () => {
    try {
      dispatch(LOADING_ON());

      /** get key for the current page */
      const LastEvaluatedKey = currentPage.next_page?.LastEvaluatedKey;

      if (LastEvaluatedKey) {

        let nextPageUri = encodeURIComponent(JSON.stringify(LastEvaluatedKey));

        const responseSchedule: any = await Schedule.listScheduleByEds({ PK: searchUrl.get('PK'), SK: searchUrl.get('SK') }, { last: nextPageUri });

        const newCurrentPage = {
          index: currentPage.index + 1,
          currentKey: LastEvaluatedKey, // The key for querying specifcally this page
          next_page: responseSchedule.next_page,
        };

        setCurrentPage(newCurrentPage);
        setPages([...pages, newCurrentPage]);

        /** format and sort schedule data */

        const schedules = responseSchedule.data.map((item: any) => {
          const [eds_id, service_id] = item.PK?.split('-');
          item.service_id = service_id;
          item.eds_id = eds_id;
          item.schedule_coverage_id = item.GS2PK;

          const data = {
            ...item,
            service: state.services.find((element: any) => element.value === item.service_id),
            coverage: state.coverages.find((element: any) => element.PK === item.schedule_coverage_id),
            service_name: state.services.find((element: any) => element.value === item.service_id)?.label,
            coverage_name: state.coverages.find((element: any) => element.PK === item.schedule_coverage_id)?.name,
          };
          return data;
        });

        schedules.sort((a: any, b: any) => {
          if (a.info?.day_of_week > b.info?.day_of_week) return 1;
          if (a.info?.day_of_week < b.info?.day_of_week) return -1;
          return 0;
        });

        schedules.sort((a: any, b: any) => {
          if (a.service_id > b.service_id) return 1;
          if (a.service_id < b.service_id) return -1;
          return 0;
        });

        setState({ ...state, data: schedules, delete_id: '', confirmation: { delete: false } })

      }

      if (!LastEvaluatedKey) {
        toast.error(`No hay más resultados.`);
      }

      dispatch(LOADING_OFF());
    } catch (e: any) {
      toast.error(`No fue posible cargar más resultados.`);
      dispatch(LOADING_OFF());
    }
  }

  return (
    <Fragment>
      <div className="row mt-4">
        <div className="col-12 d-flex justify-content-end color-white">
          <button className="btn-default" onClick={() => _handleToggleModal('create')}>
            <span className="me-2">+</span>
            Crear Bloque
          </button>
        </div>
      </div>

      <ModalComponent open={state.modals.create} noFull size={'md'} handleClose={() => _handleToggleModal('create')} hideFooter>
        <AddTimeBlock handleCancel={() => _handleToggleModal('create')} handleConfirm={_createTimeBlock} coverages={state.coverages} services={state.services} />
      </ModalComponent>

      <ModalComponent open={state.modals.update} noFull size={'md'} handleClose={() => _handleToggleModal('update')} hideFooter>
        <UpdateTimeBlock
          handleCancel={() => _handleToggleModal('update')}
          handleConfirm={_updateTimeBlock}
          coverages={state.coverages}
          services={state.services}
          data={state.selected_time_block}
        />
      </ModalComponent>

      <ConfirmationModal
        type="warning"
        title="Eliminar"
        open={state.confirmation.delete}
        size="sm"
        handleClose={() => setState({ ...state, delete_id: '', confirmation: { delete: false } })}
        handleConfirm={_deleteSchedule}
      >
        <span className="bold-300 ms-3">¿Seguro que quieres eliminar este bloque horario?</span>
      </ConfirmationModal>

      <div className="row rounded-10 mt-5 p-3">
        <div className="col-12 h-100">
          <Table
            data={state.data}
            rowClassName="striped"
            rowHeight={50}
            sortColumn={state.sort.column}
            sortType={state.sort.type}
            onSortColumn={_sortTable}
            autoHeight
            locale={{ emptyMessage: 'No hay bloques horarios' }}
          >
            {TableHeader &&
              TableHeader.map((column: any, index: any) => (
                <Table.Column align={column.align} flexGrow={column.flexGrow} key={`table-column-${index}`}>
                  <Table.HeaderCell>
                    <span style={{ textTransform: 'capitalize' }}>{column.label}</span>
                  </Table.HeaderCell>
                  <Table.Cell style={{ display: 'flex', alignItems: 'center', justifyContent: column.alignFlex }} dataKey={column.key}>
                    {(rowData) => {
                      switch (column.key) {
                        case 'duration':
                          return <span>{rowData[column.key]} min</span>;
                        default:
                          return <span>{rowData[column.key]}</span>;
                      }
                    }}
                  </Table.Cell>
                </Table.Column>
              ))}

            <Table.Column align="right" flexGrow={1}>
              <Table.HeaderCell>{''}</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  return (
                    <div className="me-4">
                      <Actions payload={rowData} />
                    </div>
                  );
                }}
              </Table.Cell>
            </Table.Column>
          </Table>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {!loader.loading && (
            <div className="mb-5 text-center d-flex justify-content-center">
              <button className="pagination-component__btn" onClick={() => prevPage()}>
                Anterior
              </button>
              <div className="pagination-component__center">{currentPage.index}</div>
              <button className="pagination-component__btn" onClick={() => nextPage()}>
                Próximo
              </button>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default TimeBlock;

/* eslint-disable react-hooks/exhaustive-deps */
import Coverage from "../../components/EDS/Coverage"
import EditEds from "../../components/EDS/EditEds";
import Title from "../../components/Title";
import Card from "../../components/EDS/Card";
import { useSelector } from "react-redux";

const DashboardEds = () => {

  const { selected_eds } = useSelector((state: any) => state);

  return (
    <div className="container-fluid">
      <div className="row mb-5">
        <Title size="sm" text={`EDS #${selected_eds.code || selected_eds.PK || ''}`} className="mt-5 bold" />
      </div>

      <div className="row my-4">

        <div className="col-4">
          <EditEds />
        </div>

        <div className="col-4">
          <Card
            label="Productos Adicionales"
            url="/productos-adicionales/eds/"
          />
        </div>

        <div className="col-4">
          <Card
            label="Bloques y Descuentos Horarios"
            url="/jornada-laboral/eds/"
          />
        </div>

      </div>

      <div className="row my-4">
        <div className="col-4">
          <Card
            label="Pedidos"
            url="/pedidos/eds/"
          />
        </div>
        <div className="col-4">
          <Card
            label="Vehículos Conductores"
            url="/drivers/eds/"
          />
        </div>
        <div className="col-4">
          <Coverage />
        </div>
      </div>
    </div>
  )
}

export default DashboardEds;
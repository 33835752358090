export const TableHeaderDriver = [
  {
    label: 'Nombre',
    key: 'name',
    flexGrow: 1,
  },
  {
    label: 'Email',
    key: 'email',
    flexGrow: 1,
  },
  {
    label: 'Teléfono',
    key: 'phone_number',
    flexGrow: 1,
  },
  {
    label: 'Fecha creación',
    key: 'created_at',
    flexGrow: 1,
  },
];

export const TableHeaderCar = [
  {
    label: 'ID',
    key: 'PK',
    flexGrow: 1,
  },
  {
    label: 'Marca',
    key: 'brand',
    flexGrow: 1,
  },
  {
    label: 'Modelo',
    key: 'model',
    flexGrow: 1,
  },

  {
    label: 'Patente',
    key: 'plate',
    flexGrow: 1,
  },
  {
    label: 'Capacidad',
    key: 'capacity',
    flexGrow: 1,
  },
  {
    label: 'Conductor',
    key: 'driver_id',
    flexGrow: 1,
  },
];

export const TableDataDriver = [
  {
    id: 1,
    code: '001',
    name: 'Nombre',
    created_at: '11.11.2021',
    effective_date: '11.11.2022',
  },
  {
    id: 2,
    code: '002',
    name: 'Nombre',
    created_at: '11.11.2021',
    effective_date: '11.11.2022',
  },
  {
    id: 3,
    code: '003',
    name: 'Nombre',
    created_at: '11.11.2021',
    effective_date: '11.11.2022',
  },
  {
    id: 4,
    code: '004',
    name: 'Nombre',
    created_at: '11.11.2021',
    effective_date: '11.11.2022',
  },
];

export const TableDataCar = [
  {
    id: 1,
    model: '001',
    plate: 'AAAAAA',
    capacity: '1254',
  },
  {
    id: 2,
    model: '001',
    plate: 'AAAAAA',
    capacity: '1254',
  },
  {
    id: 3,
    model: '001',
    plate: 'AAAAAA',
    capacity: '1254',
  },
];

export const SelectActions = [
  {
    label: 'Editar',
    value: 1,
  },
];

/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { DatePicker, SelectPicker } from 'rsuite';
import ErrorValidation from '../../components/ErrorValidation';
import validate from '../../helpers/validate';
import { ScheduleLockSchema, ScheduleSchema } from '../../validations/schedule.validations';

const AddTimeBlock = (props: any) => {
  const { handleCancel, handleConfirm } = props;
  const params: any = useParams();
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);

  const [state, setState] = useState<any>({
    inputs: {
      service_id: '',
      interval: '',
      quantity_per_block: 1,
      quantity_per_block_option: false,
    },
    blocked_time: false,
    services: [],
    intervals: [],
    work_day: [],
    validations: {},
    quantity_per_block_option: false,
  });

  useEffect(() => {
    _loadData();
  }, []);

  const _loadData = async () => {
    let interval = 0;
    const intervals = Array(18)
      .fill(15)
      .map(() => {
        if (interval < 120) {
          interval += 15;
        } else {
          interval += 60
        }

        return {
          label: interval + ' min',
          value: String(interval),
        };
      });
    try {
      setState({ ...state, intervals, work_day: props.coverages, services: props.services });
    } catch (e: any) {
      toast.error('No fue posible cargar la info');
    }
  };

  const _handleOnChange = ({ target }: any) => {
    if (target.name === 'quantity_per_block') {
      target.value = target.value.replace(/\D/g, '');
      if (!target.value) target.value = 1;
    }

    const { name, value } = target;
    if (state.validations[name]) {
      delete state.validations[name];
    }

    if (name === 'service_id') {
      const serviceData: any = state.services?.find((service: any) => service.value === value);
      const isQuantityPerBlockEligible = serviceData.type === 'kerosene-schedule' || serviceData.type === 'lavamax' || serviceData.type === 'lub'
      state.inputs.quantity_per_block_option = isQuantityPerBlockEligible;
    }

    setState({ ...state, inputs: { ...state.inputs, [name]: value }, validations: { ...state.validations } });
  };

  const _submitData = async () => {

    const Schema = state.blocked_time ? ScheduleLockSchema : ScheduleSchema;
    const validations = await validate(Schema, state.inputs);

    if (validations) {
      setState({ ...state, validations });
      return;
    }

    const payload: any = {
      duration: state.inputs.interval,
      eds_id: params.id,
      service_id: state.inputs.service_id,
      schedule_coverage_id: state.inputs.schedule_coverage_id,
      info: {
        eds: { PK: searchParams.get('PK'), SK: searchParams.get('SK') },
        service: { PK: state.inputs.service_id, SK: state.inputs.service_id },
        quantity_per_block: state.inputs.quantity_per_block || 1,
      },
    };

    const coverage: any = props.coverages.find((el: any) => el.PK === payload.schedule_coverage_id)
    const start: any = coverage?.coverage[0]?.start;
    const end: any = coverage?.coverage[0]?.end;

    const [startHour, startMinute] = start.split(':')
    const [endHour, endMinute] = end.split(':')
    const startWork = moment()
    startWork.set({ hour: startHour, minute: startMinute, second: 0, millisecond: 0 })
    const endWork = moment()
    endWork.set({ hour: endHour, minute: endMinute, second: 0, millisecond: 0 })
    const diffMinutes: any = endWork.diff(startWork, "minutes")

    if (diffMinutes < parseInt(payload.duration)) {
      toast.error("La duración del bloque horario no puede ser mayor que la duración de la jornada laboral.")
      return
    }

    if (state.blocked_time) {
      payload.lock = [
        {
          start: moment(state.inputs.start).format('HH:mm'),
          end: moment(state.inputs.end).format('HH:mm'),
          info: {
            nota: 'Hora del almuerzo',
          },
        },
      ];
    } else {
      payload.lock = [];
    }
    handleConfirm(payload);
  };

  return (
    <div className="container-fluid">
      <div className="row mt-3">
        <div className="col-12 px-4 mb-3">
          <span className="size-08 bold me-2">Servicio</span>
          <SelectPicker
            classPrefix="copec"
            className="rs-copec-cleanable"
            cleanable={false}
            data={state.services}
            placeholder="Selecciona un servicio"
            onChange={(e) => _handleOnChange({ target: { value: e, name: 'service_id' } })}
            locale={{ searchPlaceholder: 'Buscar', noResultsText: 'Ningún resultado encontrado.', emptyMessage: 'Ningún resultado encontrado.' }}
          />
          <ErrorValidation validations={state.validations} name="service_id" className="text-end bold-300 size-09" />
        </div>

        <div className="col-12 px-4 mb-3">
          <span className="size-08 bold me-2">Duración</span>
          <SelectPicker
            classPrefix="copec"
            className="rs-copec-cleanable"
            cleanable={false}
            data={state.intervals}
            placeholder="Selecciona duración"
            onChange={(e) => _handleOnChange({ target: { value: e, name: 'interval' } })}
            locale={{ searchPlaceholder: 'Buscar', noResultsText: 'Ningún resultado encontrado.', emptyMessage: 'Ningún resultado encontrado.' }}
          />
          <ErrorValidation validations={state.validations} name="interval" className="text-end bold-300 size-09" />
        </div>

        <div className="col-12 px-4 mb-3">
          <span className="size-08 bold me-2">Jornada Laboral</span>
          <SelectPicker
            classPrefix="copec"
            className="rs-copec-cleanable"
            cleanable={false}
            data={state.work_day}
            placeholder="Selecciona Jornada Laboral"
            onChange={(e) => _handleOnChange({ target: { value: e, name: 'schedule_coverage_id' } })}
            locale={{ searchPlaceholder: 'Buscar', noResultsText: 'Ningún resultado encontrado.', emptyMessage: 'Ningún resultado encontrado.' }}
            valueKey="PK"
            labelKey="name"
          />
          <ErrorValidation validations={state.validations} name="schedule_coverage_id" className="text-end bold-300 size-09" />
        </div>

        {state.inputs.quantity_per_block_option ? (
          <div className="col-12 px-4 mb-3">
            <span className="size-08 bold me-2">Cantidad de pedidos por bloque</span>
            <input
              className="input-default w-100"
              type="text"
              name="quantity_per_block"
              value={state.inputs?.quantity_per_block}
              onChange={_handleOnChange}
              placeholder="Informa cantidad de pedidos por bloque"
            />
            <ErrorValidation validations={state.validations} name="quantity_per_block" className="text-end bold-300 size-09" />
          </div>
        ) : null}

        <div className="col-12 px-4 mb-3 d-flex align-items-center my-4">
          <input
            type="checkbox"
            style={{ width: '20px', height: '20px' }}
            name="blocked_time"
            checked={state.blocked_time}
            onChange={() => setState({ ...state, blocked_time: !state.blocked_time })}
          />
          <span className="size-09 bold ms-3">Almuerzo</span>
        </div>

        {state && state.blocked_time && (
          <>
            <div className="col-6 px-4 mt-3">
              <span className="size-08 bold me-2">Desde</span>
              <div className="">
                <DatePicker
                  format="HH:mm"
                  placeholder=""
                  classPrefix="copec"
                  cleanable={false}
                  className="rs-copec-cleanable"
                  placement="auto"
                  onChange={(e) => _handleOnChange({ target: { value: e, name: 'start' } })}
                  locale={{
                    sunday: 'Dom',
                    monday: 'Lun',
                    tuesday: 'Mar',
                    wednesday: 'Mie',
                    thursday: 'Jue',
                    friday: 'Vie',
                    saturday: 'Sab',
                    ok: 'OK',
                    today: '',
                    yesterday: '',
                    hours: 'Horas',
                    minutes: 'Minutos',
                    seconds: 'Segundos',
                  }}
                />
              </div>
              <ErrorValidation validations={state.validations} name="start" className="w-100 text-end bold-300 size-09" />
            </div>

            <div className="col-6 px-4 mt-3">
              <span className=" size-08 bold me-2">Hasta</span>
              <div className="">
                <DatePicker
                  format="HH:mm"
                  placeholder=""
                  classPrefix="copec"
                  cleanable={false}
                  className="rs-copec-cleanable"
                  placement="auto"
                  onChange={(e) => _handleOnChange({ target: { value: e, name: 'end' } })}
                  locale={{
                    sunday: 'Dom',
                    monday: 'Lun',
                    tuesday: 'Mar',
                    wednesday: 'Mie',
                    thursday: 'Jue',
                    friday: 'Vie',
                    saturday: 'Sab',
                    ok: 'OK',
                    today: '',
                    yesterday: '',
                    hours: 'Horas',
                    minutes: 'Minutos',
                    seconds: 'Segundos',
                  }}
                />
              </div>
              <ErrorValidation validations={state.validations} name="end" className="w-100 text-end bold-300 size-09" />
            </div>
          </>
        )}
      </div>

      <div className="row justify-content-around mt-5">
        <div className="col-2 text-center color-white">
          <button className="btn-danger" onClick={handleCancel}>
            Cancelar
          </button>
        </div>

        <div className="col-2 text-center color-white">
          <button className="btn-default" onClick={_submitData}>
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};
export default AddTimeBlock;

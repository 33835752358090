import { useState } from "react";
import { Uploader } from "rsuite";
import { createCsvSimple, validateRut } from "../../helpers/Utils";
import validate from "../../helpers/validate";
import { SignUpSchema } from "../../validations/signup.validations";
import WhisperComponent from "../../components/WhisperComponent";

interface IMassiceLoadProps {
    handleConfirm: (data: any) => void
}

const MassiveLoad = ({ handleConfirm }: IMassiceLoadProps) => {

    const [state, setState] = useState<any>({
        fileList: [],
        info: null,
        errors: [],
        csvData: []
    });

    const _handleImage = (e: any) => {
        let data: any = null
        if (e.length === 0) {
            setState({
                ...state,
                fileList: [],
                csvData: [],
                errors: []
            })
            return
        }

        const reader = new FileReader()

        reader.onload = async () => {
            data = reader.result
            const arrayData = data.split('\n')
            const headers = ["name", "family_name", "email", "phone_number", "rut"]
            const errors: any = []

            const csvObject: any = [];
            let delimiter = ';'
            let index = 0

            arrayData.shift()

            for (const el of arrayData) {
                index++;

                let row = el.split(delimiter)

                /**
                 * If the row length is different from the headers length, change the delimiter and split again
                 */

                if (row.length !== headers.length) {
                    delimiter = ','
                    row = el.split(delimiter)
                }

                /**
                 * If the row length is different from the headers length, it means that the csv is not well formed
                 */

                if (row.length !== headers.length) continue;

                const obj: any = {}

                let emptyFields = 0;

                headers.forEach((header: any, index: number) => {
                    if (row[index] === "") emptyFields++;
                    obj[header] = row[index]
                })

                /**
                 * If all the fields are empty, it means that the row is empty
                 */

                if (emptyFields === headers.length) continue;


                const response = await validateData(obj, index)

                console.log(response)

                csvObject.push(obj)

                if (response.hasErrors) {
                    errors.push(response)
                }
            }


            setState((prevState: any) => {
                return {
                    ...prevState,
                    fileList: [e[e.length - 1]],
                    csvData: csvObject,
                    errors
                }
            })
        }
        reader.readAsBinaryString(e[e.length - 1].blobFile)
    }

    const validateData = async (item: any, line: number) => {
        let response: any = {
            line: 0,
            hasErrors: false,
            errors: []
        }

        let validations = await validate(SignUpSchema, item)

        for (const key in item) {

            /**
             * validate rut
             */

            if (key === "rut") {
                if (!validateRut(item[key])) {
                    validations = validations ? { ...validations, rut: ['El rut no es válido'] } : { rut: ['El rut no es válido'] };
                }
            }

            /** 
             * validate phone_number
             */

            // if (key === "phone_number") {
            //     if (item[key]?.replace(/_/g, '')?.length !== 11) {
            //         validations = validations ? { ...validations, phone_number: ['Número de teléfono inválido'] } : { phone_number: ['Número de teléfono inválido'] };
            //     }
            // }
        }

        if (validations) {
            response.hasErrors = true
            response.line = line
            response.errors = Object.values(validations).flat()
        }

        return response
    }

    const downloadCsvBase = () => {
        const headers: any = [
            "name",
            "last_name",
            "email",
            "phone_number",
            "rut",
        ];

        const csv: any = headers.join(',') + '\n';
        createCsvSimple(csv, 'copec_csv_base.csv');
    }

    const _handleConfirm = () => {
        handleConfirm(state.csvData)
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 bold size-13 text-start">

                    <WhisperComponent
                        text="Ingresar el rut con guión. Ingresa el número de teléfono con el código de país (ej: 56912345678)"
                        className="text-start"
                    >
                        Carga masiva de usuarios
                        <i className="bi bi-info-circle size-08 m-2"></i>
                    </WhisperComponent>

                </div>
            </div>

            <div className="row mt-3">
                <div className="col-12">
                    <span className="size-08 bold">Cargar csv</span>
                    <Uploader
                        accept=".csv"
                        listType="text"
                        fileList={state?.fileList}
                        onChange={(e: any) => _handleImage(e)}
                        autoUpload={false}
                    >
                        <button>
                            <span className="me-1">+</span> Seleccionar archivo
                        </button>
                    </Uploader>
                </div>
            </div>

            {state.errors.length > 0 && (
                <div className="row mt-3">
                    <div className="col-12">
                        <div className="alert alert-danger">
                            <span className="">Errores en el archivo. Corrija los errores, elimine el archivo de la lista y cárguelo nuevamente.</span>
                            <ul>
                                {state.errors.map((el: any, index: number) => (
                                    <li key={index}>
                                        <span style={{ fontWeight: "bolder" }}>Linea {el.line}</span>
                                        <ul>
                                            {el.errors.map((error: any, index: number) => (
                                                <li key={index}>{error}</li>
                                            ))}
                                        </ul>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>

            )}

            <div className="row mt-3">
                <div className="col-12 text-end text-white">
                    <button className="btn-default me-3" onClick={downloadCsvBase}>Descargar csv base</button>
                    <button className="btn-default" disabled={state.errors.length || state.fileList.length === 0} onClick={_handleConfirm}>Guardar</button>
                </div>
            </div>
        </div>
    );
}

export default MassiveLoad;
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Table, Tooltip, Whisper } from 'rsuite';
import AddEds from '../../components/EDS/AddEds';
import ModalComponent from '../../components/Modal';
import Title from '../../components/Title';
import { TableHeader } from './tableConfig';
import { useDispatch, useSelector } from 'react-redux';
import { set_data_eds, set_eds } from '../../redux/actions/eds';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';

import EDSDAO from '../../services/eds.service';
import { toast } from 'react-toastify';
import validate from '../../helpers/validate';
import { EdsSchema } from '../../validations/eds.validations';
import WhisperComponent from '../../components/WhisperComponent';
import usePagination from '../../hooks/usePagination';
import Pagination from '../../components/PaginationFront/Pagination';

const EDS = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { eds } = useSelector((state: any) => state);

  const onPrev = async (response: any) => {
    const availableEds: any = response.data.filter((item: any) => item.name !== '' && item.name !== undefined);

    setState({
      ...state,
      data: availableEds,
    });
  };

  const onNext = async (response: any) => {
    const availableEds: any = response.data.filter((item: any) => item.name !== '' && item.name !== undefined);

    setState({
      ...state,
      data: availableEds,
    });
  };

  const endpoint = 'getAll';

  const { currentPage, prevPage, nextPage, setFirstPage } = usePagination({ onPrev, onNext, service: EDSDAO, endpoint });

  const [state, setState] = useState<any>({
    inputs: {
      search: '',
      coordinates: '',
    },
    modals: {
      addEds: false,
    },
    columns: [],
    checks: {},
    data: [],
    validations: {},
  });

  useEffect(() => {
    _getData();
  }, []);

  const _getData = async () => {
    try {
      dispatch(LOADING_ON());
      const responseEds = await EDSDAO.getAll();
      const availableEds: any = responseEds.data.filter((item: any) => item.name !== '' && item.name !== undefined);
      dispatch(set_data_eds(availableEds));

      setFirstPage(responseEds.next_page);

      setState({ ...state, data: availableEds, modals: { addEds: false }, validations: {} });
      dispatch(LOADING_OFF());
    } catch (e: any) {
      dispatch(LOADING_OFF());
      console.log('ERROR LOG', e.message);
    }
  };

  const _filterEds = ({ target }: any) => {
    const { value } = target;
    console.log(eds);
    const filtered = eds.filter(
      (item: any) => item.name?.toLowerCase().includes(value.toLowerCase()) || item.code?.toLowerCase().includes(value.toLowerCase()) || item.PK.includes(value.toLowerCase())
    );
    setState({ ...state, data: filtered });
  };

  const Actions = (props: any) => {
    const { payload } = props;

    const _handlePush = () => {
      dispatch(set_eds(payload));
      history.push(`/eds/dashboard/${payload.PK}?PK=${payload.PK}&SK=${payload.SK}`);
    };
    return (
      <div className="row">
        <div className="col-12">
          <button className="btn p-0 me-3 size-11 color-grey-menu" onClick={_handlePush}>
            <WhisperComponent text="Editar eds">
              <i className="bi bi-pencil"></i>
            </WhisperComponent>
          </button>
        </div>
      </div>
    );
  };

  const _toggleModal = (modal: any) => setState({ ...state, validations: {}, modals: { ...state.modals, [modal]: !state.modals[modal] } });

  const _saveEds = async (payload: any) => {
    try {
      const errors = await validate(EdsSchema, payload);
      if (errors) {
        setState({ ...state, validations: errors });
        return;
      }
      dispatch(LOADING_ON());

      const params = {
        rut: '11.111.111-1',
        code: payload.code,
        name: payload.name,
        services_id: payload.services_id,
        info: {
          comerce_deferred: payload.comerce_deferred,
          comerce_simultaneous: payload.comerce_simultaneous,
          address: payload.address,
          phone: payload.phone,
          services_id: payload.services_id,
          payment_types: payload.payment_types,
          concessionaire_id: payload.concessionaire_id,
        },
      };
      await EDSDAO.create(params);
      await _getData();
      toast.success('Eds creada con éxito');
    } catch (e: any) {
      console.log('ERROR LOG', e.message);
      toast.error(e?.response?.data?.message);
      dispatch(LOADING_OFF());
    }
  };

  return (
    <div className="container-fluid">
      <ModalComponent open={state.modals.addEds} handleClose={() => _toggleModal('addEds')} hideFooter>
        <AddEds handleConfirm={_saveEds} handleClose={() => _toggleModal('addEds')} validations={state.validations} />
      </ModalComponent>

      <div className="row">
        <Title size="sm" text="EDS's" className="mt-5 bold" />
      </div>

      <div className="row">
        <div className="col-12 mt-5">
          <div className="col d-flex color-white">
            <input onChange={_filterEds} className="input-default color-black" type="text" placeholder="Buscar" name="search" />
            <button className="btn-default size-08 ms-3" onClick={() => _toggleModal('addEds')}>
              {' '}
              + Nueva EDS
            </button>
          </div>
        </div>
      </div>

      <div className="row mt-4 mb-5">
        <div className="col-12">
          <Table data={state.data} autoHeight rowClassName="striped" locale={{ emptyMessage: 'No hay eds' }}>
            {TableHeader &&
              TableHeader.map((column: any, index: any) => (
                <Table.Column flexGrow={column.key !== 'code' ? 1 : 0} key={`table-column-${index}`}>
                  <Table.HeaderCell>
                    <span style={{ textTransform: 'capitalize' }}>{column.label}</span>
                  </Table.HeaderCell>
                  <Table.Cell>
                    {(rowData) => {
                      switch (column.key) {
                        case 'address':
                          return (
                            <Whisper trigger="hover" placement="auto" controlId={`control-id-auto`} speaker={<Tooltip>{rowData.info[column.key]}</Tooltip>}>
                              <span className="capitalize">{rowData.info[column.key] ? rowData.info[column.key].toLowerCase() : ''}</span>
                            </Whisper>
                          );
                        case 'phone':
                          return (
                            <Whisper trigger="hover" placement="auto" controlId={`control-id-auto`} speaker={<Tooltip>{rowData.info[column.key]}</Tooltip>}>
                              <span>{rowData.info[column.key] ? rowData.info[column.key].toLowerCase() : ''}</span>
                            </Whisper>
                          );
                        default:
                          return (
                            <Whisper trigger="hover" placement="auto" controlId={`control-id-auto`} speaker={<Tooltip>{rowData[column.key]}</Tooltip>}>
                              <span style={{ textTransform: 'capitalize' }}>{rowData[column.key]}</span>
                            </Whisper>
                          );
                      }
                    }}
                  </Table.Cell>
                </Table.Column>
              ))}

            <Table.Column align="right" flexGrow={1} verticalAlign="middle">
              <Table.HeaderCell>{''}</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  return <Actions payload={rowData} />;
                }}
              </Table.Cell>
            </Table.Column>
          </Table>
        </div>
      </div>

      <Pagination nextPage={nextPage} prevPage={prevPage} index={currentPage.index} />
    </div>
  );
};

export default EDS;

/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Badge, Table, Tooltip, Whisper } from 'rsuite';
import Images from '../../assets/image';
import ConfirmationModal from '../../components/ConfirmationModal';
import Image from '../../components/Image';
import ModalComponent from '../../components/Modal';
import AddProduct from '../../components/Products/AddProduct';
import UpdateProduct from '../../components/Products/UpdateProduct';
import Title from '../../components/Title';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import Product from '../../services/products.service';
import { TableHeader } from './tableConfig';
import Pagination from '../../components/PaginationFront/Pagination';
import usePagination from '../../hooks/usePagination';

const Products = () => {
  const [state, setState] = useState<any>({
    inputs: {
      name: '',
      descripcion: '',
      image: '',
      sku: '',
      price: '',
      active: true,
    },
    search: '',
    products: [],
    original_products: [],
    product: {},
    modals: {
      add_product: false,
      update_product: false,
    },
    product_edited: null,
    errors: {},
  });

  const dispatch = useDispatch();
  const searchRef = useRef(null);

  const onPrev = async (response: any) => {
    setState({
      ...state,
      products: response.data,
    });
  };

  const onNext = async (response: any) => {
    setState({
      ...state,
      products: response.data,
    });
  };

  const endpoint = 'getAll';

  const { currentPage, prevPage, nextPage, setFirstPage } = usePagination({ onPrev, onNext, service: Product, endpoint });

  useEffect(() => {
    _listProducts();
  }, []);

  const _toggleUpdateModal = (product: any = null) => setState({ ...state, modals: { ...state.modals, update_product: !state.modals.update_product }, product });
  const _toggleCreateModal = () => setState({ ...state, modals: { ...state.modals, add_product: !state.modals.add_product } });
  const _toggleDeleteModal = (product: any = null) => setState({ ...state, modals: { ...state.modals, delete_product: !state.modals.delete_product }, product });

  const _deleteProduct = async () => {
    dispatch(LOADING_ON());
    try {
      await Product.delete(state.product);
      toast.success('Producto eliminado con éxito');
      _listProducts();
    } catch (e: any) {
      console.log('LOGGER', e.message);
      dispatch(LOADING_OFF());
    }
  };

  const _listProducts = async () => {
    dispatch(LOADING_ON());
    try {
      const response = await Product.getAll();
      setFirstPage(response.next_page);
      setState({ ...state, products: response.data, original_products: [...response.data], modals: { add_product: false, update_product: false, delete_product: false } });
    } catch (e: any) {
      console.log('LOGGER', e.message);
    }
    dispatch(LOADING_OFF());
  };

  const Actions = (payload: any) => {
    return (
      <div className="row">
        <div className="col-12">
          <button className="btn p-0 me-3 size-11 color-grey-menu" onClick={() => _toggleUpdateModal(payload.data)}>
            <i className="bi bi-pencil"></i>
          </button>
          <button className="btn p-0 me-3 size-11 color-grey-menu" onClick={() => _toggleDeleteModal(payload.data)}>
            <i className="bi bi-trash" />
          </button>
        </div>
      </div>
    );
  };
  const _filterOrder = (payload: any) => {
    const { original_products } = state;
    const products: any = original_products.filter((p: any) => p.name?.toLowerCase().includes(payload?.toLowerCase()) || p.sku?.toLowerCase().includes(payload?.toLowerCase()));
    setState({ ...state, products });
  };
  const _debounceFilter = debounce(_filterOrder, 500);

  return (
    <div className="container-fluid">
      <ModalComponent open={state.modals.add_product} handleClose={_toggleCreateModal} hideFooter>
        <AddProduct handleClose={() => _toggleCreateModal()} closeAndUpdate={_listProducts} />
      </ModalComponent>

      <ModalComponent open={state.modals.update_product} handleClose={() => _toggleUpdateModal()} hideFooter>
        <UpdateProduct data={state.product} handleClose={() => _toggleUpdateModal()} closeAndUpdate={_listProducts} />
      </ModalComponent>

      <ConfirmationModal open={state.modals.delete_product} size="sm" type="danger" title="Eliminar" handleClose={() => _toggleDeleteModal()} handleConfirm={_deleteProduct}>
        ¿Está seguro de que quieres eliminar este producto?
      </ConfirmationModal>
      <div className="row">
        <Title size="sm" text="Productos Adicionales" className="mt-5 to-upper bold" />
      </div>

      <div className="row mt-4 align-items-center">
        <div className="col-4 position-relative">
          <input onChange={(e: any) => _debounceFilter(e.target.value)} ref={searchRef} className="input-default w-100 rounded-06 pe-5" type="text" placeholder="Buscar" />
          <Image {...Images.SearchSvg} className="position-absolute" style={{ width: '20px', right: '30px', top: '17px' }} />
        </div>
        <div className="col-8 d-flex justify-content-end text-white">
          <button className="btn-default" onClick={() => _toggleCreateModal()}>
            <span className="me-2">+</span>
            Nuevo Producto
          </button>
        </div>
      </div>

      <div className="row border rounded-05 mt-4 p-3" style={{ height: '70vh', overflow: 'auto' }}>
        <div className="col-12 size-09">
          <Table data={state.products} autoHeight rowClassName="striped" rowHeight={80} locale={{ emptyMessage: 'No hay productos adicionales' }}>
            {TableHeader &&
              TableHeader.map((column: any, index: any) => (
                <Table.Column flexGrow={1} align={column.align} key={`table-column-${index}`}>
                  <Table.HeaderCell>
                    <span style={{ textTransform: 'capitalize' }}>{column.label}</span>
                  </Table.HeaderCell>
                  <Table.Cell style={{ display: 'flex', alignItems: 'center', justifyContent: column.alignFlex }}>
                    {(rowData) => {
                      switch (column.key) {
                        case 'image':
                          return <img src={rowData.info?.gallery?.url || Images.NotFoundJpg.image} alt="img-table" style={{ width: '50px' }} />;
                        case 'price':
                          return <>$ {rowData[column.key]?.toLocaleString('pt-BR')}</>;
                        case 'active':
                          return (
                            <>{rowData[column.key] ? <Badge color="green" content="activo" className="bold" /> : <Badge color="red" content="desactivado" className="bold" />}</>
                          );
                        default:
                          return (
                            <Whisper trigger="hover" placement="auto" controlId={`control-id-auto`} speaker={<Tooltip>{`${rowData[column.key]}`}</Tooltip>}>
                              <div style={{ textTransform: 'capitalize', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{`${rowData[column.key]}`}</div>
                            </Whisper>
                          );
                      }
                    }}
                  </Table.Cell>
                </Table.Column>
              ))}
            <Table.Column align="right" width={700} flexGrow={1} verticalAlign="middle">
              <Table.HeaderCell>{''}</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  return <Actions data={rowData} />;
                }}
              </Table.Cell>
            </Table.Column>
          </Table>
        </div>
      </div>
      <br />
      <Pagination nextPage={nextPage} prevPage={prevPage} index={currentPage.index} />
    </div>
  );
};

export default Products;

import { toBase64 } from './Utils';

export const convert_booleans_one_level = (payload: any) => {
  if (typeof payload === 'object') {
    Object.keys(payload).forEach((key: any) => {
      if (typeof payload[key] === 'string') {
        if (payload[key] === 'True') {
          payload[key] = true;
        }

        if (payload[key] === 'False') {
          payload[key] = false;
        }
      }
    });
  }

  if (Array.isArray(payload)) {
    payload.forEach((item: any) => {
      if (typeof item === 'object') {
        Object.keys(item).forEach((key: any) => {
          if (typeof item[key] === 'string') {
            if (item[key] === 'True') {
              item[key] = true;
            }

            if (item[key] === 'False') {
              item[key] = false;
            }
          }
        });
      }
    });
  }

  return payload;
};

export const convertToBase64AndFormatImage = async (payload: any, fileList: any, type: any = null) => {
  const fileImage = payload[fileList.length > 0 ? fileList.length : 0];
  const image = await toBase64(fileImage.blobFile);

  const name_original = fileImage.blobFile.name.replace(/\s/g, '-');

  const imageData = {
    info: { Nota: 'agrega informacion relevante' },
    name_original: name_original,
    type: type ? type : 'product',
    element_pk: '',
    alt: 'Imagen de copec',
    base64: image,
    file: fileImage.blobFile,
  };
  return imageData;
};

export const createImageName = (name:any, productName:any ) => {
  const image_format: any = name.split('.')[1];
  const randomID: any = parseInt(String(Math.random() * 10000000));
  const image_name: any = `${productName?.replace(/\s/g, '_')}_${randomID}.${image_format}`;
  return image_name;
};

import { createAction } from 'redux-actions';

export const SET_CONFIGS: any = createAction('SET_CONFIGS');
export const SAVE_CHANGES: any = createAction('SAVE_CHANGES');
export const CLEAR_CONFIGS: any = createAction('CLEAR_CONFIGS');

export const set_configs = (payload: any) => (dispatch: any) => {
  dispatch(SET_CONFIGS(payload));
  dispatch(SAVE_CHANGES());
  return payload;
}
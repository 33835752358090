import { createAction } from 'redux-actions';

export const SET_COUPONS: any = createAction('SET_COUPONS');
export const SAVE_CHANGES: any = createAction('SAVE_CHANGES');

export const set_coupons = (payload: any) => (dispatch: any) => {
  dispatch(SET_COUPONS(payload));
  dispatch(SAVE_CHANGES());
  return payload;
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Title from "../../components/Title";
import ModalComponent from "../../components/Modal";
import UserForm from "./UserForm";
import UserTable from "./UserTable";
import User from "../../services/user.service";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { LOADING_OFF, LOADING_ON } from "../../redux/actions/loader";
import Pagination from "../../components/PaginationFront/Pagination";
import usePagination from "../../hooks/usePagination";
import ConfirmationModal from "../../components/ConfirmationModal";
import { SelectPicker } from "rsuite";

const Users = () => {
    const [state, setState] = useState<any>({
        formModal: {
            openCreate: false,
            openUpdate: false,
        },
        confirmationModal: {
            open: false
        },
        user: {},
        data: [],
        search: '',
        role: 'CALLCENTER',
        roles: [{
            "label": "Call Center",
            "value": "CALLCENTER"
        }, {
            "label": "Admin Empresa",
            "value": "USER_COMPANY"
        }]
    })

    const [selectedRole, setSelectedRole] = useState<any>("CALLCENTER")
    const dispatch: any = useDispatch()

    const onPrev = async (response: any) => {
        _formatData(response.data)
        const users: any = response.data
        setState({
            ...state,
            data: users,
        });
    };

    const onNext = async (response: any) => {
        _formatData(response.data)
        const users: any = response.data
        setState({
            ...state,
            data: users,
        });
    };

    const { currentPage, prevPage, nextPage, setFirstPage } = usePagination({ onPrev, onNext, service: User, endpoint: '_listUsers', role: selectedRole });


    useEffect(() => {
        _getData()
    }, [])

    const _getData = async () => {
        dispatch(LOADING_ON());
        try {
            const response: any = await User._list(state.role)
            _formatData(response.data)
            setFirstPage(response.next_page);
            setState({
                ...state,
                data: response.data,
                formModal: {
                    openCreate: false,
                    openUpdate: false
                },
                confirmationModal: {
                    open: false
                }
            })
            dispatch(LOADING_OFF())
        } catch (e: any) {
            toast.error("No fue posible cargar los usuarios")
            dispatch(LOADING_OFF())
        }
    }

    const _formatData = (data: any) => {
        data.map((el: any) => {
            el.status = !el.info?.isInactive;
            return el;
        })

        return data;
    }

    const _toggleUserFormModal = (event: any, target: any = "openCreate") => {
        setState({
            ...state,
            formModal: {
                ...state.formModal,
                [target]: !state.formModal[target],
            }
        })
    }

    const _handleUpdate = (payload: any) => {
        setState({
            ...state,
            user: payload,
            formModal: {
                ...state.formModal,
                openUpdate: true
            }
        })
    }

    const _handleCloseConfirmation = () => {
        setState({
            ...state,
            user: {},
            confirmationModal: {
                open: false,
                text: ''

            }
        })
    }

    const _handleToggleStatus = (payload: any) => {
        const text: any = `¿Seguro que quieres ${payload.status ? 'desactivar' : 'activar'} este usuario?`
        setState({
            ...state,
            user: payload,
            confirmationModal: {
                open: true,
                text

            }
        })
    }

    const _toggleStatus = async () => {
        const { user } = state
        try {
            dispatch(LOADING_ON())
            user.info.isInactive = user.status;
            delete user.status;
            await User._update(user);
            const { data } = state;
            const userTable: any = data.find((el: any) => el.PK === user.PK && el.SK === user.SK)

            if (userTable) {
                userTable.status = !user.info.isInactive
                setState({
                    ...state,
                    confirmationModal: { open: false, text: '' },
                    data
                })
            }
            dispatch(LOADING_OFF())
        } catch (e: any) {
            dispatch(LOADING_OFF())
            toast.error(e?.message || "No fue posible cambiar el status de este usuario")
        }
    }

    const _handleSearch = async () => {
        try {
            dispatch(LOADING_ON())
            setSelectedRole(state.role)
            if (!state.search) {
                _getData();
                return
            }
            const response: any = await User._search({ email: state.search, role: state.role })

            const data: any = response.data?.filter((el: any) => el.GS2PK?.toLowerCase() === `ROLE#${state.role}`.toLowerCase())
            _formatData(data)
            setFirstPage(response.next_page)
            setState({
                ...state,
                data: data
            })
            dispatch(LOADING_OFF())
        } catch (e: any) {
            dispatch(LOADING_OFF())
            toast.error("No fue posible encontrar este usuario")
        }
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <Title size="sm" text={`Usuarios`} className="mt-5 to-upper bold" />
            </div>

            <div className="row">
                <div className="col-12 col-md-4 color-white">
                    <input
                        type="text"
                        className="input-default w-100 color-black"
                        placeholder="buscar por correo electronico"
                        value={state.search}
                        onChange={(e: any) => setState({ ...state, search: e.target.value })}
                    />
                </div>
                <div className="col-12 col-md-4 color-white">
                    <SelectPicker
                        locale={{ searchPlaceholder: 'Buscar', noResultsText: 'Ningún resultado encontrado.', emptyMessage: 'Ningún resultado encontrado.' }}
                        cleanable={false}
                        classPrefix="copec"
                        className="rs-copec-cleanable"
                        data={state.roles || []}
                        onChange={(value: any) => setState({ ...state, role: value })}
                        value={state?.role}
                        style={{ width: '100%' }}
                        placeholder="Roles"
                    />
                </div>
                
                <div className="col-12 col-md-4 text-end color-white">
                    <button className="btn-default me-3" onClick={_handleSearch}>
                        Buscar
                    </button>
                    <button className="btn-default" onClick={_toggleUserFormModal}>
                        + Nuevo Usuario
                    </button>
                </div>
            </div>

            <ModalComponent open={state.formModal.openCreate} size="md" noFull handleClose={(e: any) => _toggleUserFormModal("openCreate")} hideFooter>
                <UserForm action="create" handleClose={(e: any) => _toggleUserFormModal("openCreate")} handleSuccess={_getData} />
            </ModalComponent>

            <ModalComponent open={state.formModal.openUpdate} size="md" noFull handleClose={(e: any) => _toggleUserFormModal(e, "openUpdate")} hideFooter>
                <UserForm data={state.user} action="update" handleClose={(e: any) => _toggleUserFormModal("openUpdate")} handleSuccess={_getData} />
            </ModalComponent>

            <ConfirmationModal size="md" title="Atención" type="warning" noFull open={state.confirmationModal.open} handleClose={_handleCloseConfirmation} handleConfirm={_toggleStatus}>
                <span className="px-4">{state.confirmationModal.text}</span>
            </ConfirmationModal>

            <UserTable data={state.data} handleUpdate={_handleUpdate} toggleStatus={_handleToggleStatus} />

            <Pagination nextPage={nextPage} prevPage={prevPage} index={currentPage.index} />
        </div>
    )
}

export default Users;
/* eslint-disable no-useless-escape */
import * as yup from 'yup';

export const ScheduleSchema = yup.object().shape({
  interval: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .required('Este campo es requerido')
    .typeError('Este campo es requerido'),
  service_id: yup.string().required('Este campo es requerido').typeError('Este campo es requerido'),
  schedule_coverage_id: yup.string().required('Este campo es requerido').typeError('Este campo es requerido'),
  quantity_per_block_option: yup.boolean(),
  quantity_per_block: yup.number().nullable().when('quantity_per_block_option', {
    is: true,
    then: yup.number().min(1, 'Este campo debe ser mayor a 0').required('Este campo es requerido').typeError("Este campo es requerido"),
  }),
});

export const ScheduleLockSchema = yup.object().shape({
  interval: yup.number().required('Este campo es requerido').typeError('Este campo es requerido'),
  service_id: yup.string().required('Este campo es requerido').typeError('Este campo es requerido'),
  schedule_coverage_id: yup.string().required('Este campo es requerido').typeError('Este campo es requerido'),
  start: yup.date().typeError('Este campo es requerido').required('Este campo es requerido').typeError('Este campo es requerido'),
  end: yup.date().typeError('Este campo es requerido').required('Este campo es requerido').min(yup.ref('start'), 'La hora final debe ser posterior a la inicial'),
});

export const ScheduleObsSchema = yup.object().shape({
  observations: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*\n#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gi, 'Este campo contiene caracteres inválidos')
    .nullable()
    .required('Este campo es requerido'),
});

export type ScheduleType = yup.InferType<typeof ScheduleSchema>;
export type ScheduleLockType = yup.InferType<typeof ScheduleLockSchema>;
export type ScheduleObsType = yup.InferType<typeof ScheduleObsSchema>;

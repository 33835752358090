import { useEffect } from 'react';
import { Button, Modal } from 'rsuite';

const ConfirmationModal = (props: any) => {
  const { handleClose, open, handleConfirm, children, title, type, size } = props;

  useEffect(() => {
    if (open) {
      const closeElements: any = document.querySelectorAll('[title=Close]');
      Array.from(closeElements).forEach((item: any) => {
        item.setAttribute('title', 'Cerrar');
      });
    }
  }, [open]);

  const _handleTranslatetext = () => {
    const closeElements: any = document.querySelectorAll('[title=Apagar]');
    const clearElements: any = document.querySelectorAll('[title=Clear]');
    Array.from(closeElements).forEach((item: any) => {
      item.setAttribute('title', 'Cerrar');
    });
    Array.from(clearElements).forEach((item: any) => {
      item.setAttribute('title', 'Limpiar');
    });
  };

  const Warning = () => <i className="bi bi-exclamation-circle-fill" style={{ color: 'orange', fontSize: '30px' }}></i>;
  const Danger = () => <i className="bi bi-x-circle-fill size-15" style={{ color: 'red', fontSize: '30px' }}></i>;
  const Success = () => <i className="bi bi-check-circle-fill" style={{ color: 'green', fontSize: '30px' }}></i>;

  const Types: any = {
    undefined: <Success />,
    success: <Success />,
    danger: <Danger />,
    warning: <Warning />,
  };

  return (
    <Modal open={open} onClose={handleClose} size={size || 'auto'} full={false} className="px-3" onEntered={_handleTranslatetext}>
      <Modal.Header>
        <Modal.Title>
          <div className="d-flex align-items-center px-3">
            {Types[type]}
            <span className={`${type === 'none' ? '' : 'ms-4'} bold color-grey`}>{title}</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} appearance="subtle">
          Cancelar
        </Button>
        <Button onClick={handleConfirm} appearance="primary">
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ConfirmationModal;

import { handleActions } from 'redux-actions';
import { SIGNIN, SIGNOUT } from '../actions/session';

export const INITIAL_STATE = {
  isLogged: false,
};

export default handleActions({
  [SIGNIN]: (state: any, action: any) => {
    return { ...state, ...action.payload };
  },
  [SIGNOUT]: () => {
    return { isLogged: false };
  }
}, INITIAL_STATE);
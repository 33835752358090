import instance from './api_admin';
import instanceOvert from './api_overt';
import instanceAccount from './api_account';
import store from '../redux/store';
import getAllData from '../helpers/get_all_data';
import { getSession } from '../helpers/session';

class ScheduleDataAccess {
  _setHeaders = () => {
    const state: any = store.getState();
    const { session } = state;

    const oidcSession = getSession();

    if (oidcSession) {
      instance.defaults.headers.common['user'] = session.profile?.PK;
      instance.defaults.headers.common['usersk'] = session.profile?.SK;
      instance.defaults.headers.common['Authorization'] = `Bearer ${oidcSession?.access_token}`;
      instanceAccount.defaults.headers.common['user'] = session.profile?.PK;
      instanceAccount.defaults.headers.common['usersk'] = session.profile?.SK;
      instanceAccount.defaults.headers.common['Authorization'] = `Bearer ${oidcSession?.access_token}`;
      instanceOvert.defaults.headers.common['user'] = session.profile?.PK;
      instanceOvert.defaults.headers.common['usersk'] = session.profile?.SK;
      instanceOvert.defaults.headers.common['Authorization'] = `Bearer ${oidcSession?.access_token}`;
    }
  };

  listCoverage = async (paginationParams: any = null) => {
    this._setHeaders();
    try {
	  let params: any = [];

	  if (paginationParams && paginationParams.last) {
		params.push(`last=${paginationParams.last}`);
	  }

      const response = await instance.get(`/admin/schedule/coverage/list?limit=${process.env.REACT_APP_ORDERS_BASE_LIMIT}&${params.join('&')}`);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  listAllCoverage = async (paginationParams: any = null) => {
    this._setHeaders();
    try {
	  let params: any = [];

	  if (paginationParams && paginationParams.last) {
		params.push(`last=${paginationParams.last}`);
	  }
      const response = await getAllData(instance, '/admin/schedule/coverage/list', 50);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  selectListCoverage = async () => {
    this._setHeaders();
    try {
      const response = await instance.get('/admin/schedule/coverage/select');
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  createCoverage = async (params: any) => {
    this._setHeaders();
    try {
      const response = await instance.post('/admin/schedule/coverage/create', params);
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  updateCoverage = async (PK: any, params: any) => {
    this._setHeaders();
    try {
      const response = await instance.put(`/admin/schedule/coverage/update/${PK}`, params);
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  deleteCoverage = async (payload: any) => {
    this._setHeaders();
    try {
      const response = await instance.delete(`/admin/schedule/coverage/delete/${payload.PK}`, { data: payload });
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  listSchedule = async () => {
    this._setHeaders();
    try {
      const response = await instanceAccount.get(`/account/schedule/list`);
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  listScheduleByEds = async (payload: any, paginationParams: any = null) => {
    this._setHeaders();
    try {

      const params = [
        `eds_id=${payload.PK}`, 
        `PK=${payload.PK}`,
        `SK=${payload.SK}`,
        `limit=${process.env.REACT_APP_ORDERS_BASE_LIMIT}`
      ];

      if(paginationParams && paginationParams.last) {
        params.push(`last=${paginationParams.last}`);
      }

      const response = await instanceAccount.get(`/account/schedule/list?${params.join('&')}`);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  selectListSchedule = async () => {
    this._setHeaders();
    try {
      const response = await instanceAccount.get(`/account/schedule/select`);
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  createSchedule = async (params: any) => {
    this._setHeaders();
    try {
      const response = await instanceAccount.post(`/account/schedule/create`, params);
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  updateSchedule = async (PK: any, params: any) => {
    this._setHeaders();
    try {
      const response = await instanceAccount.put(`/account/schedule/update/${params.PK}`, params);
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  deleteSchedule = async (params: any) => {
    this._setHeaders();
    try {
      const response = await instanceAccount.delete(`/account/schedule/delete/${params.PK}`, { data: { hard: true, PK: params.PK, SK: params.SK } });
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  reSchedule = async (PK: any, payload: any) => {
    this._setHeaders();
    try {
      const response = await instance.put(`/admin/reservation/reschedule/${PK}?PK=${PK}&SK=${payload.SK}`, payload);
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  getAvailableTime = async (payload: any) => {
    this._setHeaders();
    try {
      const response = await instanceOvert.post('/overt/check-schedule', payload);
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };
}

const Schedule = new ScheduleDataAccess();
export default Schedule;

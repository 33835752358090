export const calendarSpanishlocale = {
  sunday: 'Dom',
  monday: 'Lun',
  tuesday: 'Mar',
  wednesday: 'Mie',
  thursday: 'Jue',
  friday: 'Vie',
  saturday: 'Sab',
  ok: 'OK',
  today: 'Hoy',
  yesterday: '',
  hours: 'Horas',
  minutes: 'Minutos',
  seconds: 'Segundos',
  formattedMonthPattern: 'MMM yyyy',
  formattedDayPattern: 'dd-MM-yyyy'
};

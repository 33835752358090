export const TableHeader = [
    {
        label: 'Orden ID',
        key: 'PK',
        flexGrow: 1
    },
    {
        label: 'Fecha Creación',
        key: 'created_at',
        flexGrow: 1
    },
    {
        label: 'Monto a reembolsar',
        key: 'amount_diff',
        flexGrow: 1
    },
    {
        label: '¿Ya fue reembolsado?',
        key: 'refunded',
        flexGrow: 1
    }
]

import { createAction } from 'redux-actions';
import {
  CREATE_ORDER,
  RETRIEVE_ORDERS,
  UPDATE_ORDER,
  DELETE_ORDER
} from "../types";
import store from '../store';

import OrdersDataOrder from "../../services/orders.service";
import Auth from '../../services/auth.service';
export const SAVE_STATE: any = createAction('SAVE_STATE');

export const createOrder = (data: any) => async (dispatch: any) => {
  try {
    const res = await OrdersDataOrder.create(data);

    dispatch({
      type: CREATE_ORDER,
      payload: res.data,
    });

    dispatch(SAVE_STATE())
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const retrieveOrders = () => async (dispatch: any) => {
  try {
    const { session } = store.getState();
    const { roles } = session.profile?.info;
    if (roles.find((role: any) => role === 'admin')) {
      const res: any = await OrdersDataOrder.list();
      dispatch({
        type: RETRIEVE_ORDERS,
        payload: res.data,
      });
      dispatch(SAVE_STATE())
      return Promise.resolve(res.data);
    } else {
      const eds = await Auth.getEds();
      let data: any = []

      for (const d of eds.data) {
        const res: any = await OrdersDataOrder.listByEds(d.value);
        data = [...data, ...res.data]
      }

      dispatch({
        type: RETRIEVE_ORDERS,
        payload: data,
      });
      dispatch(SAVE_STATE())
      return Promise.resolve(data);
    }

  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const retrieveOrdersByEds = (PK: any) => async (dispatch: any) => {
  try {
    const res: any = await OrdersDataOrder.listByEds(PK);
    dispatch({
      type: RETRIEVE_ORDERS,
      payload: res.data,
    });
    dispatch(SAVE_STATE())
    return Promise.resolve(res.data);

  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const updateOrder = (id: any, data: any) => async (dispatch: any) => {
  try {
    const res: any = await OrdersDataOrder.update(id, data);
    const n_data: any = res.data
    dispatch({
      type: UPDATE_ORDER,
      payload: n_data,
    });
    dispatch(SAVE_STATE())

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteOrder = (id: any) => async (dispatch: any) => {
  try {
    await OrdersDataOrder.delete(id);

    dispatch({
      type: DELETE_ORDER,
      payload: { id },
    });
    dispatch(SAVE_STATE())
  } catch (err) {
    console.log(err);
  }
};

// import { signout } from "../redux/actions/session";
import { getSession } from '../helpers/session';
import store from '../redux/store';
import instanceAdmin from './api_admin';

class UserService {
  _setHeaders = () => {
    const state: any = store.getState();
    const { session } = state;

    const oidcSession = getSession();

    if (oidcSession) {
      instanceAdmin.defaults.headers.common['user'] = session.profile?.PK;
      instanceAdmin.defaults.headers.common['usersk'] = session.profile?.SK;
      instanceAdmin.defaults.headers.common['Authorization'] = `Bearer ${oidcSession?.access_token}`;
    }
  };

  _list = async (role = 'CALLCENTER', paginationParams: any = null) => {
    this._setHeaders();
    try {
      let params: any = [];
      if (paginationParams && paginationParams.last) {
        params.push(`last=${paginationParams.last}`);
      }
      const response = await instanceAdmin.get(`/admin/user/list?limit=${process.env.REACT_APP_ORDERS_BASE_LIMIT}&role=${role}&${params.join('&')}`);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  _listUsers = async (paginationParams: any = null) => {
    this._setHeaders();
    try {
      let params: any = [];
      if (paginationParams && paginationParams.last) {
        params.push(`last=${paginationParams.last}`);
      }
      const response = await instanceAdmin.get(
        `/admin/user/list?limit=${process.env.REACT_APP_ORDERS_BASE_LIMIT}&role=${paginationParams.role || 'CALLCENTER'}&${params.join('&')}`
      );
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  _create = async (params: any) => {
    this._setHeaders();
    try {
      const response = await instanceAdmin.post('/admin/user/create', params);

      if (response?.data?.error) {
        throw new Error(`${params.concessionaire_id}::${response.data.error}`);
      }
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  _update = async (params: any) => {
    this._setHeaders();
    try {
      const response = await instanceAdmin.put(`/admin/user/update/${params.PK}`, params);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  _search = async (params: any) => {
    this._setHeaders();
    try {
      let body: any = [];

      if (params) {
        Object.keys(params).forEach((key: any) => {
          body.push(`${key}=${encodeURIComponent(params[key])}`);
        });
      }

      const response = await instanceAdmin.get(`/admin/user/search?limit=100&${body.join('&')}`);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  _massiveLoad = async (params: any) => {
    this._setHeaders();
    try {
      const response = await instanceAdmin.post('/admin/company/load/users', params);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
}

const User = new UserService();

export default User;

/* eslint-disable no-useless-escape */
import * as yup from 'yup';

export const SubServiceSubscriptonSchema = yup.object().shape({
  name: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .max(255, 'Este campo acepta un máximo de 255 caracteres')
    .required('Este campo es requerido'),
  descripcion: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .required('Este campo es requerido'),
  price: yup.string().required('Este campo es requerido'),
  info: yup.object({
    sku: yup
      .string()
      .matches(/^[\w\. ?¿¡!^\+\:()áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
      .max(255, 'Este campo acepta un máximo de 255 caracteres')
      .required('Este campo es requerido'),
    full_points: yup
      .string()
      .matches(/^[\w\. ?¿¡!^\+\:()áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
      .max(255, 'Este campo acepta un máximo de 255 caracteres'),
    pk_services: yup.array().of(yup.string().required('Este campo es requerido')).min(1, 'Este campo es requerido'),
    pk_eds: yup.array().of(yup.string().required('Este campo es requerido')).min(1, 'Este campo es requerido'),
    number_of_coupons_to_create: yup.string().required('Este campo es requerido'),
    subscription_duration: yup.string().required('Este campo es requerido'),
    apply: yup.string().required('Este campo es requerido'),
    // max_amount_discount: yup.string().notOneOf(["0"], "El valor del descuento debe ser mayor a 0").required('Este campo es requerido'),
    max_amount_discount: yup.string().when('apply', {
      is: 'sobre_envio',
      then: yup.string().notRequired(),
      otherwise: yup.string().notOneOf(["0"], "El valor del descuento debe ser mayor a 0").required('Este campo es requerido'),
    }),
    type_discount: yup.string().when('apply', {
      is: 'sobre_envio',
      then: yup.string().notRequired(),
      otherwise: yup.string().required('Este campo es requerido'),
    }),
  }),
});

export type SubServiceSubscriptonType = yup.InferType<typeof SubServiceSubscriptonSchema>;

/* eslint-disable no-useless-escape */
import moment from 'moment';

export function toBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function parseDrawMapsVariables(payload: any) {
  const variables: any = {
    stroke: 'strokeColor',
    'stroke-width': 'strokeWeight',
    'stroke-opacity': 'strokeOpacity',
    fill: 'fillColor',
    'fill-opacity': 'fillOpacity',
  };

  let googleFormat: any = {};
  Object.keys(variables).forEach((key: any) => {
    if (payload[key]) {
      googleFormat[variables[key]] = payload[key];
    }
  });

  return googleFormat;
}

export const getOnlyIds = (i: any) => i.map((elem: any) => elem.id);

export const fixListByIds = (item: any) => {
  Object.entries(item).forEach(([key, value]) => {
    if (Array.isArray(item[key])) {
      item[key] = getOnlyIds(item[key]);
    }
  });
  return item;
};

export const sortDateKeys = (obj: any) => {
  const key: any = Object.keys(obj).reduce((acc: any, curr: any) => {
    acc.push(curr);
    return acc;
  }, []);

  key.sort((a: any, b: any) => {
    if (moment(a).isAfter(moment(b))) return 1;
    if (moment(b).isAfter(moment(a))) return -1;
    return 0;
  });

  let temp: any = {};

  for (let i = 0; i < key.length; i++) {
    temp[key[i]] = obj[key[i]];
    delete obj[key[i]];
  }

  for (let i = 0; i < key.length; i++) {
    obj[moment(key[i]).format('DD/MM/Y')] = temp[key[i]];
  }
  return obj;
};

export function regexCognito(pass: any) {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/;
  return regex.test(pass);
}

export function formatRut(rut: any) {
  if (rut) {
    return rut.replace(/[.-]/g, '').replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, '$1.$2.$3-$4');
  }
  return '';
}

export function unformatRut(rut: any) {
  return rut ? rut.replace(/\./g, '') : '';
}

export function validateRut(rutValue: any) {
  const rut = unformatRut(rutValue);
  const rexp = new RegExp(/^([0-9])+-([kK0-9])+$/);
  if (rut.match(rexp)) {
    const RUT = rut.split('-');
    const elRut = RUT[0].split('');
    let factor: any = 2;
    let suma = 0;
    let dv;
    for (let i = elRut.length - 1; i >= 0; i--) {
      factor = factor > 7 ? 2 : factor;
      suma += parseInt(elRut[i]) * factor++;
    }
    dv = 11 - (suma % 11);
    if (dv === 11) {
      dv = 0;
    } else if (dv === 10) {
      dv = 'k';
    }

    if (dv.toString() === RUT[1].toLowerCase()) {
      return true;
    }
    return false;
  }
  return false;
}

export function validateAndFormatPhoneNumber(number: any) {
  let fixed: any = number;
  if (number?.indexOf('5656') === 0 && number.length > 11) {
    fixed = number?.substring(2);
  }

  let formated: any = '(' + fixed.substr(0, 3) + ') ' + fixed.substr(3, 4) + '-' + fixed.substr(7, 4);

  return [formated, fixed];
}

export function calculateOrderValues(order: any) {
  let amount_diff = 0;
  const payment_type = order?.payment?.payment_type;

  /**
   * If amount_diff is positive, it means that the order has reverse payment
   * If amount_diff is negative, it means that the order has a value to pay
   */

  if (order.order_complements && order.order_complements.length > 0) {
    amount_diff = order.order_complements[0].amount_diff;
  }

  const amount_off = order?.amount_off;
  const amount_shipping = order?.amount_shipping;
  const amount = order?.amount;
  const totalAmount = amount + amount_shipping - amount_off - amount_diff;

  if (payment_type === 1) amount_diff = 0;

  return { amount, amount_off, amount_shipping, totalAmount, amount_diff, payment_type, PK: order.PK };
}

export function getPaymentString(order: any): string {
  let payment = '';
  switch (order?.payment?.payment_type) {
    case 1:
      payment = 'Presencial';
      break;
    case 3:
      payment = 'WebPay Credito';
      break;
    case 2:
      payment = 'WebPay Debito';
      break;
    default:
      break;
  }

  return payment;
}

export const _getGoogleAddressComponents = (address_components: any, number: any) => {
  let streetNumber: any = address_components.find((address_comp: any) => address_comp.types.find((type: any) => type === 'street_number'));
  let street: any = address_components.find((address_comp: any) => address_comp.types.find((type: any) => type === 'route'));
  let locality: any = address_components.find((address_comp: any) => address_comp.types.find((type: any) => type === 'locality'));
  let country: any = address_components.find((address_comp: any) => address_comp.types.find((type: any) => type === 'country'));
  let commune_adm_lvl_3: any = address_components.find((address_comp: any) =>
    address_comp.types.find((type: any) => type === 'administrative_area_level_3' || type === 'administrative_area_level_2')
  );
  let region: any = address_components.find((address_comp: any) => address_comp.types.find((type: any) => type === 'administrative_area_level_1'));

  if (!streetNumber) {
    if (number !== 0) {
      streetNumber = { long_name: String(number), short_name: String(number) };
    } else {
      streetNumber = { long_name: '', short_name: '' };
    }
  }

  if (!street) {
    street = locality;
  }

  return { streetNumber, street, country, commune_adm_lvl_3, region };
};

export const listAllData = async (fn: any, paramsData: any = null) => {
  let responseData = null;
  let next_page_service = null;
  let nextPageUri = null;
  let errors = [];

  while (true) {
    try {
      nextPageUri = null;

      if (next_page_service && 'LastEvaluatedKey' in next_page_service) {
        const lastKey = next_page_service.LastEvaluatedKey; // the key for the current page

        nextPageUri = encodeURIComponent(JSON.stringify(lastKey));
      }

      let getData;
      if (paramsData) {
        getData = await fn(paramsData, nextPageUri ? { last: nextPageUri } : null);
      } else {
        getData = await fn(nextPageUri ? { last: nextPageUri } : null);
      }

      if (!responseData) {
        responseData = getData;
      } else {
        responseData.data = [...responseData.data, ...getData.data];
      }
      next_page_service = getData.next_page;

      /** BREAK LOOP IF DONT EXISTS MORE RESULTS */
      if (!getData.next_page) break;
    } catch (e: any) {
      errors.push(e.message);
      break;
    }
  }

  return { data: responseData.data, errors };
};

export const createCsvSimple = (data: any, name: any = null) => {
  try {
    let csvContent = 'data:text/csv;charset=utf-8,';
    const universalBOM: any = '\uFEFF';
    csvContent += universalBOM + data;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', name || 'my_data.csv');
    document.body.appendChild(link);
    link.click();
  } catch (e: any) {
    return false;
  }
};

export const convertTypeNames = (type: any) => {
  switch (type) {
    case 'lub':
      return 'Cambio de acete';
    case 'kerosene':
      return 'Kerosene';
    case 'kerosene-schedule':
      return 'Kerosene con Agendamiento';
    case 'lavamax':
      return 'Lavamax';
    case 'subscription':
      return 'Subscripción';
    default:
      return 'Otro';
  }
};

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';
import { Table, Tooltip, Whisper, Toggle } from 'rsuite';
import ModalComponent from '../../components/Modal';
import Title from '../../components/Title';
import validate from '../../helpers/validate';
import { CampaignSchema } from '../../validations/campaign.validations';
import AddCampaign from './AddCampaign';
import { TableCampaign } from './tableConfigs';
import campaignService from '../../services/campaign.service';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import { set_coupons } from '../../redux/actions/coupons';
import ConfirmationModal from '../../components/ConfirmationModal';
import Pagination from '../../components/PaginationFront/Pagination';
import usePagination from '../../hooks/usePagination';

const Campaigns = (props: any) => {
  const history = useHistory();
  const [state, setState] = useState<any>({
    inputs: {
      pk: null,
      name: '',
      fund: '',
      description: '',
      status: true,
      lock: false,
      start: new Date(),
      end: new Date(),
    },
    validations: {},
    modal: {
      add: {
        show: false,
      },
      update: {
        show: false,
      },
    },
    confirmation: { open: false, PK: null },
    data: [],
    campaigns_edited: null,
  });

  const onPrev = async (response: any) => {
    setState({
      ...state,
      data: response.data,
    });
  };

  const onNext = async (response: any) => {
    setState({
      ...state,
      data: response.data,
    });
  };

  const endpoint = 'list';

  const { currentPage, prevPage, nextPage, setFirstPage } = usePagination({ onPrev, onNext, service: campaignService, endpoint });

  const Actions = (props: any) => {
    const { payload } = props;

    const _handlePush = () => {
      dispatch(set_coupons(payload));
      history.push(`/cupones/campana/${payload.PK}`);
    };

    return (
      <div className="row">
        <div className="col-12">
          <button className="btn p-0 me-3 size-11 color-grey-menu" onClick={_handlePush}>
            <i className="bi bi-tag"></i>
          </button>
          <button className="btn p-0 me-3 size-11 color-grey-menu" onClick={() => _toggleUpdateModal(payload.PK)}>
            <i className="bi bi-pencil"></i>
          </button>
          <button className="btn p-0 me-3 size-11 color-grey-menu" onClick={() => setState({ ...state, confirmation: { PK: payload.PK, open: true } })}>
            <i className="bi bi-trash"></i>
          </button>
        </div>
      </div>
    );
  };

  const dispatch = useDispatch();

  useEffect(() => {
    _getCampaigns();
  }, []);

  const _toggleAddModal = () =>
    setState({
      ...state,
      validations: {},
      inputs: {
        pk: null,
        name: '',
        fund: '',
        description: '',
        status: true,
        lock: false,
        start: new Date(),
        end: new Date(),
      },
      modal: { ...state.modal, add: { show: !state.modal.add.show } },
    });

  const _toggleUpdateModal = (edit_id = null) => {
    const data: any = state.data.find((e: any) => e.PK === edit_id) || {};
    const start: any = data.lock && data.lock.start ? moment(data.lock.start).toDate() : new Date();
    const end: any = data.lock && data.lock.end ? moment(data.lock.end).toDate() : new Date();
    const inputs: any = {
      pk: edit_id,
      name: data.name,
      fund: data.fund,
      description: data.description,
      status: data.status,
      lock: data.lock?.status,
      start: start,
      end: end,
    };
    setState({
      ...state,
      campaigns_edited: edit_id,
      inputs: { ...state.inputs, ...inputs },
      modal: { ...state.modal, update: { show: !state.modal.update.show } },
      validations: {},
    });
  };

  const _handleOnChange = ({ target }: any) => {
    if (target.name === 'fund') {
      target.value = target.value.replace(/\D/g, '');
    }
    const { name, value } = target;

    if (state.validations[name]) {
      delete state.validations[name];
    }

    setState({ ...state, inputs: { ...state.inputs, [name]: value }, validations: { ...state.validations } });
  };

  const _handleOnChangeStatus = ({ row, target }: any) => {
    const { PK } = row;
    const { value } = target;
    const newData: any = state.data.map((e: any) => {
      if (e.PK === PK) {
        e.status = value;
      }
      return e;
    });
    const dataCampaign: any = {
      PK: PK,
      status: value,
    };

    setState({
      ...state,
      data: newData,
    });
    _handleUpdateStatus(dataCampaign);
  };

  const _getCampaigns = async () => {
    try {
      dispatch(LOADING_ON());
      const response = await campaignService.list();
      setFirstPage(response.next_page);
      dispatch(LOADING_OFF());
      setState({
        ...state,
        data: response.data,
        modal: {
          add: {
            show: false,
          },
          update: {
            show: false,
          },
        },
        confirmation: { open: false, PK: null },
        validations: {},
      });
    } catch (e: any) {
      dispatch(LOADING_OFF());
      toast.error('No fue posible obtener las campañas.');
    }
  };

  const _handleUpdateStatus = async (dataCampaign: any) => {
    dispatch(LOADING_ON());
    try {
      await campaignService.update({ ...dataCampaign });
      toast.success('El estado de la campaña ha sido actualizado con éxito.');
      _getCampaigns();
    } catch (e: any) {
      toast.error('No fue posible actualizar la campaña. Vuelve a intentarlo en unos minutos.');
    }
    dispatch(LOADING_OFF());
  };

  const _handleCreate = async (action: any) => {
    let validations = await validate(CampaignSchema, state.inputs);

    const start = moment(state.inputs.start);
    const end = moment(state.inputs.end);

    if (start.isAfter(end)) {
      validations = validations
        ? {
            ...validations,
            start: ['La fecha del campo Hasta debe ser posterior a la fecha del campo Desde'],
            end: ['La fecha del campo Hasta debe ser posterior a la fecha del campo Desde'],
          }
        : {
            start: ['La fecha del campo Hasta debe ser posterior a la fecha del campo Desde'],
            end: ['La fecha del campo Hasta debe ser posterior a la fecha del campo Desde'],
          };
    }

    const fund: any = parseInt(state.inputs.fund);

    if (fund < 1) {
      validations = validations
        ? {
            ...validations,
            fund: ['El valor del fondo debe ser mayor a 0'],
          }
        : {
            fund: ['El valor del fondo debe ser mayor a 0'],
          };
    }

    if (validations) {
      setState({ ...state, validations: validations });
      return;
    }

    setState({ ...state, validations: {} });
    dispatch(LOADING_ON());

    if (action === 'create') {
      try {
        const paramsCampaigns = {
          name: state.inputs.name,
          fund: parseInt(state.inputs.fund),
          status: state.inputs.status,
          description: state.inputs.description,
          lock: {
            on: state.inputs.lock,
            start: moment(state.inputs.start),
            end: moment(state.inputs.end),
            status: state.inputs.lock,
          },
          setup: [],
        };
        await campaignService.create(paramsCampaigns);
        toast.success('Campaña creada con éxito');
        await _getCampaigns();
      } catch (e: any) {
        console.log(e.message);
        toast.error('No fue posible crear la campaña. Vuelve a intentarlo en unos minutos.');
      }
    }

    if (action === 'update') {
      try {
        const paramsCampaigns = {
          PK: state.inputs.pk,
          name: state.inputs.name,
          fund: parseInt(state.inputs.fund),
          status: state.inputs.status,
          description: state.inputs.description,
          lock: {
            on: state.inputs.lock,
            start: moment(state.inputs.start),
            end: moment(state.inputs.end),
            status: state.inputs.lock,
          },
        };
        await campaignService.update(paramsCampaigns);
        toast.success('Campaña actualizada con éxito');
        await _getCampaigns();
      } catch (e: any) {
        console.log(e.message);
        toast.error('No fue posible actualizar la campaña. Vuelve a intentarlo en unos minutos.');
      }
    }

    dispatch(LOADING_OFF());
  };

  const _handleDelete = async (PK: any) => {
    const campaign: any = state.data?.find((item: any) => item.PK === PK);
    const params: any = { PK, data: { hard: false, PK: PK, SK: campaign.SK } };
    try {
      dispatch(LOADING_ON());
      await campaignService.delete(params);
      await _getCampaigns();
      dispatch(LOADING_OFF());
    } catch (e: any) {
      dispatch(LOADING_OFF());
      toast.error('No fue posible eliminar esta campaña');
      console.log(e.message);
    }
  };

  return (
    <div className="container-fluid">
      <ModalComponent is_static size="lg" noFull open={state.modal.add.show} handleClose={_toggleAddModal} handleConfirm={() => _handleCreate('create')}>
        <AddCampaign data={state} handleChange={_handleOnChange} />
      </ModalComponent>

      <ModalComponent is_static size="lg" noFull open={state.modal.update.show} handleClose={_toggleUpdateModal} handleConfirm={() => _handleCreate('update')}>
        <AddCampaign data={state} handleChange={_handleOnChange} is_edit />
      </ModalComponent>

      <ConfirmationModal
        open={state.confirmation.open}
        size="md"
        type="warning"
        title="Eliminar"
        handleClose={() => setState({ ...state, confirmation: { ...state.confirmation, open: false } })}
        handleConfirm={() => _handleDelete(state.confirmation.PK)}
      >
        ¿Seguro que quieres eliminar esta campaña?
      </ConfirmationModal>

      <div className="row">
        <Title size="sm" text={`Cupones`} className="mt-5 bold" />
      </div>

      <div className="row">
        <div className="col-12 mt-4 color-white">
          <button className="btn-default" onClick={_toggleAddModal}>
            {' '}
            + Nueva Campaña
          </button>
        </div>
      </div>
      <div className="row border rounded-10 mt-5 p-3">
        <div className="col-12">
          <div className="row">
            <Title size="sm" text={`Campañas`} className="mt-3 mb-3 bold" />
          </div>
          <Table data={state.data} autoHeight rowClassName="striped" locale={{ emptyMessage: 'No hay campañas' }}>
            {TableCampaign &&
              TableCampaign.map((column: any, index: any) => (
                <Table.Column flexGrow={1} key={`table-column-${index}`}>
                  <Table.HeaderCell align={'center'}>
                    <span style={{ textTransform: 'capitalize' }}>{column.label}</span>
                  </Table.HeaderCell>
                  <Table.Cell align={column.key === 'status' ? 'center' : 'left'}>
                    {(rowData) => {
                      switch (column.key) {
                        case 'status':
                          return <Toggle checked={rowData[column.key]} onChange={(e: any) => _handleOnChangeStatus({ row: rowData, target: { name: 'status', value: e } })} />;
                        case 'fund':
                          return (
                            <Whisper
                              trigger="hover"
                              placement="auto"
                              controlId={`control-id-auto`}
                              speaker={<Tooltip>$ {parseInt(rowData[column.key])?.toLocaleString('pt-BR')}</Tooltip>}
                            >
                              <div className="text-end">$ {parseInt(rowData[column.key])?.toLocaleString('pt-BR')}</div>
                            </Whisper>
                          );
                        default:
                          return (
                            <Whisper trigger="hover" placement="auto" controlId={`control-id-auto`} speaker={<Tooltip>{rowData[column.key]}</Tooltip>}>
                              <span style={{ textTransform: 'capitalize' }}>{rowData[column.key]}</span>
                            </Whisper>
                          );
                      }
                    }}
                  </Table.Cell>
                </Table.Column>
              ))}

            <Table.Column align="right" flexGrow={1} verticalAlign="middle">
              <Table.HeaderCell>{''}</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  return <Actions payload={rowData} />;
                }}
              </Table.Cell>
            </Table.Column>
          </Table>
        </div>
      </div>
      <br />
      <Pagination nextPage={nextPage} prevPage={prevPage} index={currentPage.index} />
    </div>
  );
};
export default Campaigns;

export const TableHeader = [
  {
    label: 'Id Orden',
    key: 'PK',
    align: 'left',
    alignFlex: 'flex-start',
    flexGrow: 1,
  },
  {
    label: 'Servicio',
    key: 'GS4PK',
    align: 'left',
    alignFlex: 'flex-start',
    flexGrow: 1,
  },
  {
    label: 'Nombre',
    key: 'name',
    sub_key: 'nombre',
    align: 'left',
    alignFlex: 'start',
    flexGrow: 1,
  },
  {
    label: 'Email',
    key: 'email',
    align: 'left',
    alignFlex: 'flex-start',
    flexGrow: 1,
  },
  {
    label: 'EDS',
    key: 'GS3PK',
    align: 'left',
    alignFlex: 'flex-start',
    flexGrow: 1,
  },
  {
    label: 'Dirección',
    key: 'direction',
    align: 'left',
    alignFlex: 'flex-start',
    flexGrow: 1,
  }
]


export const SelectActions = [
  {
    label: 'Cambiar Estado',
    value: 1
  },
  {
    label: 'Reagendar',
    value: 4
  },
  {
    label: 'Detalles',
    value: 2
  }
]

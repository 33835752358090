import instance from './api_admin';
import instanceAccount from './api_account';
import store from '../redux/store';
import { validateAndFormatPhoneNumber } from '../helpers/Utils';
import { mutate_refund } from '../helpers/mutations';
import { getSession } from '../helpers/session';

class OrdersDataOrder {
  _setHeaders = () => {
    const state: any = store.getState();
    const { session } = state;

    const oidcSession = getSession();

    if (oidcSession) {
      instance.defaults.headers.common['user'] = session.profile?.PK;
      instance.defaults.headers.common['usersk'] = session.profile?.SK;
      instance.defaults.headers.common['Authorization'] = `Bearer ${oidcSession?.access_token}`;
      instanceAccount.defaults.headers.common['user'] = session.profile?.PK;
      instanceAccount.defaults.headers.common['usersk'] = session.profile?.SK;
      instanceAccount.defaults.headers.common['Authorization'] = `Bearer ${oidcSession?.access_token}`;
    }
  };

  list = async (payload: any = {}, paginationParams: any = null) => {
    this._setHeaders();

    let params: any = [];

    try {
      if (paginationParams && paginationParams.last) {
        params.push(`last=${paginationParams.last}`);
      }
      let response: any = { data: { data: [] } };

      if (payload?.status?.toLowerCase() === 'programmed') {
        // create query string
        Object.keys(payload).forEach((key: any) => {
          params.push(`${key}=${payload[key]}`);
        });

        // get programmed orders
        const programmed: any = await instance.get(`/admin/order/list?limit=${process.env.REACT_APP_ORDERS_BASE_LIMIT}&${params.join('&')}`);

        // clear params
        params = [];

        // set status to rescheduled
        payload.status = 'RESCHEDULED'
        ;
        // create query string
        Object.keys(payload).forEach((key: any) => {
          params.push(`${key}=${payload[key]}`);
        });

        // get rescheduled orders
        const rescheduled: any = await instance.get(`/admin/order/list?limit=${process.env.REACT_APP_ORDERS_BASE_LIMIT}&${params.join('&')}`);

        // merge data
        response.data.data = [...programmed.data.data, ...rescheduled.data.data];
      } else {
        // create query string
        Object.keys(payload).forEach((key: any) => {
          params.push(`${key}=${payload[key]}`);
        });

        // get orders by status
        response = await instance.get(`/admin/order/list?limit=${process.env.REACT_APP_ORDERS_BASE_LIMIT}&${params.join('&')}`);
      }
      /**
       * Mutation phone number
       */
      if (response.data?.data) {
        response.data.data?.forEach((order: any) => {
          const { user } = order;
          if (user) {
            user.phone_formated = validateAndFormatPhoneNumber(user.phone)[0];
            user.phone_number_formated = validateAndFormatPhoneNumber(user.phone_number)[0];
            user.phone_number_plain = validateAndFormatPhoneNumber(user.phone_number)[1];
            user.phone_number_plain = validateAndFormatPhoneNumber(user.phone_number)[1];
          }
        });
      }
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  listByEds = async (payload: any) => {
    this._setHeaders();
    try {
      const response = await instance.get(`/admin/order/list?eds_id=${payload.PK}`);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  details = async (payload: any) => {
    this._setHeaders();
    try {
      const response = await instance.get(`/admin/order/${payload.PK}?PK=${payload.PK}&SK=${payload.SK}`);
      /**
       * Mutation phone number
       */
      const { user } = response.data?.data;
      if (user) {
        user.phone_formated = validateAndFormatPhoneNumber(user.phone)[0];
        user.phone_number_formated = validateAndFormatPhoneNumber(user.phone_number)[0];
        user.phone_number_plain = validateAndFormatPhoneNumber(user.phone_number)[1];
        user.phone_number_plain = validateAndFormatPhoneNumber(user.phone_number)[1];
      }
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  detailsOperator = async (id: any) => {
    this._setHeaders();
    try {
      const response = await instanceAccount.get(`/account/operator/orden/${id}`);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  detailsAdmin = async (payload: any) => {
    this._setHeaders();
    try {
      const response = await instanceAccount.get(`/account/orden/${payload.PK}?PK=${payload.PK}&SK=${payload.SK}`);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  changeStatusAdmin = async (params: any) => {
    this._setHeaders();
    try {
      const response = await instance.put('/admin/order/change_state', params);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  changeStatus = async (params: any) => {
    this._setHeaders();
    try {
      params.status = params.status.toLowerCase();
      const response = await instanceAccount.post('/account/order/update/state', params);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  listReverses = async (payload: any) => {
    this._setHeaders();
    let params: any = [];

    if (payload) {
      Object.keys(payload).forEach((key: any) => {
        params.push(`${key}=${payload[key]}`);
      });
    }
    try {
      let response: any = null;
      response = await instance.get(`/admin/order/refund?limit=10&${params.join('&')}`);
      response.data.data = mutate_refund(response.data.data);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  updateOrderPersonalData = async (payload: any, data: any) => {
    this._setHeaders();
    try {
      const response = await instanceAccount.put(`/account/order/update/${payload.PK}`, data);
      return response;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  updateOrderPersonalDataAdmin = async (payload: any, data: any) => {
    this._setHeaders();
    try {
      const response = await instance.put(`/admin/order/update/${payload.PK}`, data);
      return response;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  getCsvOrders = async () => {
    this._setHeaders();
    try {
      const response = await instance.get(`/admin/order/report`);
      return response;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  getAll = async () => {
    this._setHeaders();
    try {
      const response = await instance.get('/calendar/all');
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  get = async (id: any) => {
    this._setHeaders();
    try {
      const response = await instance.get(`/calendar/${id}`);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  create = async (data: any) => {
    this._setHeaders();
    try {
      const response = await instance.post('/calendar', data);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  update = async (id: any, data: any) => {
    this._setHeaders();
    try {
      const response = await instance.put(`/calendar/${id}`, data);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  delete = async (id: any) => {
    this._setHeaders();
    try {
      const response = await instance.delete(`/calendar/${id}`);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  updateRefundOrderStatus = async (payload: any) => {
    this._setHeaders();
    try {
      let response: any = null;
      response = await instance.post(`/admin/order/refund/status`, payload);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
}

export default new OrdersDataOrder();

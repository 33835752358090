import Title from "../../components/Title"

const Dashboard = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <Title size="sm" text="Dashboard" className="mt-5 to-upper bold" />
      </div>
    </div>
  )
}

export default Dashboard
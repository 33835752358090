import {
  CREATE_ORDER,
  RETRIEVE_ORDERS,
  UPDATE_ORDER,
  DELETE_ORDER,
} from "../types";

const initialState:any = [];

function orderReducer(orders = initialState, action:any) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_ORDER:
      return [...orders, ...payload];

    case RETRIEVE_ORDERS:
      return payload;

    case UPDATE_ORDER:
      return orders.map((order:any) => {
        if (order.id === payload[0].id) {
          return {
            ...order,
            ...payload[0],
          };
        } else {
          return order;
        }
      });

    case DELETE_ORDER:
      return orders.filter(({ id }:any) => id !== payload.id);
    default:
      return orders;
  }
};

export default orderReducer;

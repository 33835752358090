import instance from './api_admin';
import instanceAccount from './api_account';
import store from '../redux/store';
import { getSession } from '../helpers/session';
class DriverService {
  _setHeaders = () => {
    const state: any = store.getState();
    const { session } = state;

    const oidcSession = getSession();

    if (oidcSession) {
      instance.defaults.headers.common['user'] = session.profile?.PK;
      instance.defaults.headers.common['usersk'] = session.profile?.SK;
      instance.defaults.headers.common['Authorization'] = `Bearer ${oidcSession?.access_token}`;
      instanceAccount.defaults.headers.common['user'] = session.profile?.PK;
      instanceAccount.defaults.headers.common['usersk'] = session.profile?.SK;
      instanceAccount.defaults.headers.common['Authorization'] = `Bearer ${oidcSession?.access_token}`;
    }
  };

  createDriver = async (params: any) => {
    this._setHeaders();
    try {
      params.info = {
        ...params.info,
        roles: ['operator'],
        role: 'operator',
        permissions: ['all'],
        isAuthZero: true,
      };
      const response = await instanceAccount.post('/account/concessionaire/operator/create', params);

      if (response.data?.error) {
        throw new Error(response.data?.error);
      }
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  listDrivers = async (params: any) => {
    this._setHeaders();
    try {
      const response = await instanceAccount.get(`/account/concessionaire/operator/list?PK=${params.PK}`);

      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  update = async (params: any) => {
    this._setHeaders();
    try {
      params.roles = ['operator'];
      params.permissions = ['all'];
      const response = await instance.put(`/admin/user/update/${params.PK}`, params, { headers: { user: params.user_PK, usersk: params.user_SK } });

      if (response.data?.error) {
        throw new Error(response.data?.error);
      }
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  delete = async (params: any) => {
    this._setHeaders();
    try {
      const response = await instanceAccount.delete(`account/concessionaire/operator/delete`, {
        data: { hard: true, PK: params.PK, SK: params.SK },
      });
      return response;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  deleteUser = async (params: any) => {
    this._setHeaders();
    try {
      const response = await instance.delete(`admin/user/delete/${params.PK}`, {
        data: { hard: true, PK: params.PK, SK: params.SK },
      });
      return response;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };
}

export default new DriverService();

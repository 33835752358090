/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getSession } from '../../helpers/session';

const SignIn = ({ authorize }: any) => {
  const { configs } = useSelector((state: any) => state);

  useEffect(() => {
    const fusionAuthSession = getSession();

    if (fusionAuthSession) {
      const { menu } = configs;
      const redirect = menu.find((item: any) => item.name === 'Pedidos' && item.url === '/pedidos');
      if (redirect) {
        window.location.href = redirect?.url;
      } else {
        window.location.href = menu[0]?.url;
      }
    } else {
      authorize();
    }
  }, []);

  return null
};
export default SignIn;

import { Table } from 'rsuite';

const CarTable = (props: any) => {
  const headerCell: any = [
    {
      label: 'Patente',
      key: 'plate',
    },
    {
      label: 'Marca',
      key: 'brand',
    },
    {
      label: 'Modelo',
      key: 'model',
    },
    {
      label: 'Año',
      key: 'year',
    },
  ];

  return (
    <div className="container-fluid">
      {props.data && props.data.length > 0 && (
        <div className="row mb-5">
          <div className="col-12 bold size-12">Vehículo</div>
          <div className="col-12">
            <Table data={props.data || []} rowClassName="striped" autoHeight>
              {headerCell.map((column: any) => (
                <Table.Column key={column.name} flexGrow={1}>
                  <Table.HeaderCell align="center">
                    <span className="bold" style={{ textTransform: 'capitalize' }}>
                      {column.label}
                    </span>
                  </Table.HeaderCell>
                  <Table.Cell>
                    {(rowData: any) => {
                      switch (column.key) {
                        default:
                          return <div className="w-100 text-center">{rowData[column.key]}</div>;
                      }
                    }}
                  </Table.Cell>
                </Table.Column>
              ))}
            </Table>
          </div>
        </div>
      )}
    </div>
  );
};

export default CarTable;

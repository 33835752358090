import ITitle from '../../interfaces/ITitle'
const Title: React.FC<ITitle> = (props) => {

  const { size, text, className } = props

  const Element = () => {
    switch (size) {
      case 'sm':
        return <h5 className={className} dangerouslySetInnerHTML={{ __html: text }} />
      case 'md':
        return <h4 className={className} dangerouslySetInnerHTML={{ __html: text }} />
      case 'lg':
        return <h3 className={className} dangerouslySetInnerHTML={{ __html: text }} />
      case 'xl':
        return <h2 className={className} dangerouslySetInnerHTML={{ __html: text }} />
      case 'xxl':
        return <h1 className={className} dangerouslySetInnerHTML={{ __html: text }} />
      default:
        return <h1 className={className} dangerouslySetInnerHTML={{ __html: text }} />
    }
  }

  return (
    <div>
      <Element />
    </div>
  )
}

export default Title
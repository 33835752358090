const getAllData = async (instance: any, url:any, limit: any = 10) => {
  let i: any = 0;
  let loop: any = true;
  let responseRequest: any = null;
  let listResponse: any = [];

  while (loop) {
    if (i > 0) {
      const { next_page } = responseRequest?.data;

      let params: any = [];

      if (next_page && 'LastEvaluatedKey' in next_page) {
        const lastKey = next_page.LastEvaluatedKey;

        let nextPageUri = encodeURIComponent(JSON.stringify(lastKey));

        params.push(`last=${nextPageUri}`);

        responseRequest = await instance.get(`${url}?limit=${limit}&${params.join('&')}`);

        const { data } = responseRequest.data;

        listResponse = [...listResponse, ...data];
      } else {
        loop = false;
      }
    } else {
      responseRequest = await instance.get(`${url}?limit=${limit}`);

      const { data } = responseRequest.data;

      listResponse = [...listResponse, ...data];
    }
    i++;
  }

  return { data: listResponse };
};

export default getAllData;

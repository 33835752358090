/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Toggle, Uploader } from 'rsuite';
import validate from '../../helpers/validate';
import Product from '../../services/products.service';
import { ProductSchema } from '../../validations/product.validations';
import Title from '../Title';
import Error from '../Error';
import { useDispatch } from 'react-redux';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import MediaFiles from '../../services/media.service';
import { convertToBase64AndFormatImage, createImageName } from '../../helpers/convert_and_clone';

const AddProduct = (props: any) => {
  const { handleClose, closeAndUpdate } = props;
  const [state, setState] = useState<any>({
    inputs: {
      name: '',
      descripcion: '',
      sku: '',
      price: '',
      active: true,
      info: {
        nota: 'Producto nuevo...',
      },
    },
    image: null,
    fileList: [],
    validations: {},
  });

  const dispatch = useDispatch();

  const _handleOnChange = ({ target }: any) => {
    const { name, value } = target;
    if (name === 'price') {
      state.inputs[name] = value.replace(/\D/g, '');
    } else {
      state.inputs[name] = value;
    }
    setState({ ...state });
  };

  const handleImage = async (e: any) => {
    if (e.length > 0) {
      const imageData = await convertToBase64AndFormatImage(e, state.fileList);
      console.log(imageData);
      setState({
        ...state,
        image: imageData,
        fileList: [e[state.fileList.length > 0 ? state.fileList.length : 0]],
      });
    } else {
      setState({ ...state, image: null, fileList: [] });
    }
  };

  const _handleSubmit = async () => {
    const validations = await validate(ProductSchema, state.inputs);
    if (validations) {
      setState({ ...state, validations });
      return;
    }
    dispatch(LOADING_ON());
    try {
      const productResponse = await Product.create({
        ...state.inputs,
        price: parseInt(state.inputs.price),
      });
      if (state.image) {
        //CREATE IMAGE NAME
        const image_name: any = createImageName(state.image?.file?.name, state.inputs?.name);

        // CREATE UPLOAD URL
        const presignUrl = await MediaFiles.presigned({ path: `products/${image_name.toLowerCase()}`, content_type: state.image.file.type });

        // UPLOAD FILE
        await fetch(presignUrl.data.url, {
          body: state.image.file,
          method: 'PUT',
          headers: new Headers({ 'content-type': state.image.file.type, 'x-amz-acl': 'public-read' }),
        });

        //GET IMG URL
        const finalImageUrl: any = presignUrl.data?.url?.split('?')[0];
        const product = { ...productResponse.data };
        product.info = {
          ...product.info,
          gallery: { url: finalImageUrl },
        };
        product.price = parseInt(product.price);
        await Product.update(product.PK, product);
      }
      toast.success('Producto creado con éxito');
      closeAndUpdate();
    } catch (e: any) {
      dispatch(LOADING_OFF());
      console.log('LOGGER', e.message);
      toast.error(e.message);
    }
  };

  return (
    <div className="component-services px-5 mb-4">
      <div className="row">
        <Title size="sm" text="Crear Producto" className="bold to-upper size-11" />
      </div>

      <div className="row border rounded-05 py-5 px-4 mt-4">
        <div className="col-6">
          <span className=" size-08 bold me-2">Nombre</span>
          <div className="">
            <input value={state.inputs.name} onChange={_handleOnChange} name="name" className="input-default w-100" type="text" placeholder="Nombre" />
            <Error name="name" errors={state.validations} />
          </div>
        </div>

        <div className="col-6">
          <span className=" size-08 bold me-2">Precio</span>
          <div className="">
            <input value={state.inputs.price} onChange={_handleOnChange} name="price" className="input-default w-100" type="text" placeholder="00000" />
            <Error name="price" errors={state.validations} />
          </div>
        </div>

        <div className="col-6">
          <span className=" size-08 bold me-2">Descripción</span>
          <div className="">
            <input value={state.inputs.descripcion} onChange={_handleOnChange} name="descripcion" className="input-default w-100" type="text" placeholder="Descripción" />
            <Error name="descripcion" errors={state.validations} />
          </div>
        </div>

        <div className="col-6 ">
          <span className=" size-08 bold me-2">SKU</span>
          <div className="">
            <input value={state.inputs.sku} onChange={_handleOnChange} name="sku" className="input-default w-100" type="text" placeholder="SKU" />
            <Error name="sku" errors={state.validations} />
          </div>
        </div>

        <div className="col-6 mt-3">
          <span className=" size-08 bold me-2">Imagen</span>
          <div className="">
            <Uploader accept='.png, .jpg, .jpeg' listType="picture-text" onChange={handleImage} fileList={state.fileList} onError={(e: any) => console.log('ERROR LOG', e.message)} autoUpload={false}>
              <button>
                <span className="me-1">+</span> Seleccionar archivo
              </button>
            </Uploader>
          </div>
        </div>

        <div className="col-6 d-flex  flex-column justify-content-center ">
          <span className="size-08 bold me-2">Activo</span>
          <div className="">
            <Toggle checked={state.inputs.active} onChange={(e: any) => _handleOnChange({ target: { name: 'active', value: e } })} />
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12 text-end text-white">
          <button className="btn btn-danger me-5" onClick={handleClose}>
            Cancelar
          </button>
          <button className="btn btn-default" onClick={_handleSubmit}>
            Confirmar
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddProduct;

/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Images from '../../assets/image';
import Image from '../Image';
import { main_menu } from '../../helpers/menus';
import { set_configs } from '../../redux/actions/configs';
import MenuIcon from '@rsuite/icons/Menu';

import './styles.scss';
import { RolesList } from '../../routes/Roles';

const TopMenu = () => {
  const history = useHistory();
  const { configs, session } = useSelector((state: any) => state);
  const [state, setState] = useState({
    open: false,
  });
  const dispatch = useDispatch();
  const roles: any = RolesList;

  useEffect(() => {
    if (configs && configs.menu && configs.menu.length === 0) {
      dispatch(set_configs({ menu: main_menu }));
    }
  }, []);

  const _toggleMenu = () => {
    const html = document.querySelector('html');
    if (html) {
      html.style.overflow = state.open ? 'auto': 'hidden';
    }
    setState({ ...state, open: !state.open });
  };

  return (
    <>
      <div className="col-12 bg-blue-light shadow-sm px-3 py-3 d-flex d-sm-none justify-content-between align-items-center">
        <Image {...Images.LogoSvg} style={{width: '130px'}} />
        <button className="btn p-0 me-3" style={{ fontSize: '1.5rem' }} onClick={_toggleMenu}>
          <MenuIcon />
        </button>
      </div>
      <div className={`col-12 bg-blue-light flex-column justify-content-between overflow-auto d-flex d-sm-flex menu-top ${state.open ? 'open' : ''}`} style={{ height: '100vh' }}>
        <button className="btn bold-300 size-09 position-absolute color-grey-menu" style={{ right: '15px', top: '10px' }} onClick={_toggleMenu}>
          X
        </button>
        <div className="component-menu row">
          <div className="col-12 mt-5 p-3 text-center">
            <Image {...Images.LogoSvg} style={{width: '130px'}} />
          </div>

          <div className="col-12 flex-column mt-3">
            <div className="d-flex align-items-center justify-content-center">
              <Image {...Images.AvatarPng} />
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center mt-2">
              <div className="size-08 bold text-center">{session && session.profile ? `${session.profile.name}` : ''}</div>
              <div className="size-08 bold text-center capitalize">
                {session && session.profile ? `${roles.find((role: any) => role.value === session.profile?.info?.roles[0])?.label}` : ''}
              </div>
              {/* <div className='size-07 bold color-grey-menu text-center'>Editar perfil</div> */}
            </div>
          </div>

          <div className="col-12 mt-3">
            <ul className="m-0 px-3" style={{ listStyle: 'none' }}>
              {configs &&
                configs.menu &&
                configs.menu.map((menu: any, index: number) => (
                  <Link to={menu.url} key={`menu-${index}`}>
                    <li className={`color-grey-menu py-3 px-3 bold ${menu.url === history.location.pathname ? 'menu-selected' : ''}`}>
                      <i className={menu.icon} />
                      {menu.name}
                    </li>
                  </Link>
                ))}
              <Link to="/logout">
                <li className={`color-grey-menu py-3 px-3 bold`}>
                  <i className="bi bi-box-arrow-left me-3"></i>
                  Cerrar sesión
                </li>
              </Link>
            </ul>
          </div>
        </div>

        <div className="component-menu-footer row mb-3">
          <div className="row flex-column">
            <div className="col text-center size-09">© {moment().format('yyyy')} Copec a domicilio</div>
            <div className="col size-06 color-grey-menu text-center">Todos los derechos reservados</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopMenu;

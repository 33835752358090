/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router';
import Loader from '../components/Loader';
import { SET_MEDIA } from '../redux/actions/matchMedia';
import history from '../services/history';
import { ToastContainer } from 'react-toastify';
import { AuxiliarRoutes, _createFusionAuthRoutes } from './routes';
import { Roles } from './Roles';

import 'react-toastify/dist/ReactToastify.css';
import 'moment/locale/es';
import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import authConfig from '../helpers/authConfig';
import CallbackFusionAuth from '../pages/CallbackFusionAuth';
import Logout from '../pages/Logout';
import { getSession } from '../helpers/session';
import SignIn from '../pages/SignIn';
import NotFoundUser from '../pages/NotFoundUser';

const AppRouter = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state);
  const [routeData, setRouteData] = useState<any>([]);
  const { session } = state;
  const [matchMedias, setMatchMedias] = useState<any>({
    xs: {
      media: '(max-width: 575px)',
      matchMedia: null,
      _toString: 'xs',
    },
    sm: {
      media: '(min-width: 576px)',
      matchMedia: null,
      _toString: 'sm',
    },
    md: {
      media: '(min-width: 768px)',
      matchMedia: null,
      _toString: 'md',
    },
    lg: {
      media: '(min-width: 992px)',
      matchMedia: null,
      _toString: 'lg',
    },
    xl: {
      media: '(min-width: 1200px)',
      matchMedia: null,
      _toString: 'xl',
    },
    xxl: {
      media: '(min-width: 1400px)',
      matchMedia: null,
      _toString: 'xxl',
    },
  });
  const [UserManagerResponse, setUserManagerResponse] = useState<any>(null);

  const _redirectLogout = () => {
    history.push('/logout')
  }

  useEffect(() => {
    window.addEventListener('logout_start', _redirectLogout)
    return () => {
      window.removeEventListener('logout_start', _redirectLogout)
    }
  }, [])

  useEffect(() => {
    Object.keys(matchMedias).forEach((key) => {
      matchMedias[key].matchMedia = window.matchMedia(matchMedias[key].media);
    });
    setMatchMedias(matchMedias);
    handleMediaQuerys();
    window.removeEventListener('resize', () => { });
    window.addEventListener('resize', () => {
      handleMediaQuerys();
    });
  }, [state.matchMedia.media]);

  const handleMediaQuerys = async () => {
    const { matchMedia } = state;
    let size = '';
    Object.keys(matchMedias).forEach((key) => {
      if (matchMedias[key].matchMedia.matches) {
        size = matchMedias[key]._toString;
      }
    });
    if (matchMedia.media !== size) {
      dispatch(SET_MEDIA(size));
    }
  };

  const updateRouter = () => {
    _createRouterData();
  }

  let authorize = () => null

  let clearAuth = () => null

  useEffect(() => {
    _createRouterData();
  }, []);

  const _createRouterData = async () => {
    const fusionAuthSession = getSession();

    let userManager: any = null;
    if (!UserManagerResponse) {
      userManager = new UserManager({
        userStore: new WebStorageStateStore({ store: window.localStorage }),
        loadUserInfo: true,
        ...authConfig,
      });

      userManager.events.addAccessTokenExpired(() => {
        try {
          userManager.signoutRedirect()
        } catch (e: any) {
          history.push('/logout')
        }
      });

      userManager.signinSilentCallback(window.location.href);

      setUserManagerResponse(userManager);
      authorize = () => userManager.signinRedirect();
      clearAuth = () => userManager.signoutRedirect();
    } else {
      userManager = UserManagerResponse;
      authorize = () => userManager.signinRedirect();
      clearAuth = () => userManager.signoutRedirect();
    }

    /**
     * Added FusionAuth Basic Routes
     */

    const FusionAuthRoutes: any = [
      {
        component: () => CallbackFusionAuth({ authorize, clearAuth, userManager, updateRouter }),
        originalComponent: CallbackFusionAuth,
        path: '/auth/callback',
        title: 'Callback FusionAuth',
        exact: true,
        permissions: [
          Roles.ADMIN,
          Roles.CONCESSIONAIRE,
          Roles.CALLCENTER,
        ],
        menu: false
      },
      {
        component: () => Logout({ authorize, clearAuth, userManager }),
        originalComponent: Logout,
        path: '/logout',
        title: 'Logout',
        exact: true,
        permissions: [
          Roles.ADMIN,
          Roles.CONCESSIONAIRE,
          Roles.CALLCENTER,
        ],
        menu: false
      },
      {
        component: () => SignIn({ authorize, clearAuth, userManager }),
        path: ['/sign-in', '/'],
        title: 'Sign In',
        exact: true,
        permissions: [
          Roles.ADMIN,
          Roles.CONCESSIONAIRE,
          Roles.CALLCENTER,
        ],
        menu: false
      },
      {
        component: () => NotFoundUser({ authorize, clearAuth, userManager }),
        path: '/user/not-found',
        title: 'Sign In',
        exact: true,
        permissions: [
          Roles.ADMIN,
          Roles.CONCESSIONAIRE,
          Roles.CALLCENTER,
        ],
        menu: false
      },
      {
        component: () => Logout({ authorize, clearAuth, userManager }),
        path: "*",
        title: 'Logout',
        exact: true,
        permissions: [
          Roles.ADMIN,
          Roles.CONCESSIONAIRE,
          Roles.CALLCENTER,
        ],
        menu: false
      },
    ]

    /**
     * Create router data
     */
    let r: any = [...FusionAuthRoutes];
    if (fusionAuthSession) {
      const fusionAuthFnRoutes = _createFusionAuthRoutes({ authorize, clearAuth, userManager });

      if (session?.profile?.info?.roles?.find((r: any) => r === 'admin')) {
        r = fusionAuthFnRoutes.filter((route: any) => route.permissions.find((permission: any) => permission === Roles.ADMIN));
        r = [...r, ...FusionAuthRoutes, ...AuxiliarRoutes];
        setRouteData(r);
        return;
      }

      if (session?.profile?.info?.roles?.find((r: any) => r === 'concessionaire')) {
        r = fusionAuthFnRoutes.filter((route: any) => route.permissions.find((permission: any) => permission === Roles.CONCESSIONAIRE));
        r = [...r, ...FusionAuthRoutes, ...AuxiliarRoutes];
        setRouteData(r);
        return;
      }

      if (session?.profile?.info?.roles?.find((r: any) => r === 'callcenter')) {
        r = fusionAuthFnRoutes.filter((route: any) => route.permissions.find((permission: any) => permission === Roles.CALLCENTER));
        r = [...r, ...FusionAuthRoutes, ...AuxiliarRoutes];
        setRouteData(r);
        return;
      }

      if (session.profile?.info?.roles?.find((r: any) => r === 'user_company')) {
        r = fusionAuthFnRoutes.filter((route: any) => route.permissions.find((permission: any) => permission === Roles.COMPANY));
        r = [...r, ...AuxiliarRoutes];
        setRouteData(r);
        return;
      }

      setRouteData(r);
    } else {
      setRouteData([...FusionAuthRoutes]);
    }
  }

  return (
    <Router history={history}>
      <Loader />
      <ToastContainer />
      <Switch>
        {routeData &&
          routeData.map((route: any, index: any) => {
            return route.redirect ? <Redirect to={route.path} key={route.path} /> : <Route exact path={route.path} component={route.component} key={`${index}-${route.path}`} />;
          })}
      </Switch>
    </Router>
  );
};

export default AppRouter;

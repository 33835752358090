/* eslint-disable no-useless-escape */
import * as yup from 'yup';

export const ServiceKeroseneSchema = yup.object().shape({
  name: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/igm, 'Este campo contiene caracteres inválidos')
    .max(255, "Este campo acepta un máximo de 255 caracteres")
    .required('Este campo es requerido'),
  shipping_price: yup.string().required('Este campo es requerido'),
  liter: yup.string().required('Este campo es requerido'),
});

export type ServiceKeroseneType = yup.InferType<typeof ServiceKeroseneSchema>;

export const mutate_refund = (data: any) => {
  const refunds: any = [];
  data.forEach((item: any) => {
    if (item.complement?.length > 0) {
      const complement: any = item.complement[0];
      refunds.push({
        ...complement,
        refunded: item.info?.refunded === 1 ? true : false,
        order: item,
      });
    }
  });

  return refunds;
};

import TableIcon from '@rsuite/icons/Table';
import EditIcon from '@rsuite/icons/Edit';
import CalendarIcon from '@rsuite/icons/Calendar';
import TrashIcon from '@rsuite/icons/Trash';
import { createCsvSimple } from '../../helpers/csv_generator';
import moment from 'moment';
import store from '../../redux/store';
import ordersService from '../../services/orders.service';
import { toast } from 'react-toastify';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';

export const _mainMenuSpeaker = () => {
  return {
    menu: [
      {
        label: 'Exportar como csv',
        Icon: TableIcon,
        show: true,
      },
    ],
    _handleSelect: async (e: any, payload: any) => {
      const dispatch: any = store.dispatch;
      try {
        dispatch(LOADING_ON());
        const response: any = await ordersService.getCsvOrders();
        const { csv } = response.data;
        createCsvSimple(csv, `pedidos-${moment().format('DD-MM-YYYY HH:mm:ss')}.csv`);
        dispatch(LOADING_OFF());
      } catch (e: any) {
        toast.error('No fue posible generar el archivo csv');
        dispatch(LOADING_OFF());
      }
    },
  };
};

export const _actionMenuSpeaker = (data: any, _handleOnSelect: any) => {
  const { session } = store.getState();
  return {
    menu: [
      {
        label: 'Cambiar estado',
        Icon: EditIcon,
        show: session?.profile?.info?.roles?.find((rol: any) => rol === 'admin' || rol === 'concessionaire') !== undefined ? true : false,
        eventKey: 1,
      },
      {
        label: 'Editar orden',
        Icon: EditIcon,
        show: session?.profile?.info?.roles?.find((rol: any) => rol === 'admin' || rol === 'callcenter' || rol === 'concessionaire') !== undefined ? true : false,
        eventKey: 3,
      },
      {
        label: 'Reagendar',
        Icon: CalendarIcon,
        show: data?.schedule_model && data?.status !== 'delivered' && data?.status !== 'canceled',
        eventKey: 4,
      },
      {
        label: 'Anular',
        Icon: TrashIcon,
        show: data?.status !== 'canceled',
        eventKey: 5,
      },
    ],
    _handleSelect: (eventKey: any, payload: any) => _handleOnSelect({ option: eventKey, id: payload.order.PK }),
    order: data,
  };
};

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Table, Tooltip, Whisper, Toggle } from 'rsuite';
import Title from '../../components/Title';
import {  TableCoupon } from './tableConfigs';
import couponService from '../../services/coupon.service';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import Images from '../../assets/image';
import Image from '../../components/Image';

const Tags = (props: any) => {
  const params: any = useParams();
  const [state, setState] = useState<any>({
    tags: [],
    filter: ''
  });

  const dispatch = useDispatch();

  useEffect(() => {
    _getTags();
  }, []);

  const _getTags = async () => {
    try {
      dispatch(LOADING_ON());
      const payload: any = {
        campaign: params?.campaign,
        setup: params?.setup,
        limit: 10
      };
      const response = await couponService.list(payload);
      const tags: any = response.data.filter((item: any) => parseInt(item.setup) === parseInt(params?.setup))
      tags.sort((a: any, b: any) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      })
      setState({
        ...state,
        tags,
      });
      dispatch(LOADING_OFF());
    } catch (e: any) {
      dispatch(LOADING_OFF());
      toast.error('No fue posible obtener los cupones');
    }
  };


  const _handleToggleTagStatus = async ({ row, target }: any) => {
    try {
      const { setup, name, PK } = row;
      const { value } = target;

      const tag: any = state.tags.find((el: any) => el.PK === PK);

      if (!tag) {
        toast.error("No fue posible cambiar el status.")
        return
      }

      tag.status = value;

      setState({ ...state })

      const payload: any = {
        name: name,
        status: value ? 1 : 0,
        setup: setup,
        PK: PK,
      };
      dispatch(LOADING_ON());
      await couponService.update(PK, payload);
      toast.success('Cupón actualizado con éxito');
      await _getTags();
    } catch (e: any) {
      dispatch(LOADING_OFF());
      toast.error("No fue posible actualizar el status.")
    }
  };

  const _handleFilter = async (e: any) => {
    e.preventDefault();
    try {

      if (!state.filter) {
        _getTags();
        return
      }
      dispatch(LOADING_ON());
      const payload: any = {
        campaign: params?.campaign,
        code: state.filter,
      };
      const response: any = await couponService.search(payload);
      const tags: any = response.data.filter((el: any) => el.setup === parseInt(params?.setup))
      setState({ ...state, tags })
      dispatch(LOADING_OFF());
    } catch (e: any) {
      toast.error("No fue posible cargar los cupones.")
      dispatch(LOADING_OFF());
    }
  }


  return (
    <div className="container-fluid">


      <div className="row">
        <Title size="sm" text={`Cupones`} className="mt-5 bold" />
      </div>

      <form onSubmit={_handleFilter}>
        <div className="row align-items-center justify-content-center">
          <div className="col-9 ">
            <input
              type="text"
              className="input-default w-100"
              value={state.filter}
              onChange={(event: any) => setState({ ...state, filter: event.target.value.toUpperCase() })}
              placeholder="Ingresa codigo del cupon"
            />
          </div>
          <div className="col-3 text-white">
            <button className="btn-default px-0" style={{ width: '100%' }} type="submit">
              <Image {...Images.SearchWhiteSVG} style={{ width: '25px' }} className="d-inline d-sm-none" />
              <span className="d-none d-sm-block">Buscar</span>
            </button>
          </div>
        </div>
      </form>

      <div className="row border rounded-10 mt-5 p-3">
        <div className="col-12">
          <div className="row">
            <Title size="sm" text={`Listado de cupones`} className="mt-3 mb-3 bold" />
          </div>
          <Table data={state.tags} autoHeight rowClassName="striped" locale={{ emptyMessage: 'No hay cupones con este codigo' }}>
            {TableCoupon &&
              TableCoupon.map((column: any, index: any) => (
                <Table.Column flexGrow={1} key={`table-column-${index}`} align={column.align}>
                  <Table.HeaderCell>
                    <span style={{ textTransform: 'capitalize' }}>{column.label}</span>
                  </Table.HeaderCell>
                  <Table.Cell>
                    {(rowData) => {
                      switch (column.key) {
                        case 'status':
                          return (
                            <Toggle
                              checked={rowData[column.key] ? true : false}
                              onChange={(e: any) => _handleToggleTagStatus({ row: rowData, target: { name: 'status', value: e } })}
                            />
                          );
                        case 'use_count':
                          return (
                            <div className='w-100 text-center'>
                              <Whisper trigger="hover" placement="auto" controlId={`control-id-auto`} speaker={<Tooltip>{rowData[column.key]}</Tooltip>}>
                                <span style={{ textTransform: 'capitalize' }}>{rowData[column.key] || '---'}</span>
                              </Whisper>
                            </div>
                          );

                        default:
                          return (
                            <Whisper trigger="hover" placement="auto" controlId={`control-id-auto`} speaker={<Tooltip>{rowData[column.key]}</Tooltip>}>
                              <span style={{ textTransform: 'capitalize' }}>{rowData[column.key]}</span>
                            </Whisper>
                          );
                      }
                    }}
                  </Table.Cell>
                </Table.Column>
              ))}
          </Table>
        </div>
      </div>
    </div>
  );
};
export default Tags;

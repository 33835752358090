/* eslint-disable no-useless-escape */
import * as yup from 'yup';

export const CancelOrderSchema = yup.object().shape({
  observations: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .max(255, 'Este campo acepta un máximo de 255 caracteres')
    .when('textField', {
      is: true,
      then: yup
        .string()
        .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
        .max(255, 'Este campo acepta un máximo de 255 caracteres')
        .required('Este campo es requerido'),
    }),
});

export type CancelOrderType = yup.InferType<typeof CancelOrderSchema>;

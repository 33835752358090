export const ServiceTypes = () => {
    return [
        {
            value: 'small_vehicle',
            label: "Vehículo Pequeño",
            size: 1
        },
        {
            value: 'medium_vehicle',
            label: "Vehículo Mediano",
            size: 2
        },
        {
            value: 'large_vehicle',
            label: "Vehículo Grande",
            size: 3
        },
        {
            value: 'extra_large_vehicle',
            label: "Vehículo Extra Grande",
            size: 4
        }
    ]
}

export const ServiceCategory = () => {
    return [
        {
            value: 'kerosene',
            label: "Modelo Kerosene",
            size: 1
        },
        {
            value: 'kerosene-schedule',
            label: "Modelo Kerosene con agendamiento",
            size: 4
        },
        {
            value: 'lavamax',
            label: "Modelo Lavamax",
            size: 2
        },
        {
            value: 'lub',
            label: "Modelo Cambio de aceite",
            size: 3
        },
        {
            value: 'subscription',
            label: "Modelo Suscripción",
            size: 5
        },
    ]
}
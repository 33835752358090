/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

const Card = (props: any) => {
  const { url, label } = props;
  const [searchParams, setSearchParams] = useState<any>({ PK: '', SK: '' });
  const history = useHistory();
  const params: any = useParams();

  useEffect(() => {
    const searchUrl = new URLSearchParams(history.location.search);
    setSearchParams({ ...searchParams, PK: searchUrl.get('PK'), SK: searchUrl.get('SK') });
  }, []);

  return (
    <button className="border-2-blue py-4 w-100 text-center rounded-10 size-11 bold bg-color-white" onClick={() => history.push(`${url}${params.id}?PK=${searchParams.PK}&SK=${searchParams.SK}`)}>
      {label}
    </button>
  );
};

export default Card;

/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Table } from 'rsuite';
import ConfirmationModal from '../../components/ConfirmationModal';
import ModalComponent from '../../components/Modal';
import FormRecommendedSubService from '../../components/Services/FormRecommendedSubService';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import Service from '../../services/service_and_products.service';
import { ServiceCategory, ServiceTypes } from '../../types';
import { TableHeaderRecommendedSS } from './tableConfig';

const SubServiceRecommended = (props: any) => {

  const [state, setState] = useState<any>({
    data: [],
    service: {},
    modals: {
      add_service: false,
      update_service: false,
    },
    confirmation: { open: false, PK: null, SK: null },
    types: [],
    categories: [],
  });
  
  const { loader } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const getServices = async () => {
    dispatch(LOADING_ON());
    try {
      const response = await Service.list();
      const data: any = response.data;
      const service: any = data.find((item: any) => item.type === 'recommended_subservice');
      let subservices: any = [];
      if (service) {
        const subserviceResponse = await Service.details({ PK: service.PK, SK: service.SK });
        const { sub_services } = subserviceResponse.data;
        subservices = sub_services.filter((item: any) => item.info?.service_model);
      }
      const serviceTypes = ServiceTypes();
      const serviceCategories = ServiceCategory();
      const categories: any = serviceCategories.filter((item: any) => item.value !== 'kerosene');
      setState({
        ...state,
        confirmation: { open: false, PK: null, SK: null },
        data: subservices,
        service: service,
        types: serviceTypes,
        categories: categories,
        modals: { add_service: false, update_service: false },
      });
    } catch (e: any) {
      console.log('LOGGER', e.message);
      toast.error(e.message);
    }
    dispatch(LOADING_OFF());
  };

  useEffect(() => {
    getServices();
  }, []);

  const _handleDeleteSubService = async () => {
    const { PK, SK } = state.confirmation;
    try {
      dispatch(LOADING_ON());
      const payload: any = {
        PK: PK,
        data: {
          PK: PK,
          SK: SK,
          hard: false,
        },
      };
      await Service.delete(payload);
      toast.success('Sub servicio eliminado con éxito.');
      await getServices();
      dispatch(LOADING_OFF());
    } catch (e: any) {
      toast.error('No fue posible eliminar este sub servicio.');
      dispatch(LOADING_OFF());
    }
  };

  const Actions = (payload: any) => {
    return (
      <div className="row">
        <div className="col-12">
          <button className="btn p-0 me-3 size-11 color-grey-menu" onClick={() => _toggleUpdateServiceModal(payload.data.SK)}>
            <i className="bi bi-pencil"></i>
          </button>
          <button className="btn p-0 me-3 size-11 color-grey-menu" onClick={() => setState({ ...state, confirmation: { open: true, PK: payload.data.PK, SK: payload.data.SK } })}>
            <i className="bi bi-trash"></i>
          </button>
        </div>
      </div>
    );
  };

  const _toggleAddServiceModal = () => {
    setState({ ...state, modals: { ...state.modals, add_service: !state.modals?.add_service } });
  };

  const _toggleUpdateServiceModal = (edit_id = null) => {
    if (edit_id !== null) {
      const { data } = state;
      const target: any = data.find((item: any) => item.SK === edit_id);
      setState({ ...state, modals: { ...state.modals, update_service: !state.modals?.update_service }, subservice: target });
    } else {
      setState({ ...state, modals: { ...state.modals, update_service: !state.modals?.update_service } });
    }
  };

  return (
    <div className="container-fluid">
      <ModalComponent open={state.modals?.add_service} handleClose={_toggleAddServiceModal} hideFooter>
        <FormRecommendedSubService handleClose={getServices} action="create" categories={state.categories} service={state.service} />
      </ModalComponent>

      <ModalComponent open={state.modals?.update_service} handleClose={_toggleUpdateServiceModal} hideFooter>
        <FormRecommendedSubService handleClose={getServices} action="update" categories={state.categories} service={state.service} subservice={state.subservice} />
      </ModalComponent>

      <ConfirmationModal
        open={state.confirmation.open}
        size="md"
        title="Eliminar"
        type="warning"
        handleClose={() => setState({ ...state, confirmation: { ...state.confirmation, open: false } })}
        handleConfirm={_handleDeleteSubService}
      >
        ¿Seguro que quieres eliminar este sub servicio?
      </ConfirmationModal>

      <div className="row mt-4">
        <div className="col-12 d-flex justify-content-end text-white">
          <button className="btn-default" onClick={_toggleAddServiceModal}>
            <span className="me-2">+</span>
            Nuevo Sub servicio recomendado
          </button>
        </div>
      </div>

      <div className="row rounded-05 mt-4 p-3" style={{ height: '70vh', overflow: 'auto' }}>
        <div className="col-12">
          <Table data={state.data} autoHeight rowClassName="striped" locale={{ emptyMessage: 'No se encontró ningún sub servicio' }} loading={loader.loading}>
            {TableHeaderRecommendedSS &&
              TableHeaderRecommendedSS.map((column: any, index: any) => (
                <Table.Column align={column.align} flexGrow={column.flexGrow} key={`table-column-${index}`}>
                  <Table.HeaderCell>
                    <span style={{ textTransform: 'capitalize' }}>{column.label}</span>
                  </Table.HeaderCell>
                  <Table.Cell>
                    {(rowData) => {
                      switch (column.key) {
                        case 'scheduling':
                          return <>{rowData.scheduling === true || rowData.scheduling === 1 ? 'Si' : 'No'} </>;
                        case 'type':
                          return <>{state.categories.find((type: any) => type.value === rowData.info?.service_model)?.label || 'Este tipo de servicio no es válido'}</>;
                        default:
                          return <>{rowData[column.key]}</>;
                      }
                    }}
                  </Table.Cell>
                </Table.Column>
              ))}

            <Table.Column align="right" width={700} flexGrow={1} verticalAlign="middle">
              <Table.HeaderCell>{''}</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  return <Actions data={rowData} />;
                }}
              </Table.Cell>
            </Table.Column>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default SubServiceRecommended;

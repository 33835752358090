/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { SelectPicker } from 'rsuite';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import Options from '../../services/options.service';
import ordersService from '../../services/orders.service';
import Title from '../Title';

const UpdateOrder = (props: any) => {
  const { data, handleClose, handleCloseAndUpdate } = props;
  const [state, setState] = useState<any>({
    status: [],
    inputs: {
      status: '',
    },
  });

  const dispatch = useDispatch();

  useEffect(() => {
    _retrieveStatus();
  }, []);

  const _handleOnChange = ({ target }: any) => {
    const { name, value } = target;
    state.inputs[name] = value;
    setState({ ...state });
  };

  const _retrieveStatus = async () => {
    dispatch(LOADING_ON());
    try {
      const response = await Options.getOrderState();
      const status: any = response?.data?.filter((item: any) => item.value?.toLowerCase() !== 'canceled');
      setState({ ...state, status, initial_status: data.status, inputs: { ...state.inputs, status: data.status } });
    } catch (e: any) {
      toast.error('No fue posible cargar los estados.');
    }
    dispatch(LOADING_OFF());
  };

  const _handleUpdateStatus = async () => {
    dispatch(LOADING_ON());
    try {
      if (!state.inputs.status || state.inputs.status === '') {
        dispatch(LOADING_OFF());
        throw new Error('Selecciona un estado antes de continuar');
      }

      if (state.inputs.status === state.initial_status) {
        dispatch(LOADING_OFF());
        throw new Error('Debe seleccionar otro estado');
      }
      await ordersService.changeStatus({
        PK: data.PK,
        SK: data.SK,
        status: state.inputs?.status?.toUpperCase(),
      });
      toast.success('Estado cambiado con éxito');
      handleCloseAndUpdate();
    } catch (e: any) {
      toast.error(e.response?.data?.message || 'No fue posible cambiar el estado de este pedido.');
      dispatch(LOADING_OFF());
    }
  };

  return (
    <div className="component-services px-0 px-sm-5 mb-4">
      <div className="row">
        <Title size="sm" text="Cambiar estado" className="bold to-upper size-11" />
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <div className="size-08 bold">Estado</div>
          <div className="">
            <SelectPicker
              classPrefix="copec"
              className="rs-copec-cleanable"
              cleanable={false}
              value={state.inputs.status}
              onChange={(e: any) => _handleOnChange({ target: { name: 'status', value: e } })}
              data={state.status}
              locale={{ searchPlaceholder: 'Buscar', noResultsText: 'Ningún resultado encontrado.', emptyMessage: 'Ningún resultado encontrado.' }}
              placeholder="Cambiar estado"
            />
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12 text-center text-sm-end color-white">
          <button className="btn btn-danger me-5" onClick={handleClose}>
            Cancelar
          </button>
          <button className="btn btn-default color-white" onClick={_handleUpdateStatus}>
            Confirmar
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateOrder;

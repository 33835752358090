export const TableHeader = [
  {
    label: 'Código',
    key: 'code'
  },
  {
    label: 'Nombre',
    key: 'name'
  },
  {
    label: 'Teléfono',
    key: 'phone'
  },
  {
    label: 'Dirección',
    key: 'address'
  },
]

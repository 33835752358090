/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, Modal, SelectPicker, Toggle } from 'rsuite';
import Image from '../Image';
import Images from '../../assets/image';
import CloseIcon from '@rsuite/icons/Close';
import { toast } from 'react-toastify';
import { LOADING_OFF } from '../../redux/actions/loader';

const SearchReverse = (props: any) => {
  const { data, statusList, results, handleOnChange, handleSearch, handleFilter, validations } = props;
  const { matchMedia } = useSelector((state: any) => state);
  const dispatch: any = useDispatch();
  const [state, setState] = useState({
    open: false,
    full: false,
    filter: '',
    refunded: false,
  });

  const filterRef: any = useRef();

  useEffect(() => {
    if (state.open) {
      setState({ ...state, filter: '', refunded: false });
      filterRef.current?.focus();
    }
  }, [state.open]);

  useEffect(() => {
    if (matchMedia.media === 'md' || matchMedia.media === 'sm' || matchMedia.media === 'xs') {
      setState({ ...state, full: true });
    } else {
      setState({ ...state, full: false });
    }
  }, [matchMedia.media]);

  const _handleOnChange = (payload: any) => {
    try {
      handleOnChange(payload);
    } catch (e: any) {
      dispatch(LOADING_OFF());
      toast.error('No fue posible cambiar el estado del componente.');
    }
  };

  const _handleFilter = (e: any) => {
    try {
      e.preventDefault();
      handleFilter({ filter: state.filter, refunded: state.refunded });
      setState({ ...state, open: false });
    } catch (e: any) {
      dispatch(LOADING_OFF());
      toast.error('No fue posible aplicar este filtro.');
    }
  };

  const _handleSearch = () => {
    try {
      handleSearch();
    } catch (e: any) {
      dispatch(LOADING_OFF());
      toast.error('No fue posible realizar esta búsqueda.');
    }
  };

  return (
    <>
      <Modal open={state.open} size="md" full={state.full} style={state.full ? { width: '96%', left: '1%' } : {}}>
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-11">
              <h4 className="bold-300">Filtrar</h4>
            </div>
            <div className="col-1 text-end">
              <button className="btn px-0" onClick={() => setState({ ...state, open: false })}>
                <CloseIcon />
              </button>
            </div>
          </div>

          <form onSubmit={_handleFilter}>
            <div className="row align-items-center justify-content-center">
              <div className="col-9 ">
                <input
                  type="text"
                  className="input-default w-100"
                  ref={filterRef}
                  value={state.filter}
                  onChange={(event: any) => setState({ ...state, filter: event.target.value })}
                  placeholder="Ingresa correo, nombre o ID Pedido"
                />
              </div>
              <div className="col-3 text-white">
                <button className="btn-default px-0" style={{ width: '100%' }} type="submit">
                  <Image {...Images.SearchWhiteSVG} style={{ width: '25px' }} className="d-inline d-sm-none" />
                  <span className="d-none d-sm-block">Filtrar</span>
                </button>
              </div>
              <div className="col-12 bold-300 mt-2">
                <div>Reembolsado</div>
                <Toggle onChange={(value: any) => setState({ ...state, refunded: value })} checked={state.refunded} />
              </div>
            </div>
          </form>
        </div>
      </Modal>

      <div className="row mx-0 mt-4">
        <div className={`col-12 col-xl-3`}>
          <span className="bold-300 size-09">Desde</span>
          <DatePicker
            format="dd/MM/yyyy"
            placeholder="Informe la fecha"
            style={{ width: '100%' }}
            classPrefix="copec"
            className="rs-copec-cleanable"
            cleanable={false}
            value={data?.start || moment().subtract(30, 'days').toDate()}
            onChange={(value: any) => _handleOnChange({ target: { value, name: 'start' } })}
            locale={{
              sunday: 'Dom',
              monday: 'Lun',
              tuesday: 'Mar',
              wednesday: 'Mie',
              thursday: 'Jue',
              friday: 'Vie',
              saturday: 'Sab',
              ok: 'OK',
              today: 'Hoy',
              yesterday: 'Ayer',
              hours: 'Horas',
              minutes: 'Minutos',
              seconds: 'Segundos',
            }}
          />
        </div>
        <div className={`col-12 col-xl-3`}>
          <span className="bold-300 size-09">Hasta</span>
          <DatePicker
            format="dd/MM/yyyy"
            placeholder="Informe la fecha"
            style={{ width: '100%' }}
            classPrefix="copec"
            className="rs-copec-cleanable"
            cleanable={false}
            value={data?.end || moment().toDate()}
            onChange={(value) => _handleOnChange({ target: { value, name: 'end' } })}
            locale={{
              sunday: 'Dom',
              monday: 'Lun',
              tuesday: 'Mar',
              wednesday: 'Mie',
              thursday: 'Jue',
              friday: 'Vie',
              saturday: 'Sab',
              ok: 'OK',
              today: 'Hoy',
              yesterday: 'Ayer',
              hours: 'Horas',
              minutes: 'Minutos',
              seconds: 'Segundos',
            }}
          />
        </div>

        <div className={`col-12 col-xl-3`}>
          <span className="bold-300 size-09">Estado</span>
          <SelectPicker
            locale={{ searchPlaceholder: 'Buscar', noResultsText: 'Ningún resultado encontrado.', emptyMessage: 'Ningún resultado encontrado.' }}
            cleanable={false}
            classPrefix="copec"
            className="rs-copec-cleanable"
            data={statusList || []}
            onChange={(value: any) => _handleOnChange({ target: { value, name: 'status' } })}
            value={data?.status}
            style={{ width: '100%' }}
            placeholder="Estados"
          />
        </div>

        <div className="col-12 d-xl-none">
          {validations.map((item: any, index: number) => (
            <div className="error-validations error-enabled bold-300 size-09" key={`mobile-${index}`}>
              {item}
            </div>
          ))}
        </div>

        <div className={`col-12 col-xl-3 position-relative`} style={{ minHeight: '70px' }}>
          <div className="position-absolute bottom-0  text-white w-100 text-end">
            <button className="btn-default mb-1" onClick={_handleSearch}>
              Buscar
            </button>
            <button className="btn-default mb-1 ms-2" disabled={results && results.length > 0 ? false : true} onClick={() => setState({ ...state, open: true })}>
              Filtrar
            </button>
          </div>
        </div>

        <div className="col-12 d-none d-xl-block">
          {validations.map((item: any, index: any) => (
            <div className="error-validations error-enabled bold-300 size-09" key={`desktop-${index}`}>
              {item}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SearchReverse;

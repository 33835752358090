/* eslint-disable react-hooks/exhaustive-deps */
import { WebStorageStateStore } from "oidc-client-ts";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { signin } from "../../redux/actions/session";
import Auth from "../../services/auth.service";
import { toast } from "react-toastify";
import routes from "../../routes/routes";
import { Roles } from "../../routes/Roles";
import { set_configs } from "../../redux/actions/configs";

const CallbackFusionAuth = ({ userManager, clearAuth, updateRouter }: any) => {

  const { session } = useSelector((state: any) => state);
  const history = useHistory();
  const dispatch: any = useDispatch();

  useEffect(() => {
    _handleLogin();
  }, []);

  const _handleLogin = async () => {
    userManager
      .signinRedirectCallback()
      .then(async (user: any) => {
        const storage = new WebStorageStateStore({ store: window.localStorage })
        const keys = await storage.getAllKeys();
        keys.forEach(key => {
          if (!key.includes('user')) storage.remove(key);
        })

        await dispatch(signin({
          ...session,
          session: {
            message: {
              ...user,
            }
          }
        }))

        let responseSync;

        try {
          responseSync = await Auth.syncUser({
            name: user.profile.name,
            email: user.profile.email,
            id: user.profile.sub,
          });
        } catch (e) {
          history.push('/user/not-found');
          return;
        }

        const profile = responseSync?.message?.user;

        const { info } = profile;

        const isAdmin = info.roles.find((r: any) => r === 'admin');

        const permissions = info.roles.find((r: any) => r === 'admin' || r === 'concessionaire' || r === 'callcenter');

        info.permissions = permissions;

        if (!permissions) {
          toast.error('Este usuario no tiene acceso a la plataforma.');
          setTimeout(() => {
            history.push('/logout');
          }, 5000);
          return;
        }

        await dispatch(signin({
          ...session,
          profile: responseSync.message?.user,
          session: {
            message: {
              ...user,
            }
          }
        }))

        let menu = []
        if (isAdmin) {
          menu = routes.reduce((acc: any, current: any) => {
            if (current.permissions.find((permission: any) => permission === Roles.ADMIN) && current.menu !== false) {
              acc.push({ ...current.menu });
            }
            return acc;
          }, []);
          await dispatch(set_configs({ menu: menu }));
        } else {
          if (info.roles.find((r: any) => r === 'concessionaire')) {
            menu = routes.reduce((acc: any, current: any) => {
              if (current.permissions.find((permission: any) => permission === Roles.CONCESSIONAIRE) && current.menu !== false) {
                acc.push({ ...current.menu });
              }
              return acc;
            }, []);
          }
          if (info.roles.find((r: any) => r === 'callcenter')) {
            menu = routes.reduce((acc: any, current: any) => {
              if (current.permissions.find((permission: any) => permission === Roles.CALLCENTER) && current.menu !== false) {
                acc.push({ ...current.menu });
              }
              return acc;
            }, []);
          }
          await dispatch(set_configs({ menu: menu }));
        }

        history.push('/');
        updateRouter();

      })
      .catch(() => {
        window.location.href = '/logout';
      });
  }

  return (
    <div className="container-fluid" style={{ height: '100vh' }}>
      <div className="row justify-content-center align-items-center h-100">
        <div className="col-12 text-center">
          <div className="fw-bold mb-3 text-secondary ">Cargando informacion del perfil...</div>
        </div>
      </div>
    </div>
  );
}

export default CallbackFusionAuth;
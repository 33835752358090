import { getSession } from '../helpers/session';
import store from '../redux/store';
import instance from './api_overt';

instance.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error: any) {
    // if (error.response && error.response.status === 401) {
    //   const dispatch: any = store.dispatch;
    //   dispatch(signout())
    // }
    return Promise.reject(error);
  }
);

class PaymentDataObject {
  _setHeaders = () => {
    const state: any = store.getState();
    const { session } = state;

    const oidcSession = getSession();

    if (oidcSession) {
      instance.defaults.headers.common['user'] = session.profile?.PK;
      instance.defaults.headers.common['usersk'] = session.profile?.SK;
      instance.defaults.headers.common['Authorization'] = `Bearer ${oidcSession?.access_token}`;
    }
  };

  getPayment = async (PK: any) => {
    this._setHeaders();
    try {
      const response = await instance.get(`/overt/payment/${PK}`);
      return response;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };
}

const PaymentService = new PaymentDataObject();
export default PaymentService;

import { handleActions } from 'redux-actions';
import { SET_COUPONS } from '../actions/coupons';

export const INITIAL_STATE: any = {}

export default handleActions({
  [SET_COUPONS]: (_, action: any) => {
    return { ...action.payload }
  }
}, INITIAL_STATE);

export const paginate = (items: any, totalItems: number, currentPage: number = 1, pageSize: number = 10) => {
  let totalPages = Math.ceil(totalItems / pageSize);
  let page: any = currentPage;
  if (currentPage < 1) page = 1;
  if (currentPage > totalPages) page = totalPages;

  const itemsSliced: any = items.slice((page - 1) * pageSize, page * pageSize);

  return {
    items: itemsSliced,
    totalPages,
    currentPage: page,
    pageSize,
  };
};

import TemplateDefault from '../template/TemplateDefault';
import { Roles } from './Roles';
import SetTemplate from './SetTemplate';
import EDS from '../pages/EDS';
import Campaigns from '../pages/Coupons/Campaigns';
import Coupons from '../pages/Coupons/Coupons';
import Orders from '../pages/Orders';
import OrdersEds from '../pages/OrdersEds';

import Suscription from '../pages/Suscriptions';



import Concessionaire from '../pages/Concessionaire';
import DashboardEds from '../pages/DashboardEds';
import Services from '../pages/Services';
import Products from '../pages/Products';
import ProductsEds from '../pages/ProductsEds';
import Dashboard from '../pages/Dashboard';
import WorkDay from '../pages/WorkDay';
import WorkDayEds from '../pages/WorkDayEds';
import DriversEds from '../pages/DriversEds';
import Evaluations from '../pages/Evaluations';
import SignIn from '../pages/SignIn';
import DriversConcessionaire from '../pages/DriversConcessionaire';
import VehiclesConcessionaire from '../pages/VehiclesConcessionaire';
import TimeBlockConcessionaire from '../pages/TimeBlockConcessionaire';
import RelatedproductsEds from '../pages/RelatedproductsEds';
import ReverseOrder from '../pages/ReverseOrder';
import OrdersConcessionaire from '../pages/OrdersConcessionaire';
import Discounts from '../pages/Discounts';
import Tags from '../pages/Coupons/Tags';
import Users from '../pages/Users';
import UsersCompany from '../pages/UsersCompany';
import Companies from '../pages/Companies';

const TemplatedOrders = () => SetTemplate(TemplateDefault, Orders);
const TemplatedOrdersConc = () => SetTemplate(TemplateDefault, OrdersConcessionaire);
const TemplatedOrdersEds = () => SetTemplate(TemplateDefault, OrdersEds);

const TemplatedSuscription = () => SetTemplate(TemplateDefault, Suscription);

const TemplatedEds = () => SetTemplate(TemplateDefault, EDS);
const TemplatedReverse = () => SetTemplate(TemplateDefault, ReverseOrder);
const TemplatedConcessionaire = () => SetTemplate(TemplateDefault, Concessionaire);
const TemplatedUsers = () => SetTemplate(TemplateDefault, Users);
const TemplatedUsersCompany = () => SetTemplate(TemplateDefault, UsersCompany);
const TemplatedCompanies = () => SetTemplate(TemplateDefault, Companies);
const TemplatedDashboardEDS = () => SetTemplate(TemplateDefault, DashboardEds);
const TemplatedServices = () => SetTemplate(TemplateDefault, Services);
const TemplatedEvaluations = () => SetTemplate(TemplateDefault, Evaluations);
const TemplatedTimeBlockConcessionaire = () => SetTemplate(TemplateDefault, TimeBlockConcessionaire);
const TemplatedProducts = () => SetTemplate(TemplateDefault, Products);
const TemplatedRelatedProductsEds = () => SetTemplate(TemplateDefault, RelatedproductsEds);
const TemplatedProductsEds = () => SetTemplate(TemplateDefault, ProductsEds);
const TemplatedDashboard = () => SetTemplate(TemplateDefault, Dashboard);
const TemplatedWorkDay = () => SetTemplate(TemplateDefault, WorkDay);
const TemplatedWorkDayEds = () => SetTemplate(TemplateDefault, WorkDayEds);
const TemplatedDriversEds = () => SetTemplate(TemplateDefault, DriversEds);
const TemplatedDriversConcessionaire = () => SetTemplate(TemplateDefault, DriversConcessionaire);
const TemplatedVehiclesConcessionaire = () => SetTemplate(TemplateDefault, VehiclesConcessionaire);
const TemplatedCampaigns = () => SetTemplate(TemplateDefault, Campaigns);
const TemplatedCoupons = () => SetTemplate(TemplateDefault, Coupons);
const TemplatedTags = () => SetTemplate(TemplateDefault, Tags);
const TemplatedDiscounts = () => SetTemplate(TemplateDefault, Discounts);

const routes = [
  {
    component: TemplatedOrders,
    originalComponent: Orders,
    path: '/pedidos',
    title: 'Pedidos',
    exact: true,
    permissions: [Roles.ADMIN, Roles.CALLCENTER],
    menu: {
      name: 'Pedidos',
      icon: 'bi bi-file-earmark-text me-3',
      url: '/pedidos',
    },
  },
  {
    component: TemplatedSuscription,
    originalComponent: Suscription,
    path: '/suscripciones',
    title: 'Suscripciones',
    exact: true,
    permissions: [Roles.ADMIN, Roles.CALLCENTER],
    menu: {
      name: 'Suscripciones',
      icon: 'bi bi-file-earmark-text me-3',
      url: '/suscripciones',
    },
  },
  {
    component: TemplatedReverse,
    originalComponent: ReverseOrder,
    path: '/pedidos/reversa',
    title: 'Pedidos',
    exact: true,
    permissions: [Roles.CALLCENTER],
    menu: {
      name: 'Reversas',
      icon: 'bi bi-cash-coin me-3',
      url: '/pedidos/reversa',
    },
  },
  {
    component: TemplatedOrdersEds,
    originalComponent: OrdersEds,
    path: '/pedidos/eds/:id',
    title: 'Pedidos',
    exact: true,
    permissions: [Roles.ADMIN],
    menu: false,
  },
  {
    component: TemplatedUsers,
    originalComponent: Users,
    path: '/usuarios',
    title: 'Usuarios',
    exact: true,
    permissions: [Roles.ADMIN],
    menu: {
      name: 'Usuarios',
      icon: 'bi bi-person me-3',
      url: '/usuarios',
    },
  },
  {
    component: TemplatedConcessionaire,
    originalComponent: Concessionaire,
    path: '/concesionario',
    title: 'Concesionario',
    exact: true,
    permissions: [Roles.ADMIN],
    menu: {
      name: 'Concesionario',
      icon: 'bi bi-person me-3',
      url: '/concesionario',
    },
  },
  {
    component: TemplatedOrdersConc,
    originalComponent: OrdersConcessionaire,
    path: '/concesionario/pedidos',
    title: 'Pedidos',
    exact: true,
    permissions: [Roles.CONCESSIONAIRE],
    menu: {
      name: 'Pedidos',
      icon: 'bi bi-person me-3',
      url: '/concesionario/pedidos',
    },
  },
  {
    component: TemplatedEds,
    originalComponent: EDS,
    path: '/eds',
    title: 'EDS',
    exact: true,
    permissions: [Roles.ADMIN],
    menu: {
      name: "EDS's",
      icon: 'bi bi-building me-3',
      url: '/eds',
    },
  },
  {
    component: TemplatedDashboardEDS,
    originalComponent: DashboardEds,
    path: '/eds/dashboard/:id',
    title: 'EDS',
    exact: true,
    permissions: [Roles.ADMIN],
    menu: false,
  },
  {
    component: TemplatedServices,
    originalComponent: Services,
    path: '/servicios',
    title: 'Servicios',
    exact: true,
    permissions: [Roles.ADMIN],
    menu: {
      name: 'Servicios',
      icon: 'bi bi-folder me-3',
      url: '/servicios',
    },
  },
  {
    component: TemplatedCompanies,
    originalComponent: Companies,
    path: '/empresas',
    title: 'Empresas',
    exact: true,
    permissions: [Roles.ADMIN],
    menu: {
      name: 'Empresas',
      icon: 'bi bi-building me-3',
      url: '/empresas',
    },
  },
  {
    component: TemplatedProducts,
    originalComponent: Products,
    path: '/productos-adicionales',
    title: 'Productos',
    exact: true,
    permissions: [Roles.ADMIN],
    menu: {
      name: 'Productos Adicionales',
      icon: 'bi bi-bag me-3',
      url: '/productos-adicionales',
    },
  },
  {
    component: TemplatedRelatedProductsEds,
    originalComponent: RelatedproductsEds,
    path: '/eds/productos-adicionales',
    title: 'Productos',
    exact: true,
    permissions: [Roles.CONCESSIONAIRE],
    menu: {
      name: 'Productos Adicionales',
      icon: 'bi bi-bag me-3',
      url: '/eds/productos-adicionales',
    },
  },
  {
    component: TemplatedProductsEds,
    originalComponent: ProductsEds,
    path: '/productos-adicionales/eds/:id',
    title: 'Productos',
    exact: true,
    permissions: [Roles.ADMIN],
    menu: false,
  },
  {
    component: TemplatedDashboard,
    originalComponent: Dashboard,
    path: '/dashboard',
    title: 'Dashboard',
    exact: true,
    permissions: [Roles.ADMIN],
    menu: false,
  },
  {
    component: TemplatedWorkDay,
    originalComponent: WorkDay,
    path: '/jornada-laboral',
    title: 'Jornada Laboral',
    exact: true,
    permissions: [Roles.ADMIN],
    menu: {
      name: 'Jornada laboral',
      icon: 'bi bi-briefcase me-3',
      url: '/jornada-laboral',
    },
  },
  {
    component: TemplatedWorkDayEds,
    originalComponent: WorkDayEds,
    path: '/jornada-laboral/eds/:id',
    title: 'Jornada Laboral',
    exact: true,
    permissions: [Roles.ADMIN],
    menu: false,
  },
  {
    component: TemplatedDriversConcessionaire,
    originalComponent: DriversConcessionaire,
    path: '/concessionaire/drivers',
    title: 'Socio conductor',
    exact: true,
    permissions: [Roles.CONCESSIONAIRE],
    menu: {
      name: 'Conductores',
      icon: 'bi bi-people me-3',
      url: '/concessionaire/drivers',
    },
  },
  {
    component: TemplatedDriversEds,
    originalComponent: DriversEds,
    path: '/drivers/eds/:id',
    title: 'Socio conductor',
    exact: true,
    permissions: [Roles.ADMIN],
    menu: false,
  },
  {
    component: TemplatedEvaluations,
    originalComponent: Evaluations,
    path: '/evaluaciones',
    title: 'Evaluaciones',
    exact: true,
    permissions: [Roles.ADMIN],
    menu: false,
  },
  {
    component: TemplatedVehiclesConcessionaire,
    originalComponent: VehiclesConcessionaire,
    path: '/concessionaire/vehicles',
    title: 'Vehículos',
    exact: true,
    permissions: [Roles.CONCESSIONAIRE],
    menu: {
      name: 'Vehículos',
      icon: 'bi bi-truck me-3',
      url: '/concessionaire/vehicles',
    },
  },
  {
    component: TemplatedTimeBlockConcessionaire,
    originalComponent: TimeBlockConcessionaire,
    path: '/concessionaire/time-block',
    title: 'Bloques Horarios',
    exact: true,
    permissions: [Roles.CONCESSIONAIRE],
    menu: {
      name: 'Bloques Horarios',
      icon: 'bi bi-clock-history me-3',
      url: '/concessionaire/time-block',
    },
  },
  {
    component: TemplatedCampaigns,
    originalComponent: Campaigns,
    path: '/cupones',
    title: 'Cupones',
    exact: true,
    permissions: [Roles.ADMIN],
    menu: {
      name: 'Cupones',
      icon: 'bi bi-award me-3',
      url: '/cupones',
    },
  },
  {
    component: TemplatedCoupons,
    originalComponent: Coupons,
    path: '/cupones/campana/:id',
    title: 'Cupones',
    exact: true,
    permissions: [Roles.ADMIN],
    menu: false,
  },
  {
    component: TemplatedTags,
    originalComponent: Tags,
    path: '/cupones/campana/tags/:campaign/:setup',
    title: 'Cupones',
    exact: true,
    permissions: [Roles.ADMIN],
    menu: false,
  },
  {
    component: TemplatedDiscounts,
    originalComponent: Discounts,
    path: '/descuentos',
    title: 'Descuentos',
    exact: true,
    permissions: [Roles.ADMIN],
    menu: {
      name: 'Descuentos',
      icon: 'bi bi-percent me-3',
      url: '/descuentos',
    },
  },
  {
    component: TemplatedCompanies,
    originalComponent: Companies,
    path: '/admin-empresa/empresas',
    title: 'Empresas',
    exact: true,
    permissions: [Roles.COMPANY],
    menu: {
      name: 'Empresas',
      icon: 'bi bi-building me-3',
      url: '/admin-empresa/empresas',
    },
  },
  {
    component: TemplatedUsersCompany,
    originalComponent: UsersCompany,
    path: '/admin-empresa/usuarios',
    title: 'Usuarios',
    exact: true,
    permissions: [Roles.COMPANY],
    menu: {
      name: 'Usuarios',
      icon: 'bi bi-person me-3',
      url: '/admin-empresa/usuarios',
    },
  },
];

export const _createFusionAuthRoutes = (...args: any) => {
  const fusionAuthRoutes: any = routes.map((route: any) => {
    const T = () => SetTemplate(TemplateDefault, route.originalComponent, ...args);
    route.component = T;
    return route;
  });
  return fusionAuthRoutes;
};

export const AuxiliarRoutes = [
  {
    component: SignIn,
    path: '/sign-in',
    title: 'Sign In',
    exact: true,
    permissions: [Roles.ADMIN, Roles.CONCESSIONAIRE, Roles.CALLCENTER],
    menu: false,
  },
  {
    path: '/sign-in',
    redirect: true,
  },
];

export default routes;

import {
  CREATE_SERVICE,
  RETRIEVE_SERVICES,
  UPDATE_SERVICE,
  DELETE_SERVICE,
} from "../types";

const initialState:any = [];

function serviceReducer(services = initialState, action:any) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_SERVICE:
      return [...services, ...payload];

    case RETRIEVE_SERVICES:
      return payload;

    case UPDATE_SERVICE:
      return services.map((service:any) => {
        if (service.id === payload[0].id) {
          return {
            ...service,
            ...payload[0],
          };
        } else {
          return service;
        }
      });

    case DELETE_SERVICE:
      return services.filter(({ id }:any) => id !== payload.id);
    default:
      return services;
  }
};

export default serviceReducer;

import { getSession } from '../helpers/session';
import store from '../redux/store';
import instance from './api_admin'

class CampaignDataService {

    _setHeaders = () => {
        const state: any = store.getState();
        const { session } = state;
    
        const oidcSession = getSession();
    
        if (oidcSession) {
          instance.defaults.headers.common['user'] = session.profile?.PK;
          instance.defaults.headers.common['Authorization'] = `Bearer ${oidcSession?.access_token}`;
        }
    };

    list = async (paginationParams: any = null) => {
        this._setHeaders();
        try {
			let params: any = [];

			if (paginationParams && paginationParams.last) {
				params.push(`last=${paginationParams.last}`)
			}
            const response = await instance.get(`/admin/campaign/list?limit=${process.env.REACT_APP_ORDERS_BASE_LIMIT}&${params.join('&')}`);
            return response.data;
        } catch (e: any) {
            return Promise.reject(e)
        }
    }

    get = async (PK:any) => {
        this._setHeaders();
        try {
			const response = await instance.get(`/admin/campaign/get?PK=${PK}`);
            return response.data;
        } catch (e: any) {
            return Promise.reject(e)
        }
    }

    create = async (params: any) => {
        this._setHeaders();
        try {
            const response = await instance.post('/admin/campaign', params);
            response.data = response.data.data
            return response;
        } catch (e: any) {
            return Promise.reject(e)
        }
    }

    update = async (params: any) => {
        this._setHeaders();
        try {
            const response = await instance.put(`/admin/campaign/update/${params.PK}`, params);
            return response;
        } catch (e: any) {
            return Promise.reject(e)
        }
    }

    delete = async (params: any) => {
        this._setHeaders();
        try {
            const response = await instance.delete(`/admin/campaign/delete/${params.PK}`, params);
            return response;
        } catch (e: any) {
            return Promise.reject(e)
        }
    }
}

export default new CampaignDataService();

import { createAction } from 'redux-actions';

export const SET_DATA_EDS: any = createAction('SET_DATA_EDS');
export const SET_EDS: any = createAction('SET_EDS');
export const SAVE_CHANGES: any = createAction('SAVE_CHANGES');

export const set_data_eds = (payload: any) => (dispatch: any) => {
  dispatch(SET_DATA_EDS(payload));
  dispatch(SAVE_CHANGES());
  return payload;
}

export const set_eds = (payload: any) => (dispatch: any) => {
  dispatch(SET_EDS(payload));
  dispatch(SAVE_CHANGES());
  return payload;
}
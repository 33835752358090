export const CREATE_SERVICE = "CREATE_SERVICE";
export const RETRIEVE_SERVICES = "RETRIEVE_SERVICES";
export const UPDATE_SERVICE = "UPDATE_SERVICE";
export const DELETE_SERVICE = "DELETE_SERVICE";

export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const RETRIEVE_PRODUCTS = "RETRIEVE_PRODUCTS";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";

export const CREATE_ORDER = "CREATE_ORDER";
export const RETRIEVE_ORDERS = "RETRIEVE_ORDERS";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const DELETE_ORDER = "DELETE_ORDER";


export const SAVE_STATE = "SAVE_STATE";

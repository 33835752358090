import getAllData from '../helpers/get_all_data';
import { getSession } from '../helpers/session';
import store from '../redux/store';
import instance from './api_admin';

class EDSDataService {
  _setHeaders = () => {
    const state: any = store.getState();
    const { session } = state;

    const oidcSession = getSession();

    if (oidcSession) {
      instance.defaults.headers.common['user'] = session.profile?.PK;
      instance.defaults.headers.common['usersk'] = session.profile?.SK;
      instance.defaults.headers.common['Authorization'] = `Bearer ${oidcSession?.access_token}`;
    }
  };

  getAll = async (paginationParams: any = null) => {
    this._setHeaders();
    try {
      let params: any = [];
      if (paginationParams && paginationParams.last) {
        params.push(`last=${paginationParams.last}`);
      }
      const response: any = await instance.get(`/admin/eds/list/all?limit=${process.env.REACT_APP_ORDERS_BASE_LIMIT}&${params.join('&')}`);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  getAllAvailableEds = async (limit: any = 10) => {
    this._setHeaders();
    try {
      const response: any = getAllData(instance, '/admin/eds/list/all', limit);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
  selectList = async () => {
    this._setHeaders();
    try {
      const response: any = await instance.get('/admin/eds/list');
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  get = async (id: any) => {
    this._setHeaders();
    try {
      const response: any = await instance.get(`/eds/${id}`);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  create = async (payload: any) => {
    this._setHeaders();
    try {
      return await instance.post('/admin/eds', payload);
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  update = async (payload: any) => {
    this._setHeaders();
    try {
      const response: any = await instance.put(`/admin/eds/update/${payload.PK}`, payload);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  getDetails = async (payload: any) => {
    this._setHeaders();
    try {
      const response: any = await instance.get(`/admin/eds/${payload.PK}?PK=${payload.PK}&SK=${payload.SK}`);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  getCovarage = async (payload: any) => {
    this._setHeaders();
    try {
      const response: any = await instance.get(`admin/coverage/eds/${payload.PK}?PK=${payload.PK}&SK=${payload.SK}`);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  createCoverage = async (payload: any) => {
    this._setHeaders();
    try {
      const response: any = await instance.post(`/admin/coverage/create/${payload.info?.eds_id}`, payload);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  updateCoverage = async (payload: any) => {
    this._setHeaders();
    try {
      const response: any = await instance.put(`/admin/coverage/update/${payload.PK}`, payload);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  removeCoverage = async (payload: any) => {
    this._setHeaders();
    try {
      const response: any = await instance.delete(`/admin/coverage/delete/${payload.PK}`, { data: { ...payload, hard: true } });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
}

const EDSDAO = new EDSDataService();

export default EDSDAO;

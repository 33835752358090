/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, Modal, SelectPicker } from 'rsuite';
import Image from '../Image';
import Images from '../../assets/image';
import CloseIcon from '@rsuite/icons/Close';
import FileDownloadIcon from '@rsuite/icons/FileDownload';
import { toast } from 'react-toastify';
import { LOADING_OFF } from '../../redux/actions/loader';
import { calculateOrderValues, getPaymentString } from '../../helpers/Utils';

const SearchOrder = (props: any) => {
  const { hideEds, statusList, edsList, data, results, handleOnChange, handleSearch, handleFilter, validations, ordersFiltered, ordersData, eds, services } = props;
  const { matchMedia } = useSelector((state: any) => state);
  const dispatch: any = useDispatch();
  const [state, setState] = useState({
    open: false,
    full: false,
    filter: '',
  });

  const filterRef: any = useRef();

  useEffect(() => {
    if (state.open) {
      setState({ ...state, filter: '' });
      filterRef.current?.focus();
    }
  }, [state.open]);

  useEffect(() => {
    if (matchMedia.media === 'md' || matchMedia.media === 'sm' || matchMedia.media === 'xs') {
      setState({ ...state, full: true });
    } else {
      setState({ ...state, full: false });
    }
  }, [matchMedia.media]);

  const _handleOnChange = (payload: any) => {
    try {
      handleOnChange(payload);
    } catch (e: any) {
      dispatch(LOADING_OFF());
      toast.error('No fue posible cambiar el estado del componente.');
    }
  };

  const _handleFilter = (e: any) => {
    try {
      e.preventDefault();
      handleFilter(state.filter);
      setState({ ...state, open: false });
    } catch (e: any) {
      dispatch(LOADING_OFF());
      toast.error('No fue posible aplicar este filtro.');
    }
  };

  const _handleSearch = () => {
    try {
      handleSearch();
    } catch (e: any) {
      dispatch(LOADING_OFF());
      toast.error('No fue posible realizar esta búsqueda.');
    }
  };

  const _handleGenerateCSV = () => {


    if (!ordersData || ordersData.length === 0) {
      toast.error('No hay datos para generar el reporte.')
      return
    }

    if (ordersFiltered && ordersFiltered.length === 0) {
      toast.error('No hay datos para generar el reporte.')
      return
    }

    const headers = {
      order_id: 'ID Orden',
      service_name: 'Nombre Servicio Asociado',
      sub_service: 'Sub Servicio',
      eds: 'Estación de Servicio',
      order_state: 'Estado Pedido',
      address: 'Dirección',
      address_comment: 'Comentario dirección',
      longitude: 'Longitud',
      latitude: 'Latitud',
      commune: 'Comuna',
      schedule_at: 'Agendado dia',
      created_at: 'Fecha Creación',
      updated_at: 'Fecha Actualización',
      amount: 'Monto Pagado',
      unit_price: 'Precio unitario',
      amount_off: 'Monto Descuento',
      amount_shipping: 'Monto Delivery',
      amount_diff: 'Monto a reembolsar',
      amount_extra: 'Monto agregado en el local',
      user_name: 'Nombre Usuario',
      user_mail: 'Correo Usuario',
      user_phone: 'Teléfono Usuario',
      user_rut: 'Rut Usuario',
      payment_type: 'Forma de pago',
      car_model: 'Modelo Vehículo',
      car_plate: 'Patente Vehículo',
      car_brand: 'Marca Vehículo',
      car_year: 'Año Vehículo',
      car_size: 'Tamaño Vehículo',
      unknown: ' ',
    };

    const orders: any = ordersFiltered || ordersData

    orders.sort((a: any, b: any) => {
      return moment(a.created_at).diff(moment(b.created_at));
    });

    const orders_formated = orders.map((order: any) => {

      const edsData = eds.find((item: any) => item.PK === order.GS3PK)
      const address = order.info?.direction?._string?.replace(/,/g, '-')
      const commune = address?.split('-')[1] || '-'
      const communeWithoutNumbers = commune?.replace(/[0-9]/g, '')
      const schedule_at = order.info?.reservation?.schedule_at ? moment(order.info?.reservation?.schedule_at).format('DD/MM/YYYY HH:mm') : moment(order.schedule_at).format('DD/MM/YYYY')

      const serviceName = services.find((service: any) => service.value === order.GS4PK)?.label;

      const orderValues = calculateOrderValues(order);
      const payment_type = getPaymentString(order);

      /** CREATE MAIN ORDER VALUES */
      const items: any = [{
        order_id: order.PK,
        service_name: serviceName,
        sub_service: "",
        eds: edsData ? `${edsData.code}-${edsData.name}` : '-',
        order_state: order.GS1PK,
        address: address,
        address_comment: '-',
        longitude: order.info?.direction?.longitude,
        latitude: order.info?.direction?.latitude,
        commune: communeWithoutNumbers.trim(),
        schedule_at: schedule_at,
        created_at: moment(order.created_at).format('DD/MM/YYYY HH:mm'),
        updated_at: moment(order.updated_at).format('DD/MM/YYYY HH:mm'),
        amount: orderValues.totalAmount,
        unit_price: "-",
        amount_off: orderValues.amount_off,
        amount_shipping: orderValues.amount_shipping,
        amount_diff: orderValues.amount_diff > 0 ? orderValues.amount_diff : 0,
        amount_extra: orderValues.amount_diff < 0 ? orderValues.amount_diff * -1 : 0,
        user_name: order.user?.name,
        user_mail: order.user?.email,
        user_phone: order.user?.phone_number,
        user_rut: order.user?.rut,
        payment_type: payment_type,
        car_model: order.info?.vehicle ? order.info?.vehicle?.model?.label : 'N/A',
        car_plate: order.info?.vehicle ? order.info?.vehicle?.plate : 'N/A',
        car_brand: order.info?.vehicle ? order.info?.vehicle?.brand?.label : 'N/A',
        car_year: order.info?.vehicle ? order.info?.vehicle?.year : 'N/A',
        car_size: order.info?.vehicle ? order.info?.vehicle?.size : 'N/A',
        unknown: order.PK.split('-')[0] || "-",
      }];

      if (!order.order_complements || order.order_complements.length === 0) {

        order.items.sort((a: any, b: any) => {
          return a.info.type_item - b.info.type_item;
        })
        order.items?.forEach((item: any) => {
          items.push({
            order_id: order.PK,
            service_name: serviceName,
            sub_service: item.info?.name,
            eds: edsData ? `${edsData.code}-${edsData.name}` : '-',
            order_state: order.GS1PK,
            address: address,
            address_comment: '-',
            longitude: order.info?.direction?.longitude,
            latitude: order.info?.direction?.latitude,
            commune: communeWithoutNumbers.trim(),
            schedule_at: schedule_at,
            created_at: moment(order.created_at).format('DD/MM/YYYY HH:mm'),
            updated_at: moment(order.updated_at).format('DD/MM/YYYY HH:mm'),
            amount: item.info?.quantity_to_buy * item.info?.unit_price,
            unit_price: item.info?.unit_price,
            amount_off: "-",
            amount_shipping: "-",
            amount_diff: "-",
            amount_extra: "-",
            user_name: order.user?.name,
            user_mail: order.user?.email,
            user_phone: order.user?.phone_number,
            user_rut: order.user?.rut,
            payment_type: payment_type,
            car_model: order.info?.vehicle ? order.info?.vehicle?.model?.label : 'N/A',
            car_plate: order.info?.vehicle ? order.info?.vehicle?.plate : 'N/A',
            car_brand: order.info?.vehicle ? order.info?.vehicle?.brand?.label : 'N/A',
            car_year: order.info?.vehicle ? order.info?.vehicle?.year : 'N/A',
            car_size: order.info?.vehicle ? order.info?.vehicle?.size : 'N/A',
            unknown: order.PK.split('-')[0] || "-",
          })
        })
      }

      if (order.order_complements && order.order_complements.length > 0) {
        order.order_complements.shift()?.info?.items?.forEach((item: any) => {
          items.push({
            order_id: order.PK,
            service_name: serviceName,
            sub_service: item.name,
            eds: edsData ? `${edsData.code}-${edsData.name}` : '-',
            order_state: order.GS1PK,
            address: address,
            address_comment: '-',
            longitude: order.info?.direction?.longitude,
            latitude: order.info?.direction?.latitude,
            commune: communeWithoutNumbers.trim(),
            schedule_at: schedule_at,
            created_at: moment(order.created_at).format('DD/MM/YYYY HH:mm'),
            updated_at: moment(order.updated_at).format('DD/MM/YYYY HH:mm'),
            amount: item.quantity_to_buy * item.price,
            unit_price: item.price,
            amount_off: "-",
            amount_shipping: "-",
            amount_diff: "-",
            amount_extra: "-",
            user_name: order.user?.name,
            user_mail: order.user?.email,
            user_phone: order.user?.phone_number,
            user_rut: order.user?.rut,
            payment_type: payment_type,
            car_model: order.info?.vehicle ? order.info?.vehicle?.model?.label : 'N/A',
            car_plate: order.info?.vehicle ? order.info?.vehicle?.plate : 'N/A',
            car_brand: order.info?.vehicle ? order.info?.vehicle?.brand?.label : 'N/A',
            car_year: order.info?.vehicle ? order.info?.vehicle?.year : 'N/A',
            car_size: order.info?.vehicle ? order.info?.vehicle?.size : 'N/A',
            unknown: order.PK.split('-')[0] || "-",
          })
        })
      }

      return items;
    });

    let csv = [headers];

    orders_formated.forEach((order: any) => {
      order.forEach((item: any) => {
        csv.push(item)
      })
    })

    const csvContent = "data:text/csv;charset=utf-8," + csv.map((row: any) => Object.values(row).join(";")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "reporte_pedidos.csv");
    document.body.appendChild(link);
    link.click();
  }

  return (
    <>
      <Modal open={state.open} size="md" full={state.full} style={state.full ? { width: '96%', left: '1%' } : {}}>
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-11">
              <h4 className="bold-300">Filtrar</h4>
            </div>
            <div className="col-1 text-end">
              <button className="btn px-0" onClick={() => setState({ ...state, open: false })}>
                <CloseIcon />
              </button>
            </div>
          </div>

          <form onSubmit={_handleFilter}>
            <div className="row align-items-center justify-content-center">
              <div className="col-9 ">
                <input
                  type="text"
                  className="input-default w-100"
                  ref={filterRef}
                  value={state.filter}
                  onChange={(event: any) => setState({ ...state, filter: event.target.value })}
                  placeholder="Ingresa correo, nombre o ID Pedido"
                />
              </div>
              <div className="col-3 text-white">
                <button className="btn-default px-0" style={{ width: '100%' }} type="submit">
                  <Image {...Images.SearchWhiteSVG} style={{ width: '25px' }} className="d-inline d-sm-none" />
                  <span className="d-none d-sm-block">Filtrar</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>

      <div className="row mx-0 mt-4">
        <div className={`col-12 col-xl-${hideEds ? '3' : '2'}`}>
          <span className="bold-300 size-09">Desde</span>
          <DatePicker
            format="dd/MM/yyyy"
            placeholder="Informe la fecha"
            style={{ width: '100%' }}
            classPrefix="copec"
            className="rs-copec-cleanable"
            cleanable={false}
            value={data?.start || moment().subtract(30, 'days').toDate()}
            onChange={(value: any) => _handleOnChange({ target: { value, name: 'start' } })}
            locale={{
              sunday: 'Dom',
              monday: 'Lun',
              tuesday: 'Mar',
              wednesday: 'Mie',
              thursday: 'Jue',
              friday: 'Vie',
              saturday: 'Sab',
              ok: 'OK',
              today: 'Hoy',
              yesterday: 'Ayer',
              hours: 'Horas',
              minutes: 'Minutos',
              seconds: 'Segundos',
            }}
          />
        </div>
        <div className={`col-12 col-xl-${hideEds ? '3' : '2'}`}>
          <span className="bold-300 size-09">Hasta</span>
          <DatePicker
            format="dd/MM/yyyy"
            placeholder="Informe la fecha"
            style={{ width: '100%' }}
            classPrefix="copec"
            className="rs-copec-cleanable"
            cleanable={false}
            value={data?.end || moment().toDate()}
            onChange={(value) => _handleOnChange({ target: { value, name: 'end' } })}
            locale={{
              sunday: 'Dom',
              monday: 'Lun',
              tuesday: 'Mar',
              wednesday: 'Mie',
              thursday: 'Jue',
              friday: 'Vie',
              saturday: 'Sab',
              ok: 'OK',
              today: 'Hoy',
              yesterday: 'Ayer',
              hours: 'Horas',
              minutes: 'Minutos',
              seconds: 'Segundos',
            }}
          />
        </div>

        <div className={`col-12 col-xl-${hideEds ? '3' : '2'}`}>
          <span className="bold-300 size-09">Estado</span>
          <SelectPicker
            locale={{ searchPlaceholder: 'Buscar', noResultsText: 'Ningún resultado encontrado.', emptyMessage: 'Ningún resultado encontrado.' }}
            cleanable={false}
            classPrefix="copec"
            className="rs-copec-cleanable"
            data={statusList || []}
            onChange={(value: any) => _handleOnChange({ target: { value, name: 'status' } })}
            value={data?.status}
            style={{ width: '100%' }}
            placeholder="Estados"
          />
        </div>

        {hideEds ? null : (
          <div className="col-12 col-xl-3">
            <span className="bold-300 size-09">Eds</span>
            <SelectPicker
              locale={{ searchPlaceholder: 'Buscar', noResultsText: 'Ningún resultado encontrado.', emptyMessage: 'Ningún resultado encontrado.' }}
              cleanable={false}
              classPrefix="copec"
              className="rs-copec-cleanable"
              data={edsList || []}
              onChange={(value: any) => _handleOnChange({ target: { value, name: 'eds' } })}
              value={data?.eds}
              style={{ width: '100%' }}
              placeholder="Eds"
              valueKey="PK"
              labelKey="name"
            />
          </div>
        )}

        <div className="col-12 d-xl-none">
          {validations.map((item: any, index: number) => (
            <div className="error-validations error-enabled bold-300 size-09" key={`mobile-${index}`}>
              {item}
            </div>
          ))}
        </div>

        <div className={`col-12 col-xl-3 position-relative`} style={{ minHeight: '70px' }}>
          <div className="position-absolute bottom-0  text-white w-100 text-end">
            <button className="btn-default mb-1" onClick={_handleSearch}>
              Buscar
            </button>
            <button className="btn-default mb-1 ms-2" disabled={results && results.length > 0 ? false : true} onClick={() => setState({ ...state, open: true })}>
              Filtrar
            </button>
          </div>
        </div>

        <div className="col-12 d-none d-xl-block">
          {validations.map((item: any, index: any) => (
            <div className="error-validations error-enabled bold-300 size-09" key={`desktop-${index}`}>
              {item}
            </div>
          ))}
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12 d-flex justify-content-end">
          <button className='btn d-flex justify-content-center align-items-center' onClick={_handleGenerateCSV}>
            <FileDownloadIcon className='me-1' />
            Descargar reporte
          </button>
        </div>
      </div>
    </>
  );
};

export default SearchOrder;

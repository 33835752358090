/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Divider, SelectPicker, Table } from 'rsuite';
import ConfirmationModal from '../../components/ConfirmationModal';
import ModalComponent from '../../components/Modal';
import Title from '../../components/Title';
import { _sortItems } from '../../helpers/sort';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import EDSDAO from '../../services/eds.service';
import Schedule from '../../services/schedule.service';
import Service from '../../services/service_and_products.service';
import AddTimeBlock from './AddTimeBlock';
import { TableHeader } from './tableConfig';
import UpdateTimeBlock from './UpdateTimeBlock';

const TimeBlockConcessionaire = () => {
  const dispatch = useDispatch();
  const { session } = useSelector((state: any) => state);
  const [state, setState] = useState<any>({
    checks: {},
    data: [],
    coverages: [],
    services: [],
    modals: {
      create: false,
      update: false,
    },
    confirmation: {
      delete: false,
    },
    selected_time_block: {},
    sort: {
      column: '',
      type: 'asc',
    },
    delete_id: '',
    eds: [],
    eds_id: '',
  });

  const _getEds = async () => {
    dispatch(LOADING_ON());
    try {
      const response = await EDSDAO.getAllAvailableEds(50);
      const edsList: any = response.data.filter((item: any) => item?.info?.concessionaire_id === session.profile?.info?.concessionaire_id);

      if (edsList.length > 0) {
        await _loadData(edsList[0]);
        setState((prevState: any) => {
          return { ...prevState, eds: edsList };
        });
      } else {
        setState({ ...state, eds: edsList });
      }
    } catch (e: any) {
      console.log('LOGGER', e);
    }
    dispatch(LOADING_OFF());
  };

  useEffect(() => {
    _getEds();
  }, []);

  const _loadData = async (payload: any) => {
    try {
      dispatch(LOADING_ON());
      const responseSchedule: any = await Schedule.listScheduleByEds(payload);
      const responseCoverage: any = await Schedule.listAllCoverage();
      const responseService: any = await Service.list();

      responseService.data = responseService.data
        .filter((item: any) => item.type !== 'recommended_subservice' && item.scheduling)
        .map((item: any) => {
          return { ...item, label: item.name, value: item.PK };
        });
      
      const coverages: any = responseCoverage.data.map((item: any) => {
        return { ...item, label: item.name, value: item.PK };
      });

      const schedules = responseSchedule.data.map((item: any) => {
        const [eds_id, service_id] = item.PK?.split('-');
        item.service_id = service_id;
        item.eds_id = eds_id;
        item.schedule_coverage_id = item.GS2PK;
        const data = {
          ...item,
          service: responseService.data.find((element: any) => element.PK === item.service_id),
          coverage: coverages.find((element: any) => element.value === item.schedule_coverage_id),
          service_name: responseService.data.find((element: any) => element.value === item.service_id)?.label,
          coverage_name: coverages.find((element: any) => element.value === item.schedule_coverage_id)?.label,
        };
        return data;
      });

      schedules.sort((a: any, b: any) => {
        if (a.info?.day_of_week > b.info?.day_of_week) return 1;
        if (a.info?.day_of_week < b.info?.day_of_week) return -1;
        return 0;
      });

      schedules.sort((a: any, b: any) => {
        if (a.service_id > b.service_id) return 1;
        if (a.service_id < b.service_id) return -1;
        return 0;
      });

      const coverage: any = coverages.find((item: any) => parseInt(item.day_of_week) === 0);

      if (coverage) {
        coverage.day_of_week = 7;
      }

      coverages?.sort((a: any, b: any) => {
        if (parseInt(a.day_of_week) > parseInt(b.day_of_week)) return 1;
        if (parseInt(a.day_of_week) < parseInt(b.day_of_week)) return -1;
        return 0;
      });

      setState({
        ...state,
        eds_id: payload.PK,
        data: schedules,
        coverages: coverages,
        services: responseService.data,
        modals: {
          create: false,
          update: false,
        },
        confirmation: { delete: false },
        delete_id: false,
      });
      dispatch(LOADING_OFF());
    } catch (e: any) {
      console.log('ERROR LOG', e.message);
      toast.error('No fue posible cargar la info...');
      dispatch(LOADING_OFF());
    }
  };

  const _handleChangeEds = async (PK: any) => {
    const eds = state.eds.find((item: any) => item.PK === PK);
    await _loadData(eds);
  };

  const _handleToggleModal = (target: any) => {
    setState({ ...state, modals: { ...state.modals, [target]: !state.modals[target] } });
  };

  const _createTimeBlock = async (data: any) => {
    try {
      dispatch(LOADING_ON());
      const coverage: any = state.coverages?.find((element: any) => element.PK === data.schedule_coverage_id);
      data.info = { ...data.info, day_of_week: coverage?.day_of_week };
      data.eds_id = state.eds_id;
      await Schedule.createSchedule(data);
      await _handleChangeEds(state.eds_id);
      toast.success('Horario creado con éxito');
    } catch (e: any) {
      console.log('ERROR LOG', e.message);
      dispatch(LOADING_OFF());
    }
  };

  const _updateTimeBlock = async (data: any) => {
    try {
      dispatch(LOADING_ON());
      const coverage: any = state.coverages?.find((element: any) => element.PK === data.schedule_coverage_id);
      data.info = { ...data.info, day_of_week: coverage?.day_of_week };
      data.eds_id = state.eds_id;
      await Schedule.updateSchedule(data.PK, data);
      await _handleChangeEds(state.eds_id);
      toast.success('Horario actualizado con éxito');
    } catch (e: any) {
      console.log('ERROR LOG', e.message);
      dispatch(LOADING_OFF());
    }
  };

  const _deleteSchedule = async () => {
    dispatch(LOADING_ON());
    try {
      const schedule: any = state.data.find((item: any) => item.SK === state.delete_id);
      await Schedule.deleteSchedule(schedule);
      await _handleChangeEds(state.eds_id);
      toast.success('Horario eliminado con éxito');
    } catch (e: any) {
      console.log('LOGGER', e.message);
      toast.error('No fue posible eliminar este horario');
      dispatch(LOADING_OFF());
    }
    dispatch(LOADING_OFF());
  };

  const _selectTBAndOpenModal = (data: any) => {
    setState({ ...state, modals: { ...state.modals, update: true }, selected_time_block: data });
  };
  const _trashTBAndOpenModal = (data: any) => {
    setState({ ...state, delete_id: data.SK, confirmation: { delete: true } });
  };

  const Actions = (props: any) => {
    const { payload } = props;
    return (
      <>
        <button className="btn p-0 size-11 color-grey-menu" onClick={() => _selectTBAndOpenModal(payload)}>
          <i className="bi bi-pencil"></i>
        </button>
        <Divider vertical />
        <button className="btn p-0 size-11 color-grey-menu" onClick={() => _trashTBAndOpenModal(payload)}>
          <i className="bi bi-trash"></i>
        </button>
      </>
    );
  };

  const _sortTable = (column: any, type: any) => {
    const sorted = _sortItems(state.data, column, type);
    setState({ ...state, ...sorted });
  };

  return (
    <div className="container-fluid">
      <div className="row mt-5 align-items-center">
        <div className="col-8">
          <Title size="sm" text={`Bloques Horarios`} className="to-upper bold" />
        </div>
        <div className="col-4">
          <SelectPicker
            cleanable={false}
            className="rs-copec-cleanable"
            locale={{ searchPlaceholder: 'Buscar', noResultsText: 'Ningún resultado encontrado.', emptyMessage: 'Ningún resultado encontrado.' }}
            classPrefix="copec"
            value={state.eds_id}
            data={state.eds}
            onChange={(e: any) => _handleChangeEds(e)}
            style={{ width: '100%' }}
            placeholder="EDS"
            valueKey="PK"
            labelKey="name"
          />
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12 d-flex justify-content-end color-white">
          <button className="btn-default" onClick={() => _handleToggleModal('create')}>
            <span className="me-2">+</span>
            Crear Bloque
          </button>
        </div>
      </div>

      <ModalComponent open={state.modals.create} noFull size={'md'} handleClose={() => _handleToggleModal('create')} hideFooter>
        <AddTimeBlock handleCancel={() => _handleToggleModal('create')} handleConfirm={_createTimeBlock} coverages={state.coverages} services={state.services} />
      </ModalComponent>

      <ModalComponent open={state.modals.update} noFull size={'md'} handleClose={() => _handleToggleModal('update')} hideFooter>
        <UpdateTimeBlock
          handleCancel={() => _handleToggleModal('update')}
          handleConfirm={_updateTimeBlock}
          coverages={state.coverages}
          services={state.services}
          data={state.selected_time_block}
        />
      </ModalComponent>

      <ConfirmationModal
        type="warning"
        title="Eliminar"
        open={state.confirmation.delete}
        size="md"
        handleClose={() => setState({ ...state, delete_id: '', confirmation: { delete: false } })}
        handleConfirm={_deleteSchedule}
      >
        <span className="ms-3">¿Seguro que quieres eliminar este bloque horario?</span>
      </ConfirmationModal>

      <div className="row border rounded-10 mt-5 p-3" style={{ minHeight: '70vh' }}>
        <div className="col-12 h-100">
          <Table
            data={state.data}
            rowClassName="striped"
            rowHeight={50}
            sortColumn={state.sort.column}
            sortType={state.sort.type}
            onSortColumn={_sortTable}
            autoHeight
            locale={{ emptyMessage: 'No hay bloques horarios' }}
          >
            {TableHeader &&
              TableHeader.map((column: any, index: any) => (
                <Table.Column align={column.align} flexGrow={column.flexGrow} key={`table-column-${index}`} sortable>
                  <Table.HeaderCell>
                    <span style={{ textTransform: 'capitalize' }}>{column.label}</span>
                  </Table.HeaderCell>
                  <Table.Cell style={{ display: 'flex', alignItems: 'center', justifyContent: column.alignFlex }} dataKey={column.key}>
                    {(rowData) => {
                      switch (column.key) {
                        case 'duration':
                          return <span>{rowData[column.key]} min</span>;
                        default:
                          return <span>{rowData[column.key]}</span>;
                      }
                    }}
                  </Table.Cell>
                </Table.Column>
              ))}

            <Table.Column align="right" flexGrow={1}>
              <Table.HeaderCell>{''}</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  return (
                    <div className="me-4">
                      <Actions payload={rowData} />
                    </div>
                  );
                }}
              </Table.Cell>
            </Table.Column>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default TimeBlockConcessionaire;

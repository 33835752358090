/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Title from "../../components/Title";
import ModalComponent from "../../components/Modal";
import CompanyForm from "./CompanyForm";
import CompanyTable from "./CompanyTable";
import User from "../../services/user.service";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { LOADING_OFF, LOADING_ON } from "../../redux/actions/loader";
import Pagination from "../../components/PaginationFront/Pagination";
import ConfirmationModal from "../../components/ConfirmationModal";
import Company from "../../services/company.service";
import { listAllData } from "../../helpers/Utils";
import Service from "../../services/service_and_products.service";
import EDSDAO from "../../services/eds.service";
import CompanyDetails from "./CompanyDetails";
import { paginate } from "../../helpers/paginate";

const Companies = () => {
    const [state, setState] = useState<any>({
        formModal: {
            openCreate: false,
            openUpdate: false,
            openView: false,
        },
        confirmationModal: {
            open: false
        },
        user: {},
        data: [],
        dataFiltered: [],
        dataShow: [],
        edsData: [],
        serviceData: [],
        userData: [],
        search: '',
    })
    const dispatch: any = useDispatch()
    
    const { session } = useSelector((state: any) => state)
    const { profile } = session
    const isAdmin = profile?.info?.roles?.find((el: any) => el === "admin")

    const [tableLoader, setTableLoader] = useState<boolean>(false)

    const [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 10,
        items: [],
        totalPages: 0,
    })

    useEffect(() => {
        _getData()
    }, [])

    const _getData = async () => {
        dispatch(LOADING_ON());
        try {

            const { data: serviceData, errors: serviceErrors } = await listAllData(Service.list)

            if (serviceErrors.length > 0) throw new Error("No fue posible cargar los servicios")

            const { data: edsData, errors: edsErrors } = await listAllData(EDSDAO.getAll)

            if (edsErrors.length > 0) throw new Error("No fue posible cargar las eds")

            const { data: userData, errors: userErrors } = await listAllData(User._list, "USER_COMPANY")

            if (userErrors.length > 0) throw new Error("No fue posible cargar los usuarios")

            const response: any = await Company._list()

            const paginated: any = paginate(response.data, response.data.length, pagination.currentPage, pagination.pageSize)

            setPagination({ ...paginated })

            _formatData(paginated.items)

            setState({
                ...state,
                data: response.data,
                dataFiltered: response.data,
                dataShow: paginated.items,
                edsData,
                serviceData,
                userData,
                formModal: {
                    openCreate: false,
                    openUpdate: false
                },
                confirmationModal: {
                    open: false
                }
            })
            dispatch(LOADING_OFF())
        } catch (e: any) {
            toast.error("No fue posible cargar las empresas")
            dispatch(LOADING_OFF())
        }
    }

    const onNext = () => {
        if (pagination.currentPage === pagination.totalPages) {
            toast.error("No hay más páginas")
            return
        }
        setTableLoader(true)
        setTimeout(() => {
            const paginated: any = paginate(state.dataFiltered, state.dataFiltered.length, pagination.currentPage + 1, pagination.pageSize)

            setPagination({ ...paginated })
            setState({ ...state, dataShow: paginated.items })
            setTableLoader(false)
        }, 500)
    }

    const onPrevious = () => {
        if (pagination.currentPage === 1) {
            toast.warning("Ya estás en la primera página")
            return
        }
        setTableLoader(true)
        setTimeout(() => {
            const paginated: any = paginate(state.dataFiltered, state.dataFiltered.length, pagination.currentPage - 1, pagination.pageSize)
            setPagination({ ...paginated })
            setState({ ...state, dataShow: paginated.items })
            setTableLoader(false)
        }, 500)
    }

    const _formatData = (data: any) => {
        data.map((el: any) => {
            el.status = !el.info?.isInactive;
            return el;
        })

        return data;
    }

    const _toggleCompanyFormModal = (event: any, target: any = "openCreate") => {
        setState({
            ...state,
            formModal: {
                ...state.formModal,
                [target]: !state.formModal[target],
            }
        })
    }

    const _handleUpdate = (payload: any) => {
        setState({
            ...state,
            user: payload,
            formModal: {
                ...state.formModal,
                openUpdate: true
            }
        })
    }

    const _handleView = (payload: any) => {
        setState({
            ...state,
            user: payload,
            formModal: {
                ...state.formModal,
                openView: true
            }
        })
    }

    const _handleCloseConfirmation = () => {
        setState({
            ...state,
            user: {},
            confirmationModal: {
                open: false,
                text: ''

            }
        })
    }

    const _handleToggleStatus = (payload: any) => {
        const text: any = `¿Seguro que quieres ${payload.status ? 'desactivar' : 'activar'} esta empresa?`
        setState({
            ...state,
            user: payload,
            confirmationModal: {
                open: true,
                text

            }
        })
    }

    const _toggleStatus = async () => {
        const { user } = state
        try {
            dispatch(LOADING_ON())
            user.info.isInactive = user.status;
            delete user.status;
            await Company._update(user);
            const { data } = state;
            const userTable: any = data.find((el: any) => el.PK === user.PK && el.SK === user.SK)

            if (userTable) {
                userTable.status = !user.info.isInactive
                setState({
                    ...state,
                    confirmationModal: { open: false, text: '' },
                    data
                })
            }
            dispatch(LOADING_OFF())
        } catch (e: any) {
            dispatch(LOADING_OFF())
            toast.error(e?.message || "No fue posible cambiar el status de este usuario")
        }
    }

    const _handleSearch = async () => {
        try {
            dispatch(LOADING_ON())

            if (!state.search) {

                const paginated: any = paginate(state.data, state.data.length, 1, pagination.pageSize)

                setPagination({ ...paginated })

                _formatData(paginated.items)

                setState({ ...state, dataFiltered: state.data, dataShow: paginated.items })
                dispatch(LOADING_OFF())
                return
            }

            const data: any = state.data.filter((item: any) => item.name.toLowerCase().includes(state.search.toLowerCase()))

            const paginated: any = paginate(data, data.length, 1, pagination.pageSize)

            setPagination({ ...paginated })

            _formatData(paginated.items)

            setState({
                ...state,
                dataFiltered: data,
                dataShow: paginated.items
            })
            dispatch(LOADING_OFF())
        } catch (e: any) {
            dispatch(LOADING_OFF())
            toast.error("No fue posible encontrar este usuario")
        }
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <Title size="sm" text={`Empresas`} className="mt-5 to-upper bold" />
            </div>

            {isAdmin && (
                <div className="row">
                    <div className="col-12 col-md-7 color-white">
                        <input
                            type="text"
                            className="input-default w-100 color-black"
                            placeholder="buscar por nombre empresa de la empresa"
                            value={state.search}
                            onChange={(e: any) => setState({ ...state, search: e.target.value })}
                        />
                    </div>

                    <div className="col-12 col-md-5 text-end color-white">
                        <button className="btn-default me-3" onClick={_handleSearch}>
                            Buscar
                        </button>
                        <button className="btn-default" onClick={_toggleCompanyFormModal}>
                            {' '}
                            + Nueva Empresa
                        </button>
                    </div>
                </div>
            )}

            <ModalComponent open={state.formModal.openCreate} size="md" noFull handleClose={(e: any) => _toggleCompanyFormModal("openCreate")} hideFooter>
                <CompanyForm
                    action="create"
                    handleClose={(e: any) => _toggleCompanyFormModal("openCreate")}
                    handleSuccess={_getData}
                    eds={state.edsData}
                    services={state.serviceData}
                    users={state.userData}
                    isAdmin={isAdmin}
                />
            </ModalComponent>

            <ModalComponent open={state.formModal.openView} size="md" noFull handleClose={(e: any) => _toggleCompanyFormModal(e, "openView")} hideFooter>
                <CompanyDetails
                    data={state.user}
                    eds={state.edsData}
                    services={state.serviceData}
                    users={state.userData}
                />
            </ModalComponent>

            <ModalComponent open={state.formModal.openUpdate} size="md" noFull handleClose={(e: any) => _toggleCompanyFormModal(e, "openUpdate")} hideFooter>
                <CompanyForm
                    data={state.user}
                    action="update"
                    handleClose={(e: any) => _toggleCompanyFormModal(e, "openUpdate")}
                    handleSuccess={_getData}
                    eds={state.edsData}
                    services={state.serviceData}
                    users={state.userData}
                    isAdmin={isAdmin}
                />
            </ModalComponent>

            <ConfirmationModal size="md" title="Atención" type="warning" noFull open={state.confirmationModal.open} handleClose={_handleCloseConfirmation} handleConfirm={_toggleStatus}>
                <span className="px-4">{state.confirmationModal.text}</span>
            </ConfirmationModal>

            <CompanyTable
                data={state.dataShow}
                handleUpdate={_handleUpdate}
                handleView={_handleView}
                toggleStatus={_handleToggleStatus}
                eds={state.edsData}
                services={state.serviceData}
                users={state.userData}
                loading={tableLoader}
            />

            <Pagination nextPage={onNext} prevPage={onPrevious} index={pagination.currentPage} />
        </div>
    )
}

export default Companies;
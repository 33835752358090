import React from 'react';
import './styles.scss';

const DrawerComponent = (props: any) => {
  const { open, setOpen, title } = props;

  const childrenWithProps = React.Children.map(props.children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...props });
    }
    return child;
  });
  return (
    <div className={`drawer-component px-3 ${open ? 'open' : ''}`}>
      <button className="btn drawer-component__close bold size-09" onClick={setOpen}>X</button>
      <div className="row mx-2 px-3 pt-3 pb-1 mb-3 drawer-component__title">
        <div className="col-12">{title}</div>
      </div>
      {childrenWithProps}
    </div>
  );
};

export default DrawerComponent;

// import { signout } from "../redux/actions/session";
import { getSession } from '../helpers/session';
import store from '../redux/store';
import instanceAdmin from './api_admin';

class CompanyService {
  _setHeaders = () => {
    const state: any = store.getState();
    const { session } = state;

    const oidcSession = getSession();

    if (oidcSession) {
      instanceAdmin.defaults.headers.common['user'] = session.profile?.PK;
      instanceAdmin.defaults.headers.common['Authorization'] = `Bearer ${oidcSession?.access_token}`;
    }
  };

  _list = async (paginationParams:any = null) => {
    this._setHeaders();
    try {
      let params: any = [];
      if (paginationParams && paginationParams.last) {
        params.push(`last=${paginationParams.last}`);
      }
      const response = await instanceAdmin.get(`/admin/company/all`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  }

  _create = async (params: any) => {
    this._setHeaders();
    try {
      const response = await instanceAdmin.post('/admin/company/create', params);

      if (response?.data?.error) {
        throw new Error(`${params.concessionaire_id}::${response.data.error}`);
      }
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  _update = async (params: any) => {
    this._setHeaders();
    try {
      const response = await instanceAdmin.put(`/admin/company/edit`, params);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  _search = async (params: any) => {
    this._setHeaders();
    try {
      let body: any = [];

    if (params) {
      Object.keys(params).forEach((key: any) => {
        body.push(`${key}=${params[key]}`);
      });
    }
      const response = await instanceAdmin.get(`/admin/user/search?limit=100&${body.join('&')}`);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  
}

const Company = new CompanyService();

export default Company;

import { Table, Toggle, Tooltip, Whisper } from "rsuite"
import { TableCoupon } from './tableConfigs'

const ListCoupon = (props: any) => {
  const { data, handleChange } = props
  return (
    <div className="container-fluid">
      <div className="size-13 bold mb-2">
        Cupón
      </div>
      <div className="row">
        <div className="col-12">
        <Table
            data={data.list_coupons}
            height={data.list_coupons.length > 2 ? data.list_coupons.length * 60 : 150}
            rowClassName="striped"
            rowHeight={50}
            locale={{ emptyMessage: "no hay cupones"}}
          >
            {TableCoupon && TableCoupon.map((column: any, index: any) => (
              <Table.Column flexGrow={column.key !== 'code' ? 1 : 0} key={`table-column-${index}`}>
                <Table.HeaderCell>
                  <span style={{ textTransform: 'capitalize' }}>{column.label}</span>
                </Table.HeaderCell>
                <Table.Cell>
                  {rowData => {
                    switch (column.key) {
                      case 'status':
                        return (
                          <Toggle
                            checked={rowData[column.key] === 1 ? true : false}
                            onChange={(e: any) => handleChange({ row: rowData, target: { name: 'status', value: e } })}
                          />
                        )
                      default:
                        return (
                          <Whisper
                            trigger="hover"
                            placement='auto'
                            controlId={`control-id-auto`}
                            speaker={
                              <Tooltip>{rowData[column.key]}</Tooltip>
                            }
                          >
                            <span style={{ textTransform: 'capitalize' }}>{rowData[column.key]}</span>
                          </Whisper>
                        )
                    }
                  }}
                </Table.Cell>
              </Table.Column>
            ))}
          </Table>
        </div>
      </div>
    </div>
  )
}

export default ListCoupon

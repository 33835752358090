/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Table, Tooltip, Whisper } from 'rsuite';
import ConfirmationModal from '../../components/ConfirmationModal';
import ModalComponent from '../../components/Modal';
import { MoreMenu } from '../../components/MoreMenu';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import driverService from '../../services/driver.service';
import ModalDriver from './ModalDriver';
import ModalUpdateDriver from './ModalUpdateDriver';
import { TableHeaderDriver } from './tableConfig';
import { _driverSpeaker } from './_speakers';
const Drivers = (props: any) => {
  const [state, setState] = useState<any>({
    modal: {
      driver: false,
      update_driver: false,
    },
    confirmation_delete_modal: false,
    driver: {},
    drivers: [],
    eds: {},
    operator: {},
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setState({ ...state, drivers: props.drivers });
  }, [props.drivers]);

  const Actions = (props: any) => {
    const { payload } = props;
    const _speaker: any = _driverSpeaker(payload, _handleActions);
    return (
      <button className="me-3 btn">
        <Whisper controlId={payload.PK} placement="auto" trigger="click" speaker={(whisper_payload: any, ref: any) => MoreMenu(whisper_payload, ref, _speaker)}>
          <i className="bi bi-three-dots"></i>
        </Whisper>
      </button>
    );
  };

  const _handleActions = (payload: any) => {
    switch (payload.id) {
      case 1:
        setState({
          ...state,
          driver: payload.data,
          modal: { ...state.modal, update_driver: true },
        });
        break;
      case 2:
        setState({
          ...state,
          confirmation_delete_modal: true,
          operator: payload.data,
        });
        break;
      default:
        setState({
          ...state,
          driver: payload.data,
          modal: { ...state.modal, update_driver: true },
        });
    }
  };

  const _toggleModal = () => {
    setState({
      ...state,
      modal: { ...state.modal, driver: !state.modal.driver },
    });
  };

  const _handleGetDetails = () => {
    setState({
      ...state,
      modal: {
        driver: false,
        update_driver: false,
      },
    });
    props._getDetailsEds();
  };

  const _handleDeleteOperator = async () => {
    try {
      dispatch(LOADING_ON());
      await driverService.delete({ PK: state.operator.PK, SK: state.operator.SK });
      await driverService.deleteUser({ PK: state.operator.user_PK, SK: state.operator.user_SK });
      toast.success('Operador eliminado con éxito');
      setState({
        ...state,
        confirmation_delete_modal: false,
      });
      props._getDetailsEds();
    } catch (e: any) {
      console.log('LOGGER', e.message);
      toast.error('No fue posible eliminar este operador');
      dispatch(LOADING_OFF());
    }
  };

  return (
    <div className="row" style={{ minHeight: '70vh' }}>
      <ModalComponent open={state.modal.driver} noFull hideFooter handleClose={_toggleModal} size="lg">
        <ModalDriver handleClose={_handleGetDetails} />
      </ModalComponent>

      <ModalComponent
        open={state.modal.update_driver}
        noFull
        hideFooter
        handleClose={() =>
          setState({
            ...state,
            modal: { ...state.modal, update_driver: false },
          })
        }
        size="lg"
      >
        <ModalUpdateDriver handleClose={_handleGetDetails} data={state.driver} />
      </ModalComponent>

      <ConfirmationModal
        open={state.confirmation_delete_modal}
        size="md"
        type="warning"
        title="Eliminar conductor"
        handleClose={() => setState({ ...state, confirmation_delete_modal: false })}
        handleConfirm={_handleDeleteOperator}
      >
        ¿Seguro que quieres eliminar este conductor?
      </ConfirmationModal>

      <div className="col-12 text-end mb-3 text-white">
        <button className="btn-default" onClick={_toggleModal}>
          {' '}
          + Nuevo Conductor
        </button>
      </div>

      <div className="col-12 h-100">
        <Table data={state.drivers} rowClassName="striped" rowHeight={70} autoHeight locale={{ emptyMessage: 'No hay conductores' }}>
          {TableHeaderDriver &&
            TableHeaderDriver.map((column: any, index: any) => (
              <Table.Column align={column.align} flexGrow={column.flexGrow} key={`table-column-${index}`}>
                <Table.HeaderCell>
                  <span style={{ textTransform: 'capitalize' }}>{column.label}</span>
                </Table.HeaderCell>
                <Table.Cell
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: column.alignFlex,
                  }}
                >
                  {(rowData) => {
                    switch (column.key) {
                      case 'name':
                        return (
                          <Whisper trigger="hover" placement="auto" controlId={`control-id-auto`} speaker={<Tooltip>{`${rowData.name} ${rowData.family_name}`}</Tooltip>}>
                            <div
                              style={{
                                textTransform: 'capitalize',
                                width: '100%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              <span>{`${rowData.name || '---'} ${rowData.family_name || '---'}`}</span>
                            </div>
                          </Whisper>
                        );
                      case 'created_at':
                        return (
                          <Whisper trigger="hover" placement="auto" controlId={`control-id-auto`} speaker={<Tooltip>{moment(rowData.created_at).format('DD-MM-Y')}</Tooltip>}>
                            <div
                              style={{
                                textTransform: 'capitalize',
                                width: '100%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              <span>{moment(rowData.created_at).format('DD-MM-Y')}</span>
                            </div>
                          </Whisper>
                        );
                      default:
                        return (
                          <Whisper trigger="hover" placement="auto" controlId={`control-id-auto`} speaker={<Tooltip>{rowData[column.key]}</Tooltip>}>
                            <div
                              style={{
                                width: '100%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              <span>{rowData[column.key] || '---'}</span>
                            </div>
                          </Whisper>
                        );
                    }
                  }}
                </Table.Cell>
              </Table.Column>
            ))}

          <Table.Column align="right" flexGrow={1} verticalAlign="middle">
            <Table.HeaderCell>{''}</Table.HeaderCell>
            <Table.Cell>
              {(rowData) => {
                return <Actions payload={rowData} />;
              }}
            </Table.Cell>
          </Table.Column>
        </Table>
      </div>
    </div>
  );
};

export default Drivers;

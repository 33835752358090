/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Toggle } from 'rsuite';
import ErrorValidation from '../../components/ErrorValidation';
import validate from '../../helpers/validate';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import discountService from '../../services/discount.service';
import { CrossSellingSchema } from '../../validations/discounts.validations';

const CrossSelling = () => {
  const dispatch: any = useDispatch();

  const [inputs, setInputs] = useState<any>({
    percentage: '',
    description: '',
    is_available: 0,
    info: {},
  });

  const [validations, setValidations] = useState<any>({});

  useEffect(() => {
    _getDiscountData();
  }, []);

  const _getDiscountData = async () => {
    dispatch(LOADING_ON());
    try {
      const response: any = await discountService.handleGetCrossSelling(inputs);
      if (response?.data) {
        setInputs({ ...inputs, ...response.data });
        dispatch(LOADING_OFF());
      }
    } catch (e: any) {
      toast.error('No fue posible cargar los datos.');
      dispatch(LOADING_OFF());
    }
  };

  const _handleOnChange = (event: any) => {
    const { target } = event;

    if (target.name === 'percentage') {
      target.value = target.value.replace(/\D/g, '');
    }

    const { name, value, checked } = target;

    if (name === 'is_available') {
      inputs[name] = checked;
      setInputs({ ...inputs });
      return;
    }

    inputs[name] = value;
    setInputs({ ...inputs });
  };

  const _handleOnSubmit = async (event: any) => {
    event.preventDefault();
    try {
      const validations: any = await validate(CrossSellingSchema, inputs);

      if (validations || Object.keys(validations)?.length > 0) {
        setValidations(validations);
        return;
      }

      setValidations({});

      dispatch(LOADING_ON());
      await discountService.handleSaveCrossSelling(inputs);
      toast.success('Descuento actualizado con éxito');
      dispatch(LOADING_OFF());
    } catch (e: any) {
      toast.error('No fue posible actualizar el descuento');
    }
  };

  return (
    <div className="container-fluid">
      <form onSubmit={_handleOnSubmit}>
        <div className="row mb-3">
          <div className="col-6">
            <span className="bold size-08 me-2">Porcentaje</span>
            <div>
              <input className="input-default w-100" type="text" name="percentage" value={inputs.percentage} onChange={_handleOnChange} />
              <ErrorValidation name="percentage" validations={validations} className="text-end size-09 bold-300" />
            </div>
          </div>

          <div className="col-6">
            <span className="bold size-08 me-2">Descripcion</span>
            <div>
              <input className="input-default w-100" type="text" name="description" value={inputs.description} onChange={_handleOnChange} />
              <ErrorValidation name="description" validations={validations} className="text-end size-09 bold-300" />
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-6">
            <span className="bold size-08 me-2">Estado</span>
            <div>
              <Toggle checked={inputs.is_available} onChange={(value: any) => _handleOnChange({ target: { name: 'is_available', value: value, checked: value } })} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-end color-white">
            <button className="btn-default" type="submit">
              Guardar
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CrossSelling;

/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Table, Tooltip, Whisper } from 'rsuite';
import Title from '../../components/Title';
import ModalComponent from '../../components/Modal';
import UpdateOrder from '../../components/Orders/UpdateOrder';
import { TableHeader } from './tableConfig';
import moment from 'moment';
import { sortDateKeys } from '../../helpers/Utils';
import DetailsOrder from '../../components/Orders/DetailsOrder';
import { LOADING_ON, LOADING_OFF } from '../../redux/actions/loader';
import { toast } from 'react-toastify';
import Options from '../../services/options.service';
import Reschedule from '../../components/Orders/Reschedule';
import ordersService from '../../services/orders.service';
import Service from '../../services/service_and_products.service';
import MoreIcon from '@rsuite/icons/More';
import { MoreMenu } from '../../components/MoreMenu';
import { _actionMenuSpeaker, _mainMenuSpeaker } from './speakers';
import ParagraphIcon from '@rsuite/icons/Paragraph';
import ConfirmationModal from '../../components/ConfirmationModal';
import UpdateOrderData from '../../components/Orders/UpdateOrderData';
import { _updateToolTipClear } from '../../helpers/update_tootip_clear';
import OrderCard from './OrderCard';
import EDSDAO from '../../services/eds.service';
import CancelOrder from '../../components/CancelOrder';
import SearchOrder from '../../components/SearchOrder';
import PaginationFront from '../../components/PaginationFront';
import { paginate } from '../../helpers/paginate';

const Orders = (props: any) => {
  const [state, setState] = useState<any>({
    data: {},
    checks: {},
    inputs: {
      status: 'programmed',
      search: '',
      start: moment().subtract(5, 'days').toDate(),
      end: moment().add(10, 'days').toDate(),
      eds: 'all',
      filter_by_date: true,
    },
    eds_list: [],
    modals: {
      add_order: false,
      update_order: false,
      reschedule_order: false,
      update_personal_data_order: false,
      cancel_order: false,
    },
    confirmationModal: {
      open: false,
      text: '¿Estás seguro de que quieres anular este pedido?',
      id: undefined,
      is_valid: false,
      type: 'success',
    },
    order_edited: null,
    orders: [],
    orders_filtered: [],
    order: null,
    servicesSelect: [],
    statesSelect: [],
    order_state: [],
    selected_order: {},
    services: [],
    validations: [],
  });

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    perPage: 10,
  });
  const { loader } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    dispatch(LOADING_ON());
    try {
      const payload: any = {
        date_from: moment(state.inputs.start).format('YYYY-MM-DD'),
        date_to: moment(state.inputs.end).format('YYYY-MM-DD'),
        status: state.inputs?.status?.toUpperCase(),
        filter_by_date: state.inputs.filter_by_date,
      };
      const order_state: any = await Options.getOrderState();
      const orders_response: any = await ordersService.list(payload);
      const services_response: any = await Service.selectList();
      const eds_response: any = await EDSDAO.getAllAvailableEds(50);

      order_state.data.push({ value: 'all', label: 'Todos' });

      orders_response.data.sort((a: any, b: any) => {
        a.order_date = a.info?.reservation?.schedule_at ? moment(a.info?.reservation?.schedule_at) : moment(a.created_at).add(1, 'days');
        b.order_date = b.info?.reservation?.schedule_at ? moment(b.info?.reservation?.schedule_at) : moment(b.created_at).add(1, 'days');
        if (a.order_date.isAfter(b.order_date)) return 1;
        if (b.order_date.isAfter(a.order_date)) return -1;
        return 0;
      });

      orders_response.data = orders_response.data.filter((order: any) => order.info?.type_order !== 'subscription');

      const paginated: any = paginate(orders_response.data, orders_response.data?.length, pagination.currentPage, pagination.perPage);

      const [data] = getReducedOrders({ data: paginated.items });

      sortDateKeys(data);

      setPagination({ ...pagination, ...paginated });

      setState({
        ...state,
        services: services_response.data,
        order_state: order_state.data,
        data,
        eds_list: eds_response.data,
        orders: orders_response.data,
        orders_filtered: [...orders_response.data],
        modals: { update_order: false, add_order: false, details: false, reschedule_order: false, update_personal_data_order: false, cancel_order: false },
        inputs: {
          ...state.inputs,
          search: '',
        },
        confirmationModal: {
          open: false,
          text: '¿Estás seguro de que quieres anular este pedido?',
          id: undefined,
          is_valid: false,
          type: 'success',
        },
      });
    } catch (e: any) {
      toast.error('No fue posible cargas las ordenes');
      dispatch(LOADING_OFF());
    }
    dispatch(LOADING_OFF());
    _updateToolTipClear();
  };

  const _setPage = (page: any) => {
    const paginated: any = paginate(state.orders_filtered, state.orders_filtered?.length, page, pagination.perPage);
    const [data] = getReducedOrders({ data: paginated.items });
    sortDateKeys(data);
    setPagination({ ...pagination, ...paginated });
    setState({ ...state, data });
  };

  const _handleFilter = (payload: any) => {
    const { orders } = state;
    const filtered: any = orders.filter(
      (item: any) =>
        item.PK?.indexOf(payload) !== -1 ||
        item.user?.email?.toLowerCase()?.indexOf(payload?.toLowerCase()) !== -1 ||
        item.user?.name?.toLowerCase()?.indexOf(payload?.toLowerCase()) !== -1
    );
    const paginated: any = paginate(filtered, filtered.length, pagination.currentPage, pagination.perPage);
    const [data] = getReducedOrders({ data: paginated.items });
    sortDateKeys(data);
    setPagination({ ...pagination, ...paginated });
    setState({ ...state, orders_filtered: [...filtered], data });
  };

  const getDataWithComplementValues = (orders_response: any) => {
    const dataWithComplementValues: any = orders_response.data.map((item: any) => {
      if (item.complement) {
        let total = item.amount + item.amount_shipping;
        total += item.complement.products.reduce((acc: any, current: any) => {
          acc += current.quantity_to_buy * current.amount;
          return acc;
        }, 0);

        item.total = total;
      } else {
        item.total = item.amount + item.amount_shipping;
      }

      const [, order_status] = item.GS1PK?.split('-');
      item.status = order_status?.toLowerCase();
      item.order_date = item.info?.reservation?.schedule_at ? moment(item.info?.reservation?.schedule_at) : moment(item.created_at).add(1, 'days');
      return item;
    });

    dataWithComplementValues.sort((a: any, b: any) => {
      if (a.order_date.isAfter(b.order_date)) return 1;
      if (b.order_date.isAfter(a.order_date)) return -1;
      return 0;
    });

    return dataWithComplementValues;
  };

  const getReducedOrders = (orders_response: any) => {
    const dataWithComplementValues = getDataWithComplementValues(orders_response);

    const orders: any = [...dataWithComplementValues];

    const data: any = _reduceOrder(orders);

    return [data, orders];
  };

  const handleSelectChange = (payload: any) => {
    switch (payload.option) {
      case 1:
        _toggleUpdateOrderModal(payload.id);
        break;
      case 2:
        _toggleDetailsOrderModal(payload.id);
        break;
      case 3:
        const updateOrder: any = state.orders.find((item: any) => item.PK === payload.id);
        setState({ ...state, selected_order: updateOrder, modals: { ...state.modals, update_personal_data_order: true } });
        break;
      case 4:
        const order: any = state.orders.find((item: any) => item.PK === payload.id);
        setState({ ...state, selected_order: order, modals: { ...state.modals, reschedule_order: true } });
        break;
      case 5:
        const findedOrder: any = state.orders.find((order: any) => order.PK === payload.data?.PK && order.SK === payload.data?.SK);
        if (findedOrder) {
          setState({ ...state, modals: { ...state.modals, cancel_order: true }, selected_order: findedOrder });
        } else {
          toast.error('No fue posible recuperar la data de esta orden');
        }
        break;
      default:
        console.log(payload);
    }
  };

  const _toggleUpdateOrderModal = (edit_id = null) => {
    if (edit_id) {
      const order = state.orders.find((order: any) => order.PK === edit_id);
      setState({ ...state, selected_order: order, modals: { ...state.modals, update_order: !state.modals.update_order } });
    } else {
      setState({ ...state, modals: { ...state.modals, update_order: !state.modals.update_order } });
    }
  };

  const _toggleUpdatePersonalDataOrderModal = (edit_id = null) => {
    if (edit_id) {
      const order = state.orders.find((order: any) => order.PK === edit_id);
      setState({ ...state, selected_order: order, modals: { ...state.modals, update_personal_data_order: !state.modals.update_personal_data_order } });
    } else {
      setState({ ...state, modals: { ...state.modals, update_personal_data_order: !state.modals.update_personal_data_order } });
    }
  };

  const _toggleDetailsOrderModal = (id = null) => {
    if (id) {
      const order: any = state.orders.find((item: any) => item.PK === id);
      setState({ ...state, modals: { ...state.modals, details: !state.modals.details }, order_details: id, order });
    } else {
      setState({ ...state, modals: { ...state.modals, details: !state.modals.details }, order_details: id, order: null });
    }
  };

  const _closeRescheduleModal = () => setState({ ...state, modals: { ...state.modals, reschedule_order: false } });

  const _reduceOrder = (data: any) => {
    return data.reduce((acc: any, current: any) => {
      let date: any = null;
      if (current.info?.reservation?.schedule_at) {
        date = moment(current.info?.reservation?.schedule_at);
        current.schedule_model = true;
        current.delivery_date = date.toString();
      } else {
        date = moment(current.created_at).add(1, 'days');
        current.schedule_model = false;
        current.delivery_date = date.toString();
      }
      if (acc[date.format('Y/MM/DD')]) {
        current.direction = current.info.direction._string;
        acc[date.format('Y/MM/DD')].push(current);
      } else {
        current.direction = current.info.direction._string;
        acc[date.format('Y/MM/DD')] = [current];
      }
      return acc;
    }, {});
  };

  const _handleConfirmationModal = async (eventKey: any) => {
    switch (eventKey) {
      case 1:
        try {
          if (state.confirmationModal.is_valid) {
            dispatch(LOADING_ON());
            await ordersService.changeStatus({
              PK: state.confirmationModal?.order?.PK,
              SK: state.confirmationModal?.order?.SK,
              order_id: state.confirmationModal.id,
              status: 'canceled',
            });
            getData();
            toast.success('Orden anulada con éxito.');
          } else {
            setState({ ...state, confirmationModal: { ...state.confirmationModal, open: false } });
          }
        } catch (e: any) {
          toast.error('No fue posible anular esta orden');
          dispatch(LOADING_OFF());
        }
        break;
      default:
        setState({ ...state, confirmationModal: { ...state.confirmationModal, open: false } });
    }
  };

  const getReportUrl = () => {
    return `${process.env.REACT_APP_REPORTE_S3_URL}/${process.env.REACT_APP_REPORTE_PEDIDOS_CSV_FILENAME}`;
  };

  const Actions = (props: any) => {
    const { payload } = props;
    const _speaker: any = _actionMenuSpeaker(payload, handleSelectChange);
    const [open, setOpen] = useState(false);
    return (
      <div className="row me-3">
        <div className="col-12">
          <Whisper speaker={<Tooltip>Detalles</Tooltip>}>
            <button className="btn px-0" onClick={() => handleSelectChange({ option: 2, id: payload.PK })}>
              <ParagraphIcon />
            </button>
          </Whisper>
          <Divider vertical />
          <Whisper speaker={<Tooltip>Acciones</Tooltip>} open={open} onMouseOut={() => setOpen(false)} onMouseOver={() => setOpen(true)}>
            <span style={{ cursor: 'pointer' }}>
              <Whisper
                controlId={payload.PK}
                onMouseOver={() => setOpen(true)}
                placement="auto"
                trigger="click"
                speaker={(whisper_payload: any, ref: any) => MoreMenu(whisper_payload, ref, _speaker)}
              >
                <i className="bi bi-three-dots"></i>
              </Whisper>
            </span>
          </Whisper>
        </div>
      </div>
    );
  };

  const _mainSpeaker = () => {
    const _speaker = _mainMenuSpeaker();
    return _speaker;
  };

  const _handleOnChangeInputs = (payload: any) => {
    const { target } = payload;
    const value: any = target.name === 'filter_by_date' ? target.checked : target.value;
    setState({ ...state, validations: [], inputs: { ...state.inputs, [target.name]: value } });
  };

  const _handleSearch = async () => {
    const { inputs } = state;
    const { start, end, status, filter_by_date } = inputs;

    const validations: any = [];

    setState({ ...state, validations });

    const startDate = moment(start);
    const endDate = moment(end).subtract(1, 'hours');

    const diffDays: any = endDate.diff(startDate, 'days');

    if (filter_by_date) {
      if (diffDays < 0) {
        validations.push('La fecha de inicio debe ser posterior a la fecha de termino de la consulta.');
      }

      if (diffDays > 29) {
        validations.push('La diferencia entre la fecha de inicio y la fecha final debe ser de un máximo de 30 días.');
      }
    }

    if (validations.length > 0) {
      setState({ ...state, validations });
      return;
    }

    const payload: any = {
      date_from: startDate.format('YYYY-MM-DD'),
      date_to: endDate.format('YYYY-MM-DD'),
      status: status?.toUpperCase(),
    };

    if (filter_by_date) payload.filter_by_date = true;

    let orders_response: any = null;
    try {
      dispatch(LOADING_ON());
      if (payload.status === 'ALL') {
        let order_data: any = [];
        for (const status of state.order_state) {
          if (status.value !== 'all' && status.value !== 'rescheduled') {
            const ordersList = await ordersService.list({ ...payload, status: status.value?.toUpperCase() });
            order_data = [...order_data, ...ordersList.data];
          }
        }
        orders_response = { data: order_data }
      }

      if (payload.status !== 'ALL') {
        orders_response = await ordersService.list(payload);
      }
    } catch (e: any) {
      dispatch(LOADING_OFF());
      toast.error('No fue posible cargar la data.');
      return;
    }

    orders_response.data?.sort((a: any, b: any) => {
      a.order_date = a.info?.reservation?.schedule_at ? moment(a.info?.reservation?.schedule_at) : moment(a.created_at).add(1, 'days');
      b.order_date = b.info?.reservation?.schedule_at ? moment(b.info?.reservation?.schedule_at) : moment(b.created_at).add(1, 'days');
      if (a.order_date.isAfter(b.order_date)) return 1;
      if (b.order_date.isAfter(a.order_date)) return -1;
      return 0;
    });

    const paginated: any = paginate(orders_response.data, orders_response.data?.length, pagination.currentPage, pagination.perPage);

    const [data] = getReducedOrders({ data: paginated.items });

    sortDateKeys(data);

    setPagination({ ...pagination, ...paginated });

    dispatch(LOADING_OFF());

    setState({
      ...state,
      data,
      orders: orders_response.data,
      orders_filtered: [...orders_response.data],
      modals: { update_order: false, add_order: false, details: false, reschedule_order: false, update_personal_data_order: false, cancel_order: false },
      inputs: {
        ...state.inputs,
        search: '',
      },
      confirmationModal: {
        open: false,
        text: '¿Estás seguro de que quieres anular este pedido?',
        id: undefined,
        is_valid: false,
        type: 'success',
      },
      validations: [],
    });
  };

  return (
    <div className="container-fluid">
      <ModalComponent open={state.modals.update_order} noFull size="md" handleClose={_toggleUpdateOrderModal} hideFooter>
        <UpdateOrder data={state.selected_order} handleClose={_toggleUpdateOrderModal} handleCloseAndUpdate={getData} />
      </ModalComponent>

      <ModalComponent open={state.modals.update_personal_data_order} noFull size="lg" hideFooter handleClose={_toggleUpdatePersonalDataOrderModal}>
        <UpdateOrderData data={state.selected_order} handleCloseAndUpdate={getData} handleClose={_toggleUpdatePersonalDataOrderModal} />
      </ModalComponent>

      <ModalComponent open={state.modals.details} handleClose={_toggleDetailsOrderModal} handleConfirm={_toggleDetailsOrderModal} hideCancel btnConfirmText="CERRAR">
        <DetailsOrder id={state.order_details} data={state.order || {}} status={state.order_state} />
      </ModalComponent>

      <ModalComponent open={state.modals.reschedule_order} size="md" noFull hideFooter handleClose={_closeRescheduleModal}>
        <Reschedule order={state.selected_order} handleSuccess={getData} />
      </ModalComponent>

      <ModalComponent open={state.modals.cancel_order} size="md" noFull hideFooter handleClose={() => setState({ ...state, modals: { ...state.modals, cancel_order: false } })}>
        <CancelOrder order={state.selected_order} handleClose={() => setState({ ...state, modals: { ...state.modals, cancel_order: false } })} handleCloseAndUpdate={getData} />
      </ModalComponent>

      <ConfirmationModal
        open={state.confirmationModal.open}
        size="md"
        type={state.confirmationModal.type}
        title="Anular"
        handleConfirm={() => _handleConfirmationModal(1)}
        handleClose={() => _handleConfirmationModal(2)}
      >
        {state.confirmationModal.text}
      </ConfirmationModal>

      <div className="row mt-5">
        <div className="col-8">
          <Title size="sm" text={`Pedidos`} className="to-upper bold" />
        </div>
        <div className="col-4 text-end">
          <Whisper placement="auto" trigger="click" speaker={(whisper_payload: any, ref: any) => MoreMenu(whisper_payload, ref, _mainSpeaker())}>
            <button className="btn px-3 py-1 border rounded-10">
              <MoreIcon className="size-15" />
            </button>
          </Whisper>
        </div>
      </div>

      <SearchOrder
        statusList={state.order_state}
        data={state.inputs}
        results={state.orders}
        handleFilter={_handleFilter}
        handleOnChange={_handleOnChangeInputs}
        handleSearch={_handleSearch}
        validations={state.validations}
        ordersData={state.orders}
        ordersFiltered={state.orders_filtered}
        eds={state.eds_list}
        services={state.services}
        reportUrl={getReportUrl}
        hideEds
      />

      {/* DESKTOP TABLE */}
      <div className="row border rounded-10 p-3 my-5 d-none d-sm-flex">
        {(!state.data || Object.keys(state.data).length === 0) && !loader.loading && <Title text="No hay pedidos!" className="text-center size-11 bold-300" />}
        {state.data &&
          Object.keys(state.data).map((key: any) => {
            return (
              <div key={key}>
                <div className="col-12 bold mt-3 size-13">Fecha {key}</div>
                <div className="col-12 mt-4">
                  <Table data={state.data[key]} rowClassName="striped" autoHeight rowHeight={80}>
                    {TableHeader &&
                      TableHeader.map((column: any, index: any) => (
                        <Table.Column align={column.align} flexGrow={column.flexGrow} key={`table-column-${index}`}>
                          <Table.HeaderCell>
                            <span className="bold" style={{ textTransform: 'capitalize' }}>
                              {column.label}
                            </span>
                          </Table.HeaderCell>
                          <Table.Cell style={{ display: 'flex', alignItems: 'center', justifyContent: column.alignFlex }}>
                            {(rowData) => {
                              switch (column.key) {
                                case 'GS4PK':
                                  return (
                                    <Whisper
                                      trigger="hover"
                                      placement="auto"
                                      controlId={`control-id-auto`}
                                      speaker={<Tooltip>{state.services.find((service: any) => service.value === rowData[column.key])?.label}</Tooltip>}
                                    >
                                      <div style={{ textTransform: 'capitalize', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        {state.services.find((service: any) => service.value === rowData[column.key])?.label}
                                      </div>
                                    </Whisper>
                                  );
                                case 'name':
                                  return (
                                    <Whisper trigger="hover" placement="auto" controlId={`control-id-auto`} speaker={<Tooltip>{rowData.user && rowData.user[column.key]}</Tooltip>}>
                                      <div style={{ textTransform: 'capitalize', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        {rowData.user && rowData.user[column.key].toLowerCase()}
                                      </div>
                                    </Whisper>
                                  );
                                case 'email':
                                  return (
                                    <Whisper trigger="hover" placement="auto" controlId={`control-id-auto`} speaker={<Tooltip>{rowData.user && rowData.user[column.key]}</Tooltip>}>
                                      <div style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{rowData.user && rowData.user[column.key]}</div>
                                    </Whisper>
                                  );
                                case 'GS3PK':
                                  return (
                                    <Whisper
                                      trigger="hover"
                                      placement="auto"
                                      controlId={`control-id-auto`}
                                      speaker={<Tooltip>{state.eds_list.find((status: any) => status.PK === rowData[column.key])?.code}</Tooltip>}
                                    >
                                      <div style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        {state.eds_list.find((status: any) => status.PK === rowData[column.key])?.code}
                                      </div>
                                    </Whisper>
                                  );
                                case 'total':
                                  return <span className="size-09">$ {rowData[column.key]?.toLocaleString('pt-BR')}</span>;
                                default:
                                  return (
                                    <Whisper trigger="hover" placement="auto" controlId={`control-id-auto`} speaker={<Tooltip>{rowData[column.key]}</Tooltip>}>
                                      <div style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{rowData[column.key]}</div>
                                    </Whisper>
                                  );
                              }
                            }}
                          </Table.Cell>
                        </Table.Column>
                      ))}

                    <Table.Column align="right" flexGrow={1} verticalAlign="middle">
                      <Table.HeaderCell>{''}</Table.HeaderCell>
                      <Table.Cell>
                        {(rowData) => {
                          return <Actions payload={rowData} />;
                        }}
                      </Table.Cell>
                    </Table.Column>
                  </Table>
                </div>
              </div>
            );
          })}
      </div>

      {/* MOBILE CARDS */}
      <div className="row d-sm-none">
        {(!state.data || Object.keys(state.data).length === 0) && !loader.loading && <Title text="No hay pedidos!" className="text-center size-11 bold-300 my-3" />}

        {state.data &&
          Object.keys(state.data).map((key: any) => {
            return (
              <>
                <div className="col-12 my-3 bold-300 border-bottom">Fecha: {key}</div>
                {state.data[key].map((item: any) => (
                  <OrderCard
                    data={item}
                    services={state.services}
                    status={state.order_state}
                    _actionMenuSpeaker={_actionMenuSpeaker}
                    MoreMenu={MoreMenu}
                    handleCloseAndUpdate={getData}
                  />
                ))}
              </>
            );
          })}
      </div>

      {!loader.loading && (
        <div className="col-12 mb-5 text-center">
          <PaginationFront {...pagination} setPage={_setPage} />
        </div>
      )}
    </div>
  );
};

export default Orders;

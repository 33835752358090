export const _sortItems = (data: any, column: any, type: any) => {
    if (type === '' || type === 'asc') {
        data.sort((a: any, b: any) => {
            if (a[column] > b[column]) return 1;
            if (a[column] < b[column]) return -1;
            return 0;
        })
        return { data, sort: { column, type: 'asc' } }
    }

    if (type === 'desc') {
        data.sort((a: any, b: any) => {
            if (a[column] < b[column]) return 1;
            if (a[column] > b[column]) return -1;
            return 0;
        })
        return { data, sort: { column, type: 'desc' } }
    }
}
/* eslint-disable no-useless-escape */
import * as yup from 'yup';

export const EdsSchema = yup.object().shape({
  code: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .max(255, 'Este campo acepta un máximo de 255 caracteres')
    .required('Este campo es requerido'),
  name: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .max(255, 'Este campo acepta un máximo de 255 caracteres')
    .required('Este campo es requerido'),
  address: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .required('Este campo es requerido'),
  phone: yup.string().required('Este campo es requerido'),
  concessionaire_id: yup.string().nullable().required('Este campo es requerido'),
  comerce_deferred: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .required('Este campo es requerido'),
  comerce_simultaneous: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .required('Este campo es requerido'),
  code_ref: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .max(255, 'Este campo acepta un máximo de 255 caracteres')
});

export type EdsType = yup.InferType<typeof EdsSchema>;

import { handleActions } from 'redux-actions';
import { CLEAR_CONFIGS, SET_CONFIGS } from '../actions/configs';

export const INITIAL_STATE: any = {
  profile: 1,
  menu: []
}

export default handleActions({
  [SET_CONFIGS]: (state, action: any) => {
    return { ...state, ...action.payload };
  },
  [CLEAR_CONFIGS]: (state, action: any) => {
    return { ...INITIAL_STATE };
  }
}, INITIAL_STATE);
export const TableHeader = [
  {
    label: 'SKU',
    key: 'sku',
    align: 'center',
    alignFlex: 'center',
    flexGrow: 1,
  },
  {
    label: 'Imagen',
    key: 'image',
    align: 'center',
    alignFlex: 'center',
    flexGrow: 0,
  },
  {
    label: 'Nombre',
    key: 'name',
    align: 'center',
    alignFlex: 'center',
    flexGrow: 0,
  },
  {
    label: 'Descripción',
    key: 'descripcion',
    align: 'left',
    alignFlex: 'flex-start',
    flexGrow: 1,
  },
  {
    label: 'Precio',
    key: 'price',
    align: 'center',
    alignFlex: 'center',
    flexGrow: 1,
  },
  {
    label: 'Estado',
    key: 'active',
    align: 'center',
    alignFlex: 'center',
    flexGrow: 1,
  }

]


export const SelectActions = [
  {
    label: 'Editar',
    value: 1
  }
]

import './style.scss';

const Pagination = (props: any) => {
  return (
    <>
      <div className="mb-5 text-center d-flex justify-content-center">
        <button className="pagination-component__btn" onClick={() => props.prevPage()}>
          Anterior
        </button>

        <div className="pagination-component__center">{props.index}</div>

        <button className="pagination-component__btn" onClick={() => props.nextPage()}>
          Próximo
        </button>
      </div>
    </>
  );
};

export default Pagination;

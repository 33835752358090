/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { SelectPicker } from 'rsuite';
import Title from '../../components/Title';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import CarsService from '../../services/car.service';
import driverService from '../../services/driver.service';
import EDSDAO from '../../services/eds.service';
import Cars from './Cars';

const VehiclesConcessionaire = () => {
  const dispatch = useDispatch();
  const { session } = useSelector((state: any) => state);
  const [state, setState] = useState<any>({
    tabs: 1,
    checks: {},
    eds: [],
    inputs: {
      eds: null,
    },
    drivers: [],
    cars: [],
  });

  const _getEds = async () => {
    dispatch(LOADING_ON());
    try {
      const response = await EDSDAO.getAllAvailableEds(50);
      const edsList: any = response.data.filter((item: any) => item?.info?.concessionaire_id === session.profile?.info?.concessionaire_id);

      if (edsList.length === 0) return;

      const edsResponse = await EDSDAO.getDetails(edsList[0]);
      const carsResponse = await CarsService.getVehicles(edsList[0]);
      const responseListOperators = await driverService.listDrivers(edsList[0]);

      const operators: any = responseListOperators.data.map((item: any) => {
        item.user_PK = item.user?.PK;
        item.user_SK = item.user?.SK;
        delete item.user?.PK;
        delete item.user?.SK;
        return { ...item, ...item.user };
      });

      setState({
        ...state,
        eds: edsList,
        edsData: edsResponse.data,
        cars: carsResponse.data,
        inputs: { ...state.inputs, eds: edsList.length > 0 ? edsList[0].PK : null },
        operators: operators,
      });
    } catch (e: any) {
      toast.error('No fue posible recuperar los vehículos');
      console.log('LOGGER', e);
    }
    dispatch(LOADING_OFF());
  };

  useEffect(() => {
    _getEds();
  }, []);

  const _handleOnChange = async ({ target }: any) => {
    const { name, value } = target;
    state.inputs[name] = value;
    dispatch(LOADING_ON());
    if (value) {
      try {
        const eds: any = state.eds.find((item: any) => item.PK === value);
        const edsResponse = await EDSDAO.getDetails(eds);
        const carsResponse = await CarsService.getVehicles(eds);
        const responseListOperators = await driverService.listDrivers(eds);
        const operators: any = responseListOperators.data.map((item: any) => {
          item.user_PK = item.user?.PK;
          item.user_SK = item.user?.SK;
          delete item.user?.PK;
          delete item.user?.SK;
          return { ...item, ...item.user };
        });
        setState({
          ...state,
          cars: carsResponse.data,
          edsData: edsResponse.data,
          operators: operators,
        });
      } catch (e: any) {
        toast.error('No fue posible recuperar los vehículos');
        console.log('LOGGER', e);
      }
    }
    dispatch(LOADING_OFF());
  };

  const _getEdsData = async () => {
    dispatch(LOADING_ON());
    try {
      const edsResponse = await EDSDAO.getDetails({ PK: state.inputs.eds, SK: state.inputs.eds });
      const carsResponse = await CarsService.getVehicles({ PK: state.inputs.eds, SK: state.inputs.eds });
      const responseListOperators = await driverService.listDrivers({ PK: state.inputs.eds, SK: state.inputs.eds });

      const operators: any = responseListOperators.data.map((item: any) => {
        item.user_PK = item.user?.PK;
        item.user_SK = item.user?.SK;
        delete item.user?.PK;
        delete item.user?.SK;
        return { ...item, ...item.user };
      });
      setState({
        ...state,
        cars: carsResponse.data,
        edsData: edsResponse.data,
        operators: operators,
      });
    } catch (e: any) {
      console.log('LOGGER', e);
    }
    dispatch(LOADING_OFF());
  };

  return (
    <div className="container-fluid">
      <div className="row my-5 align-items-center">
        <div className="col-8">
          <Title size="sm" text={state.tabs === 1 ? 'Vehículos' : 'Vehículos'} className="to-upper bold" />
        </div>
        <div className="col-4">
          <SelectPicker
            classPrefix="copec"
            className="rs-copec-cleanable"
            value={state.inputs.eds}
            data={state.eds}
            onChange={(e: any) => _handleOnChange({ target: { name: 'eds', value: e } })}
            style={{ width: '100%' }}
            placeholder="EDS"
            locale={{ searchPlaceholder: 'Buscar', noResultsText: 'Ningún resultado encontrado.', emptyMessage: 'Ningún resultado encontrado.' }}
            cleanable={false}
            labelKey="name"
            valueKey="PK"
          />
        </div>
      </div>

      <div className="row border rounded-10 py-4 px-3">
        <Cars handleEndUpdate={_getEdsData} data={[...state.cars]} drivers={state.operators || []} eds={state.edsData} cars={state.cars} />
      </div>
    </div>
  );
};

export default VehiclesConcessionaire;

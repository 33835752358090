/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import OrderLoading from '../../../components/OrderLoading';
import CarTable from '../../../components/Orders/CarTable';
import ItemsComplement from '../../../components/Orders/ItemsComplement';
import ItemsOrder from '../../../components/Orders/ItemsOrder';
import Title from '../../../components/Title';
import { compare } from '../../../helpers/orders';

import { LOADING_OFF, LOADING_ON } from '../../../redux/actions/loader';
import ordersService from '../../../services/orders.service';
import PaymentService from '../../../services/payment.service';

const OrderDetails = (props: any) => {
  const { open, data } = props;
  const dispatch: any = useDispatch();
  const [state, setState] = useState<any>({});
  const { loader } = useSelector((state: any) => state);

  useEffect(() => {
    if (open) {
      _getDetails();
    }
  }, [open]);

  const payment_types = [
    { label: 'WebPay Crédito', value: 3 },
    { label: 'WebPay Débito', value: 2 },
    { label: 'Presencial', value: 1 },
  ];

  const AmountToCollect = () => {
    let amount: any = 0;
    const status = props.status?.find((item: any) => item.value?.toLowerCase() === props.data?.status)?.label;
    const payment = payment_types.find((item: any) => item.value === parseInt(state?.payment?.payment_type))?.label;
    if ((status === 'Programado' || status === 'Reagendado') && payment === 'Presencial') {
      amount = (state?.total - state?.amount_discount)?.toLocaleString('es-CL') ?? 0;
    }
    return <div className="col-12 text-end size-12 bold color-dark-blue mb-2">Monto a cobrar: $ {amount}</div>;
  };

  const _getDetails = async () => {
    try {
      dispatch(LOADING_ON());
      const order = data;
      const response = await ordersService.details({ PK: order.PK, SK: order.SK });
      const [payment_id, order_id] = order.PK?.split('-');
      const orderResponse = response.data;
      const [, order_status] = orderResponse.GS1PK?.split('-');
      orderResponse.status = order_status?.toLowerCase();

      let paymentResponse: any = {};
      let payment: any = {};

      try {
        paymentResponse = await PaymentService.getPayment(payment_id);
        payment = paymentResponse.data;
      } catch (e: any) {
        toast.error('No fue posible cargar los datos del pago');
      }

      orderResponse.payment_id = payment_id;
      orderResponse.order_id = order_id;

      orderResponse.delivery_date = orderResponse.reservation
        ? moment(`${orderResponse.reservation?.date} ${orderResponse.reservation?.hour}`).toString()
        : moment(orderResponse.created_at).add(1, 'days').toString();

      const { items, complement } = orderResponse;
      let items_order: any = [];
      let total: any = 0;
      let amount_discount: any = 0;
      let is_custom_order: any = false;
      let original_amount: any = items;
      let diff: any = 0;
      let responseCompare: any = {};

      if (complement?.length > 0) {
        const complementedOrder: any = complement[0];
        const itemsNewOrder: any = complementedOrder.info?.items;

        responseCompare = compare(items, itemsNewOrder);

        is_custom_order = true;
        diff = complementedOrder.amount_diff;
      } else {
        items.forEach((item: any) => {
          const name: any = item.info?.name?.toLowerCase() === 'envío' ? 'Despacho' : item.info?.name;
          items_order.push({
            name: name,
            price: item.info?.unit_price,
            quantity_to_buy: item.info?.quantity_to_buy,
            canceled: false,
            item_id: item.PK,
            new_item_id: item.PK,
            amount_discount: item.info?.amount_discount || 0,
          });
        });
      }

      if (is_custom_order) {
        total = responseCompare.total;
      } else {
        total = items_order.reduce((acc: any, current: any) => {
          if (!current.canceled) {
            acc += current.price * current.quantity_to_buy;
          }
          return acc;
        }, 0);
      }

      amount_discount = orderResponse?.amount_off;

      original_amount = items.reduce((acc: any, current: any) => {
        acc += current.info?.unit_price * current.info?.quantity_to_buy;
        return acc;
      }, 0);

      const shippingItem: any = items_order.find((item: any) => item.name?.toLowerCase() === 'despacho');
      const NotshippingItem: any = items_order.filter((item: any) => item.name?.toLowerCase() !== 'despacho');
      if (shippingItem) {
        NotshippingItem.push(shippingItem);
      }

      NotshippingItem.sort((a: any, b: any) => {
        if (a.canceled > b.canceled) return 1;
        if (a.canceled < b.canceled) return -1;
        return 0;
      });

      dispatch(LOADING_OFF());
      setState({
        ...state,
        ...orderResponse,
        order: orderResponse,
        total,
        amount_discount,
        items_order: NotshippingItem,
        diff,
        is_custom_order,
        original_amount,
        complementOrders: responseCompare,
        payment: payment,
      });
    } catch (e: any) {
      dispatch(LOADING_OFF());
      toast.error('No fue posible cargar los datos de esta orden');
    }
  };

  return (
    <div className="component-services px-1 mb-4">
      {loader && loader.loading && <OrderLoading />}
      {loader && !loader.loading && (
        <>
          <div className="row px-2 mb-3 size-09">
            <Title size="sm" text={`Pedido #${props.data?.PK}`} className="bold to-upper size-13" />
          </div>

          <div className="row mb-2 align-items-center">
            <div className="col-12 bold">Nombre</div>
            <div className="col-12 capitalize">{props.data?.user?.name?.toLowerCase()}</div>
          </div>

          <div className="row mb-2 align-items-center">
            <div className="col-12 bold">Dirección</div>
            <div className="col-12">
              <a href={`https://waze.com/ul?ll=${props.data?.info?.direction?.latitude},${props.data?.info?.direction?.longitude}&z=10&navigate=yes`} target="_blank" rel='noreferrer'>
                {props.data?.info?.direction?._string}
              </a>
            </div>
          </div>

          {state.order?.user?.house_type === 1 && state?.order?.info?.direction?.apartment && (
            <div className="row mb-2 align-items-center">
              <div className="col-12 bold">Casa/Departamento</div>
              <div className="col-12 capitalize">{state?.order?.info?.direction?.apartment}</div>
            </div>
          )}

          <div className="row mb-2 align-items-center">
            <div className="col-12 bold">Número Orden</div>
            <div className="col-12">{props.data?.PK}</div>
          </div>

          <div className="row mb-2 align-items-center">
            <div className="col-6 bold">Teléfono</div>
            <div className="col-6 bold">Estado</div>
            <div className="col-6">
              <a href={`tel:${props.data?.user?.phone_number_formated ? props.data?.user?.phone_number_plain : props.data?.user?.phone_plain}`}>
                {props.data?.user?.phone_number_formated || props.data?.user?.phone_formated || ''}
              </a>
            </div>
            <div className="col-6">{props.status?.find((item: any) => item.value?.toLowerCase() === props.data?.status)?.label}</div>
          </div>

          {state.order?.info?.cancel_reason ? (
            <div className="row mb-2 align-items-center">
              <div className="col-12 bold">Motivo</div>
              <div className="col-12">{state.order?.info?.cancel_reason}</div>
            </div>
          ) : null}

          <div className="row mb-2 align-items-center">
            <div className="col-6 bold">Número Pago</div>
            <div className="col-6 bold">Fecha Pedido</div>
            <div className="col-6">{state?.order?.payment_id}</div>
            <div className="col-6">{moment(state?.created_at).format('DD/MM/Y')}</div>
          </div>

          <div className="row mb-2">
            <div className="col-6 bold">Fecha Entrega</div>
            <div className="col-6 bold">Tipo Pago</div>
            <div className="col-6">{moment(state?.delivery_date).format('DD/MM/Y HH:mm:ss')}</div>
            {state?.payment && <div className="col-6">{payment_types.find((item: any) => item.value === parseInt(state?.payment?.payment_type))?.label}</div>}
          </div>

          <div className="row mb-2">
            {state.order?.user?.house_type && state.order?.user?.house_type === 1 ? (
              <>
                <div className="col-12 bold">Tipo de vivienda</div>
                <div className="col-12">Casa</div>
              </>
            ) : null}

            {state.order?.user?.house_type && state.order?.user?.house_type === 2 ? (
              <>
                <div className="col-12 bold">Tipo de vivienda</div>
                <div className="col-12">Edificio, {state.order?.user?.parking}</div>
              </>
            ) : null}

            {state.order?.user?.house_type && state.order?.user?.house_type === 3 ? (
              <>
                <div className="col-12 bold">Tipo de vivienda</div>
                <div className="col-12">Otro</div>
              </>
            ) : null}
          </div>

          {state.order?.info?.direction?.observations && (
            <div className="row mb-2">
              <div className="col-12 bold">Observaciones</div>
              <div className="col-12">{state?.order?.info?.direction?.observations}</div>
            </div>
          )}

          {state.order?.order_accumulated_points && (
            <div className="row mb-2">
              <div className="col-12 bold">Puntos Full</div>
              <div className="col-12">{state.order?.order_accumulated_points || 0}</div>
            </div>
          )}

          <hr />

          {state?.is_custom_order ? (
            <div className="m-0 p-0">
              <ItemsComplement data={state?.complementOrders} />
            </div>
          ) : (
            <div className="m-0 p-0">
              <ItemsOrder data={state?.items_order} />
            </div>
          )}

          {props.data?.info?.vehicle && (
            <>
              <hr />
              <CarTable
                data={[
                  {
                    plate: props.data?.info?.vehicle?.plate.toUpperCase(),
                    brand: props.data?.info?.vehicle?.brand.label,
                    model: props.data?.info?.vehicle?.model.label,
                    year: props.data?.info?.vehicle?.year,
                  },
                ]}
              />
            </>
          )}

          <div className="row mb-2 mt-3 text-white">
            {state?.amount_discount && state?.amount_discount > 0 ? (
              <>
                <div className="col-12 text-end size-12 bold color-dark-blue mb-2">Subtotal: $ {state?.total?.toLocaleString('es-CL')}</div>
                <div className="col-12 text-end size-12 bold color-dark-blue mb-2">Descuento: $ {state?.amount_discount?.toLocaleString('es-CL')}</div>
              </>
            ) : (
              <div className="col-12 text-end size-12 bold color-dark-blue mb-2">Total: $ {state?.total?.toLocaleString('es-CL')}</div>
            )}
            {state?.is_custom_order ? (
              <>
                {state?.payment?.payment_type === 1 ? (
                  <div className="col-12 text-end size-12 bold color-dark-blue">
                    <div className="col-12 text-end size-12 bold color-dark-blue mb-2">Monto a cobrar: $ {(state?.total - state?.amount_discount)?.toLocaleString('es-CL')}</div>
                  </div>
                ) : (
                  <div className="col-12 text-end size-12 bold color-dark-blue">
                    {Math.sign(state?.diff) === -1 ? 'Monto a cobrar' : 'Monto a reembolsar (Call center)'}: $ {state.diff < 0 ? (state.diff * -1)?.toLocaleString('es-CL') :state.diff?.toLocaleString('es-CL')}
                  </div>
                )}
              </>
            ) : (
              <AmountToCollect />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default OrderDetails;

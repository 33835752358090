/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { SelectPicker, TagPicker } from 'rsuite';
import { useDispatch, useSelector } from 'react-redux';
import concessionaireService from '../../../services/concessionaire.service';
import { LOADING_OFF, LOADING_ON } from '../../../redux/actions/loader';
import { toast } from 'react-toastify';
import Service from '../../../services/service_and_products.service';
import { payment_types } from '../../../helpers/payment_types';
import ErrorValidation from '../../ErrorValidation';
import ReactInputMask from 'react-input-mask';

const ModalEditEds = (props: any) => {
  const { handleClose, handleConfirm, validations } = props;

  const { selected_eds } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const [state, setState] = useState<any>({
    inputs: {
      code: '',
      name: '',
      address: '',
      phone: '',
      comerce_deferred: '',
      comerce_simultaneous: '',
      concessionaire_id: '',
      services_id: [],
      payment_types: [],
      code_ref: ''
    },
    serviceData: [],
    concessionaires: [],
  });

  const setInputs = ({ target }: any) => {
    const { name, value }: any = target;
    state.inputs[name] = value;
    setState({ ...state });
  };

  useEffect(() => {
    _loadData();
  }, []);

  const _loadData = async () => {
    dispatch(LOADING_ON());
    try {
      const response = await concessionaireService.listAll(50);
      let responseService = null;
      let next_page = null;
      let nextPageUri = null;

      while (true) {
        try {
          nextPageUri = null;

          if (next_page && "LastEvaluatedKey" in next_page) {
            const lastKey = next_page.LastEvaluatedKey; // the key for the current page

            nextPageUri = encodeURIComponent(JSON.stringify(lastKey));
          }

          const getService: any = await Service.list(nextPageUri ? { last: nextPageUri } : null);

          if (!responseService) {
            responseService = getService;
          } else {
            responseService.data = [...responseService.data, ...getService.data];
          }
          next_page = getService.next_page;

          /** BREAK LOOP IF DONT EXISTS MORE RESULTS */
          if (!getService.next_page) break

        } catch (e) {
          toast.error('No fue posible cargar los servicios');
          dispatch(LOADING_OFF());
          break;
        }
      }


      const services = responseService.data;
      const serviceData: any = [];

      services.forEach((s: any) => {
        if (s.type !== 'recommended_subservice') {
          serviceData.push({
            label: `${s.name}`,
            value: s.PK,
          });
        }
      });

      const availableServiceIds = selected_eds.info?.services_id?.filter((s: any) => serviceData.find((sd: any) => sd.value === s));

      state.inputs = {
        ...selected_eds,
        code: selected_eds.code === 'null' ? '' : selected_eds.code,
        address: selected_eds.info.address,
        phone: selected_eds.info.phone,
        comerce_deferred: selected_eds.info.comerce_deferred,
        comerce_simultaneous: selected_eds.info.comerce_simultaneous,
        concessionaire_id: selected_eds.info.concessionaire_id,
        services_id: availableServiceIds || [],
        payment_types: selected_eds?.info?.payment_types || [],
        code_ref: selected_eds?.info?.code_ref || ''
      };

      const concessionaire_list: any = response.data?.map((item: any) => {
        return { label: item.name, value: item.PK };
      });

      setState({ ...state, serviceData, concessionaires: concessionaire_list });
      dispatch(LOADING_OFF());
    } catch (e: any) {
      console.log('ERROR LOG', e.message);
      toast.error('No fue posible comunicar con el servidor');
      dispatch(LOADING_OFF());
    }
  };

  return (
    <div className="component-services px-5">
      <div className="row mb-3">
        <div className="col-6">
          <span className=" size-08 bold me-2">Código</span>
          <div className="">
            <input value={state.inputs.code} name="code" onChange={setInputs} className="input-default w-100" type="text" placeholder="123456789" />
            <ErrorValidation name="code" validations={validations} className="text-end size-09 bold-300" />
          </div>
        </div>

        <div className="col-6">
          <span className=" size-08 bold me-2">Nombre</span>
          <div className="">
            <input value={state.inputs.name} name="name" onChange={setInputs} className="input-default w-100" type="text" placeholder="Peñalólen" />
            <ErrorValidation name="name" validations={validations} className="text-end size-09 bold-300" />
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-6">
          <span className=" size-08 bold me-2">Dirección</span>
          <div className="">
            <input value={state.inputs.address} name="address" onChange={setInputs} className="input-default w-100" type="text" placeholder="Adolfo Orma, Las Condes, RM" />
            <ErrorValidation name="address" validations={validations} className="text-end size-09 bold-300" />
          </div>
        </div>

        <div className="col-6">
          <span className=" size-08 bold me-2">Teléfono</span>
          <div className="">
            <ReactInputMask mask="+56999999999" value={state.inputs.phone} name="phone" className="input-default w-100" onChange={setInputs} placeholder="+56999999999" />
            <ErrorValidation name="phone" validations={validations} className="text-end size-09 bold-300" />
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-6">
          <span className="size-08 bold me-2">Código comercio diferido</span>
          <div className="">
            <input
              value={state.inputs.comerce_deferred}
              name="comerce_deferred"
              onChange={setInputs}
              className="input-default w-100"
              type="text"
              placeholder="Adolfo Orma, Las Condes, RM"
            />
          </div>
          <ErrorValidation name="comerce_deferred" validations={validations} className="text-end size-09 bold-300" />
        </div>

        <div className="col-6">
          <span className="size-08 bold me-2">Código comercio simultáneo</span>
          <div className="">
            <input
              value={state.inputs.comerce_simultaneous}
              name="comerce_simultaneous"
              onChange={setInputs}
              className="input-default w-100"
              type="text"
              placeholder="+56 9 7894 1231"
            />
            <ErrorValidation name="comerce_simultaneous" validations={validations} className="text-end size-09 bold-300" />
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-6">
          <span className=" size-08 bold me-2">Concesionario</span>
          <div className="">
            <SelectPicker
              data={state.concessionaires}
              value={state.inputs.concessionaire_id}
              classPrefix="copec"
              onChange={(e: any) => setInputs({ target: { name: 'concessionaire_id', value: e } })}
              placeholder="Seleccionar Concesionario"
              locale={{ searchPlaceholder: 'Buscar', noResultsText: 'Ningún resultado encontrado.' }}
              className="rs-copec-cleanable"
              cleanable={false}
            />
          </div>
          <ErrorValidation name="concessionaire_id" validations={validations} className="text-end size-09 bold-300" />
        </div>

        <div className="col-6">
          <span className=" size-08 bold me-2">Servicios</span>
          <div className="">
            <TagPicker
              value={state.inputs.services_id}
              onChange={(e: any) => setInputs({ target: { name: 'services_id', value: e } })}
              data={state.serviceData}
              placeholder="Seleccionar Servicios"
              locale={{ searchPlaceholder: 'Buscar', noResultsText: 'Ningún resultado encontrado.' }}
            />
            <ErrorValidation name="services_id" validations={validations} className="text-end size-09 bold-300" />
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-6">
          <span className="size-08 bold me-4">Tipo de pagos</span>
          <div>
            <TagPicker
              placeholder="Seleccionar Tipo de Pagos"
              onChange={(e: any) => setInputs({ target: { name: 'payment_types', value: e } })}
              value={state.inputs.payment_types}
              data={payment_types}
              locale={{ searchPlaceholder: 'Buscar', noResultsText: 'Ningún resultado encontrado.' }}
            />
            <ErrorValidation name="payment_types" validations={validations} className="text-end size-09 bold-300" />
          </div>
        </div>
        <div className="col-6">
          <span className="size-08 bold me-4">Código referencia eds</span>
          <div>
            <input
              value={state.inputs.code_ref}
              name="code_ref"
              onChange={setInputs}
              className="input-default w-100"
              type="text"
              placeholder="Código de referencia"
            />
            <ErrorValidation name="code_ref" validations={validations} className="text-end size-09 bold-300" />
          </div>
        </div>
      </div>

      <div className={`row mt-5 justify-content-around`}>
        <div className="col-2 text-center">
          <button className="btn-danger" onClick={handleClose}>
            Cancelar
          </button>
        </div>
        <div className="col-2 text-center text-white">
          <button className="btn-default" onClick={() => handleConfirm({ ...state.inputs })}>
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalEditEds;

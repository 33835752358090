/* eslint-disable react-hooks/exhaustive-deps */
import { SelectPicker, TagPicker } from "rsuite";
import ErrorValidation from "../../components/ErrorValidation";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { LOADING_OFF, LOADING_ON } from "../../redux/actions/loader";
import GoogleAutocomplete from "../../components/GoogleAutocomplete";
import Company from "../../services/company.service";
import validate from "../../helpers/validate";
import { CompanySchema } from "../../validations/company.validations";

const CompanyForm = (props: any) => {
    const { action, handleClose, handleSuccess, data, eds, services, users, isAdmin } = props

    const [state, setState] = useState({
        validations: {},
        inputs: {
            name: "",
            address: "",
            company_admin: "",
            eds: "",
            services: [],
        },
        directions: {
            address: "",
            latitude: 0,
            longitude: 0,
            direction: "",
            _string: "",
            action: action
        },
        selects: {
            eds: [],
            services: [],
            administrators: [],
        },
    })
    const dispatch: any = useDispatch()

    useEffect(() => {
        _handleLoadData()
    }, [])

    const _handleLoadData = async () => {
        try {
            dispatch(LOADING_ON())

            const payload: any = {
                selects: {
                    eds: eds.map((item: any) => ({ label: item.name, value: item.PK })),
                    services: services.filter((item: any) => item.info?.is_company).map((item: any) => ({ label: item.name, value: item.PK })),
                    administrators: users.map((item: any) => ({ label: `${item.name} ${item.family_name}`, value: item.PK }))
                }
            }

            if (action === 'update') {
                payload.inputs = {
                    name: data?.name,
                    address: data?.address,
                    company_admin: data?.company_admin,
                    eds: data?.GS1SK,
                    services: data?.services,
                }

                payload.directions = {
                    ...data.info
                }
            }

            setState({
                ...state,
                ...payload
            })

            dispatch(LOADING_OFF())
        } catch (e: any) {
            toast.error(e.message || "No fue posible cargar los datos")
            dispatch(LOADING_OFF())
        }
    }

    const _handleOnChange = ({ target }: any) => {
        const { name, value } = target
        setState({
            ...state,
            inputs: {
                ...state.inputs,
                [name]: value
            }
        })
    }

    const _handleSubmit = async () => {

        let validations = await validate(CompanySchema, state.inputs)

        if (state.inputs?.address === "") {
            if (validations) validations.address = ["La dirección es requerida"]
            if (!validations) validations = { address: ["La dirección es requerida"] }
        }

        if (state.directions.latitude === 0 || state.directions.longitude === 0) {
            if (validations) validations.address = ["La dirección informada no es valida"]
            if (!validations) validations = { address: ["La dirección informada no es valida"] }
        }

        setState({ ...state, validations });
        
        if(validations) return;

        const body: any = {
            ...state.inputs,
            address: state.directions?.address,
            info: {
                ...state.directions
            },
        };

        if (action === 'create') {
            try {
                dispatch(LOADING_ON())
                await Company._create(body);
                toast.success("Empresa creada con éxito")
                handleSuccess()
            } catch (e: any) {
                dispatch(LOADING_OFF())
                toast.error(e.response?.data?.message || "No fue posible crear el usuario");
            }
        }

        if (action === 'update') {
            try {
                dispatch(LOADING_ON())
                body.PK = data.PK;
                body.SK = data.SK;
                await Company._update(body);
                toast.success("Empresa actualizada con éxito")
                handleSuccess()
            } catch (e: any) {
                dispatch(LOADING_OFF())
                toast.error(e.response?.data?.message || "No fue posible actualizar el usuario");
            }
        }
    }

    const _handleOnClickMaps = (payload: any) => {
        setTimeout(() => {
            setState((prevState: any) => {
                return {
                    ...prevState,
                    directions: {
                        ...payload
                    }
                }
            })
        }, 1)
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 bold size-13">
                    Empresa
                </div>
            </div>

            <form>
                <div className="row">

                    <div className="col-12 mb-3">
                        <span className="size-08 bold me-2">Dirección</span>
                        <GoogleAutocomplete onClick={_handleOnClickMaps} onChange={(value: any) => setState({ ...state, inputs: { ...state.inputs, address: value } })} initialData={{ ...data?.info, action: action }} />
                        <ErrorValidation className="size-09 bold-300 text-end" validations={state.validations} name="address" />
                    </div>

                    <div className="mb-3" style={{ width: "100%", height: "300px" }}><div className="map border rounded w-100 h-100" id="companymaps" /></div>

                    <div className="col-6 mb-3">
                        <span className="size-08 bold me-2">Nombre</span>
                        <div className="">
                            <input name="name" value={state.inputs.name} onChange={_handleOnChange} className="input-default w-100" type="text" placeholder="Ingresa un nombre" />
                        </div>
                        <ErrorValidation className="size-09 bold-300 text-end" validations={state.validations} name="name" />
                    </div>

                    <div className="col-6 mb-3">
                        <span className="size-08 bold me-2">Administrador Empresa</span>
                        <div className="">
                            <SelectPicker
                                data={state.selects.administrators}
                                value={state.inputs.company_admin}
                                classPrefix="copec"
                                onChange={(e: any) => _handleOnChange({ target: { name: 'company_admin', value: e } })}
                                placeholder="Seleccionar administrador"
                                locale={{ searchPlaceholder: 'Buscar', noResultsText: 'Ningún resultado encontrado.' }}
                                className="rs-copec-cleanable"
                                cleanable={false}
                                disabled={!isAdmin}
                            />
                        </div>
                        <ErrorValidation className="size-09 bold-300 text-end" validations={state.validations} name="company_admin" />
                    </div>

                    <div className="col-6 mb-3">
                        <span className="size-08 bold me-2">Eds</span>
                        <div className="">
                            <SelectPicker
                                data={state.selects.eds}
                                value={state.inputs.eds}
                                classPrefix="copec"
                                onChange={(e: any) => _handleOnChange({ target: { name: 'eds', value: e } })}
                                placeholder="Seleccionar eds"
                                locale={{ searchPlaceholder: 'Buscar', noResultsText: 'Ningún resultado encontrado.' }}
                                className="rs-copec-cleanable"
                                cleanable={false}
                                disabled={!isAdmin}
                            />
                        </div>
                        <ErrorValidation className="size-09 bold-300 text-end" validations={state.validations} name="eds" />
                    </div>

                    <div className="col-6 mb-3">
                        <span className="size-08 bold me-2">Servicios</span>
                        <div className="">
                            <TagPicker
                                cleanable={false}
                                className="rs-picker-cleanable"
                                data={state.selects.services}
                                onChange={(e: any) => _handleOnChange({ target: { name: 'services', value: e } })}
                                value={state.inputs.services}
                                placeholder="Selecciona uno o más servicios"
                                disabled={!isAdmin}
                                style={{opacity: 1}}
                            />
                        </div>
                        <ErrorValidation className="size-09 bold-300 text-end" validations={state.validations} name="services" />
                    </div>
                </div>
                <div className="row justify-content-around mx-0 mt-5 color-white">
                    <div className="col-2">
                        <button type="button" className="btn-danger" onClick={handleClose}>
                            Cancelar
                        </button>
                    </div>
                    <div className="col-2">
                        <button type="button" className="btn-default" onClick={_handleSubmit}>
                            Guardar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default CompanyForm;
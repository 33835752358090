/* eslint-disable no-useless-escape */
import * as yup from 'yup';

export const RecommendedSubServiceSchema = yup.object().shape({
  name: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .max(255, "Este campo acepta un máximo de 255 caracteres")
    .required('Este campo es requerido'),
  descripcion: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .required('Este campo es requerido'),
  price: yup.string().required('Este campo es requerido'),
  service_model: yup.string().nullable().required('Este campo es requerido'),
});

export type RecommendedSubServiceType = yup.InferType<typeof RecommendedSubServiceSchema>;

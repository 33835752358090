/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Table, Tooltip, Whisper } from 'rsuite';
import ModalComponent from '../../components/Modal';
import DetailsOrder from '../../components/Orders/DetailsOrderReverse';
import Title from '../../components/Title';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import ordersService from '../../services/orders.service';
import { TableHeader } from './tableConfigs';
import ParagraphIcon from '@rsuite/icons/Paragraph';
import ReviewPassIcon from '@rsuite/icons/ReviewPass';
import Options from '../../services/options.service';
import moment from 'moment';
import WhisperComponent from '../../components/WhisperComponent';
import ConfirmationModal from '../../components/ConfirmationModal';
import SearchReverse from '../../components/SearchReverse';
import { paginate } from '../../helpers/paginate';
import PaginationFront from '../../components/PaginationFront';

const ReverseOrder = (props: any) => {
  const [state, setState] = useState<any>({
    eds: [],
    eds_select: [],
    reverses: [],
    order: {},
    modals: {
      details: false,
    },
    confirmationModal: {
      open: false,
      pk: '',
      sk: '',
    },
    inputs: {
      status: 'delivered',
      search: '',
      start: moment().subtract(5, 'days').toDate(),
      end: moment().add(10, 'days').toDate(),
      eds: 'all',
      filter_by_date: true,
      refunded: true,
    },
    validations: [],
  });

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    perPage: 10,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    _getData();
  }, []);

  const _getData = async () => {
    try {
      dispatch(LOADING_ON());
      await _getReverses();
      dispatch(LOADING_OFF());
    } catch (e: any) {
      toast.error("No fue posible cargar las EDS's");
      dispatch(LOADING_OFF());
    }
  };

  const _getReverses = async (load_states: any = true) => {
    try {
      const { inputs } = state;
      const { start, end, status } = inputs;

      const params: any = {
        date_from: moment(start).format('YYYY-MM-DD'),
        date_to: moment(end).format('YYYY-MM-DD'),
        status: status?.toUpperCase(),
      };

      const validations: any = [];

      setState({ ...state, validations });

      const startDate = moment(start);
      const endDate = moment(end).subtract(1, 'hours');

      const diffDays: any = endDate.diff(startDate, 'days');

      if (diffDays < 0) {
        validations.push('La fecha de inicio debe ser posterior a la fecha de termino de la consulta.');
      }

      if (diffDays > 29) {
        validations.push('La diferencia entre la fecha de inicio y la fecha final debe ser de un máximo de 30 días.');
      }

      if (validations.length > 0) {
        setState({ ...state, validations });
        return;
      }

      dispatch(LOADING_ON());

      const response: any = await ordersService.listReverses(params);
      const data: any = response.data.reduce((acc: any, current: any) => {
        if (current.amount_diff > 0 && current.status !== 'pay_manually') {
          current.reverse = 'Si';
          current.moment_date = moment(current.created_at);
          acc.push(current);
        }
        return acc;
      }, []);

      data.sort((a: any, b: any) => {
        if (a.moment_date.isAfter(b.moment_date)) return 1;
        if (a.moment_date.isBefore(b.moment_date)) return -1;
        return 0;
      });

      data.sort((a: any, b: any) => {
        if (a.refunded > b.refunded) return 1;
        if (a.refunded < b.refunded) return -1;
        return 0;
      });

      const paginated: any = paginate(data, data?.length, pagination.currentPage, pagination.perPage);

      setPagination({ ...pagination, ...paginated });

      if (load_states) {
        const responseOrderState = await Options.getOrderState();
        const statusList: any = responseOrderState.data?.filter((item: any) => item.value === 'canceled' || item.value === 'delivered');
        setState({ ...state, reverses: [...paginated.items], orders: [...data], order_state: statusList, validations, confirmationModal: { pk: '', sk: '', open: false, order: {} } });
      }

      if (!load_states) {
        setState({ ...state, reverses: [...paginated.items], orders: [...data], validations, confirmationModal: { pk: '', sk: '', open: false, order: {} } });
      }

      dispatch(LOADING_OFF());
    } catch (e: any) {
      toast.error('No fue posible cargar las reversas. Baja el rango de tiempo y vuelve a intentar.');
      dispatch(LOADING_OFF());
    }
  };

  const _toggleDetailsOrderModal = async (payload: any = null) => {
    if (payload) {
      const order: any = { PK: payload.info?.PK, SK: payload.info?.SK };
      setState({ ...state, modals: { ...state.modals, details: !state.modals.details }, order_details: payload.info?.PK, order });
    } else {
      setState({ ...state, modals: { ...state.modals, details: !state.modals.details }, order_details: payload.info?.PK, order: null });
    }
  };

  const _handleFilter = (payload: any) => {
    const { orders } = state;
    const filtered: any = orders.filter(
      (item: any) =>
        (item.PK?.indexOf(payload.filter) !== -1 ||
          item.order?.user?.email?.toLowerCase()?.indexOf(payload.filter?.toLowerCase()) !== -1 ||
          item.order?.user?.name?.toLowerCase()?.indexOf(payload.filter?.toLowerCase()) !== -1) &&
        item.refunded === payload.refunded
    );
    const paginated: any = paginate(filtered, filtered.length, pagination.currentPage, pagination.perPage);
    setPagination({ ...pagination, ...paginated });
    setState({ ...state, reverses: paginated.items });
  };

  const _handleOnChangeInputs = (data: any) => {
    const { target } = data;
    setState({ ...state, inputs: { ...state.inputs, [target.name]: target.value } });
  };

  const _handleRefundOrder = async () => {
    try {
      dispatch(LOADING_ON());
      await ordersService.updateRefundOrderStatus({
        PK: state.confirmationModal.pk,
        SK: state.confirmationModal.sk,
        status: state.inputs?.status,
        refunded: 1
      });
      await _getReverses(false);
      dispatch(LOADING_OFF());
      toast.success('Orden actualizada con éxito.');
    } catch (e: any) {
      dispatch(LOADING_OFF());
      setState({ ...state, confirmationModal: { pk: '', sk: '', open: false, order: {} } });
      toast.error('No fue posible marcar esta orden como reembolsada.');
    }
  };

  const _setPage = (page: any) => {
    const paginated: any = paginate(state.orders, state.orders?.length, page, pagination.perPage);
    setPagination({ ...pagination, ...paginated });
    setState({ ...state, reverses: paginated.items });
  };

  return (
    <div className="container-fluid">
      <ModalComponent open={state.modals.details} handleClose={_toggleDetailsOrderModal} handleConfirm={_toggleDetailsOrderModal} hideCancel btnConfirmText="CERRAR">
        <DetailsOrder id={state.order_details} data={state.order || {}} status={state.order_state} />
      </ModalComponent>

      <ConfirmationModal
        size="md"
        title="Confirmación"
        type="warning"
        handleConfirm={_handleRefundOrder}
        handleClose={() => setState({ ...state, confirmationModal: { pk: '', sk: '', open: false } })}
        open={state.confirmationModal.open}
      >
        ¿Seguro que quieres marcar esta orden como reembolsada?
      </ConfirmationModal>

      <div className="row">
        <div className="col-12">
          <Title size="sm" text="Reversas" className="mt-5 bold" />
        </div>
      </div>

      <SearchReverse
        statusList={state.order_state}
        data={state.inputs}
        results={state.orders}
        handleFilter={_handleFilter}
        handleOnChange={_handleOnChangeInputs}
        handleSearch={() => _getReverses(false)}
        validations={state.validations}
      />

      <div className="row">
        <div className="col-12 mt-4">
          <Table data={state.reverses} rowClassName="striped" autoHeight rowHeight={80} locale={{ emptyMessage: 'No hay reversas' }}>
            {TableHeader &&
              TableHeader.map((column: any, index: any) => (
                <Table.Column align={column.align} flexGrow={column.flexGrow} key={`table-column-${index}`}>
                  <Table.HeaderCell>
                    <span className="bold" style={{ textTransform: 'capitalize' }}>
                      {column.label}
                    </span>
                  </Table.HeaderCell>
                  <Table.Cell style={{ display: 'flex', alignItems: 'center', justifyContent: column.alignFlex }}>
                    {(rowData) => {
                      switch (column.key) {
                        case 'items':
                          return rowData.info?.items?.length;
                        case 'order_id':
                          return rowData.info?.order_id;
                        case 'amount_diff':
                          return `$ ${(Math.sign(rowData.amount_diff) === -1 ? rowData.amount_diff * -1 : rowData.amount_diff)?.toLocaleString('pt-BR')}`;
                        case 'refunded':
                          return <div className="w-100 ps-4">{rowData.refunded ? 'Si' : 'No'}</div>;
                        case 'created_at':
                          return moment(rowData.created_at).format('DD/MM/YYYY - HH:mm');
                        default:
                          return (
                            <Whisper trigger="hover" placement="auto" controlId={`control-id-auto`} speaker={<Tooltip>{rowData[column.key]}</Tooltip>}>
                              <div style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{rowData[column.key]}</div>
                            </Whisper>
                          );
                      }
                    }}
                  </Table.Cell>
                </Table.Column>
              ))}

            <Table.Column align="right" flexGrow={1} verticalAlign="middle">
              <Table.HeaderCell>{''}</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  return (
                    <>
                      {rowData.refunded ? null : (
                        <button
                          className="btn me-3"
                          onClick={() => setState({ ...state, confirmationModal: { pk: rowData.order?.PK, sk: rowData.order?.SK, order: rowData.order, open: true } })}
                        >
                          <WhisperComponent text="Marcar como reembolsado">
                            <ReviewPassIcon />
                          </WhisperComponent>
                        </button>
                      )}

                      <button className="btn me-3" onClick={() => _toggleDetailsOrderModal(rowData)}>
                        <WhisperComponent text="Detalles">
                          <ParagraphIcon />
                        </WhisperComponent>
                      </button>
                    </>
                  );
                }}
              </Table.Cell>
            </Table.Column>
          </Table>
        </div>
      </div>
      <div className="col-12 my-5 text-center">
        <PaginationFront {...pagination} setPage={_setPage} />
      </div>
    </div>
  );
};
export default ReverseOrder;

/* eslint-disable no-useless-escape */
import * as yup from 'yup'

export const CouponConfigSchema = yup.object().shape({
  name: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .max(255, "Este campo acepta un máximo de 255 caracteres")
    .required('Este campo es requerido'),
  pk_services: yup
    .array().min(1, 'Seleccione uno o más servicios'),
  pk_eds: yup
    .array().min(1, 'Seleccione una o más EDS'),
  apply: yup
    .string().max(80, "Este campo acepta un máximo de 80 caracteres").required('Este campo es requerido'),
  type_discount: yup
    .string()
    .when("apply", {
      is: 'carrito',
      then: yup.string().required('Este campo es requerido'),
    })
    .when("apply", {
      is: 'servicios',
      then: yup.string().required('Este campo es requerido'),
    }),
  max_amount_discount: yup
    .number()
    .when("type_discount", {
      is: 'monto',
      then: yup.number().min(1, 'Este campo debe ser mayor a 1'),
    })
    .when("type_discount", {
      is: 'porcentaje',
      then: yup.number().min(1, 'Este campo debe ser mayor a 1'),
    })
    .when("type_discount", {
      is: 'litro',
      then: yup.number().min(1, 'Este campo debe ser mayor a 1'),
    }),
  vigencia: yup
    .string().required('Este campo es requerido'),
  minimum_amount: yup
    .bool(),
  minimum_amount_in_cart: yup
    .number()
    .when("minimum_amount", {
      is: true,
      then: yup.number().min(1, 'Este campo debe ser mayor a 1').required("Este campo es requerido")
    }),
  limitsame_campaign: yup
    .bool(),
  limit_used: yup
    .string()
    .when("limitsame_campaign", {
      is: true,
      then: yup.string().min(1, 'Este campo debe ser mayor a 1').required("Este campo es requerido")
    }),
  restriccion: yup
    .bool(),
  use_limit: yup
    .number()
    .when("restriccion", {
      is: true,
      then: yup.number().min(1, 'Este campo debe ser mayor a 1').required("Este campo es requerido")
    }),
  start: yup
    .string()
    .when('vigencia', {
      is: 'intervalo',
      then: yup.string().required("Este campo es requerido"),
    })
    .when('vigencia', {
      is: 'dias',
      then: yup.string().required("Este campo es requerido"),
    }),
  end: yup
    .string()
    .when('vigencia', {
      is: 'intervalo',
      then: yup.string().required("Este campo es requerido"),
    })
    .when('vigencia', {
      is: 'dias',
      then: yup.string().required("Este campo es requerido"),
    }),
  apply_days: yup
    .array()
    .when('vigencia', {
      is: 'dias',
      then: yup.array().min(1, 'Seleccione al menos un día de la semana')
    }),
});

export type CampaignType = yup.InferType<typeof CouponConfigSchema>

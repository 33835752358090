import instance from './api_admin';
import store from '../redux/store';
import moment from 'moment';
import { set_configs } from '../redux/actions/configs';
import getAllData from '../helpers/get_all_data';
import { getSession } from '../helpers/session';
class ServicesAndProducts {
  _setHeaders = () => {
    const state: any = store.getState();
    const { session } = state;

    const oidcSession = getSession();

    if (oidcSession) {
      instance.defaults.headers.common['user'] = session.profile?.PK;
      instance.defaults.headers.common['usersk'] = session.profile?.SK;
      instance.defaults.headers.common['Authorization'] = `Bearer ${oidcSession?.access_token}`;
    }
  };

  list = async (paginationParams: any = null) => {
    this._setHeaders();
    try {
	  let params: any = [];
      if (paginationParams && paginationParams.last) {	
      	params.push(`last=${paginationParams.last}`)
      }
      const response = await instance.get(`/admin/service/list/all?limit=${process.env.REACT_APP_ORDERS_BASE_LIMIT}&${params.join('&')}`);
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  selectList = async (cache: any = false) => {
    this._setHeaders();
    const { configs }: any = store.getState();
    const dispatch: any = store.dispatch;

    try {
      const response = await instance.get('/admin/service/select');
      const services: any = {
        updated_at: moment().toString(),
        data: response.data,
      };
      dispatch(set_configs({ ...configs, services: services }));
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  details = async (payload: any) => {
    this._setHeaders();
    try {
      const response = await instance.get(`/admin/service/read/${payload.PK}?PK=${payload.PK}&SK=${payload.SK}`);
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  create = async (payload: any) => {
    this._setHeaders();
    try {
      const response = await instance.post(`/admin/service/create`, payload);
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  update = async (payload: any) => {
    this._setHeaders();
    try {
      const response = await instance.put(`/admin/service/update/${payload.PK}`, payload);
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  delete = async (payload: any) => {
    this._setHeaders();
    try {
      const response = await instance.delete(`/admin/service/delete/${payload.PK}`, payload);
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  listAllServices = async (paginationParams: any = null) => {
    this._setHeaders();
    try {
      return getAllData(instance, '/admin/service/list/all', 10);
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
}

const Service = new ServicesAndProducts();
export default Service;

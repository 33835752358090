/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import ErrorValidation from "../../ErrorValidation";
import { SelectPicker, Toggle } from "rsuite";
import Service from "../../../services/service_and_products.service";
import validate from "../../../helpers/validate";
import { ServiceMessagesSchema } from "../../../validations/service_messages.validations";
import { useDispatch, useSelector } from "react-redux";
import EDSDAO from "../../../services/eds.service";
import { set_eds } from "../../../redux/actions/eds";
import { toast } from "react-toastify";
import { LOADING_OFF, LOADING_ON } from "../../../redux/actions/loader";

interface ModalMessageServicesProps {
    handleClose: () => void
}
const ModalMessageServices = ({ handleClose }: ModalMessageServicesProps) => {
    const { selected_eds } = useSelector((state: any) => state);
    const [state, setState] = useState<any>(
        {
            inputs: {
                service_id: '',
                message: '',
                dont_show_message: false
            },
            services: [],
            validations: {}
        }
    )
    const dispatch: any = useDispatch();

    useEffect(() => {
        _getData();
    }, [])

    const _getData = async () => {
        try {
            let services: any = [];
            let existMoreServices = true;
            let next_page: any = null;
            let params: any = null;
            dispatch(LOADING_ON());
            while (existMoreServices) {
                const responseService: any = await Service.list(params);
                next_page = responseService.next_page;

                services = services.concat(responseService.data);

                if (next_page && 'LastEvaluatedKey' in next_page) {
                    const lastKey = next_page.LastEvaluatedKey;

                    let nextPageUri = encodeURIComponent(JSON.stringify(lastKey));

                    params = { last: nextPageUri };

                } else {
                    existMoreServices = false;
                }

            }

            services = services.filter((service: any) => service.type !== 'recommended_subservice');

            setState({ ...state, services })
            dispatch(LOADING_OFF());
        } catch (e: any) {
            toast.error("No fue posible cargar los servicios")
            dispatch(LOADING_OFF());
        }
    }

    const setInputs = ({ target }: any) => {
        const { name, value }: any = target;
        state.inputs[name] = value;

        if (name === 'service_id') {
            state.inputs.message = '';
            state.inputs.dont_show_message = false;
        }

        if (name === 'service_id' && selected_eds.info && selected_eds.info.service_messages) {
            const service_messages = selected_eds.info.service_messages;
            const message = service_messages.find((message: any) => message.service_id === value);
            if (message) {
                state.inputs.message = message.message.replace(/<br\s*[\/]?>/gi, '\n');
                state.inputs.dont_show_message = message.dont_show_message;
            }
        }

        setState({ ...state });
    };

    const handleConfirm = async () => {
        try {
            const validations = await validate(ServiceMessagesSchema, state.inputs);
            setState({ ...state, validations })

            if (validations) return

            dispatch(LOADING_ON());
            let service_messages: any = [];

            const new_message = {
                service_id: state.inputs.service_id,
                message: state.inputs.message.replace(/(?:\r\n|\r|\n)/g, '<br>'),
                dont_show_message: state.inputs.dont_show_message
            }

            if (selected_eds?.info?.service_messages) {
                service_messages = selected_eds.info.service_messages.filter((message: any) => message.service_id !== state.inputs.service_id);
                service_messages.push(new_message);
            } else {
                service_messages.push(new_message);
            }

            selected_eds.info.service_messages = service_messages;

            await EDSDAO.update(selected_eds);
            dispatch(set_eds(selected_eds));
            dispatch(LOADING_OFF());
            setState({ ...state, inputs: { service_id: '', message: '', dont_show_message: false }, validations: {} })
            toast.success("Mensaje guardado correctamente")
        } catch (e: any) {
            toast.error("No fue posible guardar el mensaje")
            dispatch(LOADING_OFF());
        }
    };
    return (
        <div className="component-services px-5">

            <div className="row mb-3">
                <div className="col-12 mb-4">
                    <span className=" size-08 bold me-2">Servicio</span>
                    <div className="">
                        <SelectPicker
                            data={state.services}
                            value={state.inputs.service_id}
                            classPrefix="copec"
                            onChange={(e: any) => setInputs({ target: { name: 'service_id', value: e } })}
                            placeholder="Seleccionar servicios"
                            locale={{ searchPlaceholder: 'Buscar', noResultsText: 'Ningún resultado encontrado.' }}
                            className="rs-copec-cleanable"
                            cleanable={false}
                            valueKey="PK"
                            labelKey="name"
                        />
                    </div>
                    <ErrorValidation name="service_id" validations={state.validations} className="text-end size-09 bold-300" />
                </div>

                <div className="col-12">
                    <span className=" size-08 bold me-2">Mensaje</span>
                    <div className="">
                        <textarea value={state.inputs.message} name="message" onChange={setInputs} rows={10} className="input-default w-100" placeholder="" />
                        <ErrorValidation name="message" validations={state.validations} className="text-end size-09 bold-300" />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <span className="size-08 bold me-4">No mostrar este mensaje</span> <br />
                    <Toggle checked={state.inputs.dont_show_message} onChange={(e: any) => setInputs({ target: { name: 'dont_show_message', value: e } })} />
                </div>
            </div>

            <div className={`row mt-5 justify-content-around`}>
                <div className="col-2 text-center">
                    <button className="btn-danger" onClick={handleClose}>
                        Cancelar
                    </button>
                </div>
                <div className="col-2 text-center text-white">
                    <button className="btn-default" onClick={() => handleConfirm()}>
                        Guardar
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ModalMessageServices;
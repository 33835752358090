/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { SelectPicker, TagPicker, Toggle, Uploader } from 'rsuite';
import { ServiceTypes } from '../../types';
import ErrorValidation from '../ErrorValidation';
import WhisperComponent from '../WhisperComponent';
import { ReactComponent as QuestionMarkIcon } from '../../assets/image/svg/question_mark.svg';

const LavamaxSubService = (props: any) => {
  const { state, _handleOnChange, _handleImage, _handleDelete, PK, SK, recommended_subservice, type } = props;
  const [errors, setErrors] = useState({});
  const [checked, setChecked] = useState<any>(false);
  const types = ServiceTypes();
  const [ss, setSs] = useState<any>([]);

  useEffect(() => {
    setChecked(state.info.required);
  }, [state]);

  useEffect(() => {
    const errors = state.validations;
    setErrors(errors);
  }, [state?.validations]);

  useEffect(() => {
    if (recommended_subservice) {
      const { sub_services } = recommended_subservice;
      if (sub_services) {
        const services: any = sub_services
          .filter((item: any) => item?.info?.service_model === type)
          .map((item: any) => {
            return { label: item.name, value: item.SK };
          });
        setSs(services);
      }
    }
  }, [recommended_subservice]);

  const _handleOnChangeData = ({ target }: any) => {
    const { name, value } = target;
    const data: any = {};
    data.name = name;
    data.PK = PK;
    data.SK = SK;

    const numberFields: any = ['price', 'shipping_price', 'full_points', 'without_discount_price', 'points_per_division'];

    if (numberFields.includes(name)) {
      data.value = value.replace(/\D/g, '');
    } else {
      data.value = value;
    }
    _handleOnChange(data);
  };

  return (
    <div className="row border rounded-05 pb-5 pt-3 px-4 mt-4">
      {/* <div className="col-12 text-end">
        <button onClick={() => _handleDelete(PK)} className="btn btn-close size-07 p-0 m-0" />
      </div> */}
      <div className="col-6">
        <span className=" size-08 bold me-2">Nombre</span>
        <div className="">
          <input
            value={state?.name}
            onChange={_handleOnChangeData}
            name="name"
            className="input-default w-100"
            type="text"
            placeholder="LAVADO FULL"
            disabled={state.info?.is_unvailable}
          />
        </div>
        <ErrorValidation validations={errors} name="name" className="bold size-09 text-end" />
      </div>

      <div className="col-6">
        <span className="size-08 bold me-2">Imagen</span>
        <div className="w-100">
          <Uploader
            accept=".png, .jpg, .jpeg"
            listType="text"
            fileList={state?.fileList}
            onChange={(e: any) => _handleImage(e, SK, 'product')}
            autoUpload={false}
            disabled={state.info?.is_unvailable}
          >
            <button>
              <span className="me-1">+</span> Seleccionar archivo
            </button>
          </Uploader>
        </div>
      </div>

      <div className="col-4 mt-2">
        <span className=" size-08 bold me-2">Precio</span>
        <div className="">
          <input
            value={state?.price}
            onChange={_handleOnChangeData}
            maxLength={12}
            name="price"
            className="input-default w-100"
            type="string"
            placeholder="00000"
            disabled={state.info?.is_unvailable}
          />
        </div>
        <ErrorValidation validations={errors} name="price" className="bold size-09 text-end" />
      </div>

      <div className="col-4 mt-2">
        <span className=" size-08 bold me-2">Precio sin descuento</span>
        <div className="">
          <input
            value={state?.info?.without_discount_price}
            onChange={_handleOnChangeData}
            maxLength={12}
            name="without_discount_price"
            className="input-default w-100"
            type="string"
            placeholder="00000"
            disabled={state.info?.is_unvailable}
          />
        </div>
        <ErrorValidation validations={errors} name="without_discount_price" className="bold size-09 text-end" />
      </div>

      <div className="col-4 mt-2">
        <span className=" size-08 bold me-2">Tamaño vehículo</span>
        <div className="">
          <SelectPicker
            value={state?.type}
            onChange={(e: any) => _handleOnChangeData({ target: { value: e, name: 'type' } })}
            data={types}
            placeholder="Añadir tamaño vehiculo"
            placement="auto"
            classPrefix="copec"
            className="rs-copec-cleanable"
            cleanable={false}
            locale={{ searchPlaceholder: 'Buscar', emptyMessage: 'No se encontró ningún tamaño de vehículo', noResultsText: 'No se encontró ningún tamaño de vehículo' }}
            disabled={state.info?.is_unvailable}
          />
        </div>
      </div>

      <div className="col-4 mt-2">
        <span className=" size-08 bold me-2">Sub servicios recomendados</span>
        <div>
          <TagPicker
            value={state.recommended_subservices}
            onChange={(e: any) => _handleOnChangeData({ target: { value: e, name: 'recommended_subservices' } })}
            data={ss}
            placeholder="Añadir sub servicios"
            locale={{ emptyMessage: 'No se encontró ningún sub servicio', noResultsText: 'No se encontró ningún sub servicio' }}
            disabled={state.info?.is_unvailable}
          />
        </div>
      </div>

      <div className="col-4 mt-2">
        <span className=" size-08 bold me-2">SKU</span>
        <div className="">
          <input
            value={state?.info?.sku}
            onChange={_handleOnChangeData}
            name="sku"
            className="input-default w-100"
            type="text"
            placeholder="SKU del sub servicio"
            disabled={state.info?.is_unvailable}
          />
        </div>
        <ErrorValidation validations={errors} name="info.sku" className="bold size-09 text-end" />
      </div>

      <div className="col-4 mt-2">

        <div className='d-flex justify-content-start'>
        <span className='size-08 bold me-1'>Puntos Full</span>
          <span>
            <WhisperComponent text={`Número de puntos que sera multiplicado por el valor total del servicio dividido por la "Base para cálcular Puntos Full"`} placement="topStart" className="p-0 m-0 size-08 bold flex aling-items-center" align="left">
              <QuestionMarkIcon className="ms-1 mb-1" style={{ width: "20px", height: "20px" }} />
            </WhisperComponent>
          </span>
        </div>

        <div>
          <input
            value={state?.info?.points_per_division}
            onChange={_handleOnChangeData}
            name="points_per_division"
            disabled={state.info?.is_unvailable}
            className="input-default w-100"
            type="text"
            placeholder="Informa cuantos puntos Copec Full este servicio acumula"
          />
        </div>
        <ErrorValidation validations={errors} name="info.points_per_division" className="bold size-09 text-end" />
      </div>

      <div className="col-12 mt-2">
        <span className=" size-08 bold me-2">Descripción</span>
        <div className="">
          <textarea
            value={state?.descripcion}
            onChange={_handleOnChangeData}
            name="descripcion"
            className="input-default w-100"
            placeholder="Descripción"
            disabled={state.info?.is_unvailable}
          />
        </div>
        <ErrorValidation validations={errors} name="descripcion" className="bold size-09 text-end" />
      </div>

      <div className="col-6 mt-2 d-flex flex-column justify-content-center">
        <span className=" size-08 bold me-2">Desactivado</span>
        <div className="">
          <Toggle
            checked={state.info?.is_unvailable}
            onChange={(e: any) => _handleOnChangeData({ target: { name: 'is_unvailable', value: e } })}
          />
        </div>
      </div>
    </div>
  );
};

export default LavamaxSubService;

import { getSession } from '../helpers/session';
import store from '../redux/store';
import instance from './api_admin';
import instanceOvert from './api_overt';

class ProductDataObject {
  _setHeaders = () => {
    const state: any = store.getState();
    const { session } = state;

    const oidcSession = getSession();

    if (oidcSession) {
      instance.defaults.headers.common['user'] = session.profile?.PK;
      instance.defaults.headers.common['usersk'] = session.profile?.SK;
      instance.defaults.headers.common['Authorization'] = `Bearer ${oidcSession?.access_token}`;
      instanceOvert.defaults.headers.common['user'] = session.profile?.PK;
      instanceOvert.defaults.headers.common['usersk'] = session.profile?.SK;
      instanceOvert.defaults.headers.common['Authorization'] = `Bearer ${oidcSession?.access_token}`;
    }
  };

  getAll = async (paginationParams: any = null) => {
    this._setHeaders();
    try {
      let params: any = [];

      if (paginationParams && paginationParams.last) {
        params.push(`last=${paginationParams.last}`);
      }
      const response: any = await instance.get(`/admin/product/list?limit=${process.env.REACT_APP_ORDERS_BASE_LIMIT}&${params.join('&')}`);
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
    }
  };

  details = async (PK: any) => {
    this._setHeaders();
    try {
      const response: any = await instance.get(`/admin/product/detail/${PK}`);
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
    }
  };

  list = async (paginationParams: any, limit: any = 10) => {
    this._setHeaders();
    try {
      let params: any = [];

      if (paginationParams && paginationParams.last) {
        params.push(`last=${paginationParams.last}`);
      }
      const response: any = await instance.get(`/admin/product/list?limit=${limit}&${params.join('&')}`);

      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
    }
  };

  create = async (payload: any) => {
    this._setHeaders();
    try {
      const response: any = await instance.post('/admin/product/create', payload);
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
    }
  };

  update = async (PK: any, payload: any) => {
    this._setHeaders();
    try {
      const response: any = await instance.put(`/admin/product/update/${PK}`, payload);
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
    }
  };

  delete = async (payload: any) => {
    this._setHeaders();
    try {
      const response: any = await instance.delete(`/admin/product/delete/${payload.PK}`, { data: { hard: true, PK: payload.PK, SK: payload.SK } });
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
    }
  };

  related_list = async (payload: any) => {
    this._setHeaders();
    try {
      const response: any = await instanceOvert.get(`/overt/service/detail/${payload.PK}?PK=${payload.PK}&SK=${payload.SK}`);
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
    }
  };
}

const Product = new ProductDataObject();
export default Product;

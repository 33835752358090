/* eslint-disable react-hooks/exhaustive-deps */
import { SelectPicker } from "rsuite";
import ErrorValidation from "../../components/ErrorValidation";
import InputMask from 'react-input-mask';
import validate from "../../helpers/validate";
import { SignUpSchema } from "../../validations/signup.validations";
import { validateAndFormatPhoneNumber } from "../../helpers/Utils";
import { useEffect, useState } from "react";
import User from "../../services/user.service";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { LOADING_OFF, LOADING_ON } from "../../redux/actions/loader";

const UserForm = (props: any) => {
    const { action, handleClose, handleSuccess, data } = props

    const [state, setState] = useState({
        validations: {},
        inputs: {
            name: "",
            family_name: "",
            email: "",
            phone_number: "",
            rut: "",
            role: "callcenter"
        },
    })
    const dispatch:any = useDispatch()
    const roles: any = [{ value: 'callcenter', label: 'Call Center' }, { value: 'user_company', label: 'Admin Empresa' }];

    useEffect(() => {
        if (action === 'update') {
            setState({
                ...state,
                inputs: {
                    name: data?.name,
                    family_name: data?.family_name,
                    email: data?.email,
                    phone_number: data?.phone_number,
                    rut: data?.rut,
                    role: data?.info?.role || data?.info?.roles[0]
                }
            })
        }
    }, [])

    const _handleOnChange = ({ target }: any) => {
        const { name, value } = target
        setState({
            ...state,
            inputs: {
                ...state.inputs,
                [name]: value
            }
        })
    }

    const _handleSubmit = async () => {
        let validations = await validate(SignUpSchema, state.inputs);

        if (state.inputs.phone_number?.replace(/_/g, '')?.length !== 12) {
            validations = { ...validations, phone_number: ['Número de teléfono inválido'] };
        }

        if (!validateAndFormatPhoneNumber(state.inputs.rut)) {
            validations = { ...validations, rut: ['El rut no es válido'] };
        }

        if (!state.inputs.role) {
            validations = { ...validations, role: ['Este campo es requerido'] };
        }

        if (validations) {
            setState({ ...state, validations: validations });
            return;
        }

        setState({ ...state, validations: {} });

        const body: any = {
            ...state.inputs,
            info: {
                roles: [state.inputs.role],
                role: state.inputs.role,
                permissions: ['all'],
                concessionaire_id: null,
                eds_id: null,
                isAuthZero: true,
            },
        };

        if (action === 'create') {
            try {
                dispatch(LOADING_ON())
                await User._create(body);
                toast.success("Usuario creado con éxito")
                handleSuccess()
            } catch (e: any) {
                dispatch(LOADING_OFF())
                toast.error(e.response?.data?.message || "No fue posible crear el usuario");
            }
        }

        if (action === 'update') {
            try {
                dispatch(LOADING_ON())
                body.PK = data.PK;
                body.SK = data.SK;
                await User._update(body);
                toast.success("Usuario actualizado con éxito")
                handleSuccess()
            } catch (e: any) {
                dispatch(LOADING_OFF())
                toast.error(e.response?.data?.message || "No fue posible actualizar el usuario");
            }
        }
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 bold size-13">
                    Usuario
                </div>
            </div>

            <form>
                <div className="row">
                    <div className="col-6 mb-3">
                        <span className="size-08 bold me-2">Nombre</span>
                        <div className="">
                            <input name="name" value={state.inputs.name} onChange={_handleOnChange} className="input-default w-100" type="text" placeholder="Ingresa un nombre" />
                        </div>
                        <ErrorValidation className="size-09 bold-300 text-end" validations={state.validations} name="name" />
                    </div>
                    <div className="col-6 mb-3">
                        <span className="size-08 bold me-2">Apellido</span>
                        <div className="">
                            <input name="family_name" value={state.inputs.family_name} onChange={_handleOnChange} className="input-default w-100" type="text" placeholder="Ingresa un apellido" />
                        </div>
                        <ErrorValidation className="size-09 bold-300 text-end" validations={state.validations} name="family_name" />
                    </div>
                    <div className="col-6 mb-3">
                        <span className="size-08 bold me-2">Rut</span>
                        <div className="">
                            <input name="rut" value={state.inputs.rut} onChange={_handleOnChange} className="input-default w-100" type="text" placeholder="Ingresa un Rut sin puntos y con guión" />
                        </div>
                        <ErrorValidation className="size-09 bold-300 text-end" validations={state.validations} name="rut" />
                    </div>
                    <div className="col-6 mb-3">
                        <span className="size-08 bold me-2">Teléfono</span>
                        <div className="">
                            <InputMask
                                mask="+56999999999"
                                placeholder="+56999999999"
                                value={state.inputs.phone_number}
                                name="phone_number"
                                className="input-default w-100"
                                onChange={_handleOnChange}
                            />
                        </div>
                        <ErrorValidation className="size-09 bold-300 text-end" validations={state.validations} name="phone_number" />
                    </div>
                    <div className="col-6 mb-3">
                        <span className="size-08 bold me-2">Email</span>
                        <div className="">
                            <input name="email" value={state.inputs.email} onChange={_handleOnChange} className="input-default w-100" type="text" placeholder="Ingresa un email" disabled={action === 'update'} />
                        </div>
                        <ErrorValidation className="size-09 bold-300 text-end" validations={state.validations} name="email" />
                    </div>
                    <div className="col-6 mb-3">
                        <span className="size-08 bold me-2">Role</span>
                        <div className="">
                            <SelectPicker
                                data={roles}
                                value={state.inputs.role}
                                classPrefix="copec"
                                onChange={(e: any) => _handleOnChange({ target: { name: 'role', value: e } })}
                                placeholder="Seleccionar role"
                                locale={{ searchPlaceholder: 'Buscar', noResultsText: 'Ningún resultado encontrado.' }}
                                className="rs-copec-cleanable"
                                cleanable={false}
                                disabled={action === 'update'}
                            />
                        </div>
                        <ErrorValidation className="size-09 bold-300 text-end" validations={state.validations} name="role" />
                    </div>
                </div>
                <div className="row justify-content-around mx-0 mt-5 color-white">
                    <div className="col-2">
                        <button type="button" className="btn-danger" onClick={handleClose}>
                            Cancelar
                        </button>
                    </div>
                    <div className="col-2">
                        <button type="button" className="btn-default" onClick={_handleSubmit}>
                            Guardar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default UserForm;
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Table, Tag, TagGroup, Tooltip, Whisper } from 'rsuite';
import ModalComponent from '../../components/Modal';
import Title from '../../components/Title';
import { TableHeader } from './tableConfig';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import AddWorkDay from './AddWorkDay';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import EditWorkDay from './EditWorkDay';
import Schedule from '../../services/schedule.service';
import validate from '../../helpers/validate';
import { WorkDaySchema } from '../../validations/work_day.validation.ts';
import { days_of_week } from './days_of_week';
import Pagination from '../../components/PaginationFront/Pagination';
import usePagination from '../../hooks/usePagination';

const WorkDay = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState<any>({
    checks: {},
    work_day: {},
    modals: {
      create: false,
      update: false,
    },
    data: [],
    allData: [],
    search: '',
    sort: {
      column: 'name',
      type: 'asc',
    },
    validations: {},
  });

  const onPrev = async (response: any) => {
	setState({
		...state,
		data: processWorkDays(response.data)
	});
  }

  const onNext = async (response: any) => {
	setState({
		...state,
		data: processWorkDays(response.data)
	});
  }

  const endpoint = 'listCoverage';

  const {
  	currentPage, setCurrentPage, pages, setPages, prevPage, nextPage, setFirstPage
  } = usePagination({onPrev, onNext, service: Schedule, endpoint});


  const processWorkDays = (data: any) => {

	data.map((item: any) => {
      if (item.coverage) {
        item.start = item.coverage[0].start;
        item.end = item.coverage[0].end;
      }
      item._string = moment().day(item.day_of_week).format('dddd');
      return item;
    });

    data.sort((a: any, b: any) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });

    data.filter((item: any) => item.name?.toLowerCase().includes(state.search.toLowerCase()) || item.PK?.toLowerCase().includes(state.search.toLowerCase()))

    return data;
  }

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      dispatch(LOADING_ON());
      const response = await Schedule.listCoverage();
      const { data } = response;
      setFirstPage(response.next_page);

      if (Array.isArray(data)) {

        setState({
          ...state,
          data: processWorkDays(data),
          allData: data,
          modals: {
            create: false,
            update: false,
          },
          validations: {},
        });
      }

      dispatch(LOADING_OFF());
    } catch (e: any) {
      console.log('ERROR LOG', e.message);
      dispatch(LOADING_OFF());
    }
  };

  const _toggleModal = (target: any) => {
    setState({ ...state, modals: { ...state.modals, [target]: !state.modals[target] }, validations: {} });
  };

  const _filter = ({ target }: any) => {
    const { value } = target;
    setState({ ...state, search: value });
    const results = state.allData.filter((item: any) => item.name?.toLowerCase().includes(value.toLowerCase()) || item.PK?.toLowerCase().includes(value.toLowerCase()));
    setState((prevState: any) => {
      return {
        ...prevState,
        data: results,
        sort: {
          column: '',
          type: '',
        },
      };
    });
  };

  const _createData = (data: any) => {
    const payload: any = data.days_of_week.map((day: any) => {
      let name: any = data.name;

      if (data.days_of_week.length > 1) {
        name = name + ' - ' + days_of_week.find((item: any) => item.value === String(day))?.label;
      }

      const params: any = {
        name: name,
        coverage: [
          {
            start: moment(data.start).format('HH:mm'),
            end: moment(data.end).format('HH:mm'),
          },
        ],
        day_of_week: String(day),
        info: {},
      };
      if (data.PK) {
        params.PK = data.PK;
      }
      return params;
    });

    return payload;
  };

  const _createCoverage = async (data: any) => {
    const validations = await validate(WorkDaySchema, data);
    if (validations) {
      setState({ ...state, validations });
      return;
    }
    const payload: any = _createData(data);
    dispatch(LOADING_ON());
    try {
      for (const coverage of payload) {
        await Schedule.createCoverage(coverage);
      }
      await getData();
    } catch (e: any) {
      console.log('ERROR LOG', e.message);
      dispatch(LOADING_OFF());
    }
  };

  const _updateCoverage = async (data: any) => {
    const validations = await validate(WorkDaySchema, data);
    if (validations) {
      console.log(validations, data);
      setState({ ...state, validations });
      return;
    }

    const payload: any = _createData(data);
    dispatch(LOADING_ON());
    try {
      for (const coverage of payload) {
        await Schedule.updateCoverage(coverage.PK, coverage);
      }
      await getData();
    } catch (e: any) {
      console.log('ERROR LOG', e.message);
      dispatch(LOADING_OFF());
    }
  };

  const _setUpdateAndOpen = (payload: any) => {
    setState({ ...state, work_day: payload, modals: { ...state.modals, update: true } });
  };

  const Actions = (props: any) => {
    const { payload } = props;
    return (
      <div className="text-center color-grey-menu">
        <Whisper speaker={<Tooltip>Editar</Tooltip>}>
          <button className="btn px-0 color-grey-menu" onClick={() => _setUpdateAndOpen(payload)}>
            <i className="bi bi-pencil"></i>
          </button>
        </Whisper>
      </div>
    );
  };

  const _sortTable = (column: any, type: any) => {
    if (type === '' || type === 'asc') {
      state.data.sort((a: any, b: any) => {
        if (a[column] > b[column]) return 1;
        if (a[column] < b[column]) return -1;
        return 0;
      });
      setState({ ...state, sort: { column, type: 'asc' } });
      return;
    }

    if (type === 'desc') {
      state.data.sort((a: any, b: any) => {
        if (a[column] < b[column]) return 1;
        if (a[column] > b[column]) return -1;
        return 0;
      });
      setState({ ...state, sort: { column, type: 'desc' } });
    }
  };

  return (
    <div className="container-fluid">
      <div className="row mt-5 align-items-center">
        <div className="col-8">
          <Title size="sm" text={`Jornada Laboral`} className="to-upper bold" />
        </div>
      </div>

      {/* Modals */}

      <ModalComponent open={state.modals.create} size="md" noFull hideFooter handleClose={() => _toggleModal('create')}>
        <AddWorkDay handleCancel={() => _toggleModal('create')} handleConfirm={_createCoverage} validations={state.validations} />
      </ModalComponent>

      <ModalComponent open={state.modals.update} size="md" noFull hideFooter handleClose={() => _toggleModal('update')}>
        <EditWorkDay handleCancel={() => _toggleModal('update')} handleConfirm={_updateCoverage} data={state.work_day} validations={state.validations} />
      </ModalComponent>

      <div className="row mt-5">
        <div className="col-6">
          <input type="text" id="search" className=" input-default w-100" placeholder="Buscar" onChange={_filter} value={state.search} />
        </div>
        <div className="col-6 color-white">
          <button className="btn-default size-08 ms-3" onClick={() => _toggleModal('create')}>
            {' '}
            + Nueva Jornada
          </button>
        </div>
      </div>

      <div className="row border rounded-10 mt-5 p-3" style={{ minHeight: '70vh' }}>
        <div className="col-12 h-100">
          <Table data={state.data} rowClassName="striped" rowHeight={70} locale={{ emptyMessage: 'No hay jornada laboral' }} autoHeight>
            {TableHeader &&
              TableHeader.map((column: any, index: any) => (
                <Table.Column align={column.align} flexGrow={column.flexGrow} key={`table-column-${index}`}>
                  <Table.HeaderCell>
                    <span style={{ textTransform: 'capitalize' }}>{column.label}</span>
                  </Table.HeaderCell>
                  <Table.Cell style={{ display: 'flex', alignItems: 'center', justifyContent: column.alignFlex }} dataKey={column.key}>
                    {(rowData) => {
                      switch (column.key) {
                        case 'is_active':
                          return (
                            <TagGroup className="w-100">
                              <Tag className="w-100" color={rowData[column.key] ? 'blue' : 'red'}>
                                {rowData[column.key] ? 'SI' : 'NO'}
                              </Tag>
                            </TagGroup>
                          );
                        default:
                          return <span>{rowData[column.key]}</span>;
                      }
                    }}
                  </Table.Cell>
                </Table.Column>
              ))}

            <Table.Column align="right" flexGrow={0.5} verticalAlign="middle">
              <Table.HeaderCell>{''}</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  return <Actions payload={rowData} />;
                }}
              </Table.Cell>
            </Table.Column>
          </Table>
        </div>
      </div>
      <br />
      <Pagination nextPage={nextPage} prevPage={prevPage} index={currentPage.index} />
    </div>
  );
};

export default WorkDay;

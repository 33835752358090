/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import SideMenu from '../components/SideMenu';
import TopMenu from '../components/TopMenu';
import { getSession } from '../helpers/session';

const TemplateDefault = (props: any) => {
  const { children, authorize } = props;
  const [backButton, setBackButton] = useState({ show: false });
  const history = useHistory();

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...props });
    }
    return child;
  });

  useEffect(() => {
    const fusionAuthSession = getSession();
    if (!fusionAuthSession) {
      history.push('/sign-in');
    }
  }, []);

  useEffect(() => {
    if (history.location?.pathname === '/pedidos') {
      setBackButton({ ...backButton, show: false });
    } else {
      setBackButton({ ...backButton, show: true });
    }
  }, []);

  const isAuthenticated = () => {
    try {
      const profile: any = getSession();
      if (!profile) {
        throw new Error('No session');
      }

      return true
    } catch (e: any) {
      authorize();
      return false;
    }
  }

  if (!isAuthenticated()) return null;

  return (
    <div className="container-fluid">
      <div className="row h-100">

        <TopMenu />

        <SideMenu />

        <div className="col-12 col-sm-9" style={{ minHeight: '90vh' }}>
          {backButton.show && (
            <div className="row mt-3">
              <div className="col-12">
                <button className="btn bold" style={{ color: '#3e3e3e' }} onClick={() => history.goBack()}>
                  <i className="bi bi-backspace me-2"></i>
                  Volver
                </button>
              </div>
            </div>
          )}

          {childrenWithProps}
        </div>
      </div>
    </div>
  );
};

export default TemplateDefault;

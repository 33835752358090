import Title from "../../components/Title"

const Evaluations = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <Title size="sm" text="Evaluaciones" className="mt-5 to-upper bold" />
      </div>
    </div>
  )
}

export default Evaluations
/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { SelectPicker } from 'rsuite';
import ErrorValidation from '../../components/ErrorValidation';
import { validateRut } from '../../helpers/Utils';
import validate from '../../helpers/validate';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import driverService from '../../services/driver.service';
import Service from '../../services/service_and_products.service';
import { OperatorUpdateSchema } from '../../validations/operator.update.validations';

const ModalUpdateDriver = (props: any) => {
  const params: any = useParams();
  const { handleClose, data } = props;
  const dispatch = useDispatch();
  const [state, setState] = useState<any>({
    inputs: {
      email: '',
      password: '',
      password_c: '',
      phone_number: '',
      rut: '',
      name: '',
      family_name: '',
      work_day: [],
    },
    validations: {},
    coverage: [],
  });

  useEffect(() => {
    _loadData();
  }, []);

  const _loadData = async () => {
    dispatch(LOADING_ON());
    try {
      let services: any = [];
      let existMoreServices = true;
      let next_page: any = null;
      let params: any = null;

      while (existMoreServices) {
        const responseService: any = await Service.list(params);
        next_page = responseService.next_page;

        services = services.concat(responseService.data);

        if (next_page && 'LastEvaluatedKey' in next_page) {
          const lastKey = next_page.LastEvaluatedKey;

          let nextPageUri = encodeURIComponent(JSON.stringify(lastKey));

          params = { last: nextPageUri };

        } else {
          existMoreServices = false;
        }
      }

      services = services.filter((service: any) => service.type !== 'recommended_subservice');

      const inputs = {
        email: data.email,
        phone_number: data.phone_number,
        rut: data.rut,
        name: data.name,
        family_name: data.family_name,
        PK: data.PK,
        SK: data.SK,
        service_id: String(data.info?.service_id),
        user_PK: data.user_PK,
        user_SK: data.user_SK,
      };

      console.log(inputs)

      setState({ ...state, inputs: { ...inputs }, services: services });
    } catch (e: any) {
      console.log('LOGGER', e.message);
      dispatch(LOADING_OFF());
    }
    dispatch(LOADING_OFF());
  };

  const _handleChange = ({ target }: any) => {
    if (target.name === 'rut' && target.value !== '') {
      const regexRutMatch: any = target.value.match(/[0-9\-\kK]/gm);
      if (target.value.length !== regexRutMatch?.length) return;
    }
    const { name, value } = target;
    state.inputs[name] = value;
    setState({ ...state });
  };

  const _handleUpdateUser = async () => {
    let validations = await validate(OperatorUpdateSchema, state.inputs);

    if (state.inputs?.phone_number?.indexOf('_') !== -1) {
      if (validations) {
        validations.phone_number = ['Número de teléfono inválido'];
      } else {
        validations = { phone_number: ['Número de teléfono inválido'] };
      }
    }
    if (validations) {
      setState({ ...state, validations });
      return;
    }

    if (!validateRut(state.inputs.rut)) {
      setState({ ...state, validations: { rut: ['El rut no es válido'] } });
      return;
    }
    dispatch(LOADING_ON());
    try {
      const data = {
        ...state.inputs,
        eds_id: params.id,
        info: { ...props.data.info, service_id: state.inputs.service_id },
        service_id: state.inputs.service_id,
        PK: state.inputs.user_PK,
        SK: state.inputs.user_SK,
      };
      await driverService.update(data);
      handleClose();
      toast.success('Operador actualizado con éxito');
    } catch (e: any) {
      const errors = e.message ? e.message.split(':') : [''];
      if (errors.length > 1) {
        errors.splice(0, 1);
      }
      const errorText = errors.join(' ');
      toast.error(errorText);
      dispatch(LOADING_OFF());
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 size-13 bold mb-4">Actualizar Conductor</div>

        <div className="col-6 mb-3">
          <span className="size-08 bold me-2">Nombre</span>
          <div className="">
            <input name="name" value={state.inputs.name} onChange={_handleChange} className="input-default w-100" type="text" placeholder="Ingresa nombre" />
          </div>
          <ErrorValidation className="size-09 bold-300 text-end" validations={state.validations} name="name" />
        </div>

        <div className="col-6 mb-3">
          <span className="size-08 bold me-2">Apellido</span>
          <div className="">
            <input name="family_name" value={state.inputs.family_name} onChange={_handleChange} className="input-default w-100" type="text" placeholder="Ingresa apellido" />
          </div>
          <ErrorValidation className="size-09 bold-300 text-end" validations={state.validations} name="family_name" />
        </div>

        <div className="col-6 mb-3">
          <span className="size-08 bold me-2">Teléfono</span>
          <div className="">
            <ReactInputMask
              mask="+56999999999"
              value={state.inputs.phone_number}
              name="phone_number"
              className="input-default w-100"
              onChange={_handleChange}
              placeholder="Ingresa teléfono"
              autoComplete="no"
            />
          </div>
          <ErrorValidation className="size-09 bold-300 text-end" validations={state.validations} name="phone_number" />
        </div>

        <div className="col-6 mb-3">
          <span className="size-08 bold me-2">Email</span>
          <div className="">
            <input name="email" value={state.inputs.email} onChange={_handleChange} className="input-default w-100" type="text" placeholder="Ingresa email" />
          </div>
          <ErrorValidation className="size-09 bold-300 text-end" validations={state.validations} name="email" />
        </div>

        <div className="col-6 mb-3">
          <span className="size-08 bold me-2">Rut</span>
          <div className="">
            <input
              name="rut"
              maxLength={10}
              value={state.inputs.rut}
              onChange={_handleChange}
              className="input-default w-100"
              type="text"
              placeholder="Ingresa rut sin puntos y con guión"
            />
          </div>
          <ErrorValidation className="size-09 bold-300 text-end" validations={state.validations} name="rut" />
        </div>

        <div className="col-6 mb-3">
          <span className=" size-08 bold me-2">Servicio</span>
          <div className="">
            <SelectPicker
              data={state.services}
              placeholder="Servicio"
              classPrefix="copec"
              className="rs-copec-cleanable"
              cleanable={false}
              value={state.inputs.service_id}
              onChange={(e: any) => _handleChange({ target: { name: 'service_id', value: e } })}
              locale={{ searchPlaceholder: 'Buscar', noResultsText: 'Ningún resultado encontrado.', emptyMessage: 'Ningún resultado encontrado.' }}
              labelKey="name"
              valueKey="PK"
              disabled
            />
          </div>
          <ErrorValidation className="size-09 bold-300 text-end" validations={state.validations} name="service_id" />
        </div>
      </div>

      <div className="row">
        <div className="col-12 text-end text-white">
          <button className="btn-default" onClick={_handleUpdateUser}>
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalUpdateDriver;

/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import Title from '../../components/Title';
import Services from './Services';
import SubServiceRecommended from './SubServiceRecommended';

const ServicesHub = () => {
  const [state, setState] = useState<any>({
    tabs: 1,
  });

  return (
    <div className="container-fluid">
      <div className="row my-5 align-items-center">
        <div className="col-8">
          <Title size="sm" text={state.tabs === 1 ? 'Servicios' : 'Sub servicios recomendados'} className="bold" />
        </div>
      </div>

      <div className="row px-3 mt-5">
        <div
          className={`col-4 copec-tab py-2 bold text-center size-12 ${state.tabs === 1 ? 'color-white bg-color-blue' : ''}`}
          onClick={() => setState({ ...state, tabs: 1 })}
          style={{ cursor: 'pointer' }}
        >
          Servicios
        </div>
        <div
          className={`col-4 copec-tab py-2 bold text-center size-12 ${state.tabs === 2 ? 'color-white bg-color-blue' : ''}`}
          onClick={() => setState({ ...state, tabs: 2 })}
          style={{ cursor: 'pointer' }}
        >
          Sub servicios Recomendados
        </div>
      </div>

      <div className="row border rounded-10 py-4 px-3">
        {state.tabs === 1 && <Services />}

        {state.tabs === 2 && <SubServiceRecommended />}
      </div>
    </div>
  );
};

export default ServicesHub;

/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useEffect, useState } from 'react';
import { DatePicker, SelectPicker } from 'rsuite';
import ErrorValidation from '../../components/ErrorValidation';
import Title from '../../components/Title';
import { days_of_week } from './days_of_week';

const EditWorkDay = (props: any) => {
  const { handleCancel, handleConfirm, data } = props;
  const [state, setState] = useState<any>({
    inputs: {
      days_of_week: [],
      name: '',
      start: null,
      end: null,
    },
    days_of_week: [...days_of_week],
  });

  useEffect(() => {
    if (data) {
      const timeStart = moment().toDate();
      let splited = data.start.split(':');
      timeStart.setHours(splited[0]);
      timeStart.setMinutes(splited[1]);

      const timeEnd = moment().toDate();
      splited = data.end.split(':');
      timeEnd.setHours(splited[0]);
      timeEnd.setMinutes(splited[1]);

      const inputs = {
        day_of_week: data.day_of_week,
        days_of_week: [data.day_of_week],
        name: data.name,
        start: timeStart,
        end: timeEnd,
        PK: data.PK,
      };
      setState({ ...state, inputs });
    }
  }, [data]);

  const _handleOnChange = (payload: any) => {
    const { target } = payload;
    state.inputs[target.name] = target.value;
    if (target.name === 'day_of_week') {
      state.inputs.days_of_week = target.value ? [target.value] : [];
    }
    setState({ ...state });
  };

  return (
    <div className="container-fluid">
      <div className="row mb-3">
        <div className="col-12">
          <Title size="sm" text={`Jornada Laboral`} className="to-upper bold" />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-12">
          <div className="size-08 bold me-2 mb-2">Nombre Jornada</div>
          <input value={state.inputs.name} name="name" className="input-default w-100" type="text" placeholder="Lunes - Viernes" onChange={_handleOnChange} />
        </div>
        <ErrorValidation name="name" className="text-end size-09 bold-300" validations={props.validations} />
      </div>

      <div className="row mb-3">
        <div className="col-12">
          <div className="size-08 bold me-2 mb-2">Días de la semana</div>
          <SelectPicker
            data={state.days_of_week}
            classPrefix="copec"
            className="rs-copec-cleanable"
            cleanable={false}
            placeholder="Día de la semana"
            onChange={(e) => _handleOnChange({ target: { value: e, name: 'day_of_week' } })}
            value={state.inputs.day_of_week}
            locale={{ searchPlaceholder: 'Buscar', noResultsText: 'Ningún resultado encontrado.', emptyMessage: 'Ningún resultado encontrado.' }}
          />
        </div>
        <ErrorValidation name="days_of_week" className="text-end size-09 bold-300" validations={props.validations} />
      </div>

      <div className="row">
        <div className="col-6">
          <span className="size-08 bold me-2">Desde</span>
          <div>
            <DatePicker
              format="HH:mm"
              placeholder=""
              classPrefix="copec"
              placement="auto"
              onChange={(e) => _handleOnChange({ target: { value: e, name: 'start' } })}
              value={state.inputs.start}
              locale={{
                sunday: 'Dom',
                monday: 'Lun',
                tuesday: 'Mar',
                wednesday: 'Mie',
                thursday: 'Jue',
                friday: 'Vie',
                saturday: 'Sab',
                ok: 'OK',
                today: '',
                yesterday: '',
                hours: 'Horas',
                minutes: 'Minutos',
                seconds: 'Segundos',
              }}
            />
          </div>
          <ErrorValidation name="start" className="text-end size-09 bold-300" validations={props.validations} />
        </div>

        <div className="col-6">
          <span className="size-08 bold me-2">Hasta</span>
          <div>
            <DatePicker
              format="HH:mm"
              placeholder=""
              classPrefix="copec"
              placement="auto"
              onChange={(e) => _handleOnChange({ target: { value: e, name: 'end' } })}
              value={state.inputs.end}
              locale={{
                sunday: 'Dom',
                monday: 'Lun',
                tuesday: 'Mar',
                wednesday: 'Mie',
                thursday: 'Jue',
                friday: 'Vie',
                saturday: 'Sab',
                ok: 'OK',
                today: '',
                yesterday: '',
                hours: 'Horas',
                minutes: 'Minutos',
                seconds: 'Segundos',
              }}
            />
          </div>
          <ErrorValidation name="end" className="text-end size-09 bold-300" validations={props.validations} />
        </div>

        <div className="row justify-content-around mt-5">
          <div className="col-2 text-center">
            <button className="btn-danger" onClick={handleCancel}>
              Cancelar
            </button>
          </div>

          <div className="col-2 text-center text-white">
            <button className="btn-default" onClick={() => handleConfirm(state.inputs)}>
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditWorkDay;

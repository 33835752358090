/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import validate from '../../helpers/validate';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import ordersService from '../../services/orders.service';
import { PersonalDataSchema } from '../../validations/personal_data.validations';
import ErrorValidation from '../ErrorValidation';
import Title from '../Title';
import InputMask from 'react-input-mask';
import { unformatRut, validateRut } from '../../helpers/Utils';

const UpdateOrderData = (props: any) => {
  const { data, handleClose, handleCloseAndUpdate } = props;

  const [state, setState] = useState<any>({
    status: [],
    validations: {},
    inputs: {
      name: '',
      phone: '',
      document: '',
      email: '',
      rut: '',
    },
  });

  const dispatch = useDispatch();
  const { session } = useSelector((state: any) => state);
  useEffect(() => {
    data.user.rut = unformatRut(data.user.rut);
    setState({ ...state, inputs: { ...data.user, phone: data.user?.phone_number } });
  }, []);

  const _handleOnChange = ({ target }: any) => {
    if (target.name === 'rut' && target.value !== '') {
      const regexRutMatch: any = target.value.match(/[0-9\-\kK]/gm);
      if (target.value.length !== regexRutMatch?.length) return;
    }

    const { name, value } = target;
    state.inputs[name] = value;
    setState({ ...state });
  };

  const _handleUpdateStatus = async () => {
    try {
      const { profile } = session;

      const isAdmin: any = profile?.info?.roles?.find((item: any) => item === 'admin');

      state.inputs.phone = state.inputs.phone?.replace(/_/g, '');

      if (state.inputs.phone) {
        if (state.inputs.phone.length === 9) {
          state.inputs.phone = '+56' + state.inputs.phone;
        }

        if (state.inputs.phone.length === 11) {
          state.inputs.phone = '+' + state.inputs.phone;
        }
      }

      let validations: any = await validate(PersonalDataSchema, state.inputs);

      const rutValidations = validateRut(state.inputs.rut);

      if (!rutValidations) {
        if (validations) {
          validations.rut = ['El rut no es válido'];
        } else {
          validations = { rut: ['El rut no es válido'] };
        }
      }

      if (validations) {
        setState({ ...state, validations });
        return;
      } else {
        setState({ ...state, validations: {} });
      }

      dispatch(LOADING_ON());
      let payload: any = { ...state.inputs };
      payload.PK = data.PK;
      payload.SK = data.SK;
      payload.phone_number = payload.phone;

      if (isAdmin) {
        await ordersService.updateOrderPersonalDataAdmin(data, payload);
      } else {
        await ordersService.updateOrderPersonalData(data, payload);
      }
      toast.success('Orden actualizada con éxito');
      handleCloseAndUpdate();
    } catch (e: any) {
      console.log('LOGGER', e);
      toast.error('No fue posible actualizar esta orden');
      dispatch(LOADING_OFF());
    }
  };

  return (
    <div className="component-services px-sm-5 mb-4">
      <div className="row">
        <Title size="sm" text="Editar orden" className="bold to-upper size-11" />
      </div>

      <div className="row mt-3">
        <div className="col-12 col-sm-6 mb-3">
          <div className="size-08 bold">Nombre</div>
          <input type="text" className="input-default w-100" placeholder="Ingresa nombre" onChange={_handleOnChange} name="name" value={state.inputs.name} />
          <ErrorValidation validations={state.validations} name="name" className="bold size-09 text-end" />
        </div>

        <div className="col-12 col-sm-6 mb-3">
          <div className="size-08 bold">Correo electrónico</div>
          <input type="text" className="input-default w-100" placeholder="Ingresa correo electrónico" onChange={_handleOnChange} name="email" value={state.inputs.email} />
          <ErrorValidation validations={state.validations} name="email" className="bold size-09 text-end" />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-6 mb-3">
          <div className="size-08 bold">Rut</div>
          <input
            type="text"
            maxLength={10}
            className="input-default w-100"
            onChange={_handleOnChange}
            placeholder="Ingresa rut sin puntos y con guión"
            name="rut"
            value={state.inputs.rut}
          />
          <ErrorValidation validations={state.validations} name="rut" className="bold size-09 text-end" />
        </div>

        <div className="col-12 col-sm-6 mb-3">
          <div className="size-08 bold">Teléfono</div>
          <InputMask mask="+56999999999" value={state.inputs.phone} name="phone" placeholder="Ingresa teléfono" className="input-default w-100" onChange={_handleOnChange} />
          <ErrorValidation validations={state.validations} name="phone" className="bold size-09 text-end" />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 text-center text-sm-end color-white">
          <button className="btn btn-danger me-5" onClick={handleClose}>
            Cancelar
          </button>
          <button className="btn btn-default color-white" onClick={_handleUpdateStatus}>
            Confirmar
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateOrderData;

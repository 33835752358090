import instance from './api_account';
import store from '../redux/store';
import moment from 'moment';
import { set_configs } from '../redux/actions/configs';
import { getSession } from '../helpers/session';
class OptionsService {
  _setHeaders = () => {
    const state: any = store.getState();
    const { session } = state;

    const oidcSession = getSession();

    if (oidcSession) {
      instance.defaults.headers.common['user'] = session.profile?.PK;
      instance.defaults.headers.common['usersk'] = session.profile?.SK;
      instance.defaults.headers.common['Authorization'] = `Bearer ${oidcSession?.access_token}`;
    }
  };

  getOrderState = async () => {
    this._setHeaders();
    const { configs, session }: any = store.getState();
    const dispatch: any = store.dispatch;
    try {
      const response = await instance.get('/account/order/states');

      const { profile } = session;

      const roles: any = profile?.info?.roles;

      if (roles && roles.find((role: any) => role?.toLowerCase() === 'concessionaire')) {
        response.data.data = response?.data?.data?.filter((status: any) => status?.value?.toLowerCase() !== 'new')
      } else {
        const newStatus: any = response?.data?.data?.find((status: any) => status.value?.toLowerCase() === 'new');

        if (newStatus) {
          newStatus.label = 'Incompleto';
        }
      }

      const order_status: any = {
        updated_at: moment().toString(),
        data: response.data,
      };
      dispatch(set_configs({ ...configs, order_status: order_status }));
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
}

const Options = new OptionsService();
export default Options;

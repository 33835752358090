/* eslint-disable no-useless-escape */
import * as yup from 'yup';

export const ServicePopupSchema = yup.object().shape({
  popup_title: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .max(255, 'Este campo acepta un máximo de 255 caracteres')
    .required('Este campo es requerido'),
  popup_obs: yup.string().matches(/^[\w\. ?¿¡!^\+-\:()áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos'),
  popup_description: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .required('Este campo es requerido'),
});

export type ServicePopupType = yup.InferType<typeof ServicePopupSchema>;

export const TableHeader = [
  {
    label: 'ID',
    key: 'PK',
    align: 'center',
    alignFlex: 'start',
    flexGrow: 1,
  },
  {
    label: 'Nombre',
    key: 'name',
    align: 'center',
    alignFlex: 'start',
    flexGrow: 1,
  },
  {
    label: 'Modelo Servicio',
    key: 'type',
    sub_key: 'nombre',
    align: 'center',
    alignFlex: 'center',
    flexGrow: 1,
  },
  {
    label: 'Tipo Servicio',
    key: 'is_company',
    align: 'center',
    alignFlex: 'center',
    flexGrow: 1,
  },
  {
    label: 'Agendamiento',
    key: 'scheduling',
    align: 'center',
    alignFlex: 'center',
    flexGrow: 1,
  }
]

export const TableHeaderRecommendedSS = [
  {
    label: 'ID',
    key: 'SK',
    align: 'center',
    alignFlex: 'start',
    flexGrow: 1,
  },
  {
    label: 'Nombre',
    key: 'name',
    align: 'center',
    alignFlex: 'start',
    flexGrow: 1,
  },
  {
    label: 'Tipo Servicio',
    key: 'type',
    sub_key: 'nombre',
    align: 'center',
    alignFlex: 'center',
    flexGrow: 1,
  }
]


export const SelectActions = [
  {
    label: 'Editar',
    value: 1
  }
]

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SelectPicker } from 'rsuite';
import ErrorValidation from '../../components/ErrorValidation';
import validate from '../../helpers/validate';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import CarsService from '../../services/car.service';
import { CarSchema } from '../../validations/car.validations';

const ModalCreateCar = (props: any) => {
  const [state, setState] = useState({
    inputs: {
      model: '',
      brand: '',
      capacity: '',
      plate: '',
      year: '',
      clearance: '',
      driver_id: '',
    },
    validations: {},
  });

  const params: any = useParams();

  const dispatch = useDispatch();

  const handleOnChange = ({ target }: any) => {
    if (target.name === 'year' || target.name === 'capacity' || target.name === 'clearance') {
      target.value = target.value.replace(/\D/g, '');
    }
    const { name, value } = target;
    if (value) {
      setState({ ...state, inputs: { ...state.inputs, [name]: value } });
    } else {
      setState({ ...state, inputs: { ...state.inputs, [name]: '' } });
    }
  };

  const handleCreateCar = async () => {
    const validations = await validate(CarSchema, state.inputs);
    if (validations) {
      setState({ ...state, validations });
      return;
    } else {
      setState({ ...state, validations: {} });
    }

    const payload: any = {
      plate: state.inputs.plate,
      year: state.inputs.year,
      type: 'null',
      main: false,
      info: {
        brand: {
          id: 0,
          label: state.inputs.brand,
        },
        model: {
          id: 0,
          label: state.inputs.model,
        },
        size: 1,
        clearance: state.inputs.clearance,
        capacity: state.inputs.capacity,
        driver_id: state.inputs.driver_id,
        eds_id: params.id,
      },
      driver_id: state.inputs.driver_id,
      eds_id: params.id,
    };

    try {
      dispatch(LOADING_ON());
      await CarsService.createVehicle(payload);
      props.handleClose();
      toast.success('Vehículo registrado con éxito');
    } catch (e: any) {
      console.log('DEBUG', e.message);
      toast.error('No fue posible crear este vehículo');
      dispatch(LOADING_OFF());
    }
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 size-13 bold mb-4">Nuevo Vehículo</div>
        <div className="col-12 mb-3">
          <span className="size-08 bold me-2">Marca</span>
          <div>
            <input name="brand" onChange={handleOnChange} value={state.inputs.brand} className="input-default w-100" type="text" placeholder="Marca" />
          </div>
          <ErrorValidation className="text-end size-09 bold-300" validations={state.validations} name="brand" />
        </div>
        <div className="col-12 mb-3">
          <span className="size-08 bold me-2">Modelo</span>
          <div>
            <input name="model" onChange={handleOnChange} value={state.inputs.model} className="input-default w-100" type="text" placeholder="Modelo" />
          </div>
          <ErrorValidation className="text-end size-09 bold-300" validations={state.validations} name="model" />
        </div>

        <div className="col-12 mb-3">
          <span className="size-08 bold me-2">Patente</span>
          <div>
            <input name="plate" onChange={handleOnChange} value={state.inputs.plate} className="input-default w-100" type="text" placeholder="Patente" />
          </div>
          <ErrorValidation className="text-end size-09 bold-300" validations={state.validations} name="plate" />
        </div>

        <div className="col-12 mb-3">
          <span className="size-08 bold me-2">Año</span>
          <div>
            <input name="year" onChange={handleOnChange} value={state.inputs.year} className="input-default w-100" type="text" placeholder="2022" />
          </div>
          <ErrorValidation className="text-end size-09 bold-300" validations={state.validations} name="year" />
        </div>

        <div className="col-12 mb-3">
          <span className="size-08 bold me-2">Capacidad</span>
          <div>
            <input name="capacity" onChange={handleOnChange} value={state.inputs.capacity} className="input-default w-100" type="text" placeholder="Capacidad" />
          </div>
          <ErrorValidation className="text-end size-09 bold-300" validations={state.validations} name="capacity" />
        </div>

        <div className="col-12 mb-3">
          <span className="size-08 bold me-2">Holgura</span>
          <div>
            <input name="clearance" onChange={handleOnChange} value={state.inputs.clearance} className="input-default w-100" type="text" placeholder="Holgura" />
          </div>
          <ErrorValidation className="text-end size-09 bold-300" validations={state.validations} name="clearance" />
        </div>

        <div className="col-12 mb-3">
          <span className="size-08 bold me-2">Conductor</span>
          <div>
            <SelectPicker
              classPrefix="copec"
              className="rs-copec-cleanable"
              cleanable={false}
              data={props.drivers}
              style={{ width: '100%' }}
              placeholder="Conductor"
              placement="auto"
              onChange={(e: any) => handleOnChange({ target: { name: 'driver_id', value: e } })}
              locale={{ searchPlaceholder: 'Buscar', noResultsText: 'Ningún resultado encontrado' }}
            />
          </div>
          <ErrorValidation className="text-end size-09 bold-300" validations={state.validations} name="driver_id" />
        </div>
      </div>

      <div className="row">
        <div className="col-12 text-end">
          <button className="btn-default" onClick={handleCreateCar}>
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalCreateCar;

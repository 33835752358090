import ErrorValidation from "../../components/ErrorValidation"
import InputMask from 'react-input-mask'

const UpdateConcessionaire = (props: any) => {
    const { data, handleChange } = props
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 size-13 bold mb-4">
                    Actualizar concesionario
            </div>

                <div className="col-6 mb-3">
                    <span className="size-08 bold me-2">Nombre</span>
                    <div className="">
                        <input name="name" value={data.inputs.name} onChange={handleChange} className="input-default w-100" type="text" placeholder="Pablo" />
                    </div>
                    <ErrorValidation className="size-09 bold-300 text-end" validations={data.validations} name="name" />
                </div>
                <div className="col-6 mb-3">
                    <span className="size-08 bold me-2">Apellido</span>
                    <div className="">
                        <input name="family_name" value={data.inputs.family_name} onChange={handleChange} className="input-default w-100" type="text" placeholder="Gonzalez" />
                    </div>
                    <ErrorValidation className="size-09 bold-300 text-end" validations={data.validations} name="family_name" />
                </div>
                <div className="col-6 mb-3">
                    <span className="size-08 bold me-2">Rut</span>
                    <div className="">
                        <input name="rut" value={data.inputs.rut} onChange={handleChange} className="input-default w-100" type="text" placeholder="Ingresa su número sin puntos" />
                    </div>
                    <ErrorValidation className="size-09 bold-300 text-end" validations={data.validations} name="rut" />
                </div>
                <div className="col-6 mb-3">
                    <span className="size-08 bold me-2">Teléfono</span>
                    <div className="">
                        <InputMask mask="+56999999999" value={data.inputs.phone_number} name="phone_number" className="input-default w-100" onChange={handleChange} />
                    </div>
                    <ErrorValidation className="size-09 bold-300 text-end" validations={data.validations} name="phone_number" />
                </div>
            </div>
        </div>
    )

}
export default UpdateConcessionaire

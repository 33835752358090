import { Toggle, Uploader } from "rsuite"
import ErrorValidation from "../ErrorValidation"

const DynamicPopUp = (props: any) => {
    const { validations, _handleOnChange, data, _handlePopUpImage } = props
    return (
        <div className="row">
            <div className="col-12 mt-3">
                <span className="size-08 bold me-4">Mostrar Pop-up</span>
                <div className='d-flex align-items-center' style={{ height: '50px' }}>
                    <Toggle checked={data?.service.show_dynamic_popup} onChange={(e: any) => _handleOnChange({ target: { name: 'show_dynamic_popup', value: e } })} />
                </div>
            </div>

            {data?.service?.show_dynamic_popup ? (
                <>
                    <div className="col-6 mt-3">
                        <span className="size-08 bold me-4">Titulo</span>
                        <div>
                            <input
                                value={data?.service?.popup_title}
                                onChange={_handleOnChange}
                                name="popup_title"
                                className="input-default w-100"
                                type="text"
                                placeholder="Importante"
                            />
                        </div>
                        <ErrorValidation validations={validations} name="popup_title" className="bold size-09 text-end" />
                    </div>

                    <div className="col-6 mt-3">
                        <span className="size-08 bold me-2">Imagen</span>
                        <div className="w-100">
                            <Uploader accept=".png, .jpg, .jpeg" listType="text" fileList={data.popUpFileList} onChange={_handlePopUpImage} autoUpload={false}>
                                <button>
                                    <span className="me-1">+</span> Seleccionar archivo
                                </button>
                            </Uploader>
                        </div>
                    </div>

                    <div className="col-12 mt-2">
                        <span className=" size-08 bold me-2">Descripción</span>
                        <div className="">
                            <textarea value={data?.service?.popup_description} onChange={_handleOnChange} name="popup_description" className="input-default w-100" placeholder="Descripción" />
                        </div>
                        <ErrorValidation validations={validations} name="popup_description" className="bold size-09 text-end" />
                    </div>

                    <div className="col-12 mt-3">
                        <span className="size-08 bold me-4">Observaciones</span>
                        <div>
                            <textarea value={data?.service?.popup_obs} onChange={_handleOnChange} name="popup_obs" className="input-default w-100" placeholder="Observaciones" />
                        </div>
                        <ErrorValidation validations={validations} name="popup_obs" className="bold size-09 text-end" />
                    </div>
                </>
            ) : null}
        </div>
    )
}

export default DynamicPopUp

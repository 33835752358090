import LogoSvg from './svg/logo.svg'
import SearchSVG from './svg/search.svg'
import SearchWhiteSVG from './svg/search-white.svg'
import UserSVG from './svg/user_line.svg'
import PasswordSVG from './svg/password.svg'

import AvatarPng from './png/avatar.png'
import BidonPng from './png/bidon_2.png'

import NotFoundJpg from './jpg/not-found.jpg';

const Images: any = {
  LogoSvg: {
    image: LogoSvg,
    alt: 'logo',
  },
  SearchSvg: {
    image: SearchSVG,
    alt: 'search',
  },
  SearchWhiteSVG: {
    image: SearchWhiteSVG,
    alt: 'search',
  },
  UserSVG: {
    image: UserSVG,
    alt: 'icon',
  },
  PasswordSVG: {
    image: PasswordSVG,
    alt: 'icon',
  },
  AvatarPng: {
    image: AvatarPng,
    alt: 'avatar-icon',
  },
  BidonPng: {
    image: BidonPng,
    alt: 'bidon-image',
  },
  NotFoundJpg: {
    image: NotFoundJpg,
    alt: 'not-found-image',
  },
}

export default Images
/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DatePicker, SelectPicker } from 'rsuite';
import ErrorValidation from '../../components/ErrorValidation';
import validate from '../../helpers/validate';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import discountService from '../../services/discount.service';
import { DiscountHourSchema } from '../../validations/discount_hour.validations';

const FormHourDiscount = (props: any) => {
  const { handleCancel, handleConfirm, type, services, days, data } = props;
  const [state, setState] = useState<any>({
    service_id: null,
    discount: '',
    day_of_week: null,
    start: null,
    end: null,
    validations: {},
  });
  const params: any = useParams();
  const dispatch: any = useDispatch();

  useEffect(() => {
    if (type === 'update') {
      data.start = moment(data.start, 'HH:mm').toDate();
      data.end = moment(data.end, 'HH:mm').toDate();
      setState({ ...state, ...data });
    }
  }, []);

  const _handleOnChange = ({ target }: any) => {
    if (target.name === 'discount') {
      target.value = target.value.replace(/\D/g, '');
    }
    state[target.name] = target.value;

    if (state.validations[target.name]) {
      delete state.validations[target.name];
    }
    setState({ ...state, validations: { ...state.validations } });
  };

  const _submitData = async () => {
    let validations: any = await validate(DiscountHourSchema, state);

    
    if (parseInt(state.discount) > 100) {
      validations = validations ? { ...validations, discount: ['No se puede ingresar un valor sobre el 100%'] } : { discount: ['No se puede ingresar un valor sobre el 100%'] };
    }
    
    setState({ ...state, validations });
    if (validations) return;

    const payload: any = {
      service_id: state.service_id,
      discount: state.discount,
      day_of_week: state.day_of_week?.toString(),
      eds_id: params.id,
      start: moment(state.start).format('HH:mm'),
      end: moment(state.end).format('HH:mm'),
      info: {
        nota: 'Descuento',
      },
    };

    dispatch(LOADING_ON());
    try {
      if (type === 'create') {
        await discountService.create(payload);
        toast.success('Descuento creado con éxito.')
      } else {
        payload.PK = state.PK;
        payload.SK = state.SK;
        await discountService.update(payload);
        toast.success('Descuento actualizado con éxito.')
      }
      handleConfirm();
    } catch (e: any) {
      toast.error(e.message);
      dispatch(LOADING_OFF());
    }
  };

  return (
    <div className="container-fluid">
      <div className="row mt-3">
        <div className="col-6 px-4 mb-3">
          <span className="size-08 bold me-2">Servicio</span>
          <SelectPicker
            classPrefix="copec"
            className="rs-copec-cleanable"
            cleanable={false}
            data={services}
            value={state.service_id}
            placeholder="Selecciona un servicio"
            onChange={(e) => _handleOnChange({ target: { value: e, name: 'service_id' } })}
            locale={{ searchPlaceholder: 'Buscar', noResultsText: 'Ningún resultado encontrado.', emptyMessage: 'Ningún resultado encontrado.' }}
          />
          <ErrorValidation validations={state.validations} name="service_id" className="text-end bold-300 size-09" />
        </div>

        <div className="col-6 px-4 mb-3">
          <span className="size-08 bold me-2">Descuento (%)</span>
          <input type="string" value={state.discount} className=" input-default w-100" placeholder="%" name="discount" onChange={_handleOnChange} />
          <ErrorValidation validations={state.validations} name="discount" className="text-end bold-300 size-09" />
        </div>

        <div className="col-12 px-4 mb-3">
          <span className="size-08 bold me-2">Día de la semana</span>
          <SelectPicker
            classPrefix="copec"
            className="rs-copec-cleanable"
            cleanable={false}
            data={days}
            value={state.day_of_week}
            placeholder="Selecciona día de la semana"
            onChange={(e) => _handleOnChange({ target: { value: e, name: 'day_of_week' } })}
            locale={{ searchPlaceholder: 'Buscar', noResultsText: 'Ningún resultado encontrado.', emptyMessage: 'Ningún resultado encontrado.' }}
          />
          <ErrorValidation validations={state.validations} name="day_of_week" className="text-end bold-300 size-09" />
        </div>

        <div className="col-6 px-4">
          <span className="size-08 bold me-2">Desde</span>
          <div className="">
            <DatePicker
              format="HH:mm"
              placeholder=""
              classPrefix="copec"
              placement="auto"
              cleanable={false}
              className="rs-copec-cleanable"
              value={state.start}
              onChange={(e) => _handleOnChange({ target: { value: e, name: 'start' } })}
              locale={{
                sunday: 'Dom',
                monday: 'Lun',
                tuesday: 'Mar',
                wednesday: 'Mie',
                thursday: 'Jue',
                friday: 'Vie',
                saturday: 'Sab',
                ok: 'OK',
                today: '',
                yesterday: '',
                hours: 'Horas',
                minutes: 'Minutos',
                seconds: 'Segundos',
              }}
            />
          </div>
          <ErrorValidation validations={state.validations} name="start" className="w-100 text-end bold-300 size-09" />
        </div>

        <div className="col-6 px-4">
          <span className=" size-08 bold me-2">Hasta</span>
          <div className="">
            <DatePicker
              format="HH:mm"
              placeholder=""
              classPrefix="copec"
              placement="auto"
              value={state.end}
              cleanable={false}
              className="rs-copec-cleanable"
              onChange={(e) => _handleOnChange({ target: { value: e, name: 'end' } })}
              locale={{
                sunday: 'Dom',
                monday: 'Lun',
                tuesday: 'Mar',
                wednesday: 'Mie',
                thursday: 'Jue',
                friday: 'Vie',
                saturday: 'Sab',
                ok: 'OK',
                today: '',
                yesterday: '',
                hours: 'Horas',
                minutes: 'Minutos',
                seconds: 'Segundos',
              }}
            />
          </div>
          <ErrorValidation validations={state.validations} name="end" className="w-100 text-end bold-300 size-09" />
        </div>
      </div>

      <div className="row justify-content-around mt-5">
        <div className="col-2 text-center color-white">
          <button className="btn-danger" onClick={handleCancel}>
            Cancelar
          </button>
        </div>

        <div className="col-2 text-center color-white">
          <button className="btn-default" onClick={_submitData}>
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormHourDiscount;

/* eslint-disable no-useless-escape */
import * as yup from 'yup';

export const CrossSellingSchema = yup.object().shape({
  percentage: yup
    .number()
    .max(99, 'Este campo debe ser menor a 100')
    .min(1, 'Este campo debe ser mayor a 0')
    .required('Este campo es requerido'),
  description: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .max(255, 'Este campo acepta un máximo de 255 caracteres')
    .required('Este campo es requerido'),
});

export type CrossSellingType = yup.InferType<typeof CrossSellingSchema>;

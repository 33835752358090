export const sub_service = {
    name: "",
    scheduling: "",
    shipping_price: "",
    price: "",
    descripcion: "",
    info: {
        Nota: "",
    },
    new_service: true
}

export const service_format = {
    name: "",
    scheduling: false,
    shipping_price: "",
    price: "",
    type: "",
    descripcion: "",
    info: {
        Nota: "",
    },
    sub_services: [],
    liter: '',
    min_reservation_time: 0,
    dont_show_grey_service: false,
    show_dynamic_popup: false,
    popup_title: '',
    popup_obs: '',
    popup_description: '',
    base_division: '',
}
/* eslint-disable react-hooks/exhaustive-deps */
import { Toggle, RadioGroup, Radio, DatePicker, TagPicker } from 'rsuite';
import ErrorValidation from '../../components/ErrorValidation';
import { useEffect, useState } from 'react';

const AddCouponConfig = (props: any) => {
  const { data, handleChange, is_edit } = props;
  const [disableLiter, setDisableLiter] = useState(true);

  useEffect(() => {
    const selectedServices: any = data.servicios.filter((service: any) => data.inputs?.pk_services.find((pk: any) => pk === service.value));
    if (selectedServices?.length === 0 || selectedServices.find((service: any) => service.type !== 'kerosene' && service.type !== 'kerosene-schedule')) {
      setDisableLiter(true);
    } else {
      setDisableLiter(false);
    }
  }, [data]);

  return (
    <div className="container-fluid">
      <div className="size-13 bold mb-2">{is_edit ? 'Edición de la ' : 'Nueva'} configuración del cupón</div>
      <div className="row border rounded-10 mt-2 p-3">
        <div className="row">
          <div className="col-12 size-13 bold mb-4">Configuración inicial</div>
          <div className="col-6 mb-3">
            <span className="size-08 bold me-2">Nombre</span>
            <div className="">
              <input name="name" value={data.inputs?.name} onChange={handleChange} className="input-default w-100" type="text" placeholder="Nombre" />
              <input name="pk" value={data.inputs?.pk || undefined} onChange={handleChange} type="hidden" />
            </div>
            <ErrorValidation className="size-09 bold-300 text-end" validations={data.validations} name="name" />
          </div>
          <div className="col-6 mb-3">
            <span className="size-08 bold me-2">Servicio</span>
            <div className="">
              <TagPicker
                cleanable={false}
                className="rs-picker-cleanable"
                data={data.servicios}
                onChange={(e: any) => handleChange({ target: { name: 'pk_services', value: e } })}
                value={data.inputs?.pk_services}
                placeholder="Selecciona uno o más servicios"
              />
            </div>
            <ErrorValidation className="size-09 bold-300 text-end" validations={data.validations} name="pk_services" />
          </div>
          <div className="col-6 mb-3">
            <span className="size-08 bold me-2">EDS</span>
            <div className="">
              <TagPicker
                cleanable={false}
                className="rs-picker-cleanable"
                data={data.eds}
                value={data.inputs?.pk_eds}
                onChange={(e) => handleChange({ target: { value: e, name: 'pk_eds' } })}
                placeholder="Selecciona una eds"
              />
            </div>
            <ErrorValidation className="size-09 bold-300 text-end" validations={data.validations} name="pk_eds" />
          </div>
          <div className="col-6 mb-3">
            <span className="size-08 bold me-2">Descuento</span>
            <div className="mt-2">
              <RadioGroup inline name="apply" onChange={(e: any) => handleChange({ target: { name: 'apply', value: e } })} value={data.inputs?.apply}>
                <Radio value="carrito">Sobre el total</Radio>
                <Radio value="sobre_envio">Envío gratis</Radio>
                <Radio value="servicios">Servicios</Radio>
              </RadioGroup>
            </div>
            <ErrorValidation className="size-09 bold-300 text-end" validations={data.validations} name="apply" />
          </div>
          <div className="col-6 mb-3">
            <span className="size-08 bold me-2">Tiene límite por uso de cliente</span>
            <div className="mt-2">
              <Toggle
                checked={data.inputs?.limitsame_campaign}
                onChange={(e: any) => handleChange({ target: { name: 'limitsame_campaign', value: e } })}
                className="me-2"
                style={{ width: 'fit-content' }}
              />
            </div>
            <ErrorValidation className="size-09 bold-300 text-end" validations={data.validations} name="limitsame_campaign" />
            {data.inputs?.limitsame_campaign && (
              <>
                <input name="limit_used" value={data.inputs?.limit_used} onChange={handleChange} className="input-default w-100 m-2" type="text" />
                <ErrorValidation className="size-09 bold-300 text-end" validations={data.validations} name="limit_used" />
              </>
            )}
          </div>
          <div className="col-6 mb-3">
            <span className="size-08 bold me-2">Tiene cantidad disponible</span>
            <div className="mt-2">
              <Toggle
                checked={data.inputs?.quantity_available_checkbox}
                onChange={(e: any) => handleChange({ target: { name: 'quantity_available_checkbox', value: e } })}
                className="me-2"
                style={{ width: 'fit-content' }}
              />
            </div>
            <ErrorValidation className="size-09 bold-300 text-end" validations={data.validations} name="quantity_available_checkbox" />
            {data.inputs?.quantity_available_checkbox && (
              <>
                <input name="initial_quantity_available" value={data.inputs?.initial_quantity_available} onChange={handleChange} className="input-default w-100 m-2" type="text" />
                <ErrorValidation className="size-09 bold-300 text-end" validations={data.validations} name="initial_quantity_available" />
              </>
            )}
          </div>
        </div>
      </div>
      {data.inputs?.apply && data.inputs?.apply !== 'sobre_envio' && (
        <div className="row border rounded-10 mt-2 p-3">
          <div className="row">
            <div className="col-12 size-13 bold mb-4">Categoría cupón</div>
            <div className="col-7 mb-3">
              <span className="size-08 bold me-2">Categoría</span>
              <div className="mt-2">
                <RadioGroup inline onChange={(e: any) => handleChange({ target: { name: 'type_discount', value: e } })} value={data.inputs?.type_discount}>
                  <Radio value="monto">Monto</Radio>
                  <Radio value="porcentaje">Porcentaje</Radio>
                  <Radio value="litro" disabled={disableLiter}>
                    Por Litro
                  </Radio>
                </RadioGroup>
              </div>
              <ErrorValidation className="size-09 bold-300 text-end" validations={data.validations} name="type_discount" />
              {data.inputs?.type_discount && (
                <>
                  <input
                    name="max_amount_discount"
                    value={data.inputs?.max_amount_discount}
                    onChange={handleChange}
                    className="input-default w-100 m-2"
                    type="text"
                    maxLength={15}
                  />
                  <ErrorValidation className="size-09 bold-300 text-end" validations={data.validations} name="max_amount_discount" />
                </>
              )}
            </div>
            <div className="col-5 mb-3">
              <span className="size-08 bold me-2">Acumulable con descuento del día</span>
              <div className="mt-2">
                <Toggle
                  checked={data.inputs?.cumulative_day_discount}
                  onChange={(e: any) => handleChange({ target: { name: 'cumulative_day_discount', value: e } })}
                  className="me-2"
                  style={{ width: 'fit-content' }}
                />
              </div>
              <ErrorValidation className="size-09 bold-300 text-end" validations={data.validations} name="cumulative_day_discount" />
            </div>
            <div className="col-7 mb-3">
              <span className="size-08 bold me-2">Restricción</span>
              <div className="mt-2">
                <Toggle
                  checked={data.inputs?.restriccion}
                  onChange={(e: any) => handleChange({ target: { name: 'restriccion', value: e } })}
                  className="me-2"
                  style={{ width: 'fit-content' }}
                />
              </div>
              <ErrorValidation className="size-09 bold-300 text-end" validations={data.validations} name="restriccion" />
              {data.inputs?.restriccion && (
                <>
                  <input name="use_limit" value={data.inputs?.use_limit} onChange={handleChange} className="input-default w-100 m-2" type="text" maxLength={15} />
                  <ErrorValidation className="size-09 bold-300 text-end" validations={data.validations} name="use_limit" />
                </>
              )}
            </div>
            <div className="col-5 mb-3">
              <span className="size-08 bold me-2">Monto mínimo de activación</span>
              <div className="mt-2">
                <Toggle
                  checked={data.inputs?.minimum_amount}
                  onChange={(e: any) => handleChange({ target: { name: 'minimum_amount', value: e } })}
                  className="me-2"
                  style={{ width: 'fit-content' }}
                />
              </div>
              <ErrorValidation className="size-09 bold-300 text-end" validations={data.validations} name="minimum_amount" />
              {data.inputs?.minimum_amount && (
                <>
                  <input
                    name="minimum_amount_in_cart"
                    value={data.inputs?.minimum_amount_in_cart}
                    onChange={handleChange}
                    className="input-default w-100 m-2"
                    type="text"
                    maxLength={15}
                  />
                  <ErrorValidation className="size-09 bold-300 text-end" validations={data.validations} name="minimum_amount_in_cart" />
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {data.inputs?.apply && (
        <>
          <div className="row border rounded-10 mt-2 p-3">
            <div className="row">
              <div className="col-12 size-13 bold mb-4">Vigencia cupón</div>
              <div className="col-12 mb-3">
                <span className="size-08 bold me-2">Vigencia</span>
                <div className="mt-2">
                  <RadioGroup inline name="vigencia" onChange={(e: any) => handleChange({ target: { name: 'vigencia', value: e } })} value={data.inputs?.vigencia}>
                    <Radio value="campana">Igual a la campaña</Radio>
                    <Radio value="intervalo">Intervalo de tiempo</Radio>
                    <Radio value="dias">Día(s) de la semana</Radio>
                  </RadioGroup>
                </div>
                <ErrorValidation className="size-09 bold-300 text-end" validations={data.validations} name="vigencia" />
              </div>
              {data.inputs?.vigencia && data.inputs?.vigencia === 'dias' && (
                <div className="col-7 mb-3">
                  <div className="col-12">
                    <div className="size-08 bold me-2 mb-2">Días de la semana</div>
                    <TagPicker
                      data={data.days_of_week}
                      value={data.inputs?.apply_days}
                      placeholder="Selecciona uno o más días de la semana"
                      onChange={(e) => handleChange({ target: { value: e, name: 'apply_days' } })}
                    />
                  </div>
                  <ErrorValidation className="size-09 bold-300 text-end" validations={data.validations} name="apply_days" />
                </div>
              )}
              {data.inputs?.vigencia && (data.inputs?.vigencia === 'intervalo' || data.inputs?.vigencia === 'dias') && (
                <div className="col-7 mb-3">
                  <div className="col-12 mt-3">
                    <span className="size-08 bold me-2">Desde</span>
                    <div className="">
                      <DatePicker
                        format="dd/MM/yyyy -- HH:mm"
                        placeholder=""
                        classPrefix="copec"
                        cleanable={false}
                        className="rs-copec-cleanable"
                        placement="auto"
                        value={data.inputs?.start ? data.inputs?.start : new Date()}
                        onChange={(e) => handleChange({ target: { value: e, name: 'start' } })}
                        locale={{
                          sunday: 'Dom',
                          monday: 'Lun',
                          tuesday: 'Mar',
                          wednesday: 'Mie',
                          thursday: 'Jue',
                          friday: 'Vie',
                          saturday: 'Sab',
                          ok: 'OK',
                          today: '',
                          yesterday: '',
                          hours: 'Horas',
                          minutes: 'Minutos',
                          seconds: 'Segundos',
                        }}
                      />
                    </div>
                    <ErrorValidation className="size-09 bold-300 text-end" validations={data.validations} name="start" />
                  </div>
                  <div className="col-12 mt-3">
                    <span className=" size-08 bold me-2">Hasta</span>
                    <div className="">
                      <DatePicker
                        format="dd/MM/yyyy -- HH:mm"
                        placeholder=""
                        classPrefix="copec"
                        cleanable={false}
                        className="rs-copec-cleanable"
                        placement="auto"
                        value={data.inputs?.end ? data.inputs?.end : new Date()}
                        onChange={(e) => handleChange({ target: { value: e, name: 'end' } })}
                        locale={{
                          sunday: 'Dom',
                          monday: 'Lun',
                          tuesday: 'Mar',
                          wednesday: 'Mie',
                          thursday: 'Jue',
                          friday: 'Vie',
                          saturday: 'Sab',
                          ok: 'OK',
                          today: '',
                          yesterday: '',
                          hours: 'Horas',
                          minutes: 'Minutos',
                          seconds: 'Segundos',
                        }}
                      />
                    </div>
                    <ErrorValidation className="size-09 bold-300 text-end" validations={data.validations} name="end" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AddCouponConfig;

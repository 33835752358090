// import { signout } from "../redux/actions/session";
import store from '../redux/store';
import instanceOvert from './api_overt';
import instanceAdmin from './api_admin';
import { getSession } from '../helpers/session';

class AuthService {
  _setHeaders = () => {
    const state: any = store.getState();
    const { session } = state;

    const oidcSession = getSession();

    if (oidcSession) {
      instanceOvert.defaults.headers.common['user'] = session.profile?.PK;
      instanceOvert.defaults.headers.common['Authorization'] = `Bearer ${oidcSession?.access_token}`;
      instanceAdmin.defaults.headers.common['user'] = session.profile?.PK;
      instanceAdmin.defaults.headers.common['Authorization'] = `Bearer ${oidcSession?.access_token}`;
      return;
    }
  };

  signin = async (params: any) => {
    try {
      const response = await instanceOvert.post('/overt/sign-in', params);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  signinWithAuth0 = async (params: any) => {
    try {
      const response = await instanceOvert.post('/overt/admin/login', params);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  confirmCode = async (params: any) => {
    try {
      const response = await instanceOvert.post('/overt/confirm/code', params);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  register = async (params: any) => {
    try {
      const response = await instanceOvert.post('/overt/sign-up', params);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  getProfile = async ({ token }: { token: string }) => {
    try {
      const response = await instanceAdmin.get(`/admin/account`, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  updateUserProfile = async (params: any) => {
    try {
      const { session } = store.getState();
      const response = await instanceOvert.put('/overt/account/update', params, { headers: { userid: session.profile.PK } });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  getEds = async () => {
    this._setHeaders();
    try {
      const { session } = store.getState();
      const response = await instanceAdmin.get(`/admin/eds/list`, { headers: { userid: session.profile?.PK } });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  syncUser = async (params: any) => {
    this._setHeaders();
    try {
      const response = await instanceAdmin.post('admin/auth/sync_user', params);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
}

const Auth = new AuthService();

export default Auth;

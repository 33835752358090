/* eslint-disable react-hooks/exhaustive-deps */
import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Badge, SelectPicker, Table, Toggle, Tooltip, Whisper } from 'rsuite';
import Images from '../../assets/image';
import Image from '../../components/Image';
import Pagination from '../../components/PaginationFront/Pagination';
import Title from '../../components/Title';
import usePagination from '../../hooks/usePagination';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import concessionaireService from '../../services/concessionaire.service';
import EDSDAO from '../../services/eds.service';
import Product from '../../services/products.service';
import Service from '../../services/service_and_products.service';
import { TableHeader } from './tableConfig';

const ProductsEds = () => {
  const dispatch = useDispatch();
  const params: any = useParams();
  const history: any = useHistory();
  const [state, setState] = useState<any>({
    tabs: 1,
    checks: {},
    eds: [],
    inputs: {
      eds: null,
      service: null,
    },
    eds_data: {},
    drivers: [],
    products: [],
    original_products: [],
    selected_related_products: [],
    modals: {
      update: { open: false },
    },
  });

  const { loader } = useSelector((state: any) => state);

  const searchRef = useRef<any>(null);

  const onPrev = async (response: any) => {
    let products: any = [];
    dispatch(LOADING_ON());

    try {
      const related_products = state.eds_data.info?.related_products;
      let related_PK: any = null;
      let related_SK: any = null;
      if (related_products) {
        const related_product_PK = related_products.find((rp: any) => String(rp.service_PK) === String(state.inputs.service));
        if (related_product_PK) {
          related_PK = related_product_PK.PK;
          related_SK = related_product_PK.service_PK;
        }
      }

      if (related_PK) {
        const selected_products = await Product.related_list({ PK: related_PK, SK: related_SK });
        products = response.data.map((product: any) => {
          product.checked = selected_products.data?.related_products?.find((rp: any) => rp.PK === product.PK) ? true : false;
          return product;
        });
      } else {
        products = response.data.map((product: any) => {
          product.checked = false;
          return product;
        });
      }
    } catch (e: any) {
      toast.error('No fue posible cargar los productos.');
      dispatch(LOADING_OFF());
    }

    dispatch(LOADING_OFF());

    setState({
      ...state,
      products: products,
      original_products: products,
    });
  };

  const onNext = async (response: any) => {
    let products: any = [];
    dispatch(LOADING_ON());

    try {
      const related_products = state.eds_data.info?.related_products;
      let related_PK: any = null;
      let related_SK: any = null;
      if (related_products) {
        const related_product_PK = related_products.find((rp: any) => String(rp.service_PK) === String(state.inputs.service));
        if (related_product_PK) {
          related_PK = related_product_PK.PK;
          related_SK = related_product_PK.service_PK;
        }
      }

      if (related_PK) {
        const selected_products = await Product.related_list({ PK: related_PK, SK: related_SK });
        products = response.data.map((product: any) => {
          product.checked = selected_products.data?.related_products?.find((rp: any) => rp.PK === product.PK) ? true : false;
          return product;
        });
      } else {
        products = response.data.map((product: any) => {
          product.checked = false;
          return product;
        });
      }
    } catch (e: any) {
      toast.error('No fue posible cargar los productos.');
      dispatch(LOADING_OFF());
    }

    dispatch(LOADING_OFF());

    setState({
      ...state,
      products: products,
      original_products: products,
    });
  };

  const endpoint = 'getAll';

  const { currentPage, prevPage, nextPage, setFirstPage } = usePagination({ onPrev, onNext, service: Product, endpoint });

  const _getEds = async () => {
    dispatch(LOADING_ON());
    try {
      const searchParams = new URLSearchParams(history.location.search);
      const response = await EDSDAO.getDetails({ PK: searchParams.get('PK'), SK: searchParams.get('SK') });
      const serviceList = await Service.listAllServices();
      const services = serviceList.data.reduce((acc: any, current: any) => {
        if (response.data.info?.services_id?.find((s: any) => s === current.PK)) {
          acc.push({ value: current.PK, label: current.name });
        }
        return acc;
      }, []);
      setState({ ...state, eds_data: response.data, services, inputs: { ...state.inputs, eds: params.id } });
    } catch (e: any) {
      toast.error("No fue posible cargar las eds.")
    }
    dispatch(LOADING_OFF());
  };

  useEffect(() => {
    _getEds();
  }, []);

  const _filterOrder = (payload: any) => {
    const { original_products } = state;
    const products: any = original_products.filter((p: any) => p.name?.toLowerCase().includes(payload?.toLowerCase()) || p.sku?.toLowerCase().includes(payload?.toLowerCase()));
    setState({ ...state, products });
  };

  const _debounceFilter = debounce(_filterOrder, 500);

  const _handleToggleProduct = (PK: any, checked: any) => {
    const { original_products } = state;
    const original_product: any = original_products.find((p: any) => p.PK === PK);
    original_product.checked = !original_product.checked;
    setState({ ...state, original_products });
  };

  const Actions = (payload: any) => {
    const { data } = payload;
    return (
      <div className="row">
        <div className="col-12 color-grey-menu text-center">
          <Toggle checked={data.checked} onChange={(e: any) => _handleToggleProduct(data.PK, e)} disabled={!data.active} />
        </div>
      </div>
    );
  };

  const _handleSelectService = async ({ target }: any) => {
    dispatch(LOADING_ON());
    try {
      if (target.value) {
        const response = await Product.getAll();

        setFirstPage(response.next_page);

        const related_products = state.eds_data.info?.related_products;
        let related_PK: any = null;
        let related_SK: any = null;
        if (related_products) {
          const related_product_PK = related_products.find((rp: any) => String(rp.service_PK) === String(target.value));
          if (related_product_PK) {
            related_PK = related_product_PK.PK;
            related_SK = related_product_PK.service_PK;
          }
        }

        let products: any = [];

        let selected_related_products: any = [];

        if (related_PK) {
          const selected_products = await Product.related_list({ PK: related_PK, SK: related_SK });
          selected_related_products = selected_products.data?.related_products;
          products = response.data.map((product: any) => {
            product.checked = selected_products.data?.related_products?.find((rp: any) => rp.PK === product.PK) ? true : false;
            return product;
          });
        } else {
          products = response.data.map((product: any) => {
            product.checked = false;
            return product;
          });
        }

        state.inputs[target.name] = target.value;
        setState({ ...state, products, original_products: products, selected_related_products });
      } else {
        state.inputs[target.name] = target.value;
        setState({ ...state, products: [], original_products: [] });
      }
    } catch (e: any) {
      toast.error("No fue posible cargar los productos.")
    }
    dispatch(LOADING_OFF());
  };

  const _handleSubmit = async () => {
    dispatch(LOADING_ON());
    try {
      const products = [...state.original_products];

      const list = products.reduce((acc: any, current: any) => {
        if (current.checked === true) {
          acc.push(current.PK);
        }
        return acc;
      }, []);

      state.selected_related_products.forEach((srp: any) => {
        const product: any = list.find((PK: any) => PK === srp.PK);
        const removed: any = products.find((p: any) => p.PK === srp.PK && p.checked === false);

        if (!product && !removed) {
          list.push(srp.PK);
        }
      });

      const params = {
        eds_id: state.eds_data.PK,
        service_id: String(state.inputs.service),
        products_id: list,
        info: {
          Nota1: 'Los productos tiene que estar activos para poder ser agregados',
          Nota2: 'La EDS tiene que tene asociado el servicio en la lista de services_id',
        },
      };

      const response: any = await concessionaireService.relatedProducts(params);

      if (response.status === 'error') {
        throw new Error(response.data[0]);
      }

      const params_eds = {
        PK: state.eds_data.PK,
        concessionaire_id: state.eds_data.concessionaire_id,
        name: state.eds_data.name,
        code: state.eds_data.code,
        rut: state.eds_data.rut,
        info: state.eds_data.info,
      };

      if (state.eds_data.info.related_products) {
        const search = params_eds.info?.related_products?.find((rp: any) => rp.PK === response.data.PK && rp.service_PK === state.inputs.service);
        if (!search) {
          params_eds.info.related_products.push({ PK: response.data.PK, SK: response.data.SK, service_PK: state.inputs.service });
        }
      } else {
        params_eds.info.related_products = [{ PK: response.data.PK, SK: response.data.SK, service_PK: state.inputs.service }];
      }

      await EDSDAO.update(params_eds);

      toast.success('Productos agregados con éxito');
      setState({ ...state, products: [], original_products: [], inputs: { eds: null, service: null } });
    } catch (e: any) {
      dispatch(LOADING_OFF());
      toast.error(e.message);
    }
    dispatch(LOADING_OFF());
  };

  return (
    <div className="container-fluid">
      <div className="row my-5 align-items-center">
        <div className="col-4">
          <Title size="sm" text="Productos Adicionales" className="to-upper bold" />
        </div>

        <div className="col-8 position-relative text-end">
          <input
            onChange={(e: any) => _debounceFilter(e.target.value)}
            ref={searchRef}
            className="input-default w-50 rounded-06 pe-5"
            type="text"
            placeholder="Buscar"
            disabled={state.original_products.length === 0}
          />
          <Image {...Images.SearchSvg} className="position-absolute" style={{ width: '20px', right: '30px', top: '17px' }} />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <Title text={`#${state.eds_data?.code || ''} ${state.eds_data?.name || ''}`} className="size-11 bold color-dark-blue" />
        </div>
      </div>

      <div className="row border rounded-05 mt-4 p-3">
        <div className="col-12" style={{ zIndex: 101 }}>
          <SelectPicker
            classPrefix="copec"
            className="rs-copec-cleanable"
            cleanable={false}
            value={state.inputs.service}
            data={state.services}
            onChange={(e: any) => _handleSelectService({ target: { name: 'service', value: e } })}
            style={{ width: '100%', zIndex: 101 }}
            placeholder="Selecciona un servicio"
            locale={{ emptyMessage: '¡Pronto!', noResultsText: 'No hay servicios asociados', searchPlaceholder: 'Buscar' }}
          />
        </div>

        <div className="col-12 size-09 mt-5">
          <Table data={state.products} autoHeight rowClassName="striped" rowHeight={80} locale={{ emptyMessage: 'No hay productos.' }} loading={loader.loading}>
            {TableHeader &&
              TableHeader.map((column: any, index: any) => (
                <Table.Column flexGrow={1} align={column.align} key={`table-column-${index}`}>
                  <Table.HeaderCell>
                    <span style={{ textTransform: 'capitalize' }}>{column.label}</span>
                  </Table.HeaderCell>
                  <Table.Cell style={{ display: 'flex', alignItems: 'center', justifyContent: column.alignFlex }}>
                    {(rowData) => {
                      switch (column.key) {
                        case 'image':
                          return <img src={rowData?.info?.gallery?.url || Images.NotFoundJpg.image} alt="img-table" style={{ width: '50px' }} />;
                        case 'price':
                          return <>$ {rowData[column.key]?.toLocaleString('pt-BR')}</>;
                        case 'active':
                          return (
                            <>{rowData[column.key] ? <Badge color="green" content="activo" className="bold" /> : <Badge color="red" content="desactivado" className="bold" />}</>
                          );
                        default:
                          return (
                            <Whisper trigger="hover" placement="auto" controlId={`control-id-auto`} speaker={<Tooltip>{`${rowData[column.key]}`}</Tooltip>}>
                              <div style={{ textTransform: 'capitalize', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{`${rowData[column.key]}`}</div>
                            </Whisper>
                          );
                      }
                    }}
                  </Table.Cell>
                </Table.Column>
              ))}
            <Table.Column align="center" width={700} flexGrow={1} verticalAlign="middle">
              <Table.HeaderCell>{'Deshabilitar|Habilitar'}</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  return <Actions data={rowData} />;
                }}
              </Table.Cell>
            </Table.Column>
          </Table>
        </div>
        {state.products && state.products.length > 0 && (
          <>
            <div className="col-12 mt-4">
              <Pagination nextPage={nextPage} prevPage={prevPage} index={currentPage.index} />
            </div>
            <div className="col-12 d-flex justify-content-end mt-5 position-sticky bg-white shadow-bottom py-3" style={{ top: '0px', zIndex: 100 }}>
              <button className="btn btn-default" onClick={_handleSubmit}>
                Guardar
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProductsEds;

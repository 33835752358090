import './styles.scss';

interface ResultItemProps extends React.HTMLAttributes<HTMLDivElement> {
    item: any;
    onClick: (data: any) => void;
}

const ResultItem = ({ item, onClick, ...rest }: ResultItemProps) => {
    return (
        <div {...rest} className={`google-autocomplete-result-item ${rest.className}`} onClick={() => onClick(item)} >
            <div className="bold">{item.title}</div>
            <div className="size-08">{item.subtitle}</div>
        </div>
    );
}

export default ResultItem;
import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Calendar } from 'rsuite';
import { calendarSpanishlocale } from '../../helpers/locale';
import validate from '../../helpers/validate';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import ordersService from '../../services/orders.service';
import Schedule from '../../services/schedule.service';
import { ScheduleObsSchema } from '../../validations/schedule.validations';
import ErrorValidation from '../ErrorValidation';
import HourItem from '../HourItem';
import Title from '../Title';

const Reschedule = (props: any) => {
  const [state, setState] = useState<any>({
    schedule: { open: false, step: 1, day: moment().toDate() },
    availableHours: [],
    observations: '',
    validations: {},
  });

  const dispatch = useDispatch();
  const { loader } = useSelector((state: any) => state);

  const _getDetails = async () => {
    return await ordersService.details({ PK: props.order.PK, SK: props.order.SK });
  };

  const _handleOnChangeDate = (data: any) => {
    setState({ ...state, schedule: { ...state.schedule, day: moment(data).toDate() } });
  };

  const _handleOnChangeHour = (id: any) => {
    try {
      const newChecked = state.availableHours.find((h: any) => h.PK === id);
      const checked = state.availableHours.find((h: any) => h.checked === true);
      newChecked.checked = true;
      if (checked) {
        checked.checked = false;
      }
      setState({ ...state });
    } catch (e: any) {
      console.log('LOGGER', e);
    }
  };

  const _handleSchedule = async () => {
    if (state.schedule.step === 1) {
      try {
        const now = moment();
        const day = moment(state.schedule.day).add('1', 'hour');

        if (day.isBefore(now)) {
          toast.error('Selecciona un día válido.');
          return;
        }
        const params: any = {
          eds_id: props.order.SK,
          service_id: props.order.GS4PK,
          date: moment(state.schedule.day).format('YYYY-MM-DD'),
        };

        dispatch(LOADING_ON());
        const response = await Schedule.getAvailableTime(params);
        const { blocks } = response.schedule;

        if (!blocks) {
          toast.error('No hay horarios disponibles para la fecha seleccionada.');
          dispatch(LOADING_OFF());
          return;
        }

        const availables: any = blocks?.filter((item: any) => item.enable === true);

        console.log(availables);

        if (availables && availables.length === 0) {
          toast.error('No hay horarios disponibles para la fecha seleccionada.');
          dispatch(LOADING_OFF());
          return;
        }

        blocks.map((item: any, index: any) => {
          item.PK = index;
          const _ruleMoment = moment(item.date + ' ' + item.time);
          const today = moment();
          let status = false;

          _ruleMoment.subtract(1, 'hours');

          if (_ruleMoment.isAfter(today)) {
            status = true;
          }

          item.disabled = item.enable && status ? false : true;

          return item;
        });

        setState({ ...state, schedule: { ...state.schedule, step: 2 }, availableHours: blocks });
      } catch (e: any) {
        console.log('LOGGER', e.message);
        toast.error('No fue posible cargar las horas');
        dispatch(LOADING_OFF());
      }
      dispatch(LOADING_OFF());
    }

    if (state.schedule.step === 2) {
      const validations = await validate(ScheduleObsSchema, state);
      setState({ ...state, validations });
      if (validations) return;

      const hour = state.availableHours.find((h: any) => h.checked === true);
      if (hour) {
        const schedule_at = moment(`${hour.date} ${hour.time}`).toString();
        dispatch(LOADING_ON());
        try {
          const details = await _getDetails();
          const { reservation } = details.data;

          const params: any = {
            schedule_at,
            order_id: props.order.PK,
            PK: reservation?.PK,
            SK: reservation?.SK,
            info: { note: state.observations },
          };

          await Schedule.reSchedule(encodeURIComponent(reservation?.PK), params);
          props.handleSuccess();
          toast.success('Servicio reagendado con éxito');
        } catch (e: any) {
          console.log('LOGGER', e.message);
          toast.error('No fue posible reagendar su entrega. Favor intentar con otra hora.');
          dispatch(LOADING_OFF());
        }
      } else {
        toast.warning('Elige una hora antes de continuar');
      }
    }
  };

  const _handleRenderCalendarCell = (data: any) => {
    const now = moment();
    const day = moment(data).add(1, 'hour');
    if (day.isBefore(now)) {
      return <div style={{ width: '100%', height: '100%', position: 'absolute', backgroundColor: '#e3e3e3', opacity: '.3', top: '0', left: '0', zIndex: 1000 }} />;
    }

    return null;
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <Title size="sm" text="Reagendar" className="bold to-upper size-11" />
      </div>

      {state.schedule.step === 1 && (
        <Calendar compact bordered locale={calendarSpanishlocale} renderCell={_handleRenderCalendarCell} value={state.schedule.day} onChange={_handleOnChangeDate} />
      )}

      {state.schedule.step === 2 && (
        <>
          {state.availableHours &&
            state.availableHours.map((hour: any) => (
              <HourItem
                checked={hour.checked}
                day={state.schedule.day}
                hour={hour.time}
                discount={hour.discount}
                disabled={hour.disabled}
                onChange={() => _handleOnChangeHour(hour.PK)}
                key={hour.PK}
              />
            ))}

          <div className="row mt-3">
            <div className="col-12 bold-300 size-09">Observaciones</div>
            <div className="col-12">
              <textarea className="form-control" value={state.observations} onChange={(e: any) => setState({ ...state, observations: e.target.value })} />
            </div>
            <div className="col-12">
              <ErrorValidation validations={state.validations} name="observations" className="size-09 bold text-end" />
            </div>
          </div>
        </>
      )}

      {!loader.loading && state.availableHours.length === 0 && state.schedule.step === 2 && (
        <div className="row mt-5">
          <Title text="No encontramos ningun horario disponible para esta fecha" className="text-center color-dark-blue size-12 bold" />
        </div>
      )}

      <div className="row mt-4">
        <div className="col-12 text-end color-white">
          <button
            className="btn me-5"
            onClick={() => setState({ ...state, schedule: { ...state.schedule, step: 1 } })}
            style={{ cursor: state.schedule.step === 1 ? 'not-allowed' : 'pointer' }}
            disabled={state.schedule.step === 1}
          >
            Volver
          </button>
          <button className="btn btn-default" onClick={_handleSchedule}>
            {state.schedule.step === 1 ? 'Continuar' : 'Guardar'}
          </button>
        </div>
      </div>
    </div>
  );
};
export default Reschedule;

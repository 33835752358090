/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Loader } from '@googlemaps/js-api-loader';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { parseDrawMapsVariables } from '../../../helpers/Utils';
import ModalComponent from '../../Modal';
import Title from '../../Title';
import GEODAO from '../../../services/geozone.service';
import { LOADING_OFF, LOADING_ON } from '../../../redux/actions/loader';
import { SelectPicker, toaster } from 'rsuite';
import Message from '../../Message';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import EDSDAO from '../../../services/eds.service';
import { set_eds } from '../../../redux/actions/eds';
import Service from '../../../services/service_and_products.service';
import { ServiceTypes } from '../../../types';

const Coverage = () => {
  const { selected_eds, services } = useSelector((state: any) => state);
  const params: any = useParams();
  const dispatch = useDispatch();
  const [state, setState] = useState<any>({
    inputs: {
      coordinates: '',
      service: 0,
    },
    modals: {
      coverage: false,
    },
    coverages: [],
    coverage: null,
    listServices: [],
  });

  const mapRef = useRef<any>(null);

  useEffect(() => {
    if (state.modals.coverage === true) {
      _loadMapsAndGetServices();
    }
  }, [state.modals.coverage]);

  const _loadMapsAndGetServices = async () => {
    dispatch(LOADING_ON());
    try {
      const types = ServiceTypes();

      let responseService = null;
      let next_page = null;
      let nextPageUri = null;

      while (true) {
        try {
          nextPageUri = null;

          if (next_page && "LastEvaluatedKey" in next_page) {
            const lastKey = next_page.LastEvaluatedKey; // the key for the current page

            nextPageUri = encodeURIComponent(JSON.stringify(lastKey));
          }

          const getService: any = await Service.list(nextPageUri ? { last: nextPageUri } : null);

          if (!responseService) {
            responseService = getService;
          } else {
            responseService.data = [...responseService.data, ...getService.data];
          }
          next_page = getService.next_page;

          /** BREAK LOOP IF DONT EXISTS MORE RESULTS */
          if (!getService.next_page) break

        } catch (e) {
          toast.error('No fue posible cargar los servicios');
          dispatch(LOADING_OFF());
          break;
        }
      }


      const responseCoverage = await EDSDAO.getCovarage({ PK: selected_eds.PK, SK: selected_eds.SK });
      dispatch(LOADING_OFF());
      const services = responseService.data;
      const listServices: any = [];

      services.forEach((s: any) => {
        if (s.type !== 'recommended_subservice') {
          listServices.push({
            label: `${s.name}`,
            value: s.PK,
          });
        }
      });

      if (mapRef.current) {
        if (!state.google && state.modals.coverage) {
          const loader: any = new Loader({
            apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : '',
            version: 'weekly',
            libraries: ['places', 'geometry'],
            ...{},
          });

          loader.load().then(async (google: any) => {
            const location = { lat: -33.44875413813615, lng: -70.66793565449402 };
            const map = new google.maps.Map(mapRef.current, {
              center: location,
              zoom: 10,
            });
            const paths: any = [];
            setState({
              ...state,
              listServices,
              inputs: { ...state.inputs, coordinates: '' },
              google,
              paths,
              map,
              api_key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
              coverages: responseCoverage.data,
            });
          });
        } else {
          const location = { lat: -33.44875413813615, lng: -70.66793565449402 };
          const map = new state.google.maps.Map(mapRef.current, {
            center: location,
            zoom: 10,
          });
          setState({ ...state, listServices, inputs: { ...state.inputs, coordinates: '' }, map, coverages: responseCoverage.data });
        }
      }
    } catch (e: any) {
      console.log('LOGGER', e.message);
      dispatch(LOADING_OFF());
    }
  };

  const _handleChangeService = ({ target }: any) => {
    const { coverages } = state;
    const coverage = coverages.find((item: any) => item.info?.service_id === target.value);
    if (coverage) {
      setState({ ...state, coverage, inputs: { coordinates: JSON.stringify(coverage.info.json), service: target.value } });
    } else {
      setState({ ...state, inputs: { coordinates: '', service: target.value }, coverage: null });
    }
  };

  const _handleToggleModal = (target: any) => {
    setState({ ...state, modals: { ...state.modals, [target]: !state.modals[target] } });
  };

  const _handleChange = (e: any) => {
    setState({ ...state, inputs: { ...state.inputs, [e.target.name]: e.target.value } });
  };

  const _handleCoordinates = () => {
    try {
      const jsonData = JSON.parse(state.inputs.coordinates);
      const { features } = jsonData;
      const location: any = {};
      const data = features.map((feat: any) => {
        const latLng: any = [];
        feat.geometry.coordinates[0].forEach((coord: any) => {
          const [lng, lat] = coord;
          latLng.push({ lat, lng });
          location.lat = lat;
          location.lng = lng;
        });
        return {
          properties: feat.properties,
          geometry: {
            type: feat.geometry.type,
            coordinates: latLng,
          },
        };
      });
      const paths: any = [];
      data.forEach((item: any) => {
        if (state.paths && state.paths.length > 0) {
          state.paths.forEach((p: any) => p.setMap(null));
        }

        const mapsProperties = parseDrawMapsVariables(item.properties);
        const params = {
          paths: item.geometry.coordinates,
          ...mapsProperties,
        };
        const path = new state.google.maps.Polygon(params);

        path.setMap(state.map);

        paths.push(path);
      });
      setState({ ...state, paths });
      state.map.setCenter(new state.google.maps.LatLng(location.lat, location.lng), 9);
    } catch (e: any) {
      toast.error('Revisa el json antes de continuar');
    }
  };

  const _drawOnMap = async (location: any, google: any, map: any, paths: any) => {
    dispatch(LOADING_ON());
    const response = await GEODAO.getAll();
    const coverage: any = response.data.find((c: any) => c.eds_id[0].id === selected_eds.id);
    if (!coverage) {
      dispatch(LOADING_OFF());
      return '';
    }
    try {
      const jsonData = typeof coverage.coordinate === 'string' ? JSON.parse(coverage.coordinate) : coverage.coordinate;
      const { features } = jsonData;
      const data = features.map((feat: any) => {
        const latLng: any = [];
        feat.geometry.coordinates[0].forEach((coord: any) => {
          const [lng, lat] = coord;
          location.lat = lat;
          location.lng = lng;
          latLng.push({ lat, lng });
        });
        return {
          properties: feat.properties,
          geometry: {
            type: feat.geometry.type,
            coordinates: latLng,
          },
        };
      });
      data.forEach((item: any) => {
        if (paths && paths.length > 0) {
          paths.forEach((p: any) => p.setMap(null));
        }

        const mapsProperties = parseDrawMapsVariables(item.properties);
        const params = {
          paths: item.geometry.coordinates,
          ...mapsProperties,
        };
        const path = new google.maps.Polygon(params);

        path.setMap(map);

        paths.push(path);
      });

      map.setCenter(new google.maps.LatLng(location.lat, location.lng), 9);
      dispatch(LOADING_OFF());
      return jsonData;
    } catch (e: any) {
      console.log('ERROR LOG', e.message);
      toast.error('No fue posible recuperar el json de geozona', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(LOADING_OFF());
      return '';
    }
  };

  const _submitCoverageArea = async () => {
    dispatch(LOADING_ON());
    try {
      const { coverages } = state;
      const coordinates = JSON.parse(state.inputs.coordinates);
      const params: any = {
        info: {
          eds_id: selected_eds.PK,
          service_id: state.inputs.service,
          json: { ...coordinates },
        },
      };

      if (!params.info.service_id) {
        toast.error('Selecciona un servicio antes de continuar...');
        dispatch(LOADING_OFF());
        return;
      }

      const coverage = coverages.find((item: any) => item.info?.service_id === params.info.service_id);

      if (coverage) {
        params.PK = coverage.PK;
        params.SK = coverage.SK;
        await EDSDAO.updateCoverage(params);
      } else {
        await EDSDAO.createCoverage(params);
      }

      dispatch(LOADING_OFF());
      _handleToggleModal('coverage');
      toast.success('Cobertura agregada con éxito');
    } catch (e: any) {
      console.log('LOGGER', e);
      toast.error('Revisa el json antes de continuar');
      dispatch(LOADING_OFF());
    }
  };

  const _deleteCoverage = async () => {
    dispatch(LOADING_ON());
    try {
      const { coverage } = state;
      await EDSDAO.removeCoverage({ PK: coverage.PK, SK: coverage.SK });
      _handleToggleModal('coverage');
      toast.success('Cobertura eliminada con éxito');
    } catch (e: any) {
      toast.error('No fue posible eliminar la cobertura');
    }
    dispatch(LOADING_OFF());
  };

  return (
    <div>
      <ModalComponent hideFooter open={state.modals.coverage} handleClose={() => _handleToggleModal('coverage')}>
        <div className="container-fluid px-5">
          <div className="row mb-3">
            <div className="col-12">
              <div className="bold-300">Servicio</div>
              <SelectPicker
                locale={{ searchPlaceholder: 'Buscar', noResultsText: 'Ningún resultado encontrado.', emptyMessage: 'Ningún resultado encontrado.' }}
                data={state.listServices}
                placeholder="Selecciona un servicio"
                onChange={(e: any) => _handleChangeService({ target: { value: e } })}
                className="rs-copec-cleanable w-100"
                cleanable={false}
              />
            </div>
            <div className="col-12 mt-2">
              <div className="bold-300">Geojson</div>
              <textarea
                value={state.inputs.coordinates}
                onChange={_handleChange}
                className="input-default w-100 size-08"
                placeholder="Pega la información generada en geojson.io aquí"
                name="coordinates"
              />
            </div>
          </div>
          <div className="row justify-content-end">
            <div className="col-4 color-white">
              <button className="btn-default w-100 size-08" onClick={_handleCoordinates}>
                {' '}
                Cargar en el mapa
              </button>
            </div>
          </div>

          <div className="row justify-content-center my-3 ">
            <div className="col-12 rounded-15" ref={mapRef} style={{ height: '32vh', maxHeight: '32vh', overflow: 'auto' }} />
          </div>

          <div className="row mt-5">
            <div className="col-12 text-end color-white">
              <button className="btn-danger me-3" onClick={_deleteCoverage} disabled={!state.coverage}>
                Eliminar Cobertura
              </button>
              <a href="https://geojson.io/" className="me-3 color-white" target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>
                <button className="btn-default">Ir a Geojson.io</button>
              </a>
              <button className="btn-default" onClick={_submitCoverageArea}>
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </ModalComponent>

      <button className="border-2-blue py-4 w-100 text-center rounded-10 size-11 bold bg-color-white" onClick={() => _handleToggleModal('coverage')}>
        Cobertura
      </button>
    </div>
  );
};

export default Coverage;

import { createAction } from 'redux-actions';


export const SAVE_VERSION: any = createAction('SAVE_VERSION');
export const SAVE_CHANGES: any = createAction('SAVE_CHANGES');


export const save_version = (payload: any) => (dispatch: any) => {
    dispatch(SAVE_VERSION(payload));
    dispatch(SAVE_CHANGES());
}
/* eslint-disable no-useless-escape */
import * as yup from 'yup';

export const CompanySchema = yup.object().shape({
  name: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .max(255, 'Este campo acepta un máximo de 255 caracteres')
    .required('Este campo es requerido'),
  company_admin: yup.string().required('Este campo es requerido'),
  eds: yup.string().required('Este campo es requerido'),
  services: yup.array().min(1, "Selecciona uno o mas servicios").required('Este campo es requerido'),
});

export type CompanyType = yup.InferType<typeof CompanySchema>;

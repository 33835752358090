import { getSession } from '../helpers/session';
import store from '../redux/store';
import instance from './api_admin';

class GeozoneDataService {
  _setHeaders = () => {
    const state: any = store.getState();
    const { session } = state;

    const oidcSession = getSession();

    if (oidcSession) {
      instance.defaults.headers.common['user'] = session.profile?.PK;
      instance.defaults.headers.common['usersk'] = session.profile?.SK;
      instance.defaults.headers.common['Authorization'] = `Bearer ${oidcSession?.access_token}`;
    }
  };

  getAll = () => {
    this._setHeaders();
    return instance.get('/geozona/all');
  };

  create = (payload: any) => {
    this._setHeaders();
    try {
      const response = instance.post(`/admin/coverage/create/${payload.eds_id}`, payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  update = (payload: any) => {
    this._setHeaders();
    try {
      const response = instance.put(`/admin/coverage/update/${payload.PK}`, payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  remove = (payload: any) => {
    this._setHeaders();
    try {
      const response = instance.delete(`/admin/coverage/delete/${payload}`, { data: { hard: true } });
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
}

const GEODAO = new GeozoneDataService();

export default GEODAO;

export const main_menu = [
  {
    name: "Pedidos",
    icon: "bi bi-file-earmark-text me-3",
    url: "/pedidos",
  },
  {
    name: "Concesionario",
    icon: "bi bi-person me-3",
    url: "/concesionario",
  },
  {
    name: "EDS's",
    icon: "bi bi-building me-3",
    url: "/eds",
  },
  {
    name: "Servicios",
    icon: "bi bi-folder me-3",
    url: "/servicios",
  },
  {
    name: "Evaluaciones",
    icon: "bi bi-graph-up me-3",
    url: "/evaluaciones",
  },
  {
    name: "Productos Adicionales",
    icon: "bi bi-bag me-3",
    url: "/productos-adicionales",
  },
  {
    name: "Dashboard",
    icon: "bi bi-grid me-3",
    url: "/dashboard",
  },
  {
    name: "Jornada laborales",
    icon: "bi bi-briefcase me-3",
    url: "/jornada-laboral",
  },
]

export const eds_menu = [
  {
    name: "Pedidos",
    icon: "bi bi-file-earmark-text me-3",
    url: "/pedidos",
  },
  {
    name: "Conductores",
    icon: "bi bi-people me-3",
    url: "/drivers",
  },
  {
    name: "Productos",
    icon: "bi bi-bag me-3",
    url: "/productos",
  },
  {
    name: "Zona de cobertura",
    icon: "bi bi-map me-3",
    url: "/coverage",
  },
  {
    name: "Ruta",
    icon: "bi bi-geo-alt me-3",
    url: "/router",
  },
  {
    name: "Dashboard",
    icon: "bi bi-graph-up me-3",
    url: "/productos-adicionales",
  },
  {
    name: "Feedback",
    icon: "bi bi-star me-3",
    url: "/dashboard",
  },
  {
    name: "Horario",
    icon: "bi bi-clock me-3",
    url: "/jornada-laboral",
  }
]
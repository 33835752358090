/* eslint-disable no-useless-escape */
import * as yup from 'yup';

export const SubServiceSchema = yup.object().shape({
  name: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .max(255, 'Este campo acepta un máximo de 255 caracteres')
    .required('Este campo es requerido'),
  descripcion: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .required('Este campo es requerido'),
  extra_lubricant_sku: yup.string().matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos'),
  price: yup.string().required('Este campo es requerido'),
  info: yup.object({
    sku: yup
      .string()
      .matches(/^[\w\. ?¿¡!^\+\:()áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
      .max(255, 'Este campo acepta un máximo de 255 caracteres')
      .required('Este campo es requerido'),
    full_points: yup
      .string()
      .matches(/^[\w\. ?¿¡!^\+\:()áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
      .max(255, 'Este campo acepta un máximo de 255 caracteres')
  }),
});

export type SubServiceType = yup.InferType<typeof SubServiceSchema>;

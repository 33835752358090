import { Table } from "rsuite";
import { TableHeader } from "./tableConfigs";
import WhisperComponent from "../../components/WhisperComponent";

const UserTable = (props: any) => {
    const { data, toggleStatus, handleUpdate } = props

    const Translate = (props: any) => {
        const { role: apiRole, roles: apiRoles } = props
        let role = '';
        if (apiRole) role = apiRole
        if (!apiRole && apiRoles?.length > 0) role = apiRoles[0]

        switch (role) {
            case 'concessionaire': return <>Concesionario</>
            case 'admin': return <>Administrador</>
            case 'client_company': return <>Cliente</>
            case 'operator': return <>Operador</>
            case 'callcenter': return <>Call Center</>
            case 'user_company': return <>Admin Empresa</>
            default: return <>Inactivo</>
        }
    }

    const Actions = (props: any) => {
        const { payload } = props;
        return (
            <>
                <div className="d-flex">
                    <WhisperComponent text={payload.status ? 'Desactivar' : 'Activar'}>
                        <button className="btn p-0 m-0" onClick={() => toggleStatus(payload)}>
                            {payload.status ? <i className="bi bi-trash"></i> : <i className="bi bi-arrow-clockwise"></i>}
                        </button>
                    </WhisperComponent>
                </div>
                <div className="d-flex">
                    <WhisperComponent text={'Editar'}>
                        <button className="btn p-0 m-0" onClick={() => handleUpdate(payload)}>
                            <i className="bi bi-pencil-square"></i>
                        </button>
                    </WhisperComponent>
                </div>
            </>
        );
    };
    return (
        <div className="row border rounded-10 p-3 my-5">
            <div className="col-12">
                <Table data={data} autoHeight rowHeight={80} locale={{ emptyMessage: 'No hay usuarios' }}>
                    {TableHeader &&
                        TableHeader.map((column: any, index: any) => (
                            <Table.Column align={column.align} flexGrow={column.flexGrow} key={`table-column-${index}`}>
                                <Table.HeaderCell>
                                    <span className="bold" style={{ textTransform: 'capitalize' }}>
                                        {column.label}
                                    </span>
                                </Table.HeaderCell>
                                <Table.Cell style={{ display: 'flex', alignItems: 'center', justifyContent: column.alignFlex }}>
                                    {(rowData) => {
                                        switch (column.key) {
                                            case 'name':
                                                return (
                                                    <WhisperComponent text={`${rowData?.name}`} capitalizeOff align='left'>
                                                        <span>{`${rowData?.name} ${rowData?.family_name}`}</span>
                                                    </WhisperComponent>
                                                );
                                            case 'email':
                                                return (
                                                    <WhisperComponent text={`${rowData?.email}`} capitalizeOff align='left'>
                                                        <span>{`${rowData?.email}`}</span>
                                                    </WhisperComponent>
                                                );
                                            case 'role':
                                                return (
                                                    <WhisperComponent text={<Translate role={rowData?.info?.role} roles={rowData?.info?.roles} />}>
                                                        <span className="size-08"><Translate role={rowData?.info?.role} roles={rowData?.info?.roles} /></span>
                                                    </WhisperComponent>
                                                );
                                            case 'status':
                                                return (
                                                    <WhisperComponent text={rowData.status ? 'Activo' : 'Inactivo'}>
                                                        <span className="size-08">{rowData.status ? 'Activo' : 'Inactivo'}</span>
                                                    </WhisperComponent>
                                                );
                                            default:
                                                return <span>{rowData[column.key]}</span>;
                                        }
                                    }}
                                </Table.Cell>
                            </Table.Column>

                        ))}
                    <Table.Column align="center" flexGrow={1} verticalAlign="middle">
                        <Table.HeaderCell>{''}</Table.HeaderCell>
                        <Table.Cell>
                            {(rowData) => {
                                return <Actions payload={rowData} />;
                            }}
                        </Table.Cell>
                    </Table.Column>
                </Table>
            </div>
        </div >
    )
}

export default UserTable;
/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Divider, Table } from 'rsuite';
import ConfirmationModal from '../../components/ConfirmationModal';
import ModalComponent from '../../components/Modal';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import discountService from '../../services/discount.service';
import Service from '../../services/service_and_products.service';
import FormHourDiscount from './FormHourDiscount';
import { TableHeaderDiscount } from './tableConfig';

const HourDiscount = () => {
  const [modal, setModal] = useState({
    open: false,
    type: 'create',
    data: null,
  });
  const [state, setState] = useState<any>({
    data: [],
    services: [],
    consfirmations: {},
    days_of_week: [
      {
        value: '0',
        label: 'Domingo',
      },
      {
        value: '1',
        label: 'Lunes',
      },
      {
        value: '2',
        label: 'Martes',
      },
      {
        value: '3',
        label: 'Miércoles',
      },
      {
        value: '4',
        label: 'Jueves',
      },
      {
        value: '5',
        label: 'Viernes',
      },
      {
        value: '6',
        label: 'Sábado',
      },
    ],
  });
  const dispatch: any = useDispatch();
  const params: any = useParams();
  const { loader } = useSelector((state: any) => state);

  const [currentPage, setCurrentPage] = useState({
    index: 0,
    next_page: {
      LastEvaluatedKey: {},
    },
  });

  const [pages, setPages] = useState([
    {
      index: 0,
      currentKey: {}, // the LastEvaluatedKey for this element
      next_page: {
        LastEvaluatedKey: {},
      },
    },
  ]);

  useEffect(() => {
    _getData();
  }, []);

  const _getData = async () => {
    dispatch(LOADING_ON());
    try {
      let responseService = null;
      let next_page_service = null;
      let nextPageUri = null;

      while (true) {
        try {
          nextPageUri = null;

          if (next_page_service && "LastEvaluatedKey" in next_page_service) {
            const lastKey = next_page_service.LastEvaluatedKey; // the key for the current page

            nextPageUri = encodeURIComponent(JSON.stringify(lastKey));
          }

          const getService: any = await Service.list(nextPageUri ? { last: nextPageUri } : null);

          if (!responseService) {
            responseService = getService;
          } else {
            responseService.data = [...responseService.data, ...getService.data];
          }
          next_page_service = getService.next_page;

          /** BREAK LOOP IF DONT EXISTS MORE RESULTS */
          if (!getService.next_page) break

        } catch (e) {
          toast.error('No fue posible cargar los servicios');
          dispatch(LOADING_OFF());
          break;
        }
      }

      let serviceList: any = responseService.data.filter((item: any) => item.type !== 'recommended_subservice' && item.scheduling);
      serviceList = serviceList.map((item: any) => {
        return {
          value: item.PK,
          label: item.name,
        };
      });

      const responseDiscounts: any = await discountService.list(params.id);

      responseDiscounts?.data?.sort((a: any, b: any) => {
        if (a.service_id > b.service_id) return 1;
        if (a.service_id < b.service_id) return -1;
        return 0;
      });

      const next_page: any = responseDiscounts.next_page;

      const firstPage = {
        index: 1,
        currentKey: {},
        next_page: next_page,
      };

      setCurrentPage(firstPage);
      setPages([firstPage]);

      setState({ ...state, services: serviceList, data: responseDiscounts.data, delete_id: '', confirmation: { open: false } });
      setModal({ open: false, type: 'create', data: null });
      dispatch(LOADING_OFF());
    } catch (e: any) {
      toast.error(e.message);
      dispatch(LOADING_OFF());
    }
  };

  const prevPage = async () => {
    dispatch(LOADING_ON());

    const prevPage = currentPage.index - 1;

    const prevItem = pages.find((item) => item.index === prevPage);

    if (!prevItem) {
      toast.warning(`Ya estas en la pagina ${currentPage.index}`);
      dispatch(LOADING_OFF());
      return;
    }

    const keyPrevPage = prevItem.currentKey;

    let prevPageUri = encodeURIComponent(JSON.stringify(keyPrevPage));

    let pageQuery;

    if (Object.keys(keyPrevPage).length > 0) {
      pageQuery = { last: prevPageUri };
    } else {
      pageQuery = {};
    }

    const responseDiscounts: any = await discountService.list(params.id, pageQuery);

    const newCurrentPage = {
      index: currentPage.index - 1,
      currentKey: keyPrevPage, // The key for querying specifcally this page
      next_page: responseDiscounts.next_page,
    };

    setCurrentPage(newCurrentPage);

    setState({ ...state, data: responseDiscounts.data, delete_id: '', confirmation: { open: false } })

    dispatch(LOADING_OFF());
  };

  const nextPage = async () => {
    dispatch(LOADING_ON());
    // Having LastEvaluatedKey means theres probably more results
    const nextPage = currentPage.next_page;

    if (nextPage && 'LastEvaluatedKey' in nextPage) {
      const lastKey = nextPage.LastEvaluatedKey; // the key for the current page

      let nextPageUri = encodeURIComponent(JSON.stringify(lastKey));

      const responseDiscounts: any = await discountService.list(params.id, { last: nextPageUri });

      responseDiscounts?.data?.sort((a: any, b: any) => {
        if (a.service_id > b.service_id) return 1;
        if (a.service_id < b.service_id) return -1;
        return 0;
      });

      const newCurrentPage = {
        index: currentPage.index + 1,
        currentKey: lastKey, // The key for querying specifcally this page
        next_page: responseDiscounts.next_page,
      };

      setCurrentPage(newCurrentPage);
      setPages([...pages, newCurrentPage]);
      setState({ ...state, data: responseDiscounts.data, delete_id: '', confirmation: { open: false } })
    } else {
      toast.error(`No hay más resultados.`);
    }
    dispatch(LOADING_OFF());
  };

  const _handleToggleModal = (action: any = 'create', data: any = null) => {
    setModal({ ...modal, type: action, data: data, open: !modal.open });
  };

  const _deleteDiscount = async () => {
    dispatch(LOADING_ON());
    try {
      const discountTime: any = state.data.find((item: any) => item.SK === state.delete_id);
      await discountService.delete(discountTime);
      _getData();
      toast.success('Ítem eliminado con éxito!');
    } catch (e: any) {
      toast.error(e.message);
      dispatch(LOADING_OFF());
    }
  };

  const Actions = (props: any) => {
    const { payload } = props;
    return (
      <>
        <button className="btn p-0 size-11 color-grey-menu" onClick={() => _handleToggleModal('update', { ...payload })}>
          <i className="bi bi-pencil"></i>
        </button>
        <Divider vertical />
        <button className="btn p-0 size-11 color-grey-menu" onClick={() => setState({ ...state, confirmation: { open: true }, delete_id: payload.SK })}>
          <i className="bi bi-trash"></i>
        </button>
      </>
    );
  };

  return (
    <Fragment>
      <div className="row mt-4">
        <div className="col-12 d-flex justify-content-end color-white">
          <button className="btn-default" onClick={() => _handleToggleModal('create', null)}>
            <span className="me-2">+</span>
            Crear Descuento
          </button>
        </div>
      </div>

      <ModalComponent open={modal.open} noFull size={'md'} handleClose={() => _handleToggleModal('create', null)} hideFooter>
        <FormHourDiscount handleCancel={_handleToggleModal} handleConfirm={_getData} type={modal.type} data={modal.data} services={state.services} days={state.days_of_week} />
      </ModalComponent>

      <ConfirmationModal
        type="warning"
        open={state.confirmation?.open}
        size="sm"
        title="Eliminar"
        handleClose={() => setState({ ...state, delete_id: '', confirmation: { open: false } })}
        handleConfirm={_deleteDiscount}
      >
        <span className="bold-300 ms-3">¿Seguro que quieres eliminar este descuento?</span>
      </ConfirmationModal>

      <div className="row rounded-10 mt-5 p-3">
        <div className="col-12 h-100">
          <Table data={state.data} rowClassName="striped" rowHeight={50} autoHeight locale={{ emptyMessage: 'No hay descuentos por hora' }}>
            {TableHeaderDiscount &&
              TableHeaderDiscount.map((column: any, index: any) => (
                <Table.Column align={column.align} flexGrow={column.flexGrow} key={`table-column-${index}`}>
                  <Table.HeaderCell>
                    <span>{column.label}</span>
                  </Table.HeaderCell>
                  <Table.Cell style={{ display: 'flex', alignItems: 'center', justifyContent: column.alignFlex }} dataKey={column.key}>
                    {(rowData) => {
                      switch (column.key) {
                        case 'service_id':
                          return <span>{state.services?.find((item: any) => item.value === rowData[column.key])?.label}</span>;
                        case 'day_of_week':
                          return <span>{state.days_of_week?.find((item: any) => item.value === rowData[column.key])?.label}</span>;
                        default:
                          return <span>{rowData[column.key]}</span>;
                      }
                    }}
                  </Table.Cell>
                </Table.Column>
              ))}

            <Table.Column align="right" flexGrow={1}>
              <Table.HeaderCell>{''}</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  return (
                    <div className="me-4">
                      <Actions payload={rowData} />
                    </div>
                  );
                }}
              </Table.Cell>
            </Table.Column>
          </Table>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {!loader.loading && (
            <div className="mb-5 text-center d-flex justify-content-center">
              <button className="pagination-component__btn" onClick={() => prevPage()}>
                Anterior
              </button>
              <div className="pagination-component__center">{currentPage.index}</div>
              <button className="pagination-component__btn" onClick={() => nextPage()}>
                Próximo
              </button>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default HourDiscount;

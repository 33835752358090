import { Table } from 'rsuite';

const ItemsComplement = (props: any) => {
  const headerCellChanges: any = [
    {
      label: 'Nombre',
      key: 'name',
    },
    {
      label: 'Cantidad inicial',
      key: 'quantity_to_buy',
    },
    {
      label: 'Cantidad actual',
      key: 'new_quantity_to_buy',
    },
    {
      label: 'Precio',
      key: 'price',
    },
    {
      label: 'Total',
      key: 'total',
    },
  ];

  const headerCell: any = [
    {
      label: 'Nombre',
      key: 'name',
    },
    {
      label: 'Cantidad',
      key: 'quantity_to_buy',
    },
    {
      label: 'Precio',
      key: 'price',
    },
    {
      label: 'Total',
      key: 'total',
    },
  ];

  return (
    <div className="container-fluid">
      {props.data?.itemsWithoutChanges && props.data.itemsWithoutChanges.length > 0 && (
        <div className="row">
          <div className="col-12 bold size-12">Ítems</div>
          <div className="col-12 p-0 m-0">
            <Table data={props.data.itemsWithoutChanges || []} rowClassName="striped" autoHeight>
              {headerCell.map((column: any) => (
                <Table.Column key={column.name} flexGrow={1}>
                  <Table.HeaderCell align="center">
                    <span className="bold" style={{ textTransform: 'capitalize' }}>
                      {column.label}
                    </span>
                  </Table.HeaderCell>
                  <Table.Cell>
                    {(rowData: any) => {
                      switch (column.key) {
                        case 'total':
                          return <div className="w-100 text-center">$ {(rowData.price * rowData.quantity_to_buy)?.toLocaleString('pt-BR')}</div>;
                        case 'quantity_to_buy':
                        case 'new_quantity_to_buy':
                          return <div className="w-100 text-center">{rowData[column.key]}</div>;
                        case 'price':
                          return <div className="w-100 text-center">$ {rowData[column.key]?.toLocaleString('pt-BR')}</div>;
                        default:
                          return <div className="w-100 text-left">{rowData[column.key]}</div>;
                      }
                    }}
                  </Table.Cell>
                </Table.Column>
              ))}
            </Table>
          </div>
        </div>
      )}

      {props.data?.newItems && props.data.newItems.length > 0 && (
        <div className="row mt-4">
          <div className="col-12 bold size-12">Ítems nuevos</div>
          <div className="col-12">
            <Table data={props.data.newItems || []} rowClassName="striped" autoHeight>
              {headerCell.map((column: any) => (
                <Table.Column key={column.name} flexGrow={1}>
                  <Table.HeaderCell align="center">
                    <span className="bold" style={{ textTransform: 'capitalize' }}>
                      {column.label}
                    </span>
                  </Table.HeaderCell>
                  <Table.Cell>
                    {(rowData: any) => {
                      switch (column.key) {
                        case 'total':
                          return <div className="w-100 text-center">$ {(rowData.price * rowData.quantity_to_buy)?.toLocaleString('pt-BR')}</div>;
                        case 'quantity_to_buy':
                        case 'new_quantity_to_buy':
                          return <div className="w-100 text-center">{rowData[column.key]}</div>;
                        case 'price':
                          return <div className="w-100 text-center">$ {rowData[column.key]?.toLocaleString('pt-BR')}</div>;
                        default:
                          return <div className="w-100 text-left">{rowData[column.key]}</div>;
                      }
                    }}
                  </Table.Cell>
                </Table.Column>
              ))}
            </Table>
          </div>
        </div>
      )}

      {props.data?.itemsWithChanges && props.data.itemsWithChanges.length > 0 && (
        <div className="row mt-4">
          <div className="col-12 bold size-12">Ítems con cambios</div>
          <div className="col-12">
            <Table data={props.data.itemsWithChanges || []} rowClassName="striped" autoHeight>
              {headerCellChanges.map((column: any) => (
                <Table.Column key={column.name} flexGrow={1}>
                  <Table.HeaderCell align="center">
                    <span className="bold" style={{ textTransform: 'capitalize' }}>
                      {column.label}
                    </span>
                  </Table.HeaderCell>
                  <Table.Cell>
                    {(rowData: any) => {
                      switch (column.key) {
                        case 'total':
                          return <div className="w-100 text-center">$ {(rowData.price * rowData.new_quantity_to_buy)?.toLocaleString('pt-BR')}</div>;
                        case 'quantity_to_buy':
                        case 'new_quantity_to_buy':
                          return <div className="w-100 text-center">{rowData[column.key]}</div>;
                        case 'price':
                          return <div className="w-100 text-center">$ {rowData[column.key]?.toLocaleString('pt-BR')}</div>;
                        default:
                          return <div className="w-100 text-left">{rowData[column.key]}</div>;
                      }
                    }}
                  </Table.Cell>
                </Table.Column>
              ))}
            </Table>
          </div>
        </div>
      )}
    </div>
  );
};

export default ItemsComplement;

/* eslint-disable no-useless-escape */
import * as yup from 'yup';

export const WorkDaySchema = yup.object().shape({
  days_of_week: yup.array().min(1, 'Este campo es requerido').required('Este campo es requerido'),
  name: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .max(255, 'Este campo acepta un máximo de 255 caracteres')
    .required('Este campo es requerido'),
  start: yup.date().typeError('Este campo es requerido').required('Este campo es requerido'),
  end: yup.date().typeError('Este campo es requerido').required('Este campo es requerido'),
});

export type ScheduleLockType = yup.InferType<typeof WorkDaySchema>;

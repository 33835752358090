/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { DatePicker, Radio, RadioGroup, TagPicker, Toggle, Uploader } from 'rsuite';
import ErrorValidation from '../ErrorValidation';
import WhisperComponent from '../WhisperComponent';
import { ReactComponent as QuestionMarkIcon } from '../../assets/image/svg/question_mark.svg';

const SubscriptionSubService = (props: any) => {
  const { state, _handleOnChange, _handleImage, _handleDelete, PK, SK, recommended_subservice, type, sub_services_list, eds_list } = props;
  const [errors, setErrors] = useState({});
  const [ss, setSs] = useState<any>([]);

  useEffect(() => {
    const errors = state.validations;
    setErrors(errors);
  }, [state?.validations]);

  useEffect(() => {
    if (recommended_subservice) {
      const { sub_services } = recommended_subservice;
      if (sub_services) {
        const services: any = sub_services
          .filter((item: any) => item?.info?.service_model === type)
          .map((item: any) => {
            return { label: item.name, value: item.SK };
          });
        setSs(services);
      }
    }
  }, [recommended_subservice]);

  const _handleOnChangeData = ({ target }: any) => {
    const { name, value } = target;
    const data: any = {};
    data.name = name;
    data.PK = PK;
    data.SK = SK;

    const numberFields: any = [
      'price',
      'shipping_price',
      'full_points',
      'without_discount_price',
      'points_per_division',
      'number_of_coupons_to_create',
      'subscription_duration',
      'max_amount_discount',
    ];

    if (numberFields.includes(name)) {
      data.value = value.replace(/\D/g, '');
    } else {
      data.value = value;
    }
    _handleOnChange(data);
  };

  return (
    <div className="row border rounded-05 pb-5 pt-3 px-4 mt-4">
      <div className="col-6">
        <span className=" size-08 bold me-2">Nombre</span>
        <div className="">
          <input
            value={state?.name}
            onChange={_handleOnChangeData}
            name="name"
            className="input-default w-100"
            type="text"
            placeholder="Suscripción"
            disabled={state.info?.is_unvailable}
          />
        </div>
        <ErrorValidation validations={errors} name="name" className="bold size-09 text-end" />
      </div>

      <div className="col-3">
        <span className=" size-08 bold me-2">SKU</span>
        <div className="">
          <input
            value={state?.info?.sku}
            onChange={_handleOnChangeData}
            name="sku"
            className="input-default w-100"
            type="text"
            placeholder="SKU del sub servicio o producto"
            disabled={state.info?.is_unvailable}
          />
        </div>
        <ErrorValidation validations={errors} name="info.sku" className="bold size-09 text-end" />
      </div>

      <div className="col-3">
        <div className="d-flex justify-content-start">
          <span className="size-08 bold me-1">Puntos Full</span>
          <span>
            <WhisperComponent
              text={`Número de puntos que sera multiplicado por el valor total del servicio dividido por la "Base para cálcular Puntos Full"`}
              placement="topStart"
              className="p-0 m-0 size-08 bold flex aling-items-center"
              align="left"
            >
              <QuestionMarkIcon className="ms-1 mb-1" style={{ width: '20px', height: '20px' }} />
            </WhisperComponent>
          </span>
        </div>

        <div>
          <input
            value={state?.info?.points_per_division}
            onChange={_handleOnChangeData}
            name="points_per_division"
            className="input-default w-100"
            type="text"
            placeholder="Informa cuantos puntos Copec Full este servicio acumula"
          />
        </div>
        <ErrorValidation validations={errors} name="info.points_per_division" className="bold size-09 text-end" />
      </div>

      <div className="col-4 mt-2">
        <span className=" size-08 bold me-2">Precio</span>
        <div className="">
          <input
            value={state?.price}
            onChange={_handleOnChangeData}
            name="price"
            maxLength={12}
            className="input-default w-100"
            type="string"
            placeholder="00000"
            disabled={state.info?.is_unvailable}
          />
        </div>
        <ErrorValidation validations={errors} name="price" className="bold size-09 text-end" />
      </div>

      <div className="col-4 mt-2">
        <span className=" size-08 bold me-2">Precio sin descuento</span>
        <div className="">
          <input
            value={state?.info?.without_discount_price}
            onChange={_handleOnChangeData}
            name="without_discount_price"
            maxLength={12}
            className="input-default w-100"
            type="string"
            placeholder="00000"
            disabled={state.info?.is_unvailable}
          />
        </div>
        <ErrorValidation validations={errors} name="without_discount_price" className="bold size-09 text-end" />
      </div>

      <div className="col-4 mt-2">
        <span className="size-08 bold me-2">Imagen</span>
        <div className="w-100">
          <Uploader
            accept=".png, .jpg, .jpeg"
            listType="text"
            fileList={state?.fileList}
            onChange={(e: any) => _handleImage(e, SK, 'product')}
            autoUpload={false}
            disabled={state.info?.is_unvailable}
          >
            <button>
              <span className="me-1">+</span> Seleccionar archivo
            </button>
          </Uploader>
        </div>
      </div>

      <div className="col-6 mt-2">
        <span className=" size-08 bold me-2">Servicios</span>
        <div className="">
          <TagPicker
            cleanable={false}
            className="rs-picker-cleanable"
            data={sub_services_list}
            groupBy="type"
            onChange={(e: any) => _handleOnChangeData({ target: { name: 'pk_services', value: e } })}
            value={state.info?.pk_services}
            placeholder="Selecciona uno o más servicios"
            placement="auto"
          />
        </div>
        <ErrorValidation validations={errors} name="info.pk_services" className="bold size-09 text-end" />
      </div>

      <div className="col-6 mt-2">
        <span className=" size-08 bold me-2">EDS</span>
        <div className="">
          <TagPicker
            cleanable={false}
            className="rs-picker-cleanable"
            data={eds_list}
            onChange={(e: any) => _handleOnChangeData({ target: { name: 'pk_eds', value: e } })}
            value={state.info?.pk_eds}
            placeholder="Selecciona uno o más eds's"
          />
        </div>
        <ErrorValidation validations={errors} name="info.pk_eds" className="bold size-09 text-end" />
      </div>

      <div className="col-6 mt-2">
        <span className=" size-08 bold me-2">Cantidad de cupones</span>
        <div className="">
          <input
            value={state?.info?.number_of_coupons_to_create}
            onChange={_handleOnChangeData}
            name="number_of_coupons_to_create"
            maxLength={12}
            className="input-default w-100"
            type="string"
            placeholder="10"
          />
        </div>
        <ErrorValidation validations={errors} name="info.number_of_coupons_to_create" className="bold size-09 text-end" />
      </div>

      <div className="col-6 mt-2">
        <span className=" size-08 bold me-2">Tiempo de duración de la suscripción</span>
        <div className="">
          <input
            value={state?.info?.subscription_duration}
            onChange={_handleOnChangeData}
            name="subscription_duration"
            maxLength={12}
            className="input-default w-100"
            type="string"
            placeholder="Informa el número de días que dura una suscripción."
          />
        </div>
        <ErrorValidation validations={errors} name="info.subscription_duration" className="bold size-09 text-end" />
      </div>

      <div className="col-12 mt-2">
        <span className=" size-08 bold me-2">Descripción</span>
        <div className="">
          <textarea
            value={state?.descripcion}
            onChange={_handleOnChangeData}
            name="descripcion"
            className="input-default w-100"
            placeholder="Descripción"
            disabled={state.info?.is_unvailable}
          />
        </div>
        <ErrorValidation validations={errors} name="descripcion" className="bold size-09 text-end" />
      </div>

      <div className="col-6 my-4">
        <span className=" size-08 bold me-2">Categoria</span>
        <div className="">
          <RadioGroup inline name="apply" onChange={(e: any) => _handleOnChangeData({ target: { name: 'apply', value: e } })} value={state.info?.apply}>
            <Radio value="carrito">Sobre el total</Radio>
            <Radio value="sobre_envio">Envío gratis</Radio>
            <Radio value="servicios">Servicios</Radio>
          </RadioGroup>
        </div>
        <ErrorValidation validations={errors} name="info.apply" className="bold size-09 text-start" />
      </div>

      <div className="col-6 my-4">
        <span className=" size-08 bold me-2">Descuento</span>
        <div className="">
          <RadioGroup
            inline
            onChange={(e: any) => _handleOnChangeData({ target: { name: 'type_discount', value: e } })}
            value={state?.info?.type_discount}
            disabled={state.info?.apply === 'sobre_envio'}
          >
            <Radio value="monto">Monto</Radio>
            <Radio value="porcentaje">Porcentaje</Radio>
          </RadioGroup>
        </div>
        <ErrorValidation validations={errors} name="info.type_discount" className="bold size-09 text-start" />
        {state.info?.type_discount && (
          <>
            <input
              name="max_amount_discount"
              value={state.info?.max_amount_discount}
              onChange={_handleOnChangeData}
              className="input-default w-100 m-2"
              type="text"
              maxLength={15}
              disabled={state.info?.apply === 'sobre_envio'}
            />
            <ErrorValidation className="size-09 bold-300 text-end" validations={errors} name="info.max_amount_discount" />
          </>
        )}
      </div>

      <div className="col-6 mt-2 d-flex flex-column justify-content-center">
        <span className=" size-08 bold me-2">Desactivado</span>
        <div className="">
          <Toggle checked={state.info?.is_unvailable} onChange={(e: any) => _handleOnChangeData({ target: { name: 'is_unvailable', value: e } })} />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionSubService;

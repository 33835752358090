import { useState } from 'react'
import { DatePicker, TagPicker } from 'rsuite'
import ErrorValidation from '../../components/ErrorValidation'
import Title from '../../components/Title'

const AddWorkDay = (props: any) => {
  const { handleCancel, handleConfirm } = props
  const [state, setState] = useState<any>({
    inputs: {
      end: null,
      start: null,
      name: '',
      days_of_week: []
    },
    days_of_week: [
      {
        value: 0,
        label: 'Domingo',
      },
      {
        value: 1,
        label: 'Lunes',
      },
      {
        value: 2,
        label: 'Martes',
      },
      {
        value: 3,
        label: 'Miércoles',
      },
      {
        value: 4,
        label: 'Jueves',
      },
      {
        value: 5,
        label: 'Viernes',
      },
      {
        value: 6,
        label: 'Sábado',
      },
    ],
  })

  const _handleOnChange = (payload: any) => {
    const { target } = payload;
    setState({ ...state, inputs: { ...state.inputs, [target.name]: target.value } })
  }

  return (
    <div className="container-fluid">

      <div className="row mb-3">
        <div className="col-12">
          <Title size="sm" text={`Jornada Laboral`} className="to-upper bold" />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-12">
          <div className="size-08 bold me-2 mb-2">Nombre Jornada</div>
          <input
            value={state.inputs.name}
            name="name"
            className="input-default w-100"
            type="text"
            placeholder="Lunes - Viernes"
            onChange={_handleOnChange}
          />
        </div>
        <ErrorValidation name="name" className="text-end size-09 bold-300" validations={props.validations} />
      </div>

      <div className="row mb-3">
        <div className="col-12">
          <div className="size-08 bold me-2 mb-2">Días de la semana</div>
          <TagPicker
            data={state.days_of_week}
            placeholder="Días de la semana"
            onChange={(e) => _handleOnChange({ target: { value: e, name: 'days_of_week' } })}
          />
        </div>
        <ErrorValidation name="days_of_week" className="text-end size-09 bold-300" validations={props.validations} />
      </div>

      <div className="row">
        <div className="col-6">
          <span className="size-08 bold me-2">Desde</span>
          <div>
            <DatePicker
              format="HH:mm"
              placeholder=""
              classPrefix="copec"
              placement="auto"
              onChange={(e) => _handleOnChange({ target: { value: e, name: 'start' } })}
              locale={{
                sunday: 'Dom',
                monday: 'Lun',
                tuesday: 'Mar',
                wednesday: 'Mie',
                thursday: 'Jue',
                friday: 'Vie',
                saturday: 'Sab',
                ok: 'OK',
                today: '',
                yesterday: '',
                hours: 'Horas',
                minutes: 'Minutos',
                seconds: 'Segundos'
              }}
            />
          </div>
          <ErrorValidation name="start" className="text-end size-09 bold-300" validations={props.validations} />
        </div>

        <div className="col-6">
          <span className="size-08 bold me-2">Hasta</span>
          <div>
            <DatePicker
              format="HH:mm"
              placeholder=""
              classPrefix="copec"
              placement="auto"
              onChange={(e) => _handleOnChange({ target: { value: e, name: 'end' } })}
              locale={{
                sunday: 'Dom',
                monday: 'Lun',
                tuesday: 'Mar',
                wednesday: 'Mie',
                thursday: 'Jue',
                friday: 'Vie',
                saturday: 'Sab',
                ok: 'OK',
                today: '',
                yesterday: '',
                hours: 'Horas',
                minutes: 'Minutos',
                seconds: 'Segundos'
              }}
            />
          </div>
          <ErrorValidation name="end" className="text-end size-09 bold-300" validations={props.validations} />
        </div>

        <div className="row justify-content-around mt-5">
          <div className="col-2 text-center">
            <button className="btn-danger" onClick={handleCancel}>Cancelar</button>
          </div>

          <div className="col-2 text-center text-white">
            <button className="btn-default" onClick={() => handleConfirm(state.inputs)}>Guardar</button>
          </div>
        </div>
      </div>
    </div>
  )

}
export default AddWorkDay
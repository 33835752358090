import { createStore, combineReducers, applyMiddleware } from 'redux';
import { loader, matchMedia, products, services, orders, configs, work_day,
  eds, selected_eds, select_campaing, session, version } from './reducers';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

let intialState: any = {};
try {
  const storage: any = localStorage.getItem("global_state")
  intialState = storage ? JSON.parse(storage) : {};
} catch (error) {
  console.log('Get Storage Error', error)
}

const reducers = combineReducers({
  loader,
  products,
  services,
  orders,
  matchMedia,
  configs,
  work_day,
  eds,
  selected_eds,
  select_campaing,
  session,
  version
});

const saver = (store: any) => (next: any) => (action: any) => {
  let stateToSave = store.getState();
  localStorage.setItem("global_state", JSON.stringify({ ...stateToSave }))
  next(action);
}

let store: any;

if (process.env.NODE_ENV === 'production') {
  store = createStore(reducers, intialState, applyMiddleware(thunk, saver));
} else {
  store = createStore(reducers, intialState, composeWithDevTools(
    applyMiddleware(thunk, saver)
  ));
}

export default store;

const CompanyDetails = ({ data, eds, services, users }: { data: any, eds: any, services: any, users: any }) => {

    const getUserData = (id: any) => {
        const user = users.find((user: any) => user.PK === id);
        return user ? `${user.name} ${user.family_name}` : '';
    }

    const getServiceData = (ids: any) => {
        const servicesData = services.filter((service: any) => ids.includes(service.PK));
        return servicesData.map((item: any) => item.name).join(", ")
    }

    const getEdsData = (id: any) => {
        const edsData = eds.find((item: any) => item.PK === id);
        return edsData ? `${edsData.name}` : '';
    }

    return (
        <div className="container-fluid size-09">
            <div className="row mb-3">
                <div className="col-12 size-13 bold">
                    Empresa
                </div>
            </div>

            <div className="row size-09 mb-2">
                <div className="col-3 bold">
                    PK:
                </div>
                <div className="col-9">
                    {data?.PK}
                </div>
            </div>

            <div className="row size-09 mb-2">
                <div className="col-3 bold">
                    SK:
                </div>
                <div className="col-9">
                    {data?.SK}
                </div>
            </div>

            <div className="row size-09 mb-2">
                <div className="col-3 bold">
                    Nombre:
                </div>
                <div className="col-9">
                    {data?.name}
                </div>
            </div>

            <div className="row size-09 mb-2">
                <div className="col-3 bold">
                    Servicios:
                </div>
                <div className="col-9">
                    {getServiceData(data?.services)}
                </div>
            </div>

            <div className="row size-09 mb-2">
                <div className="col-3 bold">
                    EDS:
                </div>
                <div className="col-9">
                    {getEdsData(data?.GS1SK)}
                </div>
            </div>

            <div className="row size-09 mb-2">
                <div className="col-3 bold">
                    Admin Empresa:
                </div>
                <div className="col-9">
                    {getUserData(data?.company_admin)}
                </div>
            </div>

            <div className="row size-09 mb-2">
                <div className="col-3 bold">
                   Dirección:
                </div>
                <div className="col-9">
                    {data?.address}
                </div>
            </div>

        </div>
    )
}

export default CompanyDetails;
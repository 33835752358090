/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Title from '../../components/Title';
import HourDiscount from './HourDiscount';
import TimeBlock from './TimeBlock';

const WorkDayEds = () => {
  const { selected_eds } = useSelector((state: any) => state);
  const [tab, setTab] = useState<any>(1);

  return (
    <div className="container-fluid">
      <div className="row mt-5 align-items-center">
        <div className="col-8">
          <Title size="sm" text={`${tab === 1 ? 'Bloques horarios' : 'Descuento por hora'} EDS #${selected_eds.code}`} className="to-upper bold" />
        </div>
      </div>

      <div className="row px-3 mt-5">
        <div
          className={`col-4 copec-tab py-2 bold text-center size-12 ${tab === 1 ? 'color-white bg-color-blue' : ''}`}
          onClick={() => setTab(1)}
          style={{ cursor: 'pointer' }}
        >
          Bloque horario
        </div>
        <div
          className={`col-4 copec-tab py-2 bold text-center size-12 ${tab === 2 ? 'color-white bg-color-blue' : ''}`}
          onClick={() => setTab(2)}
          style={{ cursor: 'pointer' }}
        >
          Descuento por hora
        </div>
      </div>

      <div className="row border rounded-10 py-4 px-3 mb-3">
        {tab === 1 && <TimeBlock />}

        {tab === 2 && <HourDiscount />}
      </div>
    </div>
  );
};

export default WorkDayEds;

import { getSession } from '../helpers/session';
import store from '../redux/store';
import instance from './api_admin';

class MediaDataAccess {
  _setHeaders = () => {
    const state: any = store.getState();
    const { session } = state;

    const oidcSession = getSession();

    if (oidcSession) {
      instance.defaults.headers.common['user'] = session.profile?.PK;
      instance.defaults.headers.common['usersk'] = session.profile?.SK;
      instance.defaults.headers.common['Authorization'] = `Bearer ${oidcSession?.access_token}`;
    }
  };

  _deleteHeaders = () => {
    delete instance.defaults.headers.common['user'];
    delete instance.defaults.headers.common['usersk'];
    delete instance.defaults.headers.common['Authorization'];
  };
  create = async (payload: any) => {
    this._setHeaders();
    try {
      const response = await instance.post('/admin/upload/file', payload);
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  delete = async (PK: any) => {
    this._setHeaders();
    try {
      const response = await instance.post(`/admin/delete/file/${PK}`, { data: { hard: true } });
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  presigned = async (payload: any) => {
    this._setHeaders();
    try {
      const response = await instance.post(`/admin/presigned/s3`, payload);
      console.log(response);
      return response;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  presignedSend = async (url: any, file: any) => {
    this._deleteHeaders();
    try {
      instance.defaults.headers.common['x-amz-acl'] = 'public-read';
      delete instance.defaults.headers.common['Content-type'];
      instance.defaults.headers.common['Content-type'] = "image/png";
      const response = await instance.put(url, file);
      delete instance.defaults.headers.common['Content-type'];
      instance.defaults.headers.common['Content-type'] = "application/json";
      this._setHeaders();
      return response;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };
}

const MediaFiles = new MediaDataAccess();
export default MediaFiles;

/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Table } from 'rsuite';
import ModalComponent from '../../components/Modal';
import FormDynamicService from '../../components/Services/FormDynamicService';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import Service from '../../services/service_and_products.service';
import { ServiceCategory, ServiceTypes } from '../../types';
import { TableHeader } from './tableConfig';
import Pagination from '../../components/PaginationFront/Pagination';
import usePagination from '../../hooks/usePagination';

const Services = (props: any) => {
  const [state, setState] = useState<any>({
    inputs: {
      nombre: '',
      descripcion: '',
      productos: [],
      timestamp: '',
      sub_servicios: [],
      agendamiento: false,
      precio: '0',
    },
    checks: {
      kerosene: false,
      lub: false,
      lavamax: false,
    },
    services: [],
    service: {},
    recommended_subservice: {},
    modals: {
      add_service: false,
      update_service: false,
    },
    service_edited: null,
    count: 0,
    types: [],
    categories: [],
  });

  const onPrev = async (response: any) => {
    const services: any = response.data.filter((item: any) => item.type !== 'recommended_subservice');

    setState({
      ...state,
      services,
    });
  };

  const onNext = async (response: any) => {
    const services: any = response.data.filter((item: any) => item.type !== 'recommended_subservice');

    setState({
      ...state,
      services,
    });
  };

  const endpoint = 'list';

  const { currentPage, prevPage, nextPage, setFirstPage } = usePagination({ onPrev, onNext, service: Service, endpoint });

  const dispatch = useDispatch();

  const getServices = async () => {
    dispatch(LOADING_ON());
    try {
      const response = await Service.list();
      const services: any = response.data.filter((item: any) => item.type !== 'recommended_subservice');

      setFirstPage(response.next_page);

      const recommended_subservice: any = response.data.find((item: any) => item.type === 'recommended_subservice');
      let recommended_subservice_response: any = { data: { sub_services: [] } };

      if (recommended_subservice) {
        recommended_subservice_response = await Service.details({ PK: recommended_subservice.PK, SK: recommended_subservice.SK });
      }
      services.sort((a: any, b: any) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      const serviceTypes = ServiceTypes();
      const serviceCategories = ServiceCategory();
      setState({
        ...state,
        services,
        recommended_subservice: recommended_subservice_response.data,
        types: serviceTypes,
        categories: serviceCategories,
        modals: { add_service: false, update_service: false },
      });
    } catch (e: any) {
      console.log('LOGGER', e.message);
      toast.error(e.message);
    }
    dispatch(LOADING_OFF());
  };

  useEffect(() => {
    getServices();
  }, []);

  const Actions = (payload: any) => {
    return (
      <div className="row">
        <div className="col-12">
          <button className="btn p-0 me-3 size-11 color-grey-menu" onClick={() => _toggleUpdateServiceModal(payload.data.PK)}>
            <i className="bi bi-pencil"></i>
          </button>
        </div>
      </div>
    );
  };

  const _toggleAddServiceModal = () => {
    setState({ ...state, modals: { ...state.modals, add_service: !state.modals.add_service }, inputs: {} });
  };
  const _toggleUpdateServiceModal = (edit_id = null) => {
    const service: any = state.services.find((item: any) => item.PK === edit_id);
    setState({
      ...state,
      modals: { ...state.modals, update_service: !state.modals.update_service },
      service_edited: edit_id,
      service: service,
      inputs: { ...state.inputs, ...(state.services.find((e: any) => e.id == edit_id) || {}) },
    });
  };

  return (
    <div className="container-fluid">
      <ModalComponent open={state.modals.add_service} handleClose={_toggleAddServiceModal} hideFooter>
        <FormDynamicService handleClose={getServices} action="create" recommended_subservice={state.recommended_subservice} />
      </ModalComponent>

      <ModalComponent open={state.modals.update_service} handleClose={_toggleUpdateServiceModal} hideFooter>
        <FormDynamicService handleClose={getServices} action="update" data={state.service} PK={state.service_edited} recommended_subservice={state.recommended_subservice} />
      </ModalComponent>

      <div className="row mt-4">
        <div className="col-12 d-flex justify-content-end text-white">
          <button className="btn-default" onClick={_toggleAddServiceModal}>
            <span className="me-2">+</span>
            Nuevo servicio
          </button>
        </div>
      </div>

      <div className="row rounded-05 mt-4 p-3" style={{ height: '70vh', overflow: 'auto' }}>
        <div className="col-12">
          <Table data={state.services} autoHeight rowClassName="striped" locale={{ emptyMessage: 'No se encontró ningún servicio' }}>
            {TableHeader &&
              TableHeader.map((column: any, index: any) => (
                <Table.Column align={column.align} flexGrow={column.flexGrow} key={`table-column-${index}`}>
                  <Table.HeaderCell>
                    <span style={{ textTransform: 'capitalize' }}>{column.label}</span>
                  </Table.HeaderCell>
                  <Table.Cell>
                    {(rowData) => {
                      switch (column.key) {
                        case 'scheduling':
                          return <>{rowData.scheduling === true || rowData.scheduling === 1 ? 'Si' : 'No'} </>;
                        case 'type':
                          return <>{state.categories.find((type: any) => type.value === rowData.type)?.label}</>;
                        case 'is_company':
                          return <>{rowData.info?.is_company === true || rowData.info?.is_company === 1 ? 'Empresa' : 'Normal'}</>;
                        default:
                          return <>{rowData[column.key]}</>;
                      }
                    }}
                  </Table.Cell>
                </Table.Column>
              ))}

            <Table.Column align="right" width={700} flexGrow={1} verticalAlign="middle">
              <Table.HeaderCell>{''}</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  return <Actions data={rowData} />;
                }}
              </Table.Cell>
            </Table.Column>
          </Table>
        </div>
        <div>
          <Pagination nextPage={nextPage} prevPage={prevPage} index={currentPage.index} />
        </div>
      </div>
    </div>
  );
};

export default Services;

import { getSession } from '../helpers/session';
import store from '../redux/store';
import instance from './api_admin'

class CouponDataService {

    _setHeaders = () => {
        const state: any = store.getState();
        const { session } = state;
    
        const oidcSession = getSession();
    
        if (oidcSession) {
          instance.defaults.headers.common['user'] = session.profile?.PK;
          instance.defaults.headers.common['usersk'] = session.profile?.SK;
          instance.defaults.headers.common['Authorization'] = `Bearer ${oidcSession?.access_token}`;
        }
      };

    create = async (params: any) => {
        this._setHeaders();
        try {
            const response = await instance.post('/admin/coupon', params);
            response.data = response.data.data
            return response;
        } catch (e: any) {
            return Promise.reject(e)
        }
    }
    list = async (params: any) => {
        this._setHeaders();
        try {
            const response = await instance.get(`/admin/coupon/list?limit=${params.limit}&campaign_id=${params.campaign}&setup=${params.setup}`);
            response.data = response.data.data
            return response;
        } catch (e: any) {
            return Promise.reject(e)
        }
    }
    update = async (campaign: any, params: any) => {
        this._setHeaders();
        try {
            const response = await instance.put(`/admin/coupon/update/${campaign}`, params);
            response.data = response.data.data
            return response;
        } catch (e: any) {
            return Promise.reject(e)
        }
    }

    search = async (params: any) => {
        this._setHeaders();
        try {
            const response = await instance.get(`/admin/coupon/search?campaign_id=${params.campaign}&code=${params.code}`);
            response.data = response.data.data
            return response;
        } catch (e: any) {
            return Promise.reject(e)
        }
    }
}

export default new CouponDataService();

/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { compare } from '../../helpers/orders';
import { LOADING_ON, LOADING_OFF } from '../../redux/actions/loader';
import ordersService from '../../services/orders.service';
import PaymentService from '../../services/payment.service';
import OrderLoading from '../OrderLoading';
import Title from '../Title';
import CarTable from './CarTable';
import ItemsComplement from './ItemsComplement';
import ItemsOrder from './ItemsOrder';

const AmountToCollect = (props: any) => {
  let amount: any = 0;
  if ((props.status === 'Programado' || props.status === 'Reagendado') && props.payment === 'Presencial') {
    amount = (props.state.total - props.state.amount_discount)?.toLocaleString('es-CL') ?? 0;
  }
  return <div className="col-12 text-end size-12 bold color-dark-blue mb-2">Monto a cobrar: $ {amount}</div>;
};

const DetailsOrder = (props: any) => {
  const { id, data } = props;

  const [state, setState] = useState<any>({
    order: {},
    total: 0,
    items_order: [],
    diff: 0,
    is_custom_order: false,
    original_amount: 0,
    payment: null,
    payment_types: [
      { label: 'WebPay Crédito', value: 3 },
      { label: 'WebPay Débito', value: 2 },
      { label: 'Presencial', value: 1 },
    ],
  });
  const dispatch = useDispatch();
  const { loader } = useSelector((state: any) => state);

  useEffect(() => {
    if (typeof id !== 'string') return;
    _getDetails(id);
  }, [id]);

  const _getDetails = async (id: any) => {
    try {
      dispatch(LOADING_ON());
      const order = data;
      const response = await ordersService.details({ PK: order.PK, SK: order.SK });
      const [payment_id, order_id] = order.PK?.split('-');
      const orderResponse = response.data;
      const [, order_status] = orderResponse.GS1PK?.split('-');
      orderResponse.status = order_status?.toLowerCase();

      let paymentResponse: any = {};
      let payment: any = {};

      try {
        paymentResponse = await PaymentService.getPayment(payment_id);
        payment = paymentResponse.data;
      } catch (e: any) {
        toast.error('No fue posible cargar los datos del pago.');
      }

      orderResponse.payment_id = payment_id;
      orderResponse.order_id = order_id;

      orderResponse.delivery_date = orderResponse.reservation
        ? moment(`${orderResponse.reservation?.date} ${orderResponse.reservation?.hour}`).toString()
        : moment(orderResponse.created_at).add(1, 'days').toString();

      const { items, complement } = orderResponse;
      let items_order: any = [];
      let total: any = 0;
      let amount_discount: any = 0;
      let is_custom_order: any = false;
      let original_amount: any = items;
      let diff: any = 0;
      let responseCompare: any = {};

      if (complement?.length > 0) {
        const complementedOrder: any = complement[0];
        const itemsNewOrder: any = complementedOrder.info?.items;

        responseCompare = compare(items, itemsNewOrder);

        is_custom_order = true;
        diff = complementedOrder.amount_diff;
      } else {
        items.forEach((item: any) => {
          const name: any = item.info?.name?.toLowerCase() === 'envío' ? 'Despacho' : item.info?.name;
          items_order.push({
            name: name,
            price: item.info?.unit_price,
            quantity_to_buy: item.info?.quantity_to_buy,
            canceled: false,
            item_id: item.PK,
            new_item_id: item.PK,
            amount_discount: item.info?.amount_discount || 0,
          });
        });
      }

      if (is_custom_order) {
        total = responseCompare.total;
      } else {
        total = items_order.reduce((acc: any, current: any) => {
          if (!current.canceled) {
            acc += current.price * current.quantity_to_buy;
          }
          return acc;
        }, 0);
      }

      amount_discount = orderResponse?.amount_off;

      original_amount = items.reduce((acc: any, current: any) => {
        acc += current.info?.unit_price * current.info?.quantity_to_buy;
        return acc;
      }, 0);

      const shippingItem: any = items_order.find((item: any) => item.name?.toLowerCase() === 'despacho');
      const NotshippingItem: any = items_order.filter((item: any) => item.name?.toLowerCase() !== 'despacho');
      if (shippingItem) {
        NotshippingItem.push(shippingItem);
      }

      NotshippingItem.sort((a: any, b: any) => {
        if (a.canceled > b.canceled) return 1;
        if (a.canceled < b.canceled) return -1;
        return 0;
      });

      dispatch(LOADING_OFF());
      
      setState({
        ...state,
        ...orderResponse,
        order: orderResponse,
        total,
        amount_discount,
        items_order: NotshippingItem,
        diff,
        is_custom_order,
        original_amount,
        complementOrders: responseCompare,
        payment: payment,
      });
    } catch (e: any) {
      dispatch(LOADING_OFF());
      toast.error('No fue posible cargar los datos de esta orden');
    }
  };

  return (
    <div className="component-services px-5 mb-4 size-09">
      {loader && loader.loading && <OrderLoading />}
      {loader && !loader.loading && (
        <>
          <div className="row">
            <Title size="md" text={`Pedido #${state.PK}`} className="bold to-upper" />
          </div>

          <hr />

          <div className="row ps-3">
            <div className="col-2 bold mb-2">Nombre</div>
            <div className="col-4 capitalize">{state.user?.name?.toLowerCase()}</div>
          </div>

          <div className="row ps-3">
            <div className="col-2 bold mb-2">Dirección</div>
            <div className="col-10">
              <a href={`https://waze.com/ul?ll=${state.order?.info?.direction?.latitude},${state.order?.info?.direction?.longitude}&z=10&navigate=yes`} target="_blank" rel='noreferrer'>
                {state.order?.info?.direction?._string}
              </a>
            </div>
          </div>

          <div className="row ps-3">
            {state.order?.user?.house_type === 1 && state.order?.info?.direction?.apartment && (
              <>
                <div className="col-2 bold mb-2">Casa/Departamento</div>
                <div className="col-4 capitalize">{state.order?.info?.direction?.apartment}</div>
              </>
            )}

            <div className="col-2 bold mb-2">Teléfono</div>
            <div className="col-4">
              <a href={`tel:${state.user?.phone_number_formated ? state.user?.phone_number_plain : state.user?.phone_plain}`}>
                {state.user?.phone_number_formated || state.user?.phone_formated || ''}
              </a>
            </div>
          </div>

          <div className="row ps-3">
            <div className="col-2 bold mb-2">Estado</div>
            <div className="col-4">{props.status?.find((item: any) => item.value?.toLowerCase() === state.order?.status)?.label}</div>
            <div className="col-2 bold mb-2">Número Orden</div>
            <div className="col-4">{state.PK}</div>
          </div>

          {state.order?.info?.cancel_reason ? (
            <div className="row ps-3">
              <div className="col-2 bold mb-2">Motivo</div>
              <div className="col-10">{state.order?.info?.cancel_reason}</div>
            </div>
          ) : null}

          <div className="row ps-3">
            <div className="col-2 bold mb-2">Número Pago</div>
            <div className="col-4">{state.payment_id}</div>
            <div className="col-2 bold mb-2">Fecha Pedido</div>
            <div className="col-4">{moment(state.created_at).format('DD/MM/Y')}</div>
          </div>

          <div className="row ps-3">
            <div className="col-2 bold mb-2">Fecha Entrega</div>
            <div className="col-4">{moment(state.delivery_date).format('DD/MM/Y HH:mm:ss')}  - {`${moment(state.delivery_date, 'HH:mm').add(1, 'hours').add(15, 'minutes').format('HH:mm')}`}</div>
            <div className="col-2 bold mb-2">Tipo Pago</div>
            {state.payment && <div className="col-4">{state.payment_types.find((item: any) => item.value === state.payment?.payment_type)?.label}</div>}
          </div>

          <div className="row ps-3">
            {state.order?.user?.house_type && state.order?.user?.house_type === 1 ? (
              <>
                <div className="col-2 bold mb-2">Tipo de vivienda</div>
                <div className="col-10">Casa</div>
              </>
            ) : null}

            {state.order?.user?.house_type && state.order?.user?.house_type === 2 ? (
              <>
                <div className="col-2 bold mb-2">Tipo de vivienda</div>
                <div className="col-10">Edificio, {state.order?.user?.parking}</div>
              </>
            ) : null}

            {state.order?.user?.house_type && state.order?.user?.house_type === 3 ? (
              <>
                <div className="col-2 bold mb-2">Tipo de vivienda</div>
                <div className="col-10">Otro</div>
              </>
            ) : null}

            {state.order?.info?.direction?.observations && (
              <>
                <div className="col-2 bold mb-2">Observaciones</div>
                <div className="col-10 capitalize mb-2">{state.order?.info?.direction?.observations}</div>
              </>
            )}

            {state.order?.order_accumulated_points && (
              <>
                <div className="col-2 bold mb-2">Puntos Full</div>
                <div className="col-10 capitalize mb-2">{state.order?.order_accumulated_points || 0}</div>
              </>
            )}
          </div>

          <hr />

          {state.is_custom_order ? <ItemsComplement data={state.complementOrders} /> : <ItemsOrder data={state.items_order} />}

          {state.info?.vehicle && (
            <>
              <hr />
              <CarTable
                data={[
                  {
                    plate: state.info?.vehicle?.plate.toUpperCase(),
                    brand: state.info?.vehicle?.brand.label,
                    model: state.info?.vehicle?.model.label,
                    year: state.info?.vehicle?.year,
                  },
                ]}
              />
            </>
          )}

          <div className="row mt-3 text-white">
            {state.amount_discount && state.amount_discount > 0 ? (
              <>
                <div className="col-12 text-end size-12 bold color-dark-blue mb-2">Subtotal: $ {state.total?.toLocaleString('es-CL')}</div>
                <div className="col-12 text-end size-12 bold color-dark-blue mb-2">Descuento: $ {state.amount_discount?.toLocaleString('es-CL')}</div>
              </>
            ) : (
              <div className="col-12 text-end size-12 bold color-dark-blue mb-2">Total: $ {state.total?.toLocaleString('es-CL')}</div>
            )}
            {state.is_custom_order ? (
              <>
                {state.payment?.payment_type === 1 ? (
                  <div className="col-12 text-end size-12 bold color-dark-blue">
                    <div className="col-12 text-end size-12 bold color-dark-blue mb-2">Monto a cobrar: $ {(state.total - state.amount_discount)?.toLocaleString('es-CL')}</div>
                  </div>
                ) : (
                  <div className="col-12 text-end size-12 bold color-dark-blue">
                    {Math.sign(state?.diff) === -1 ? 'Monto a cobrar' : 'Monto a reembolsar (Call center)'}: $ {state.diff < 0 ? (state.diff * -1)?.toLocaleString('es-CL') :state.diff?.toLocaleString('es-CL')}
                  </div>
                )}
              </>
            ) : (
              <AmountToCollect
                state={state}
                status={props.status?.find((item: any) => item.value?.toLowerCase() === state.order?.status)?.label}
                payment={state.payment_types.find((item: any) => item.value === state.payment?.payment_type)?.label}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default DetailsOrder;

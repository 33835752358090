/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Title from '../../components/Title';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import driverService from '../../services/driver.service';
import Cars from './Cars';
import Drivers from './Drivers';

const DriversEds = () => {
  const [state, setState] = useState<any>({
    tabs: 1,
    checks: {},
    drivers: [],
    services: [
      {
        label: 'Kerosene',
        value: 1,
      },
      {
        label: 'Lub',
        value: 2,
      },
      {
        label: 'Lavamax',
        value: 3,
      },
    ],
  });

  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    _getDetailsEds();
  }, []);

  const _getDetailsEds = async () => {
    dispatch(LOADING_ON());
    try {
      const searchParams = new URLSearchParams(history.location.search);
      const params: any = { PK: searchParams.get('PK'), SK: searchParams.get('SK') };
      const responseOperatorsList = await driverService.listDrivers(params);
      const operators: any = responseOperatorsList.data.map((item: any) => {
        item.user_PK = item.user?.PK;
        item.user_SK = item.user?.SK;
        delete item.user?.PK;
        delete item.user?.SK;
        return { ...item, ...item.user };
      });

      setState({
        ...state,
        drivers: operators || [],
      });
    } catch (e: any) {
      console.log('LOGGER', e);
      toast.error('No fue posible recuperar los operadores');
    }
    dispatch(LOADING_OFF());
  };

  return (
    <div className="container-fluid">
      <div className="row my-5 align-items-center">
        <div className="col-8">
          <Title size="sm" text={state.tabs === 1 ? 'Conductores' : 'Vehículos'} className="to-upper bold" />
        </div>
        {/* <div className="col-4">
          <SelectPicker classPrefix="copec" defaultValue="1" data={state.services} onChange={(e: any) => console.log('ERROR LOG', e.message)} style={{ width: '100%' }} placeholder="Servicios" locale={{ searchPlaceholder: 'Buscar', noResultsText: 'Ningún resultado encontrado.', emptyMessage: 'Ningún resultado encontrado.' }} />
        </div> */}
      </div>

      <div className="row px-3 mt-5">
        <div
          className={`col-3 copec-tab py-4 bold text-center size-12 ${state.tabs === 1 ? 'color-white bg-color-blue' : ''}`}
          onClick={() => setState({ ...state, tabs: 1 })}
          style={{ cursor: 'pointer' }}
        >
          Conductor
        </div>
        <div
          className={`col-3 copec-tab py-4 bold text-center size-12 ${state.tabs === 2 ? 'color-white bg-color-blue' : ''}`}
          onClick={() => setState({ ...state, tabs: 2 })}
          style={{ cursor: 'pointer' }}
        >
          Vehículos
        </div>
      </div>

      <div className="row border rounded-10 py-4 px-3">
        {state.tabs === 1 && <Drivers drivers={state.drivers} _getDetailsEds={_getDetailsEds} />}

        {state.tabs === 2 && <Cars drivers={state.drivers} _getDetailsEds={_getDetailsEds} />}
      </div>
    </div>
  );
};

export default DriversEds;

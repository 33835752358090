import axios from 'axios';
// import store from '../redux/store'; 

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_OVERT,
    headers: {
        'Content-type': 'application/json',
        'gux-api-key-overt': process.env.REACT_APP_API_OVERT || ''
    }
});
instance.interceptors.response.use(function (response: any) {
    return response;
}, function (error: any) {
    // if (error.response && error.response.status === 401) {
    //   const dispatch: any = store.dispatch;
    //   dispatch(signout())
    // }
    return Promise.reject(error);
});

export default instance;
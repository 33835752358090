import * as yup from 'yup';

export const DiscountHourSchema = yup.object().shape({
  service_id: yup.string().nullable().required('Este campo es requerido'),
  discount: yup.string().required('Este campo es requerido'),
  day_of_week: yup.string().nullable().required('Este campo es requerido'),
  start: yup.date().nullable().required('Este campo es requerido'),
  end: yup.date().nullable().required('Este campo es requerido'),
});

export type DiscountHourType = yup.InferType<typeof DiscountHourSchema>;

import TrashIcon from "@rsuite/icons/Trash";
import EditIcon from "@rsuite/icons/Edit";
export const _carSpeaker = (payload: any, _handleActions: any) => {
  return {
    menu: [
      {
        label: "Editar",
        Icon: EditIcon,
        show: true,
        eventKey: 1,
      },
      {
        label: "Eliminar",
        Icon: TrashIcon,
        show: true,
        eventKey: 2,
      },
    ],
    _handleSelect: (eventKey: any) => {
      _handleActions(eventKey, payload);
    },
  };
};

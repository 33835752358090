import { Table } from "rsuite";
import { TableHeader } from "./tableConfigs";
import WhisperComponent from "../../components/WhisperComponent";
import ParagraphIcon from '@rsuite/icons/Paragraph';

const CompanyTable = (props: any) => {
    const { data, handleUpdate, eds, services, users, handleView, loading } = props

    const Actions = (props: any) => {
        const { payload } = props;
        return (
            <div className="d-flex">
                <WhisperComponent text={'Detalles'}>
                    <button className="btn p-0 m-0" onClick={() => handleView(payload)}>
                        <ParagraphIcon />
                    </button>
                </WhisperComponent>

                <WhisperComponent text="Editar">
                    <button className="btn p-0 m-0" onClick={() => handleUpdate(payload)}>
                        <i className="bi bi-pencil"></i>
                    </button>
                </WhisperComponent>

            </div>
        );
    };

    const getUserData = (id: any) => {
        const user = users.find((user: any) => user.PK === id);
        return user ? `${user.name} ${user.family_name}` : '';
    }

    const getServiceData = (ids: any) => {
        const servicesData = services.filter((service: any) => ids.includes(service.PK));
        return servicesData.map((item: any) => item.name).join(", ")
    }

    const getEdsData = (id: any) => {
        const edsData = eds.find((item: any) => item.PK === id);
        return edsData ? `${edsData.name}` : '';
    }

    return (
        <div className="row border rounded-10 p-3 my-5">
            <div className="col-12">
                <Table data={data} autoHeight rowHeight={80} locale={{ emptyMessage: 'No hay empresas' }} loading={loading}>
                    {TableHeader &&
                        TableHeader.map((column: any, index: any) => (
                            <Table.Column align={column.align} flexGrow={column.flexGrow} key={`table-column-${index}`}>
                                <Table.HeaderCell>
                                    <span className="bold" style={{ textTransform: 'capitalize' }}>
                                        {column.label}
                                    </span>
                                </Table.HeaderCell>
                                <Table.Cell style={{ display: 'flex', alignItems: 'center', justifyContent: column.alignFlex }}>
                                    {(rowData) => {
                                        switch (column.key) {
                                            case 'name':
                                                return (
                                                    <WhisperComponent text={`${rowData?.name}`} capitalizeOff align='center'>
                                                        <span className="size-08">{`${rowData?.name}`}</span>
                                                    </WhisperComponent>
                                                );
                                            case 'eds':
                                                return (
                                                    <WhisperComponent text={getEdsData(rowData.GS1SK)} capitalizeOff align='center'>
                                                        <span className="size-08">{`${getEdsData(rowData.GS1SK)}`}</span>
                                                    </WhisperComponent>
                                                );
                                            case 'company_admin':
                                                return (
                                                    <WhisperComponent text={getUserData(rowData.company_admin)} capitalizeOff align='left'>
                                                        <span className="size-08">{`${getUserData(rowData.company_admin)}`}</span>
                                                    </WhisperComponent>
                                                );
                                            case 'services':
                                                return (
                                                    <WhisperComponent text={getServiceData(rowData.services)}>
                                                        <span className="size-08">{getServiceData(rowData.services)}</span>
                                                    </WhisperComponent>
                                                );
                                            default:
                                                return <span>{rowData[column.key]}</span>;
                                        }
                                    }}
                                </Table.Cell>
                            </Table.Column>

                        ))}
                    <Table.Column align="right" flexGrow={1} verticalAlign="middle">
                        <Table.HeaderCell>{''}</Table.HeaderCell>
                        <Table.Cell>
                            {(rowData) => {
                                return <Actions payload={rowData} />;
                            }}
                        </Table.Cell>
                    </Table.Column>
                </Table>
            </div>
        </div >
    )
}

export default CompanyTable;